import { Avatar, Badge, Box, Grid } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { SectionHeader } from 'components/sections/header';
import {
  IQuickSessionContext,
  QuickSessionStep,
} from 'contexts/quick-session.context';
import { t } from 'i18next';
import { IDisplayCol } from 'interfaces/i-tables';
import {
  PitcherHand,
  PitcherRelease,
  PlayerLevel,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import React from 'react';

const COMPONENT_NAME = 'QuickSessionSelectCard';

interface IProps {
  quickCx: IQuickSessionContext;
}

interface IStateFilters {
  filterNames: string[];
  filterRelease?: PitcherRelease;
  filterLevel?: PlayerLevel;
  filterHand?: PitcherHand;
}

const DEFAULT_FILTERS: IStateFilters = {
  filterNames: [],
};

interface IState extends IStateFilters {}

export class SelectCard extends React.Component<IProps, IState> {
  private filterName?: CommonSearchInput;
  private filterSlot?: CommonSelectInput;
  private filterLevel?: CommonSelectInput;
  private filterHand?: CommonSelectInput;

  constructor(props: IProps) {
    super(props);

    this.state = {
      ...DEFAULT_FILTERS,
    };

    this.renderToolbar = this.renderToolbar.bind(this);
  }

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: '',
      key: '_avatar',
      thClassNameFn: () => 'width-60px',
      align: 'center',
      formatFn: (m: IPitchList) => (
        <Avatar
          mt="2"
          mb="2"
          src={this.props.quickCx.avatarURLs[m.card?.avatar_path ?? '']?.url}
          fallback={PitchListHelper.getAvatarFallback(m)}
        />
      ),
      disableSort: true,
    },
    {
      label: 'Pitcher',
      key: '_name',
      formatFn: (m: IPitchList) => m.card?.name ?? '???',
      sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) =>
        (b.card?.name ?? '').localeCompare(a.card?.name ?? '') * dir,
    },
    {
      label: 'common.hand',
      key: '_hand',
      align: 'center',
      formatFn: (m: IPitchList) => {
        const isLeft = m.card?.hand === PitcherHand.LHP;
        return (
          <Badge color={isLeft ? RADIX.COLOR.LEFT : RADIX.COLOR.RIGHT}>
            {t(isLeft ? 'common.lhp' : 'common.rhp')}
          </Badge>
        );
      },
      sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) =>
        (b.card?.hand ?? '').localeCompare(a.card?.hand ?? '') * dir,
    },
    {
      label: 'Slot',
      key: '_slot',
      formatFn: (m: IPitchList) =>
        PitchListHelper.shortPitcherRelease(m.card?.release),
      sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) =>
        (b.card?.release ?? '').localeCompare(a.card?.release ?? '') * dir,
    },
    {
      label: 'Level',
      key: '_level',
      formatFn: (m: IPitchList) => m.card?.level ?? '',
      sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) => {
        const aIndex = Object.values(PlayerLevel).findIndex(
          (l) => l === a.card?.level
        );
        const bIndex = Object.values(PlayerLevel).findIndex(
          (l) => l === b.card?.level
        );
        return (aIndex > bIndex ? 1 : -1) * dir;
      },
    },
  ];

  render() {
    const filteredData = this.props.quickCx.cards
      .filter(
        (c) =>
          this.state.filterNames.length === 0 ||
          this.state.filterNames.includes(c.card?.name ?? '')
      )
      .filter(
        (c) =>
          !this.state.filterLevel || c.card?.level === this.state.filterLevel
      )
      .filter(
        (c) => !this.state.filterHand || c.card?.hand === this.state.filterHand
      )
      .filter(
        (c) =>
          !this.state.filterRelease ||
          c.card?.release === this.state.filterRelease
      );

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={
            <>
              <SectionHeader header={t('qs.select-a-player')} />

              {this.props.quickCx.cards.length === 0 && (
                <CommonCallout text="qs.msg-support-add-cards" />
              )}

              {this.props.quickCx.cards.length > 0 &&
                filteredData.length === 0 && (
                  <CommonCallout text="qs.msg-no-cards-found" />
                )}
            </>
          }
          table={
            <CommonTable
              id={COMPONENT_NAME}
              displayColumns={this.BASE_COLUMNS}
              displayData={filteredData}
              blockSelection={this.props.quickCx.loading}
              toolbarContent={this.renderToolbar()}
              afterSelectRow={async (config: { model: IPitchList }) => {
                NotifyHelper.success({
                  message_md: `Loading pitches, please wait...`,
                });

                this.props.quickCx
                  .selectCard(config.model._id)
                  .then(() =>
                    this.props.quickCx.setStep(QuickSessionStep.SelectPitch)
                  );
              }}
              enableSort
              vFlex
            />
          }
        />
      </ErrorBoundary>
    );
  }

  private renderToolbar() {
    return (
      <Grid columns="5" gap={RADIX.FLEX.GAP.SM}>
        <Box>
          <CommonSearchInput
            ref={(elem) => (this.filterName = elem as CommonSearchInput)}
            id="filter-names"
            placeholder="common.name"
            options={this.props.quickCx.options.names.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterNames}
            onChange={(v) => {
              this.setState({ filterNames: v });
            }}
            multiple
            optional
          />
        </Box>

        <CommonSelectInput
          ref={(elem) => (this.filterSlot = elem as CommonSelectInput)}
          id="card-slot"
          placeholder="common.slot"
          name="filter-slot"
          options={Object.values(PitcherRelease).map((m) => ({
            label: m,
            value: m,
          }))}
          value={this.state.filterRelease}
          onChange={(v) =>
            this.setState({ filterRelease: v as PitcherRelease })
          }
          optional
        />

        <CommonSelectInput
          ref={(elem) => (this.filterLevel = elem as CommonSelectInput)}
          id="card-level"
          placeholder="common.level"
          name="filter-level"
          options={Object.values(PlayerLevel).map((m) => ({
            label: m,
            value: m,
          }))}
          value={this.state.filterLevel}
          onChange={(v) => this.setState({ filterLevel: v as PlayerLevel })}
          optional
          skipSort
        />

        <CommonSelectInput
          ref={(elem) => (this.filterHand = elem as CommonSelectInput)}
          id="card-hand"
          placeholder="common.hand"
          name="filter-hand"
          options={Object.values(PitcherHand).map((m) => ({
            label: m,
            value: m,
          }))}
          value={this.state.filterHand}
          onChange={(v) => this.setState({ filterHand: v as PitcherHand })}
          optional
        />

        <CommonTableButton
          label="common.reset"
          color={RADIX.COLOR.NEUTRAL}
          onClick={() => {
            this.setState(
              {
                ...DEFAULT_FILTERS,
              },
              () => {
                this.filterName?.reset();
                this.filterSlot?.reset(true);
                this.filterLevel?.reset(true);
                this.filterHand?.reset(true);
              }
            );
          }}
        />
      </Grid>
    );
  }
}
