import { IHitter } from 'lib_ts/interfaces/i-hitter';
import { BaseRESTService } from 'services/_base-rest.service';

export class HittersService extends BaseRESTService {
  private static instance: HittersService;
  static getInstance(): HittersService {
    if (!HittersService.instance) {
      HittersService.instance = new HittersService();
    }

    return HittersService.instance;
  }

  private constructor() {
    super({
      controller: 'hitters',
    });
  }

  /** get list of user's hitters */
  async getHitters(): Promise<IHitter[]> {
    return await this.get({
      uri: 'list',
    });
  }

  /** use post to allow deleting multiple at once */
  async deleteHitters(ids: string[]): Promise<boolean> {
    return await this.post(
      {
        uri: 'delete',
      },
      ids
    ).then((result) => !!result && result.success);
  }

  async postHitter(data: Partial<IHitter>): Promise<IHitter> {
    /** never save _checked value */
    delete data._checked;

    return await this.post(
      {
        uri: 'create',
      },
      data
    );
  }

  async updateHitter(data: Partial<IHitter>): Promise<IHitter> {
    /** never save _checked value */
    delete data._checked;

    if (!data._id) {
      throw new Error('Cannot put without id');
    }
    return await this.put(
      {
        uri: '',
        params: {
          hitter_id: data._id,
        } as any,
      },
      data
    );
  }
}
