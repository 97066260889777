import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { AccessTab } from 'components/common/pitch-lists/manage-card/access-tab';
import { DetailsTab } from 'components/common/pitch-lists/manage-card/details-tab';
import { CommonTabs } from 'components/common/tabs';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches/i-pitch-list';
import React from 'react';

const COMPONENT_NAME = 'ManageCardDialog';

export const getVisibilityBlurb = (
  parent?: string,
  machineID?: string,
  multiple?: boolean
) => {
  switch (parent) {
    case 'teams': {
      return `${
        multiple ? 'These lists' : 'This list'
      } will be accessible to all users of your team.`;
    }

    case 'team-machines': {
      return `${
        multiple ? 'These lists' : 'This list'
      } will only be accessible to users connected to ${
        machineID || 'the machine'
      }.`;
    }

    case 'team-users': {
      return `${
        multiple ? 'These lists' : 'This list'
      } will only be accessible to you.`;
    }

    default: {
      return;
    }
  }
};

enum TabKey {
  Details = 'Details',
  Access = 'Access',
}

interface IProps extends IBaseDialog {
  listID: string;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  listsCx: IPitchListsContext;
}

interface IState {
  activeTab: TabKey;

  list: IPitchList;
  loading: boolean;
}

/** used for creating, copying, and editing pitch lists */
export class ManageCardDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const list = props.listsCx.lists.find((l) => l._id === props.listID);

    if (!list) {
      throw new Error(`Could not find list ${props.listID} in lists context!`);
    }

    /** load the current values from list context */
    this.state = {
      loading: false,
      list: list,
      activeTab: TabKey.Details,
    };
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.LG}
          title="Manage Player Card"
          content={
            <CommonTabs
              value={this.state.activeTab}
              onValueChange={(value) => {
                this.setState({ activeTab: value as TabKey });
              }}
              tabs={[
                {
                  value: TabKey.Details,
                  label: 'Details',
                  content: (
                    <DetailsTab
                      list={this.state.list}
                      listsCx={this.props.listsCx}
                    />
                  ),
                },
                {
                  value: TabKey.Access,
                  label: 'Access',
                  content: (
                    <AccessTab
                      list_id={this.state.list._id}
                      authCx={this.props.authCx}
                    />
                  ),
                },
              ]}
            />
          }
          loading={this.state.loading}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
