import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Text } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { CommonLink } from 'components/common/link';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';

interface IProps {
  url: string;
}

export const HelpCallout = (props: IProps) => (
  <CommonCallout
    icon={<InfoCircledIcon />}
    color={RADIX.COLOR.INFO}
    content={
      <Text>
        {t('common.feeling-lost')}{' '}
        <CommonLink url={props.url}>{t('common.click-here')}</CommonLink>{' '}
        {t('common.for-helpful-tips-msg')}{' '}
      </Text>
    }
  />
);
