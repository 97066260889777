import { Box } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { getAdjustmentsOptions, getSampleSizes } from 'enums/training.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { DEFAULT_PRESET_TRAINING_SPEC } from 'lib_ts/enums/training.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IPresetTrainingSpec } from 'lib_ts/interfaces/training/i-preset-training-spec';
import React from 'react';

interface IProps {
  spec: IPresetTrainingSpec | undefined;
}

interface IState {
  loading?: boolean;
  spec: IPresetTrainingSpec;

  sampleSizeOptions: IOption[];
  adjustmentsOptions: IOption[];
}

export class PresetTrainingSpecTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    /** make a copy to manipulate via forms */
    this.state = {
      spec: props.spec ?? DEFAULT_PRESET_TRAINING_SPEC,
      sampleSizeOptions: getSampleSizes(3),
      adjustmentsOptions: getAdjustmentsOptions(),
    };

    this.getSpec = this.getSpec.bind(this);
    this.setSpec = this.setSpec.bind(this);
  }

  getSpec() {
    return this.state.spec;
  }

  private setSpec(spec: Partial<IPresetTrainingSpec>) {
    this.setState({
      spec: {
        ...this.state.spec,
        ...spec,
      },
    });
  }

  render() {
    return (
      <CommonFormGrid columns={3}>
        <Box gridColumn="span 3">
          <CommonCallout
            color={RADIX.COLOR.INFO}
            text_md="Use the form below to specify the **default values** used for precision training."
          />
        </Box>
        <CommonSelectInput
          id="precision-train-samples"
          name="sampleSize"
          label="Samples"
          options={this.state.sampleSizeOptions}
          value={this.state.spec.sampleSize.toString()}
          onNumericChange={(v) => {
            if (v <= 0) {
              return;
            }

            this.setSpec({
              sampleSize: v,
            });
          }}
        />
        <CommonSelectInput
          id="precision-train-iterations"
          name="iterations"
          label="Max Machine Adjustments"
          options={this.state.adjustmentsOptions}
          value={this.state.spec.iterations.toString()}
          onNumericChange={(v) => {
            if (v <= 0) {
              return;
            }

            this.setSpec({
              iterations: v,
            });
          }}
        />
        <Box gridColumn="1">
          <CommonTextInput
            id="precision-train-max-speed"
            label="Max Speed Delta (mph)"
            type="number"
            name="deltaSpeedMPH"
            title="Provide a positive number"
            value={this.state.spec.deltaSpeedMPH.toString()}
            onNumericChange={(v) => {
              this.setSpec({
                deltaSpeedMPH: Math.abs(v),
              });
            }}
          />
        </Box>
        <CommonTextInput
          id="precision-train-max-breaks"
          label="Max Breaks Delta (in)"
          type="number"
          name="deltaBreaksInches"
          title="Provide a positive number"
          value={this.state.spec.deltaBreaksInches.toString()}
          onNumericChange={(v) => {
            this.setSpec({
              deltaBreaksInches: Math.abs(v),
            });
          }}
        />
        <CommonTextInput
          id="precision-train-max-spin"
          label="Max Spins Delta (rpm)"
          type="number"
          name="deltaSpinsRPM"
          title="Provide a positive number"
          value={this.state.spec.deltaSpinsRPM.toString()}
          onNumericChange={(v) => {
            this.setSpec({
              deltaSpinsRPM: Math.abs(v),
            });
          }}
        />
      </CommonFormGrid>
    );
  }
}
