import {
  ClockIcon,
  MixerHorizontalIcon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, Grid, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SettingsSection } from 'components/common/settings-dialog';
import { IAuthContext } from 'contexts/auth.context';
import { ISectionsContext } from 'contexts/sections.context';
import { ISessionEventsContext } from 'contexts/session-events.context';
import { SectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { GAME_STATUS_DISABLE_ACTION_MSG } from 'lib_ts/enums/mlb.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ICombinedPermissions } from 'lib_ts/interfaces/i-permissions';
import React from 'react';
import { IntercomContextValues, useIntercom } from 'react-use-intercom';
import './index.scss';

const BLOCK_CLASS_NAME = 'MainMenuSection rounded-lg cursor-pointer';

interface ISectionDef {
  section: SectionName;
  header: string;
  invisible: boolean;
  /** if non-empty, clicking will show a notification with the msg inside instead of doing anything */
  disabledMsg?: string;
  background: string;
}

interface ISubSectionDef {
  header: string;
  icon: JSX.Element;
  invisible: boolean;
  /** if non-empty, clicking will show a notification with the msg inside instead of doing anything */
  disabledMsg?: string;
  onClick: () => void;
}

interface IBaseProps {
  authCx: IAuthContext;
  sectionsCx: ISectionsContext;
  sessionsCx: ISessionEventsContext;
}

interface IProps extends IBaseProps {
  intercom: IntercomContextValues;
}

interface IState {
  restricted: boolean;
  sections: ISectionDef[];
  subSections: ISubSectionDef[];
}

// wrapper to get intercom as a prop
export const Home = (props: IBaseProps) => {
  const intercom = useIntercom();
  return <Helper {...props} intercom={intercom} />;
};

class Helper extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      restricted: false,
      sections: [],
      subSections: [],
    };

    this.getSections = this.getSections.bind(this);
    this.getSubSections = this.getSubSections.bind(this);
    this.refreshSections = this.refreshSections.bind(this);
  }

  componentDidMount(): void {
    if (!this.init) {
      this.init = true;
      this.refreshSections();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      this.state.restricted !== this.props.authCx.restrictedGameStatus() ||
      this.props.authCx.current.session !== prevProps.authCx.current.session
    ) {
      this.refreshSections();
    }
  }

  private refreshSections() {
    const restricted = this.props.authCx.restrictedGameStatus();
    this.setState({
      restricted: restricted,
      sections: this.getSections(restricted, this.props.authCx.current),
      subSections: this.getSubSections(restricted),
    });
  }

  private getSections(
    restricted: boolean,
    permissions: ICombinedPermissions
  ): ISectionDef[] {
    return [
      {
        header: 'common.pitch-upload',
        section: SectionName.BulkPitchUpload,
        invisible: false,
        disabledMsg: restricted ? GAME_STATUS_DISABLE_ACTION_MSG : undefined,
        background: '/img/main-menu/pitch-upload.jpg',
      },
      {
        header: 'common.quick-session',
        section: SectionName.QuickSession,
        invisible: !permissions.quick_session,
        background: '/img/main-menu/quick-session.jpg',
      },
      {
        header: 'common.video-library',
        section: SectionName.VideoLibrary,
        invisible: false,
        disabledMsg: restricted ? GAME_STATUS_DISABLE_ACTION_MSG : undefined,
        background: '/img/main-menu/video-library.jpg',
      },
      {
        header: 'main.pitch-design',
        section: SectionName.PitchDesign,
        invisible: false,
        disabledMsg: restricted ? GAME_STATUS_DISABLE_ACTION_MSG : undefined,
        background: '/img/main-menu/pitch-design.jpg',
      },
    ];
  }

  private getSubSections(restricted: boolean): ISubSectionDef[] {
    return [
      {
        header: 'common.settings',
        icon: (
          <MixerHorizontalIcon
            style={{
              width: '40px',
              height: '40px',
            }}
          />
        ),
        invisible: false,
        onClick: () => {
          this.props.sessionsCx.setSettingsDialog({
            initialSection: SettingsSection.Machine,
          });
        },
      },
      {
        header: 'common.help-center',
        icon: (
          <QuestionMarkCircledIcon
            style={{
              width: '40px',
              height: '40px',
            }}
          />
        ),
        invisible: false,
        onClick: () => this.props.intercom.showNewMessage(),
      },
      {
        header: 'common.session-history',
        icon: (
          <ClockIcon
            style={{
              width: '40px',
              height: '40px',
            }}
          />
        ),
        invisible: false,
        disabledMsg: restricted ? GAME_STATUS_DISABLE_ACTION_MSG : undefined,
        onClick: () => {
          this.props.sectionsCx.tryChangeSection({
            trigger: 'main menu',
            name: SectionName.SessionHistory,
          });
        },
      },
    ];
  }

  render() {
    const visibleSections = this.state.sections.filter((s) => !s.invisible);
    const visibleSubSections = this.state.subSections.filter(
      (s) => !s.invisible
    );

    return (
      <ErrorBoundary componentName="MainMenu">
        <Box className="MainMenu">
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            <Grid
              columns={{
                initial: '1',
                md: visibleSections.length.toString(),
              }}
              gap={RADIX.FLEX.GAP.MD}
            >
              {visibleSections.map((section, i) => (
                <Flex
                  key={`main-section-${i}`}
                  data-identifier="MainSection"
                  direction="column"
                  gap={RADIX.FLEX.GAP.SM}
                  justify="center"
                  style={{ backgroundImage: `url(${section.background})` }}
                  className={BLOCK_CLASS_NAME}
                  height="300px"
                  align="center"
                  onClick={() => {
                    if (section.disabledMsg) {
                      NotifyHelper.warning({
                        message_md: section.disabledMsg,
                      });
                      return;
                    }

                    this.props.sectionsCx.tryChangeSection({
                      trigger: 'main menu',
                      name: section.section,
                    });
                  }}
                >
                  <Heading>{t(section.header)}</Heading>
                </Flex>
              ))}
            </Grid>

            <Grid
              columns={{
                initial: '1',
                md: visibleSubSections.length.toString(),
              }}
              gap={RADIX.FLEX.GAP.MD}
            >
              {visibleSubSections.map((subSection, i) => (
                <Box
                  key={`sub-section-${i}`}
                  data-identifier="SubSection"
                  className={BLOCK_CLASS_NAME}
                  height="200px"
                  onClick={() => {
                    if (subSection.disabledMsg) {
                      NotifyHelper.warning({
                        message_md: subSection.disabledMsg,
                      });
                      return;
                    }

                    subSection.onClick();
                  }}
                >
                  <Flex
                    direction="column"
                    gap={RADIX.FLEX.GAP.SM}
                    style={{
                      height: '100%',
                      textAlign: 'center',
                    }}
                    justify="center"
                  >
                    <Box>{subSection.icon}</Box>
                    <Heading>{t(subSection.header)}</Heading>
                  </Flex>
                </Box>
              ))}
            </Grid>
          </Flex>
        </Box>
      </ErrorBoundary>
    );
  }
}
