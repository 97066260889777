import { Badge, Box, Flex, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { AdminIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SettingsSection } from 'components/common/settings-dialog/';
import { MachineFiringTab } from 'components/common/settings-dialog//machine-tabs/firing.tab';
import { MachineGeneralTab } from 'components/common/settings-dialog//machine-tabs/general.tab';
import { MachineMaintenanceTab } from 'components/common/settings-dialog/machine-tabs/maintenance.tab';
import { MachineProjectorTab } from 'components/common/settings-dialog/machine-tabs/projector.tab';
import { MachineSwitchTab } from 'components/common/settings-dialog/machine-tabs/switch.tab';
import { MachineTrackingDeviceTab } from 'components/common/settings-dialog/machine-tabs/tracking-device.tab';
import { CommonTabs } from 'components/common/tabs';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { ISectionsContext } from 'contexts/sections.context';
import { t } from 'i18next';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';

const COMPONENT_NAME = 'MachineTab';

export const ACTIVE_ALERT = (
  <CommonCallout text={t('settings.inactive-warning-msg').toString()} />
);

export enum MachineSettingsTabKey {
  General = 'General',
  Projector = 'Projector',
  Firing = 'Firing',
  TrackingDevice = 'Tracking Device',
  Maintenance = 'Maintenance',
  Switch = 'Switch Machine',
}

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  sectionsCx: ISectionsContext;
  machineCx: IMachineContext;

  initialTab?: MachineSettingsTabKey;

  onClose: () => void;
}

interface IState {
  activeTab: MachineSettingsTabKey;
}

export class MachineSection extends React.Component<IProps, IState> {
  private saveTimeout?: any;
  private savePayload?: Partial<IMachine>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: props.initialTab ?? MachineSettingsTabKey.General,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  private async handleSave(payload: Partial<IMachine>) {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    // merge the latest payload with any existing payload, for when multiple attributes change before timeout resolves
    this.savePayload = {
      ...this.savePayload,
      ...payload,
    };

    this.saveTimeout = setTimeout(async () => {
      clearTimeout(this.saveTimeout);

      if (!this.savePayload) {
        console.warn(`${COMPONENT_NAME}: triggered save without a payload`);
        return;
      }

      const prevBallType = this.props.machineCx.machine.ball_type;
      const success = await this.props.machineCx.update(this.savePayload);

      if (!success) {
        console.warn(`${COMPONENT_NAME}: update failed`);
        return;
      }

      /** if ball type was changed, show a warning after a brief delay */
      const nextBallType = this.savePayload.ball_type;
      if (nextBallType && nextBallType !== prevBallType) {
        NotifyHelper.warning({
          message_md: t('settings.change-ball-type-msg', { x: nextBallType }),
          delay_ms: 0,
          inbox: true,
          buttons: [
            {
              label: 'common.dismiss',
              onClick: () => {
                // nothing
              },
              dismissAfterClick: true,
            },
          ],
        });
      }

      // reset the payload
      this.savePayload = undefined;
    }, 1_000);
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Box>
          <Flex gap={RADIX.FLEX.GAP.SM}>
            <Heading size={RADIX.HEADING.SIZE.MD}>
              {SettingsSection.Machine}
            </Heading>
            <Badge color={RADIX.COLOR.WARNING}>
              {this.props.machineCx.machine.machineID}
            </Badge>
          </Flex>

          <CommonTabs
            value={this.state.activeTab}
            onValueChange={(value) => {
              this.setState({ activeTab: value as MachineSettingsTabKey });
            }}
            tabs={[
              {
                value: MachineSettingsTabKey.General,
                label: 'settings.general',
                content: (
                  <MachineGeneralTab
                    cookiesCx={this.props.cookiesCx}
                    authCx={this.props.authCx}
                    machineCx={this.props.machineCx}
                    onSave={this.handleSave}
                    onClose={() => this.props.onClose()}
                  />
                ),
              },
              {
                value: MachineSettingsTabKey.Projector,
                label: 'settings.projector',
                content: (
                  <MachineProjectorTab
                    machineCx={this.props.machineCx}
                    onSave={this.handleSave}
                  />
                ),
              },
              {
                value: MachineSettingsTabKey.Firing,
                label: 'settings.firing',
                content: <MachineFiringTab />,
              },
              {
                value: MachineSettingsTabKey.TrackingDevice,
                label: 'settings.tracking-device',
                content: (
                  <MachineTrackingDeviceTab
                    authCx={this.props.authCx}
                    machineCx={this.props.machineCx}
                    onSave={this.handleSave}
                  />
                ),
              },
              {
                value: MachineSettingsTabKey.Maintenance,
                label: 'settings.maintenance',
                content: (
                  <MachineMaintenanceTab
                    authCx={this.props.authCx}
                    machineCx={this.props.machineCx}
                  />
                ),
              },
              {
                value: MachineSettingsTabKey.Switch,
                label: 'settings.switch-machine',
                labelIcon: <AdminIcon />,
                content: (
                  <MachineSwitchTab
                    authCx={this.props.authCx}
                    machineCx={this.props.machineCx}
                    onClose={() => this.props.onClose()}
                  />
                ),
                invisible: ![UserRole.admin, UserRole.team_admin].includes(
                  this.props.authCx.current.role
                ),
              },
            ]}
          />
        </Box>
      </ErrorBoundary>
    );
  }
}
