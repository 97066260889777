import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { addMilliseconds } from 'date-fns';
import {
  IAimingBaseResult,
  IAimingRequest,
  IAimingResult,
} from 'interfaces/i-pitch-aiming';
import { ISendConfig } from 'interfaces/i-pitch-list';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMergedMSDict, getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { DefaultVideoID } from 'lib_ts/enums/machine-msg.enum';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IMachineStateMsg } from 'lib_ts/interfaces/i-machine-msg';
import { DEFAULT_PLATE, IPitch, IPlateLoc } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';

const CONTEXT_NAME = 'AimingContext';
const CHECK_ASSERTS = false;

export interface IAimingContext {
  pitch: IPitch | undefined;
  setPitch: (
    pitch: IPitch | undefined,
    forceLoadShots?: boolean
  ) => Promise<void>;

  // for plate view to reference
  plate: IPlateLoc;
  setPlate: (loc: IPlateLoc) => void;

  getAimed: (config: Partial<IAimingRequest>) => IAimingResult | undefined;

  sendToMachine: (config: ISendConfig) => Promise<void>;

  updateMatches: (limit?: number) => Promise<IMachineShot[] | undefined>;

  checkRequireSend: () => boolean;

  // set this to slightly in the future when sending to machine so fire button immediately shows loading until after
  loadingUntil: Date;
}

const DEFAULT: IAimingContext = {
  pitch: undefined,
  setPitch: () => new Promise(() => console.debug('not init')),

  plate: DEFAULT_PLATE,
  setPlate: () => console.debug('not init'),

  getAimed: () => undefined,

  sendToMachine: () => new Promise(() => console.debug('not init')),

  updateMatches: () => new Promise(() => []),

  checkRequireSend: () => true,

  loadingUntil: new Date(),
};

export const AimingContext = createContext(DEFAULT);

interface IProps {
  machineCx: IMachineContext;
  // provided when aiming with shots is required
  matchingCx?: IMatchingShotsContext;
  // provided when fallback to default videos is required
  videosCx?: IVideosContext;

  // when provided, every shot update query will include the value for newerThan (e.g. when training)
  newerThan?: string;

  children: ReactNode;
}

export const AimingProvider: FC<IProps> = (props) => {
  const [_pitch, _setPitch] = useState(DEFAULT.pitch);
  const [_plate, _setPlate] = useState<IPlateLoc>(DEFAULT.plate);

  const [_sentHash, _setSentHash] = useState<string | undefined>();
  const [_loadingUntil, _setLoadingUntil] = useState(DEFAULT.loadingUntil);

  // bare minimum with pitch and ms aimed at target
  const _getAimedBase = (
    config: IAimingRequest
  ): IAimingBaseResult | undefined => {
    const FN_NAME = `${CONTEXT_NAME} > _getAimedBase`;

    if (!_pitch) {
      return;
    }

    const ms = getMSFromMSDict(_pitch, props.machineCx.machine).ms;
    if (!ms) {
      console.warn(`${FN_NAME}: no ms found`);
      return;
    }

    if (!_pitch.bs || !ms || !_pitch.traj) {
      /** should not trigger, but will be filtered out to be safe */
      console.warn({
        event: `${FN_NAME}: cannot rotate pitch, invalid data detected`,
        pitch: _pitch,
        invalid_bs: !_pitch.bs,
        invalid_ms: !ms,
        invalid_traj: !_pitch.traj,
      });

      NotifyHelper.warning({
        message_md: `Data for pitch "${_pitch.name}" is invalid. Please see console for more info.`,
        delay_ms: 0,
      });

      return;
    }

    const chars = AimingHelper.aimWithShots({
      source: FN_NAME,
      chars: {
        bs: _pitch.bs,
        ms: ms,
        traj: _pitch.traj,

        seams: _pitch.seams,
        breaks: _pitch.breaks,
        priority: _pitch.priority ?? BuildPriority.Spins,
      },
      release: _pitch.bs,
      plate_location: _plate,
      plate_distance: props.machineCx.machine.plate_distance,
      shots: config.usingShots,
      stepSize: config?.stepSize,
    });

    const plate = TrajHelper.getPlateLoc(chars.traj);

    if (CHECK_ASSERTS) {
      AimingHelper.checkAssertions({
        chars: chars,
        plate: plate,
        refPlate: _plate,
      });
    }

    const result: IPitch = {
      ..._pitch,
      bs: chars.bs,
      traj: chars.traj,
      msDict: getMergedMSDict(
        props.machineCx.machine,
        [chars.ms],
        _pitch.msDict
      ),
    };

    return {
      pitch: result,
      ms: chars.ms,
      usingShots: config.usingShots,
    };
  };

  // adds video fallback and ball type to create MS msg
  const _getAimed = (config: Partial<IAimingRequest>) => {
    if (!_pitch) {
      return;
    }

    const safeShots =
      config.usingShots ??
      (_pitch && props.matchingCx
        ? props.matchingCx.safeGetShotsByPitch(_pitch)
        : []);

    const base = _getAimedBase({
      training: !!config.training,
      stepSize: config.stepSize,
      usingShots: safeShots,
    });

    if (!base) {
      return;
    }

    const safeVideoID = config.training
      ? DefaultVideoID.training_short
      : // use || instead of ?? because video_id might be an empty string or undefined
        base.pitch.video_id ||
        (base.pitch.bs.px > 0
          ? props.machineCx.machine.default_video_lhp ??
            DefaultVideoID.default_LHP
          : props.machineCx.machine.default_video_rhp ??
            DefaultVideoID.default_RHP);

    const msg: IMachineStateMsg = {
      ...base.ms,
      ball_type: props.machineCx.machine.ball_type,
      video_uuid: safeVideoID,
      training: !!config.training,
    };

    const output: IAimingResult = {
      usingShots: base.usingShots,
      pitch: base.pitch,
      ms: base.ms,
      msg: msg,
      msgHash: MiscHelper.hashify(msg),
    };

    return output;
  };

  const _updateMatches = async (pitch: IPitch, limit?: number) => {
    await props.matchingCx?.updatePitches({
      pitches: [pitch],
      newerThan: props.newerThan,
      includeHitterPresent: false,
      includeLowConfidence: true,
      limit: limit,
    });

    return props.matchingCx?.safeGetShotsByPitch(pitch);
  };

  const _sendToMachine = async (config: ISendConfig) => {
    const aimed = _getAimed({
      training: config.training,
      usingShots:
        _pitch && props.matchingCx
          ? props.matchingCx.safeGetShotsByPitch(_pitch)
          : [],
    });

    if (!aimed) {
      return;
    }

    const video = config.training
      ? undefined
      : props.videosCx?.videos.find((v) => v._id === aimed.msg.video_uuid);

    if (!config.skipPreview) {
      props.machineCx.sendPitchPreview({
        trigger: config.trigger,
        current: aimed.pitch,
      });
    }

    const result = await props.machineCx.sendTarget({
      source: `${CONTEXT_NAME} using ${aimed.usingShots.length} shots`,
      msMsg: aimed.msg,
      pitch: aimed.pitch,
      video: video,
      plate: _plate,
      list: config.list,
      hitter_id: config.hitter?._id,
      trigger: config.trigger,
    });

    if (result.success) {
      _setLoadingUntil(addMilliseconds(new Date(), 500));
      _setSentHash(result.hash);
    }

    config.onSuccess?.(result.success);
  };

  const state: IAimingContext = {
    pitch: _pitch,
    setPitch: async (pitch, forceLoadShots) => {
      _setPitch(pitch);

      if (!pitch) {
        return;
      }

      if (!props.matchingCx) {
        // can't refresh shots anyway
        return;
      }

      const existingShots = props.matchingCx?.safeGetShotsByPitch(pitch);
      if (existingShots && existingShots.length > 0 && !forceLoadShots) {
        // don't reload
        return;
      }

      // update matches (if matchingCx is provided) whenever changing pitches
      await _updateMatches(pitch);
    },

    plate: _plate,
    setPlate: _setPlate,

    getAimed: _getAimed,

    checkRequireSend: () =>
      !_sentHash || _sentHash !== props.machineCx.lastMSHash,

    loadingUntil: _loadingUntil,

    sendToMachine: _sendToMachine,

    updateMatches: async (limit) => {
      return _pitch && props.matchingCx
        ? _updateMatches(_pitch, limit)
        : undefined;
    },
  };

  // reset the sent hash whenever changing pitch or target location
  useEffect(() => {
    _setSentHash(undefined);
  }, [_pitch, _plate]);

  return (
    <AimingContext.Provider value={state}>
      {props.children}
    </AimingContext.Provider>
  );
};
