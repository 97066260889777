import { NotifyHelper } from 'classes/helpers/notify.helper';
import { RestartMode } from 'lib_ts/enums/admin.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { IError } from 'lib_ts/interfaces/common/i-error';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IMachineConfig } from 'lib_ts/interfaces/i-machine-config';
import { IMachineDetails } from 'lib_ts/interfaces/i-machine-details';
import { IMachineLogFile } from 'lib_ts/interfaces/i-machine-log-file';
import { ICustomRequestMsg, IWSMsg } from 'lib_ts/interfaces/i-machine-msg';
import { IServerSummary } from 'lib_ts/interfaces/i-machine-summary';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import {
  IRepeatabilityFilter,
  IRepeatabilityResult,
} from 'lib_ts/interfaces/modelling/i-repeatability';
import { BaseRESTService } from 'services/_base-rest.service';

export interface IRecentSession {
  _id: string;
  total: number;
  max_created: string;
}

export class AdminMachinesService extends BaseRESTService {
  private static instance: AdminMachinesService;
  static getInstance(): AdminMachinesService {
    if (!AdminMachinesService.instance) {
      AdminMachinesService.instance = new AdminMachinesService();
    }

    return AdminMachinesService.instance;
  }

  private constructor() {
    super({
      controller: 'admin-machines',
    });
  }

  /** get all machines, limited to user's team if not a super admin */
  async getMachines(global: boolean): Promise<IMachine[]> {
    return await this.get({
      uri: '',
      params: { global: global } as any,
    });
  }

  async getMachineDetails(machineID: string): Promise<IMachineDetails> {
    return await this.get({
      uri: `${machineID}/details`,
    });
  }

  // i.e. models that are not archived
  async getActiveModels(): Promise<IMachineModel[]> {
    return await this.get({
      uri: 'models',
    });
  }

  async upgradeFirmware(machineIDs: string[]): Promise<void> {
    return await this.post(
      {
        uri: 'firmware/upgrade',
      },
      machineIDs
    );
  }

  async checkFirmware(machineID: string): Promise<void> {
    return await this.get({
      uri: `${machineID}/firmware/check`,
    });
  }

  async getRecentSessions(machineID: string): Promise<IRecentSession[]> {
    return await this.get({
      uri: `${machineID}/recent-sessions`,
    }).then((result: IServerResponse) => {
      if (!result.success) {
        NotifyHelper.warning({
          message_md:
            result.error ??
            `There was an error getting recent sessions for ${machineID}.`,
        });
        return [];
      }

      return result.data;
    });
  }

  async getMachineConfig(
    machineID: string
  ): Promise<IMachineConfig | undefined> {
    return await this.get({
      uri: `${machineID}/config`,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              `There was an error getting configuration for ${machineID}.`,
          });
          return undefined;
        }

        if (!result.data) {
          NotifyHelper.warning({
            message_md: `The last config found for ${machineID} is empty.`,
          });
          return undefined;
        }

        return result.data;
      })
      .catch((error) => {
        console.error(error);
        NotifyHelper.error({
          message_md: `There was an error getting configuration for ${machineID}.`,
        });
        return undefined;
      });
  }

  async setMachineConfig(
    machineID: string,
    value: any,
    preset?: string
  ): Promise<boolean> {
    return await this.put(
      {
        uri: `${machineID}/config`,
      },
      { value: value, preset: preset }
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              `There was an error while sending configuration update request to ${machineID}.`,
          });
          return false;
        }

        NotifyHelper.info({
          message_md: `Sent request to update configuration to ${machineID}. Please wait...`,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
        NotifyHelper.error({
          message_md: `There was an error while sending configuration update request to ${machineID}.`,
        });
        return false;
      });
  }

  async sideloadWsCommand(
    machineID: string,
    type: WsMsgType,
    payload: any
  ): Promise<void> {
    return await this.post(
      {
        uri: `${machineID}/sideload-ws/${type}`,
      },
      payload
    );
  }

  async postCustomRequest(
    machineID: string,
    data: ICustomRequestMsg
  ): Promise<boolean> {
    const msg: IWSMsg = {
      type: WsMsgType.Misc_CustomRequest,
      machineID: machineID,
      data: data,
    };

    return await this.post(
      {
        uri: `${machineID}/custom-request`,
      },
      msg
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              `There was an error while sending custom request to ${machineID}.`,
          });
          return false;
        }

        NotifyHelper.info({
          message_md: `Sent custom request to ${machineID}.`,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
        NotifyHelper.error({
          message_md: `There was an error while sending custom request to ${machineID}.`,
        });
        return false;
      });
  }

  /** for admins, triggers clearing of all connections to a specific machine */
  async resetConnections(machineID: string): Promise<void> {
    return await this.get({
      uri: `${machineID}/connections/reset`,
    });
  }

  /*
    process => system restart, restart the Arc processes on the machine
    os => soft reboot, restart Ubuntu OS on the machine
  */
  async restart(mode: RestartMode, machineIDs: string[]): Promise<void> {
    switch (mode) {
      case RestartMode.arc: {
        NotifyHelper.info({
          message_md: `Restart requested...`,
        });
        break;
      }

      case RestartMode.os: {
        NotifyHelper.info({
          message_md: `Restart operating system requested...`,
        });
        break;
      }

      default: {
        console.warn(`Encountered unexpected restart mode: ${mode}`);
        return;
      }
    }

    return await this.post(
      {
        uri: `restart/${mode}`,
      },
      machineIDs
    );
  }

  /** for admins */
  async getMachinesSummary(): Promise<IServerSummary> {
    return await this.get({
      uri: 'summary',
    });
  }

  async create(data: Partial<IMachine>): Promise<IMachine> {
    if (!data.machineID) {
      throw new Error('Cannot post without machineID');
    }

    if (!data._parent_id || !data._parent_def || !data._parent_field) {
      throw new Error('Cannot post without parent attributes');
    }

    return await this.post(
      {
        uri: `team/${data._parent_id}`,
      },
      data
    );
  }

  async update(data: Partial<IMachine>): Promise<IMachine | undefined> {
    delete data._checked;

    if (!data._id) {
      NotifyHelper.warning({ message_md: 'Cannot update machine without ID' });
      return;
    }

    return this.put(
      {
        uri: '',
        params: {
          machine_id: data._id,
        } as any,
      },
      data
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ?? 'There was a problem updating the machine.',
          });
          return undefined;
        }

        return result.data as IMachine;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was a problem updating the machine.',
        });
        return undefined;
      });
  }

  // todo: deprecate this pipeline
  async getMachineMetrics(machine_id: string): Promise<IRealMachineMetric[]> {
    return await this.get({
      uri: 'metrics',
      params: {
        machine_id: machine_id,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'There was an error while fetching machine metrics.',
          });
          return [];
        }

        return result.data as IRealMachineMetric[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was an error while fetching machine metrics.',
        });
        return [];
      });
  }

  // todo: deprecate this pipeline
  async getMachineMetric(
    metric_id: string
  ): Promise<IRealMachineMetric | undefined> {
    return await this.get({
      uri: 'metric',
      params: {
        metric_id: metric_id,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'There was an error while fetching machine metric.',
          });
          return undefined;
        }

        return result.data as IRealMachineMetric;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was an error while fetching machine metric.',
        });
        return undefined;
      });
  }

  async getLatestMachineErrors(config: {
    machineID: string;
    skip: number;
    limit: number;
  }): Promise<IError[]> {
    return await this.get({
      uri: `${config.machineID}/errors`,
      params: {
        skip: config.skip,
        limit: config.limit,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'There was an error while fetching machine errors.',
          });
          return [];
        }

        return result.data as IError[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was an error while fetching machine errors.',
        });
        return [];
      });
  }

  async getLatestMachineLogFiles(config: {
    machineID: string;
    skip: number;
    limit: number;
  }): Promise<IMachineLogFile[]> {
    return await this.get({
      uri: `${config.machineID}/log-files`,
      params: {
        skip: config.skip,
        limit: config.limit,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ?? 'There was an error while fetching machine logs.',
          });
          return [];
        }

        return result.data as IMachineLogFile[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was an error while fetching machine logs.',
        });
        return [];
      });
  }

  async getRepeatabilityImages(
    filter: IRepeatabilityFilter
  ): Promise<IRepeatabilityResult> {
    return await this.post(
      {
        uri: '/repeatability-images',
      },
      filter
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.error({
            message_md:
              result.error ??
              'There was an error while fetching repeatability report.',
          });
          return {
            success: false,
            images: [],
            error: result.error ?? 'Unknown error',
          };
        }
        return result.data as IRepeatabilityResult;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was an error while fetching repeatability report.',
        });

        return {
          success: false,
          images: [],
          error: reason,
        };
      });
  }
}
