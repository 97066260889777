import { Box, Heading, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormButton } from 'components/common/form/button';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { ManageAccessTable } from 'components/common/pitch-lists/manage-card/manage-access-table';
import { getVisibilityBlurb } from 'components/common/pitch-lists/manage-list';
import { IAuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { EMPTY_SELECT_VALUE, RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IPitchListLink } from 'lib_ts/interfaces/pitches/i-pitch-list-link';
import React from 'react';
import { AdminPitchListsService } from 'services/admin/pitch-lists.service';

interface IProps {
  list_id: string;
  authCx: IAuthContext;
}

interface IState {
  loading: boolean;
  payload: Partial<IPitchListLink>;

  defOptions: IOption[];
  idOptions: IOption[];

  /** for triggering manage access table to reload */
  lastFetched: Date;
}

export class AccessTab extends React.Component<IProps, IState> {
  private inputParentDef?: CommonSelectInput;
  private inputParentId?: CommonSelectInput;

  constructor(props: IProps) {
    super(props);

    const defOptions: IOption[] = [];

    if (props.authCx.reassignOptions.users.length > 0) {
      defOptions.push({ label: 'User', value: 'team-users' });
    }

    if (props.authCx.reassignOptions.machines.length > 0) {
      defOptions.push({ label: 'Machine', value: 'team-machines' });
    }

    if (props.authCx.reassignOptions.teams.length > 0) {
      defOptions.push({ label: 'Team', value: 'teams' });
    }

    this.state = {
      loading: false,
      payload: { list_id: props.list_id },

      defOptions: defOptions,
      idOptions: [],

      lastFetched: new Date(),
    };

    this.updateIdOptions = this.updateIdOptions.bind(this);
  }

  componentDidMount(): void {
    this.updateIdOptions();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    if (prevState.payload._parent_def !== this.state.payload._parent_def) {
      this.updateIdOptions();
    }
  }

  private updateIdOptions() {
    const admin = this.props.authCx.current;
    const options = this.props.authCx.reassignOptions;

    const idOptions: IOption[] = (() => {
      switch (this.state.payload._parent_def) {
        case 'team-users': {
          return options.users.map((m) => {
            const o: IOption = {
              label: m.email,
              value: m._id,
              /** team admins would only see their own team's users listed but this won't hurt */
              disabled:
                admin.role !== UserRole.admin && m._parent_id !== admin.teamID,
            };

            return o;
          });
        }

        case 'team-machines': {
          return options.machines.map((m) => {
            const o: IOption = {
              label: `${m.machineID}${m.sandbox ? ' (Sandbox)' : ''}`,
              value: m._id,
              /** team admins would only see their own team's machines listed but this won't hurt */
              disabled:
                admin.role !== UserRole.admin && m._parent_id !== admin.teamID,
            };

            return o;
          });
        }

        case 'teams': {
          return options.teams.map((m) => {
            const o: IOption = {
              label: m.name,
              value: m._id,
              /** team admins would only see their own team listed but this won't hurt */
              disabled: admin.role !== UserRole.admin && m._id !== admin.teamID,
            };

            return o;
          });
        }

        default: {
          return [
            {
              label: 'Select a parent type first',
              value: EMPTY_SELECT_VALUE,
              disabled: true,
            },
          ];
        }
      }
    })().sort((a: IOption, b: IOption) => a.label.localeCompare(b.label));

    this.setState({ idOptions: idOptions });
  }

  render() {
    const selectedMachine = this.props.authCx.reassignOptions.machines.find(
      (m) => m._id === this.state.payload._parent_id
    );
    const warningBlurb = getVisibilityBlurb(
      this.state.payload._parent_def,
      selectedMachine ? selectedMachine.machineID : undefined
    );
    return (
      <ErrorBoundary componentName="AccessTab">
        <CommonFormGrid columns={1}>
          <Heading size={RADIX.HEADING.SIZE.SM}>Grant Access</Heading>
          <Box>
            <Text>
              Use the form below to grant access to this pitch list to a user,
              machine, or team.
            </Text>
          </Box>

          <CommonFormGrid columns={3}>
            <Box>
              <CommonSelectInput
                id="access-parent-type"
                ref={(elem) =>
                  (this.inputParentDef = elem as CommonSelectInput)
                }
                name="_parent_def"
                label="Type"
                options={this.state.defOptions}
                value={this.state.payload._parent_def}
                onChange={(v) =>
                  this.setState({
                    payload: {
                      ...this.state.payload,
                      _parent_def: v,
                      _parent_id: undefined,
                      _parent_name: undefined,
                    },
                  })
                }
                hint_md={warningBlurb}
                optional
              />
            </Box>
            <Box>
              <CommonSelectInput
                id="access-parent"
                ref={(elem) => (this.inputParentId = elem as CommonSelectInput)}
                name="_parent_id"
                label="Name"
                options={this.state.idOptions}
                value={this.state.payload._parent_id}
                onChange={(v) => {
                  const parent = this.state.idOptions.find(
                    (m) => m.value === v
                  );
                  this.setState({
                    payload: {
                      ...this.state.payload,
                      _parent_id: parent?.value,
                      _parent_name: parent?.label,
                    },
                  });
                }}
                optional
              />
            </Box>
            <Box>
              <CommonFormButton
                id="access-create"
                label="common.create"
                disabled={this.state.loading}
                onClick={() => {
                  if (this.props.authCx.current.role !== UserRole.admin) {
                    NotifyHelper.error({
                      message_md: 'Only super admins can perform this action.',
                    });
                    return;
                  }

                  this.setState({ loading: true });
                  AdminPitchListsService.getInstance()
                    .createLink(this.state.payload)
                    .then((result) => {
                      if (result) {
                        NotifyHelper.success({
                          message_md:
                            'Access granted successfully, resetting form...',
                        });

                        this.setState(
                          {
                            payload: { list_id: this.props.list_id },
                            lastFetched: new Date(),
                          },
                          () => {
                            this.inputParentDef?.reset();
                            this.inputParentId?.reset();
                          }
                        );
                      }
                    })
                    .finally(() => this.setState({ loading: false }));
                  return;
                }}
              />
            </Box>
          </CommonFormGrid>

          <Heading size={RADIX.HEADING.SIZE.SM}>Manage Access</Heading>

          <CookiesContext.Consumer>
            {(cookiesCx) => (
              <ManageAccessTable
                list_id={this.props.list_id}
                cookiesCx={cookiesCx}
                lastFetched={this.state.lastFetched}
              />
            )}
          </CookiesContext.Consumer>
        </CommonFormGrid>
      </ErrorBoundary>
    );
  }
}
