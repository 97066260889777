import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import { createContext, FC, ReactNode, useState } from 'react';

export interface IPitchContext {
  /** mainly used for passing pitches into pitch designer */
  active?: IPitch;

  readonly setActivePitch: (pitch?: IPitch) => void;
}

const DEFAULT: IPitchContext = {
  setActivePitch: () => console.debug('not init'),
};

export const PitchContext = createContext(DEFAULT);

interface IProps {
  context: IPitchListsContext;
  children: ReactNode;
}

export const PitchProvider: FC<IProps> = (props) => {
  const [_active, _setActive] = useState(DEFAULT.active);

  const state: IPitchContext = {
    active: _active,
    setActivePitch: (pitch) => {
      _setActive(pitch);
    },
  };

  return (
    <PitchContext.Provider value={state}>
      {props.children}
    </PitchContext.Provider>
  );
};
