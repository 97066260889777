import { Button, Code } from '@radix-ui/themes';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTable } from 'components/common/table';
import { IMachineCalibrationContext } from 'contexts/machine-calibration.context';
import { IMachineContext } from 'contexts/machine.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { CalibrationStep } from 'enums/machine.enums';
import { ACTIONS_KEY } from 'enums/tables';
import { t } from 'i18next';
import { IDisplayCol } from 'interfaces/i-tables';
import { MetricInterval } from 'lib_ts/enums/machine-models.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import React from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';
import './index.scss';

const COMPONENT_NAME = 'RecentMetrics';

interface IProps {
  machineCx: IMachineContext;
  calibrationCx: IMachineCalibrationContext;
}

interface IState {
  metrics?: IRealMachineMetric[];
}

export class RecentMetrics extends React.Component<IProps, IState> {
  private init = false;

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.created',
      key: '_created',
      formatFn: (m: IRealMachineMetric) =>
        format(parseISO(m._created), LOCAL_DATETIME_FORMAT, {
          timeZone: LOCAL_TIMEZONE,
        }),
    },
    {
      label: 'ID',
      key: '_id',
      formatFn: (m: IRealMachineMetric) => <Code>{m._id}</Code>,
    },
    {
      label: 'common.ball-type',
      key: '_ballType',
      formatFn: (m: IRealMachineMetric) => {
        return m.dataFilter.ball_type ?? '(none)';
      },
      sortRowsFn: (
        a: IRealMachineMetric,
        b: IRealMachineMetric,
        dir: number
      ) => {
        const aBall = a.dataFilter.ball_type ?? '';
        const bBall = b.dataFilter.ball_type ?? '';
        return aBall.localeCompare(bBall) * dir;
      },
    },
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      formatFn: (m: IRealMachineMetric) => {
        return (
          <Button
            color={RADIX.COLOR.INFO}
            size={RADIX.BUTTON.SIZE.SM}
            onClick={() => {
              this.props.calibrationCx.setRealMachineMetric(m);
              this.props.calibrationCx.setStep(CalibrationStep.ReviewMetric);
            }}
          >
            {t('common.review')}
          </Button>
        );
      },
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.renderBody = this.renderBody.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    AdminMachineModelsService.getInstance()
      .getMetricsForMachine({
        machine_id: this.props.machineCx.machine._id,
        job_interval: MetricInterval.Calibration,
      })
      .then((result) => {
        if (result) {
          /** newest first */
          result.sort((a, b) => b._created.localeCompare(a._created));

          /** only keep 5 */
          this.setState({ metrics: result.filter((_, i) => i < 5) });
        }
      })
      .catch((reason) => console.error(reason));
  }

  private renderBody() {
    if (!this.state.metrics) {
      return <p>Please wait...</p>;
    }

    if (this.state.metrics.length === 0) {
      return <p>{t('common.no-data')}</p>;
    }

    return (
      <CommonTable
        id="RecentMetricsList"
        displayColumns={this.BASE_COLUMNS}
        displayData={this.state.metrics}
        suspendKeyListener
      />
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDetails summary="Recent Metrics">
          {this.renderBody()}
        </CommonDetails>
      </ErrorBoundary>
    );
  }
}
