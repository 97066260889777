import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Badge, Flex } from '@radix-ui/themes';
import { MlbStatsHelper } from 'classes/helpers/mlb-stats.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CopyPitchesDialog } from 'components/common/dialogs/copy-pitches';
import { PitchDataDialog } from 'components/common/dialogs/pitch-data';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTable } from 'components/common/table';
import { CommonTooltip } from 'components/common/tooltip';
import { ActiveCalibrationModelWarning } from 'components/common/warnings/active-calibration-model-warning';
import { SectionHeader } from 'components/sections/header';
import { MlbStatsAdditionalFilters } from 'components/sections/mlb-stats-browse/additional-filters';
import { GenerateVideoDialog } from 'components/sections/mlb-stats-browse/dialogs/generate-video';
import { ViewBroadcastDialog } from 'components/sections/mlb-stats-browse/dialogs/view-broadcast';
import { MlbStatsFooter } from 'components/sections/mlb-stats-browse/footer';
import { MlbStatsMainFilters } from 'components/sections/mlb-stats-browse/main-filters';
import { MlbStatsBrowseSidebar } from 'components/sections/mlb-stats-browse/sidebar';
import { IAimingContext } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
} from 'contexts/layout/checked.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMlbBrowseContext } from 'contexts/mlb-browse.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { IPitchContext } from 'contexts/pitch-lists/pitch.context';
import { ISectionsContext } from 'contexts/sections.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { SectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { IDisplayCol, ITableAction, ITablePageable } from 'interfaces/i-tables';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { FT_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { PlateHelper } from 'lib_ts/classes/plate.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { lookupPitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { IPitch } from 'lib_ts/interfaces/pitches';
import React from 'react';
import { MlbStatsService } from 'services/mlb-stats.service';

const COMPONENT_NAME = 'MlbStatsBrowse';
const IDENTIFIER = TableIdentifier.PitchList;

const ENABLE_SKELETAL_DOWNLOAD = false;
const ENABLE_VIDEO_GENERATION = false;

const ERROR_ICON = <ExclamationTriangleIcon />;

const isObjectNonEmpty = (m: any, ignoreKeys?: string[]): boolean => {
  return (
    Object.keys(m)
      .filter((k) => !ignoreKeys || !ignoreKeys.includes(k))
      .filter((k) => {
        const v = m[k];

        if (v === undefined) {
          return false;
        }

        if (v === null) {
          return false;
        }

        if (typeof v === 'string') {
          // empty strings don't count
          return v.length > 0;
        }

        if (ArrayHelper.isArray(v)) {
          // empty arrays don't count
          return (v as any[]).length > 0;
        }

        return true;
      }).length > 0
  );
};

interface IProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  browseCx: IMlbBrowseContext;
  listsCx: IPitchListsContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  pitchCx: IPitchContext;
  sectionsCx: ISectionsContext;
  videosCx: IVideosContext;
  aimingCx: IAimingContext;
}

interface IDialogs {
  dialogAdd?: number;
  dialogBroadcast?: number;
  dialogData?: number;
  dialogVideo?: number;
}

interface IState extends IDialogs {
  managePitches?: IPitch[];

  // for broadcast dialog
  selectedGuid?: IMlbPitchExt;
}

const PAGE_SIZES = TABLES.PAGE_SIZES.LG;

export class MlbStatsBrowse extends React.Component<IProps, IState> {
  private tableNode?: CommonTable;

  // currently not used
  private footer?: MlbStatsFooter;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.getActions = this.getActions.bind(this);

    this.afterSelectRow = this.afterSelectRow.bind(this);

    this.renderBody = this.renderBody.bind(this);
    this.renderDialogs = this.renderDialogs.bind(this);
    this.getCheckedMenuActions = this.getCheckedMenuActions.bind(this);

    this.sendSelected = this.sendSelected.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const gameFilterChanged =
      prevProps.browseCx.gameFilter !== this.props.browseCx.gameFilter;

    const pitchFilterChanged =
      prevProps.browseCx.pitchFilter !== this.props.browseCx.pitchFilter;

    if (gameFilterChanged || pitchFilterChanged) {
      this.tableNode?.setCoordinates({
        target: undefined,
        cascade: false,
      });

      this.props.aimingCx.setPitch(undefined);
    }
  }

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: this.getActions(),
    },
    {
      label: '',
      key: '_issues',
      formatFn: (pitch: IMlbPitchExt) => {
        const errors = MlbStatsHelper.getErrors(pitch);
        if (errors.length > 0) {
          return (
            <CommonTooltip
              trigger={ERROR_ICON}
              content={
                <p>
                  Invalid data detected:
                  <ul>
                    {errors.map((e, i) => (
                      <li key={`guid-${pitch.guid}-error-${i}`}>{e}</li>
                    ))}
                  </ul>
                </p>
              }
            />
          );
        }

        return '';
      },
      sortRowsFn: (a: IMlbPitchExt, b: IMlbPitchExt, dir: number) => {
        const va = MlbStatsHelper.getErrors(a).length;
        const vb = MlbStatsHelper.getErrors(b).length;
        return dir * (va > vb ? 1 : -1);
      },
    },

    {
      label: 'common.pitcher',
      key: 'pitcher',
      formatFn: (m: IMlbPitchExt) => {
        const isLeft = m.pitchHand === 'L';
        return (
          <>
            {m.pitcher}
            &nbsp;
            <Badge color={isLeft ? RADIX.COLOR.LEFT : RADIX.COLOR.RIGHT}>
              {t(isLeft ? 'common.lhp' : 'common.rhp')}
            </Badge>
          </>
        );
      },
    },
    {
      label: 'common.batter',
      key: 'batter',
      formatFn: (m: IMlbPitchExt) => {
        const isLeft = m.batSide === 'L';
        return (
          <>
            {m.batter}
            &nbsp;
            <Badge color={isLeft ? RADIX.COLOR.LEFT : RADIX.COLOR.RIGHT}>
              {t(isLeft ? 'hitters.lhh' : 'hitters.rhh')}
            </Badge>
          </>
        );
      },
    },
    {
      label: 'common.type',
      key: 'type',
      formatFn: (pitch: IMlbPitchExt) => {
        return lookupPitchType(pitch.type);
      },
      sortRowsFn: (pitchA: IMlbPitchExt, pitchB: IMlbPitchExt, dir: number) => {
        const textA = pitchA.type ?? '';
        const textB = pitchB.type ?? '';
        return -dir * textA.localeCompare(textB);
      },
    },
    {
      label: 'common.inning',
      key: 'inning',
      formatFn: (pitch: IMlbPitchExt) => {
        return `${pitch.count.inning}`;
      },
      sortRowsFn: (pitchA: IMlbPitchExt, pitchB: IMlbPitchExt, dir: number) => {
        const textA = pitchA.count.inning ?? 0;
        const textB = pitchB.count.inning ?? 0;
        return -dir * (textA < textB ? -1 : 1);
      },
    },
    {
      label: 'common.outcome',
      key: 'outcome',
      formatFn: (pitch: IMlbPitchExt) => {
        return pitch.outcome;
      },
      sortRowsFn: (pitchA: IMlbPitchExt, pitchB: IMlbPitchExt, dir: number) => {
        const textA = pitchA.outcome ?? '';
        const textB = pitchB.outcome ?? '';
        return -dir * textA.localeCompare(textB);
      },
    },
    {
      label: 'Zone',
      key: '_zone',
      align: 'center',
      formatFn: (pitch: IMlbPitchExt) => {
        const chars = MlbStatsHelper.convertToChars(
          pitch,
          this.props.cookiesCx.app.pitch_upload_options.priority,
          false
        ).chars;

        if (!chars?.plate) {
          return '';
        }

        const summary = PlateHelper.getPlateSummary(chars.plate);

        return (
          <img
            className="StrikeZoneIcon"
            width={24}
            height={24}
            src={`/img/icons/strike-zone/${summary.grid}.svg`}
          />
        );
      },
      sortRowsFn: (pitchA: IMlbPitchExt, pitchB: IMlbPitchExt, dir: number) => {
        const charsA = MlbStatsHelper.convertToChars(
          pitchA,
          this.props.cookiesCx.app.pitch_upload_options.priority,
          false
        ).chars;

        const charsB = MlbStatsHelper.convertToChars(
          pitchB,
          this.props.cookiesCx.app.pitch_upload_options.priority,
          false
        ).chars;

        const sortA = charsA?.plate
          ? PlateHelper.getPlateSummary(charsA.plate).sort
          : 0;

        const sortB = charsB?.plate
          ? PlateHelper.getPlateSummary(charsB.plate).sort
          : 0;

        return -dir * (sortA < sortB ? -1 : 1);
      },
    },
    {
      label: 'common.speed',
      key: 'speed',
      subLabel: 'mph',
      align: 'right',
      formatFn: (pitch: IMlbPitchExt) => {
        const v = pitch.lastMeasuredData?.velocity;

        return MlbStatsHelper.validVelocity(v)
          ? BallHelper.getSpeed({
              vx: v?.x ?? 0,
              vy: v?.y ?? 0,
              vz: v?.z ?? 0,
            }).toFixed(1)
          : ERROR_ICON;
      },
      sortRowsFn: (pitchA: IMlbPitchExt, pitchB: IMlbPitchExt, dir: number) => {
        const veloA = pitchA.lastMeasuredData?.velocity;
        const va = MlbStatsHelper.validVelocity(veloA)
          ? BallHelper.getSpeed({
              vx: veloA?.x ?? 0,
              vy: veloA?.y ?? 0,
              vz: veloA?.z ?? 0,
            })
          : 0;

        const veloB = pitchB.lastMeasuredData?.velocity;
        const vb = MlbStatsHelper.validVelocity(veloB)
          ? BallHelper.getSpeed({
              vx: veloB?.x ?? 0,
              vy: veloB?.y ?? 0,
              vz: veloB?.z ?? 0,
            })
          : 0;

        return dir * (va < vb ? 1 : -1);
      },
    },
    {
      label: 'common.spin',
      key: 'spin',
      subLabel: 'rpm',
      align: 'right',
      formatFn: (pitch: IMlbPitchExt) => {
        const sv = pitch.releaseData?.spinVector;

        return MlbStatsHelper.validSpinVector(sv)
          ? BallHelper.getNetSpin({
              wx: sv?.x ?? 0,
              wy: sv?.y ?? 0,
              wz: sv?.z ?? 0,
            }).toFixed(0)
          : ERROR_ICON;
      },
      sortRowsFn: (pitchA: IMlbPitchExt, pitchB: IMlbPitchExt, dir: number) => {
        const vectorA = pitchA.releaseData?.spinVector;
        const va = MlbStatsHelper.validSpinVector(vectorA)
          ? BallHelper.getNetSpin({
              wx: vectorA?.x ?? 0,
              wy: vectorA?.y ?? 0,
              wz: vectorA?.z ?? 0,
            })
          : 0;

        const vectorB = pitchB.releaseData?.spinVector;
        const vb = MlbStatsHelper.validSpinVector(vectorB)
          ? BallHelper.getNetSpin({
              wx: vectorB?.x ?? 0,
              wy: vectorB?.y ?? 0,
              wz: vectorB?.z ?? 0,
            })
          : 0;

        return dir * (va < vb ? 1 : -1);
      },
    },
    {
      label: 'H. Break',
      key: 'breaks_xInches',
      subLabel: 'in',
      align: 'right',
      formatFn: (pitch: IMlbPitchExt) => {
        const traj = pitch.trajectoryData;
        return MlbStatsHelper.validTrajectory(traj)
          ? ((traj?.horizontalBreak ?? 0) * FT_TO_INCHES).toFixed(1)
          : ERROR_ICON;
      },
      sortRowsFn: (pitchA: IMlbPitchExt, pitchB: IMlbPitchExt, dir: number) => {
        const va = pitchA.trajectoryData?.horizontalBreak ?? 0;
        const vb = pitchB.trajectoryData?.horizontalBreak ?? 0;
        return dir * (va < vb ? 1 : -1);
      },
    },
    {
      label: 'V. Break',
      key: 'breaks_zInches',
      subLabel: 'in',
      align: 'right',
      formatFn: (pitch: IMlbPitchExt) => {
        const traj = pitch.trajectoryData;
        return MlbStatsHelper.validTrajectory(traj)
          ? ((traj?.verticalBreakInduced ?? 0) * FT_TO_INCHES).toFixed(1)
          : ERROR_ICON;
      },
      sortRowsFn: (pitchA: IMlbPitchExt, pitchB: IMlbPitchExt, dir: number) => {
        const va = pitchA.trajectoryData?.verticalBreakInduced ?? 0;
        const vb = pitchB.trajectoryData?.verticalBreakInduced ?? 0;
        return dir * (va < vb ? 1 : -1);
      },
    },
  ];

  private getActions(): ITableAction[] {
    const restricted = this.props.authCx.restrictedGameStatus();

    const output: ITableAction[] = [
      {
        label: 'Add Pitch',
        invisibleFn: () => restricted,
        onClick: async (guid: IMlbPitchExt) => {
          const managePitches = await this.props.browseCx.buildPitches({
            machine: this.props.machineCx.machine,
            pitches: [guid],
          });

          if (managePitches.length === 0) {
            return;
          }

          if (this.props.aimingCx.pitch?.video_id) {
            const selected = managePitches.find(
              (p) => p.mlb_guid === this.props.aimingCx.pitch?.mlb_guid
            );

            if (selected) {
              selected.video_id = this.props.aimingCx.pitch.video_id;
            }
          }

          this.setState({
            managePitches: managePitches,
            dialogAdd: Date.now(),
          });
        },
      },
      {
        label: 'pl.view-pitch-data',
        onClick: async (guid: IMlbPitchExt) => {
          const pitch = (
            await this.props.browseCx.buildPitches({
              machine: this.props.machineCx.machine,
              pitches: [guid],
            })
          )[0];

          if (!pitch) {
            return;
          }

          await this.props.aimingCx.setPitch(pitch);

          this.setState({
            dialogData: Date.now(),
          });
        },
      },
      {
        label: 'main.pitch-design',
        invisibleFn: () => restricted,
        onClick: async (guid: IMlbPitchExt) => {
          const pitch = (
            await this.props.browseCx.buildPitches({
              machine: this.props.machineCx.machine,
              pitches: [guid],
            })
          )[0];

          if (!pitch) {
            return;
          }

          /** set pitch to be used by pitch design */
          this.props.pitchCx.setActivePitch({
            ...pitch,
            /** empty pitch _id will cause pitch designer to suppress the update pitch button */
            _id: '',
          });

          /** move to pitch designer */
          this.props.sectionsCx.tryChangeSection({
            trigger: `${COMPONENT_NAME}, context menu`,
            name: SectionName.PitchDesign,
          });
        },
      },
      {
        label: 'View Broadcast',
        invisibleFn: () => restricted,
        onClick: async (guid: IMlbPitchExt) => {
          this.setState({
            selectedGuid: guid,
            dialogBroadcast: Date.now(),
          });
        },
      },
      {
        label: 'Generate Video',
        color: RADIX.COLOR.WARNING,
        invisibleFn: () => !ENABLE_VIDEO_GENERATION || restricted,
        onClick: async (guid: IMlbPitchExt) => {
          this.setState({
            selectedGuid: guid,
            dialogVideo: Date.now(),
          });
        },
      },
      {
        label: 'Skeletal Data',
        icon: <SuperAdminIcon />,
        color: RADIX.COLOR.WARNING,
        invisibleFn: () =>
          !ENABLE_SKELETAL_DOWNLOAD ||
          this.props.authCx.current.role !== UserRole.admin,
        onClick: async (guid: IMlbPitchExt) => {
          const data =
            await MlbStatsService.getInstance().getGamePlaySkeletalData(
              guid.gamePk,
              guid.guid
            );

          if (!data) {
            return;
          }

          MiscHelper.saveAs(
            new Blob([JSON.stringify(data, null, 2)]),
            `game-${guid.gamePk}_play-${guid.guid}_skeletal.json`
          );
        },
      },
    ];

    return output;
  }

  /** for active row selection functionality */
  private async afterSelectRow(config: { model: IMlbPitchExt | undefined }) {
    if (!config.model) {
      this.props.aimingCx.setPitch(undefined);
      return;
    }

    if (this.props.aimingCx.pitch?.mlb_guid === config.model.guid) {
      // it's already selected, don't re-trigger everything
      return;
    }

    const pitch = (
      await this.props.browseCx.buildPitches({
        machine: this.props.machineCx.machine,
        pitches: [config.model],
      })
    )[0];

    if (!pitch) {
      return;
    }

    // if a video was selected, keep using that video
    pitch.video_id = this.props.aimingCx.pitch?.video_id;

    await this.props.aimingCx.setPitch(pitch);
    const initialLocation = TrajHelper.getPlateLoc(pitch.traj);
    this.props.aimingCx.setPlate(initialLocation);

    if (this.props.machineCx.checkActive(true)) {
      this.sendSelected();
    }
  }

  private sendSelected() {
    if (!this.props.browseCx.checkLaneWarning(() => this.sendSelected())) {
      return;
    }

    if (this.props.globalCx.dialogs.length > 0) {
      return;
    }

    this.footer?.fireButton?.setAwaitingResend(true);

    this.props.aimingCx.sendToMachine({
      training: false,
      skipPreview: false,
      trigger: `${COMPONENT_NAME} > sendSelected`,
      onSuccess: (success) => {
        if (success) {
          this.props.pitchCx.setActivePitch(this.props.aimingCx.pitch);
        }
        this.footer?.fireButton?.setAwaitingResend(false);
      },
    });
  }

  private renderBody() {
    const data = this.props.browseCx.getFilteredPitches();

    const pagination: ITablePageable = {
      total: data.length,
      enablePagination: true,
      pageSize: this.props.cookiesCx.getPageSize(IDENTIFIER) ?? PAGE_SIZES[0],
      pageSizeOptions: PAGE_SIZES,
      pageSizeCallback: (value) =>
        this.props.cookiesCx.setPageSize(IDENTIFIER, value),
    };

    const isFiltering =
      isObjectNonEmpty(this.props.browseCx.gameFilter, ['season']) ||
      isObjectNonEmpty(this.props.browseCx.pitchFilter);

    return (
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={<ActiveCalibrationModelWarning showSettingsButton />}
        table={
          <CheckedProvider data={data}>
            <CheckedContext.Consumer>
              {(checkedCx) => (
                <CommonTable
                  id={COMPONENT_NAME}
                  ref={(elem) => (this.tableNode = elem as CommonTable)}
                  checkedCx={checkedCx}
                  checkedMenuActions={this.getCheckedMenuActions()}
                  suspendKeyListener={this.props.globalCx.dialogs.length > 0}
                  toolbarContent={
                    <MlbStatsMainFilters
                      browseCx={this.props.browseCx}
                      resetChecked={() => checkedCx.checkAll(false)}
                    />
                  }
                  extraToolbarContent={
                    <MlbStatsAdditionalFilters
                      browseCx={this.props.browseCx}
                      resetChecked={() => checkedCx.checkAll(false)}
                    />
                  }
                  displayColumns={this.BASE_COLUMNS}
                  displayData={data}
                  noDataHeader="common.no-pitches-to-display"
                  noDataBody={
                    isFiltering
                      ? 'common.no-matches-msg'
                      : 'common.browse-welcome-msg'
                  }
                  afterSelectRow={this.afterSelectRow}
                  checkboxColumnIndex={0}
                  {...pagination}
                  loading={this.props.browseCx.loading}
                  enableSort
                  vFlex
                />
              )}
            </CheckedContext.Consumer>
          </CheckedProvider>
        }
        footer={
          <p>
            Major League Baseball trademarks and copyrights are used with
            permission of MLB Advanced Media, L.P. All rights reserved.
          </p>
        }
      />
    );
  }

  private getCheckedMenuActions(): IMenuAction[] {
    const checked = this.props.browseCx
      .getFilteredPitches()
      .filter((p) => p._checked);

    const restricted = this.props.authCx.restrictedGameStatus();

    const actions: IMenuAction[] = [
      {
        label: 'Add Pitches',
        invisible: restricted,
        onClick: async () => {
          const managePitches = await this.props.browseCx.buildPitches({
            machine: this.props.machineCx.machine,
            pitches: checked,
          });

          if (managePitches.length === 0) {
            return;
          }

          if (this.props.aimingCx.pitch?.video_id) {
            const selected = managePitches.find(
              (p) => p.mlb_guid === this.props.aimingCx.pitch?.mlb_guid
            );

            if (selected) {
              selected.video_id = this.props.aimingCx.pitch.video_id;
            }
          }

          this.setState({
            dialogAdd: Date.now(),
            managePitches: managePitches,
          });
        },
      },
      {
        label: 'Add Averages',
        invisible: restricted,
        onClick: async () => {
          const averages = MlbStatsHelper.averagePitches(checked);
          if (!averages || averages.length === 0) {
            NotifyHelper.warning({
              message_md: `There was a problem calculating average pitches. Please check your selections and try again.`,
            });
            return;
          }

          const built = await this.props.browseCx.buildPitches({
            machine: this.props.machineCx.machine,
            pitches: averages,
            isAverage: true,
          });
          if (built.length === 0) {
            return;
          }

          this.setState({
            dialogAdd: Date.now(),
            managePitches: built,
          });
        },
      },
    ];

    return actions;
  }

  private renderDialogs() {
    return (
      <>
        {this.state.dialogAdd && this.state.managePitches && (
          <CopyPitchesDialog
            key={this.state.dialogAdd}
            identifier={`${COMPONENT_NAME}AddPitchesDialog`}
            authCx={this.props.authCx}
            listsCx={this.props.listsCx}
            title={t('common.copy-x', {
              x: t(
                this.state.managePitches.length === 1
                  ? 'common.pitch'
                  : 'common.pitches'
              ),
            }).toString()}
            description={t('pl.select-pitch-list-to-copy-into').toString()}
            pitches={this.state.managePitches}
            onCreated={() => this.setState({ dialogAdd: undefined })}
            onClose={() => this.setState({ dialogAdd: undefined })}
          />
        )}

        {this.state.dialogData && this.props.aimingCx.pitch && (
          <PitchDataDialog
            key={this.state.dialogData}
            identifier={`${COMPONENT_NAME}ViewDataDialog`}
            cookiesCx={this.props.cookiesCx}
            authCx={this.props.authCx}
            machine={this.props.machineCx.machine}
            pitch={this.props.aimingCx.pitch}
            onClose={() => this.setState({ dialogData: undefined })}
          />
        )}

        {this.state.dialogBroadcast && this.state.selectedGuid && (
          <ViewBroadcastDialog
            key={this.state.dialogBroadcast}
            identifier={`${COMPONENT_NAME}ViewBroadcastDialog`}
            gamePk={this.state.selectedGuid.gamePk}
            guid={this.state.selectedGuid.guid}
            onClose={() => this.setState({ dialogBroadcast: undefined })}
          />
        )}

        {this.state.dialogVideo && this.state.selectedGuid && (
          <GenerateVideoDialog
            key={this.state.dialogVideo}
            identifier={`${COMPONENT_NAME}GenerateVideoDialog`}
            videosCx={this.props.videosCx}
            guid={this.state.selectedGuid}
            onClose={() => this.setState({ dialogVideo: undefined })}
          />
        )}
      </>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex
          className={`Browse ${RADIX.VFLEX.WRAPPER}`}
          direction="column"
          gap={RADIX.FLEX.GAP.SECTION}
        >
          <SectionHeader header={t('main.browse')} />

          <CommonContentWithSidebar
            left={this.renderBody()}
            right={
              this.props.aimingCx.pitch && (
                <MlbStatsBrowseSidebar
                  authCx={this.props.authCx}
                  machineCx={this.props.machineCx}
                  matchingCx={this.props.matchingCx}
                  videosCx={this.props.videosCx}
                  aimingCx={this.props.aimingCx}
                  handleVideoChange={(video_id) => {
                    const pitch = this.props.aimingCx.pitch;

                    if (!pitch) {
                      return;
                    }

                    const changed = pitch.video_id !== video_id;

                    if (!changed) {
                      return;
                    }

                    this.props.aimingCx.setPitch({
                      ...pitch,
                      video_id: video_id ?? '',
                    });

                    this.props.machineCx.resetMSHash();
                  }}
                />
              )
            }
            hideSidebar={!this.props.aimingCx.pitch}
            vFlex
          />
        </Flex>
        {this.renderDialogs()}
      </ErrorBoundary>
    );
  }
}
