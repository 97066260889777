import { sha1 } from 'object-hash';

export interface INumberTally {
  [key: string]: number[];
}

export interface INumberDict {
  [key: string]: number;
}

export class MiscHelper {
  /** await this to delay execution in sync functions */
  static sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  /** returns a sorted array of unique values based on nonUnique input */
  static saveAs(blob: Blob, fileNameWithExt: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileNameWithExt;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  static asOrdinal = (n: number): string => {
    if ([11, 12, 13].includes(n)) {
      return n + 'th';
    }

    const m = n % 10;
    switch (m) {
      case 1: {
        return n + 'st';
      }

      case 2: {
        return n + 'nd';
      }

      case 3: {
        return n + 'rd';
      }

      default: {
        return n + 'th';
      }
    }
  };

  static hashify = (input: any): string | undefined => {
    try {
      if (typeof input === 'object') {
        return sha1(JSON.stringify(input));
      }

      if (typeof input === 'string') {
        return sha1(input);
      }

      /** attempt a dirty conversion */
      return sha1(input ?? '');
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  static getMean = (values: number[]): number => {
    if (values.length === 0) {
      return 0;
    }

    return values.reduce((prev, curr) => prev + curr, 0) / values.length;
  };

  // returns 0 if you give it an empty list (don't do that)
  static getMax = (values: number[]): number => {
    if (values.length === 0) {
      return 0;
    }

    return values.reduce((a, b) => (a > b ? a : b));
  };

  static getMedian = (values: number[]): number => {
    if (values.length === 0) {
      return 0;
    }

    const sortedValues = [...values].sort((a, b) => (a < b ? -1 : 1));
    const index = Math.floor(sortedValues.length / 2);

    return sortedValues.length % 2 === 0
      ? (sortedValues[index - 1] + sortedValues[index]) / 2
      : sortedValues[index];
  };

  // returns 0 if you give it an empty list (don't do that)
  static getMin = (values: number[]): number => {
    if (values.length === 0) {
      return 0;
    }

    return values.reduce((a, b) => (a < b ? a : b));
  };

  static getStDevObject = <T extends { [value: string]: any }>(
    input: T[]
  ): Partial<T> => {
    if (!input || input.length === 0) {
      return {};
    }

    const tally: INumberTally = {};

    input.forEach((item) => {
      /** compile all the attribute values from each item */
      Object.keys(item)
        .filter((key) => typeof item[key] === 'number')
        .forEach((key) => {
          if (!tally[key]) {
            tally[key] = [];
          }

          tally[key].push(item[key]);
        });
    });

    const result: INumberDict = {};

    Object.keys(tally).forEach((key) => {
      /** compute the average value for each key */
      const list = tally[key];
      if (list.length > 0) {
        const avg = this.getMean(list);
        const stdev = Math.sqrt(
          list.map((x) => Math.pow(x - avg, 2)).reduce((a, b) => a + b) /
            list.length
        );
        result[key] = stdev;
      }
    });

    return result as Partial<T>;
  };

  static getReducedStringKeyObject = <T extends { [key: string]: any }>(
    input: T[],
    fn: (value: any[]) => any
  ): Partial<T> => {
    if (input.length === 0) {
      return {};
    }

    const tally = {} as { [key: string]: any[] };

    input.forEach((item) => {
      /** compile all the attribute values from each item */
      for (const key in item) {
        if (!tally[key]) {
          tally[key] = [];
        }
        tally[key].push(item[key]);
      }
    });

    const result = {} as { [key: string]: any };
    Object.keys(tally).forEach((key) => {
      /** compute the reduction for each key */
      result[key] = fn(tally[key]);
    });

    return result as T;
  };

  static getMinObject = <T extends { [value: string]: any }>(
    input: T[]
  ): Partial<T> =>
    MiscHelper.getReducedStringKeyObject(input, MiscHelper.getMin);

  static getMaxObject = <T extends { [value: string]: any }>(
    input: T[]
  ): Partial<T> =>
    MiscHelper.getReducedStringKeyObject(input, MiscHelper.getMax);

  static getMedianObject = <T extends { [value: string]: any }>(
    input: T[]
  ): Partial<T> =>
    MiscHelper.getReducedStringKeyObject(input, MiscHelper.getMedian);

  static getMeanObject = <T extends { [value: string]: any }>(
    input: T[]
  ): Partial<T> =>
    MiscHelper.getReducedStringKeyObject(input, MiscHelper.getMean);
}
