import { DownloadIcon } from '@radix-ui/react-icons';
import { Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { DialogButton } from 'components/common/dialogs/button';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonTable } from 'components/common/table';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { IDisplayCol } from 'interfaces/i-tables';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IError } from 'lib_ts/interfaces/common/i-error';
import { IErrorType } from 'lib_ts/interfaces/common/i-error-type';
import { IMachineDetails } from 'lib_ts/interfaces/i-machine-details';
import React from 'react';
import { AdminErrorTypesService } from 'services/admin/error-types.service';
import { AdminMachinesService } from 'services/admin/machines.service';

const RECORDS_PER_REQUEST = 10;

interface IProps {
  details: IMachineDetails;
}

interface IState {
  filterCreated: string[];
  filterSender: string[];

  noMore: boolean;
  errors: IError[];

  // currently loaded but unused
  types: IErrorType[];

  options: {
    created: string[];
    sender: string[];
  };
}

export class ErrorsTab extends React.Component<IProps, IState> {
  private init = false;
  private loading = false;

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'Created',
      key: '_created',
      formatFn: (m: IError) =>
        format(parseISO(m._created), LOCAL_DATETIME_FORMAT, {
          timeZone: LOCAL_TIMEZONE,
        }),
    },
    {
      label: 'Sender',
      key: 'sender',
    },
    {
      label: 'Message',
      key: 'message',
      classNameFn: () => 'text-wrap',
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      noMore: false,
      filterCreated: [],
      filterSender: [],
      errors: [],
      types: [],
      options: {
        created: [],
        sender: [],
      },
    };

    this.loadErrors = this.loadErrors.bind(this);
    this.loadErrorTypes = this.loadErrorTypes.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    this.loadErrorTypes();
    this.loadErrors(false);
  }

  private async loadErrorTypes() {
    const types = await AdminErrorTypesService.getInstance().getAll();

    types.sort((a, b) => a.errorID.localeCompare(b.errorID));

    this.setState({
      types: types,
    });
  }

  private async loadErrors(notify: boolean) {
    if (this.loading) {
      return;
    }

    try {
      this.loading = true;

      const latestErrors =
        await AdminMachinesService.getInstance().getLatestMachineErrors({
          machineID: this.props.details.machineID,
          skip: this.state.errors.length,
          limit: RECORDS_PER_REQUEST,
        });

      const next = [...this.state.errors, ...latestErrors];

      this.setState(
        {
          noMore: latestErrors.length < RECORDS_PER_REQUEST,
          errors: next,
          options: {
            ...this.state.options,
            created: ArrayHelper.unique(
              next.map((m) =>
                format(parseISO(m._created), 'yyyy-MM-dd', {
                  timeZone: LOCAL_TIMEZONE,
                })
              )
            ),
            sender: ArrayHelper.unique(next.map((m) => m.sender ?? '')).sort(),
          },
        },
        () => {
          if (notify) {
            const anyFilters =
              this.state.filterSender.length > 0 ||
              this.state.filterCreated.length > 0;
            const single = latestErrors.length === 1;

            NotifyHelper.success({
              message_md: anyFilters
                ? `Fetched ${latestErrors.length} more ${
                    single ? 'error' : 'errors'
                  }, showing filtered results only.`
                : `Fetched ${latestErrors.length} more ${
                    single ? 'error' : 'errors'
                  }, showing all results.`,
            });
          }
        }
      );
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  render() {
    const filteredData = this.state.errors
      .filter(
        (m) =>
          this.state.filterCreated.length === 0 ||
          this.state.filterCreated.includes(
            format(parseISO(m._created), 'yyyy-MM-dd', {
              timeZone: LOCAL_TIMEZONE,
            })
          )
      )
      .filter((m) => {
        if (this.state.filterSender.length === 0) {
          return true;
        }

        if (!m.sender) {
          return false;
        }

        return this.state.filterSender.includes(m.sender);
      });

    return (
      <ErrorBoundary componentName="MachineDetailsErrorsTab">
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <CommonFormGrid columns={2}>
            <CommonSearchInput
              id="errors-created"
              placeholder="common.created"
              options={this.state.options.created.map((o) => ({
                label: o,
                value: o,
              }))}
              values={this.state.filterCreated}
              onChange={(v) => {
                this.setState({ filterCreated: v });
              }}
              multiple
            />
            <CommonSearchInput
              id="errors-sender"
              placeholder="Sender"
              options={this.state.options.sender.map((o) => ({
                label: o,
                value: o,
              }))}
              values={this.state.filterSender}
              onChange={(v) => {
                this.setState({ filterSender: v });
              }}
              multiple
            />
          </CommonFormGrid>

          <CommonTable
            id="MachineErrorsLog"
            displayColumns={this.BASE_COLUMNS}
            displayData={filteredData}
            suspendKeyListener
          />

          {!this.state.noMore && (
            <DialogButton
              icon={<DownloadIcon />}
              label="Load More"
              className="btn-block"
              onClick={() => this.loadErrors(true)}
            />
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
