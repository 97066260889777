import { CSVBall } from 'classes/csv-ball';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getMachineActiveModelID, getModelKey } from 'lib_ts/classes/ms.helper';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import {
  IBuildPitchChars,
  IBuildPitchRequest,
  IBuildPitchResult,
  IClosedLoopBuildChars,
  IClosedLoopBuildRequest,
  IClosedLoopBuildResult,
  IPitch,
} from 'lib_ts/interfaces/pitches';
import { IManualShot } from 'lib_ts/interfaces/training/i-manual-shot';
import { BaseRESTService } from 'services/_base-rest.service';

export class StateTransformService extends BaseRESTService {
  private static instance: StateTransformService;
  static getInstance(): StateTransformService {
    if (!StateTransformService.instance) {
      StateTransformService.instance = new StateTransformService();
    }

    return StateTransformService.instance;
  }

  private constructor() {
    super({
      controller: 'state',
    });
  }

  /** encapsulated logic for taking ball chars and positions to build pitches
   * to be used for all add/test/train pitch instances
   * */
  async buildPitches(config: {
    machine: IMachine;
    notifyError: boolean;
    pitches: Partial<IBuildPitchChars>[];
    /** provide to automatically mixin the chars into the balls before returning */
    mixinBalls?: CSVBall[];
  }): Promise<Partial<IBuildPitchChars>[]> {
    try {
      const withoutPriority = config.pitches.filter((p) => !p.priority);
      if (withoutPriority.length > 0) {
        console.error(withoutPriority);
        throw new Error(
          `Cannot build ${withoutPriority.length} pitches without priority`
        );
      }

      if (config.mixinBalls) {
        if (config.mixinBalls.length !== config.pitches.length) {
          throw new Error(
            `Length mismatch between pitches (${config.pitches.length}) and balls (${config.mixinBalls.length}).`
          );
        }

        /** auto-assign temp_index to each pitch and ball for later */
        config.mixinBalls.forEach((ball, index) => {
          ball.rawChars.temp_index = index;
          config.pitches[index].temp_index = index;
        });
      }

      const model_id = getMachineActiveModelID(config.machine);
      if (!model_id) {
        throw new Error(
          `Cannot proceed without a valid model assigned to your machine for key ${getModelKey(
            config.machine
          )}.`
        );
      }

      const payload: IBuildPitchRequest = {
        machineID: config.machine.machineID,
        model_key: getModelKey(config.machine),
        model_id: model_id,
        ball_type: config.machine.ball_type,
        pitches: config.pitches,
      };

      // results will already be rotated by the server to point at given plate locations in the payload
      const results: IBuildPitchResult = await this.post(
        { uri: 'build-generic' },
        payload
      );

      if (results.errors.length > 0) {
        NotifyHelper.warning({
          message_md: `An error occurred while building ${
            config.pitches.length === 1 ? 'a pitch' : 'pitches'
          }. See console for details.`,
        });
        console.error({
          event: 'STATE TRANSFORM: error(s) while building pitches',
          errors: results.errors,
        });
      }

      /** update the original balls with matching temp_index */
      if (config.mixinBalls) {
        config.mixinBalls.forEach((ball) => {
          const chars = results.pitches.find(
            (c) => c.temp_index === ball.rawChars.temp_index
          ) as IBuildPitchChars;

          if (chars) {
            ball.mixinChars(chars, config.machine.plate_distance);
          } else {
            console.warn(
              `STATE TRANSFORM: failed to locate char for ball with temp_index ${ball.rawChars.temp_index}`
            );
          }
        });
      }

      return results.pitches;
    } catch (e) {
      console.error(e);

      if (config.notifyError) {
        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an unknown error while building ${
                  config.pitches.length === 1 ? 'pitch' : 'pitches'
                }. ${ERROR_MSGS.CONTACT_SUPPORT}}`,
        });
      }

      return [];
    }
  }

  async buildClosedLoop(config: {
    machineID: string;
    pitches: IClosedLoopBuildChars[];
    notifyError: boolean;
    stepSize: number;
  }): Promise<IClosedLoopBuildChars[]> {
    try {
      const payload: IClosedLoopBuildRequest = {
        pitches: config.pitches,
        step_size: config.stepSize,
      };

      const results: IClosedLoopBuildResult = await this.post(
        { uri: 'build-closed-loop' },
        payload
      );

      if (results.errors.length > 0) {
        NotifyHelper.warning({
          message_md: `An error occurred while building ${
            config.pitches.length === 1 ? 'a pitch' : 'pitches'
          }. See console for details.`,
        });
        console.error({
          event:
            'STATE TRANSFORM: error(s) while building pitches with closed loop',
          errors: results.errors,
        });
      }

      return results.pitches;
    } catch (e) {
      console.error(e);

      if (config.notifyError) {
        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an unknown error while building ${
                  config.pitches.length === 1 ? 'pitch' : 'pitches'
                } with closed loop. ${ERROR_MSGS.CONTACT_SUPPORT}}`,
        });
      }

      return [];
    }
  }

  async forceRefreshPitches(config: {
    pitches: Partial<IPitch>[];
    ms: boolean;
    traj: boolean;
  }): Promise<IPitch[]> {
    return await this.post(
      {
        uri: 'refresh',
        params: { force: true, ms: config.ms, traj: config.traj } as any,
      },
      ArrayHelper.unique(config.pitches.map((p) => p._id))
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ?? 'There was a problem refreshing your pitches.',
          });
          return [];
        }

        return result.data as IPitch[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was a problem refreshing your pitches.',
        });
        return [];
      });
  }

  async postManualInput(payload: IManualShot): Promise<boolean> {
    return await this.post(
      {
        uri: 'manual',
      },
      payload
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ?? 'There was a problem submitting your input.',
          });
        }

        return result.success;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was a problem submitting your input.',
        });
        return false;
      });
  }
}
