import { Box, Checkbox, Flex, Table, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CheckedContext } from 'contexts/layout/checked.context';
import {
  ACTIONS_KEY,
  CHECKBOX_KEY,
  CheckMode,
  DRAGDROP_KEY,
} from 'enums/tables';
import { t } from 'i18next';
import { IDisplayCol } from 'interfaces/i-tables';
import { RADIX, RadixFlexAlign, RadixTextAlign } from 'lib_ts/enums/radix-ui';
import { VoidIcon } from '../custom-icon/shorthands';
import { CommonTableSortIcon } from './sort-icon';

interface IHeaderCell {
  onSort: (key: string) => void;
  sort?: 'asc' | 'desc';

  col: IDisplayCol;
  afterCheckAll?: (mode: CheckMode) => void;
}

const convertAlignToJustify = (
  value: RadixTextAlign | undefined
): RadixFlexAlign | undefined => {
  switch (value) {
    case 'center': {
      return 'center';
    }

    case 'left': {
      return 'start';
    }

    case 'right': {
      return 'end';
    }

    default: {
      return undefined;
    }
  }
};

const TableHeaderText = (props: IHeaderCell) => (
  <Flex
    gap={RADIX.FLEX.GAP.XS}
    justify={convertAlignToJustify(props.col.align)}
  >
    <Box>
      <Text color={props.col.color}>
        {t(props.col.label)}&nbsp;
        {props.col.subLabel && <small>{t(props.col.subLabel)}</small>}
      </Text>
      {/* if labelIcon is not needed, this preserves the text height without adding gaps */}
      {!props.col.labelIcon && (
        <span style={{ marginTop: '2px' }}>
          <VoidIcon />
        </span>
      )}
    </Box>

    {props.col.labelIcon && (
      <Box style={{ marginTop: '2px' }}>{props.col.labelIcon}</Box>
    )}

    <CommonTableSortIcon
      colKey={props.col.key}
      disabled={props.col.disableSort}
      sort={props.sort}
    />
  </Flex>
);

const CheckboxCell = (props: IHeaderCell) => {
  const classes = StringHelper.classNames([
    'width-40px',
    props.col.thClassNameFn?.(props.col),
  ]);

  return (
    <CheckedContext.Consumer>
      {(checkedCx) => {
        const mode = checkedCx.tally.mode;

        return (
          <Table.ColumnHeaderCell
            className={classes}
            data-testid="col-checkbox"
            data-mode={mode}
            align={props.col.align ?? 'center'}
          >
            <Checkbox
              // redraw whenever the tally is updated
              key={checkedCx.tally.key}
              checked={mode === 'some' ? 'indeterminate' : mode === 'all'}
              color={props.col.color}
              onCheckedChange={(checked) => {
                const isAll = checked !== false;
                checkedCx.checkAll(isAll);
                props.afterCheckAll?.(isAll ? 'all' : 'none');
              }}
            />
          </Table.ColumnHeaderCell>
        );
      }}
    </CheckedContext.Consumer>
  );
};

const ActionsCell = (props: IHeaderCell) => {
  const safeProps = { ...props };

  if (!safeProps.col.align) {
    safeProps.col.align = 'center';
  }

  const classes = StringHelper.classNames([
    'width-80px',
    safeProps.col.thClassNameFn?.(safeProps.col),
  ]);

  return (
    <Table.ColumnHeaderCell
      title={safeProps.col.labelTooltip}
      className={classes}
      data-testid="col-actions"
      align={safeProps.col.align}
    >
      <TableHeaderText {...safeProps} />
    </Table.ColumnHeaderCell>
  );
};

const UnsortableCell = (props: IHeaderCell) => {
  const classes = StringHelper.classNames([
    props.col.thClassNameFn?.(props.col),
  ]);

  return (
    <Table.ColumnHeaderCell
      title={props.col.labelTooltip}
      className={classes}
      align={props.col.align}
    >
      <TableHeaderText {...props} />
    </Table.ColumnHeaderCell>
  );
};

const BasicCell = (props: IHeaderCell) => {
  const classes = StringHelper.classNames([
    'cursor-pointer',
    props.col.thClassNameFn?.(props.col),
  ]);

  return (
    <Table.ColumnHeaderCell
      title={props.col.labelTooltip}
      data-type={props.col.dataType}
      className={classes}
      align={props.col.align}
      onClick={() => props.onSort?.(props.col.key)}
    >
      <TableHeaderText {...props} />
    </Table.ColumnHeaderCell>
  );
};

export const CommonTableHeaderCell = (props: IHeaderCell) => {
  switch (props.col.key) {
    case DRAGDROP_KEY: {
      return <Table.ColumnHeaderCell />;
    }

    case CHECKBOX_KEY: {
      return <CheckboxCell {...props} />;
    }

    case ACTIONS_KEY: {
      return <ActionsCell {...props} />;
    }

    default: {
      if (!props.onSort || props.col.disableSort) {
        return <UnsortableCell {...props} />;
      }

      return <BasicCell {...props} />;
    }
  }
};
