import { LoginMethod } from 'enums/env';
import { AdminRoute as AdminFragment, SectionName } from 'enums/route.enums';
import slugify from 'slugify';

export class RouteHelper {
  static fragment = (value: AdminFragment): string => {
    const routeParts = [value]
      .filter((m) => !!m)
      .map((m) =>
        slugify(m as string, { lower: true, strict: true, trim: true })
      );
    // note the lack of leading '/' char
    const output = `${routeParts.join('/')}`;
    return output;
  };

  static section = (section: SectionName, fragment?: string): string => {
    const routeParts = [section, fragment]
      .filter((m) => !!m)
      .map((m) =>
        slugify(m as string, { lower: true, strict: true, trim: true })
      );
    const output = `/${routeParts.join('/')}`;
    return output;
  };

  static login = (login: LoginMethod): string => {
    return `/${slugify(login as string, {
      lower: true,
      strict: true,
      trim: true,
    })}`;
  };
}
