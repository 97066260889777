import { CopyIcon, InfoCircledIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Box, Flex, Grid, Text } from '@radix-ui/themes';
import { AimingContextHelper } from 'classes/helpers/aiming-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTooltip } from 'components/common/tooltip';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict, getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IBallState,
  IClosedLoopBuildChars,
  IPitch,
} from 'lib_ts/interfaces/pitches';
import { ISpin } from 'lib_ts/interfaces/pitches/i-base';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { StateTransformService } from 'services/state-transform.service';

const COMPONENT_NAME = 'EditSpinsDialog';

// how many decimals to print
const PRECISION = 0;

// show warning if any axis requested differs from corresponding avgs by more than this
const WARNING_THRESHOLD_RPM = 500;

interface IProps {
  pitch: IPitch;
  listsCx: IPitchListsContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  onClose: () => void;

  // hides the update button
  readonly: boolean;
}

interface IState {
  actual_wx_text?: string;
  actual_wx_rpm: number;
  actual_wy_text?: string;
  actual_wy_rpm: number;
  actual_wz_text?: string;
  actual_wz_rpm: number;

  target_wx_text?: string;
  target_wx_rpm: number;
  target_wy_text?: string;
  target_wy_rpm: number;
  target_wz_text?: string;
  target_wz_rpm: number;

  overriding: boolean;
}

export class EditSpinsDialog extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      actual_wx_rpm: 0,
      actual_wy_rpm: 0,
      actual_wz_rpm: 0,

      target_wx_rpm: props.pitch.bs?.wx ?? 0,
      target_wy_rpm: props.pitch.bs?.wy ?? 0,
      target_wz_rpm: props.pitch.bs?.wz ?? 0,

      overriding: false,
    };

    this.getMedianSpins = this.getMedianSpins.bind(this);
    this.getShotsWithBallStates = this.getShotsWithBallStates.bind(this);
    this.initialize = this.initialize.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.resetActualSpins = this.resetActualSpins.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount(): void {
    if (!this.init) {
      this.init = true;
      this.initialize();
    }
  }

  private async initialize() {
    if (this.getShotsWithBallStates().length === 0) {
      await this.props.matchingCx.updatePitch(
        {
          pitch: this.props.pitch,
          includeHitterPresent: false,
          includeLowConfidence: false,
        },
        true
      );
    }

    const target = this.props.pitch.bs;
    const useTarget = this.props.pitch.priority
      ? this.props.pitch.priority === 'Spins'
      : true;

    const medianSpins = this.getMedianSpins();

    const target_wx_rpm = useTarget ? target.wx : medianSpins.wx;
    const target_wy_rpm = useTarget ? target.wy : medianSpins.wy;
    const target_wz_rpm = useTarget ? target.wz : medianSpins.wz;

    this.setState({
      actual_wx_rpm: medianSpins.wx,
      actual_wx_text: medianSpins.wx.toFixed(PRECISION),
      actual_wy_rpm: medianSpins.wy,
      actual_wy_text: medianSpins.wy.toFixed(PRECISION),
      actual_wz_rpm: medianSpins.wz,
      actual_wz_text: medianSpins.wz.toFixed(PRECISION),

      target_wx_rpm: target_wx_rpm,
      target_wx_text: target_wx_rpm.toFixed(PRECISION),
      target_wy_rpm: target_wy_rpm,
      target_wy_text: target_wy_rpm.toFixed(PRECISION),
      target_wz_rpm: target_wz_rpm,
      target_wz_text: target_wz_rpm.toFixed(PRECISION),
    });
  }

  private resetActualSpins() {
    const medianSpins = this.getMedianSpins();

    this.setState({
      actual_wx_rpm: medianSpins.wx,
      actual_wx_text: medianSpins.wx.toFixed(PRECISION),
      actual_wy_rpm: medianSpins.wy,
      actual_wy_text: medianSpins.wy.toFixed(PRECISION),
      actual_wz_rpm: medianSpins.wz,
      actual_wz_text: medianSpins.wz.toFixed(PRECISION),
    });
  }

  private getShotsWithBallStates(): IMachineShot[] {
    return this.props.matchingCx
      .safeGetShotsByPitch(this.props.pitch)
      .filter((s) => !!s.bs);
  }

  private getMedianSpins(): ISpin {
    const spins = this.getShotsWithBallStates().map((s) => s.bs as ISpin);

    if (spins.length === 0) {
      return {
        wx: 0,
        wy: 0,
        wz: 0,
      };
    }

    return MiscHelper.getMedianObject(spins) as ISpin;
  }

  private async submit(mode: 'copy' | 'update') {
    if (mode === 'update' && this.props.readonly) {
      NotifyHelper.error({
        message_md: 'Updating is not allowed in read-only mode.',
      });
      return;
    }

    const medianSpins = this.getMedianSpins();
    if (!medianSpins) {
      NotifyHelper.error({
        message_md: 'There is insufficient data to proceed.',
      });
      return;
    }

    const msResult = getMSFromMSDict(
      this.props.pitch,
      this.props.machineCx.machine
    );
    if (!msResult.ms) {
      NotifyHelper.error({ message_md: 'There is no machine state.' });
      return;
    }

    const newSpin = {
      wx: this.state.target_wx_rpm,
      wy: this.state.target_wy_rpm,
      wz: this.state.target_wz_rpm,
    } as ISpin;

    const newTargetBS = {
      ...this.props.pitch.bs,
      ...newSpin,
      wnet: BallHelper.getNetSpin(newSpin),
    };

    const actualSpin = {
      wx: this.state.actual_wx_rpm,
      wy: this.state.actual_wy_rpm,
      wz: this.state.actual_wz_rpm,
    } as ISpin;

    const medianBS = MiscHelper.getMedianObject(
      this.getShotsWithBallStates().map((s) => s.bs as IBallState)
    );

    const actualBS = {
      ...medianBS,
      ...actualSpin,
    } as unknown as IBallState;

    const chars: IClosedLoopBuildChars = {
      // machineID: this.props.machineCx.machine.machineID,
      mongo_id: this.props.pitch._id,
      priority: BuildPriority.Spins,
      use_gradient: true,
      ms: msResult.ms,
      target_bs: newTargetBS,
      actual_bs: actualBS,
      traj: this.props.pitch.traj,
    };

    const result = (
      await StateTransformService.getInstance().buildClosedLoop({
        machineID: this.props.machineCx.machine.machineID,
        pitches: [chars],
        stepSize: 1,
        notifyError: true,
      })
    ).find((p) => p.mongo_id === this.props.pitch._id);

    if (!result) {
      NotifyHelper.error({
        message_md: 'Failed to find adjusted pitch by ID.',
      });
      return;
    }

    const nextMSDict = getMergedMSDict(
      this.props.machineCx.machine,
      [result.ms],
      this.props.pitch.msDict
    );

    const payload: Partial<IPitch> = {
      ...this.props.pitch,
      bs: result.target_bs,
      traj: result.traj,
      msDict: nextMSDict,
      priority: BuildPriority.Spins,
    };

    const aimed = AimingContextHelper.getAdHocAimed({
      source: `${COMPONENT_NAME} > submit`,
      machine: this.props.machineCx.machine,
      pitch: payload as IPitch,
      plate: TrajHelper.getPlateLoc(this.props.pitch.traj),
      usingShots: [],
    });

    if (!aimed) {
      NotifyHelper.error({ message_md: 'Failed to build new pitch.' });
      return;
    }

    switch (mode) {
      case 'copy': {
        aimed.pitch._original_id = this.props.pitch._id;
        // add "(Edited)" to end of name to differentiate
        const nameSuffix = `(Edited)`;
        aimed.pitch.name = `${this.props.pitch.name} ${nameSuffix}`;

        await PitchListsService.getInstance()
          .postPitchesToList({
            listID: this.props.pitch._parent_id,
            data: [aimed.pitch],
          })
          .then((result) => {
            if (!result.success) {
              NotifyHelper.warning({
                message_md: result.error ?? 'Pitch could not be created.',
              });
              return;
            }

            /** update the active pitches to show newly added pitch */
            if (
              this.props.pitch._parent_id === this.props.listsCx.active?._id
            ) {
              this.props.listsCx.refreshActive();
            }
          });
        break;
      }

      case 'update': {
        await this.props.listsCx.updatePitches({ payloads: [aimed.pitch] });
        break;
      }

      default: {
        break;
      }
    }

    this.props.onClose();
  }

  render() {
    const pitchName = this.props.pitch.name;
    const shots = this.getShotsWithBallStates();

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          width={RADIX.DIALOG.WIDTH.LG}
          title={`${t('pl.edit-spins')} for "${pitchName}"`}
          content={this.renderContent()}
          buttons={[
            {
              label: `Override: ${this.state.overriding ? 'ON' : 'OFF'}`,
              color: this.state.overriding ? RADIX.COLOR.WARNING : undefined,
              onClick: () =>
                this.setState(
                  {
                    overriding: !this.state.overriding,
                  },
                  () => this.resetActualSpins()
                ),
            },
            {
              icon: <CopyIcon />,
              label: 'common.copy',
              color: RADIX.COLOR.SUCCESS,
              disabled: shots.length === 0,
              onClick: () => this.submit('copy'),
            },
            {
              invisible: this.props.readonly,
              icon: <UpdateIcon />,
              label: 'common.update',
              color: RADIX.COLOR.WARNING,
              disabled: shots.length === 0,
              onClick: () => this.submit('update'),
            },
          ]}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }

  private renderContent() {
    const diffX = this.state.actual_wx_rpm - this.state.target_wx_rpm;
    const warnX = Math.abs(diffX) > WARNING_THRESHOLD_RPM;

    const diffY = this.state.actual_wy_rpm - this.state.target_wy_rpm;
    const warnY = Math.abs(diffY) > WARNING_THRESHOLD_RPM;

    const diffZ = this.state.actual_wz_rpm - this.state.target_wz_rpm;
    const warnZ = Math.abs(diffZ) > WARNING_THRESHOLD_RPM;

    return (
      <Flex gap={RADIX.FLEX.GAP.LG} direction="column">
        <Text>
          Use this feature to directly control the 3D spin of a pitch. This
          feature will save a new pitch with the adjusted spin values.
        </Text>

        <Grid columns="3" gap={RADIX.FLEX.GAP.MD}>
          {/* row 1 */}
          <Box></Box>
          <Box>
            {this.state.overriding ? 'User Override' : 'Actual'} &nbsp;
            <CommonTooltip
              trigger={<InfoCircledIcon />}
              text_md={
                this.state.overriding
                  ? 'Actual spins as input by user'
                  : 'Actual spins as reported by Rapsodo'
              }
            />
          </Box>
          <Box>Target</Box>
          {/* row 2 */}
          <Box>Spin X (RPM)</Box>
          <Box>
            <CommonTextInput
              id="edit-spins-actual-wx"
              name="actual_wx_text"
              type="number"
              className="align-center"
              value={this.state.actual_wx_text}
              disabled={this.props.listsCx.loading || !this.state.overriding}
              onOptionalNumericChange={(v) => {
                this.setState({
                  actual_wx_text: v?.toString(),
                  actual_wx_rpm: v ?? 0,
                });
              }}
            />
          </Box>
          <Box>
            <CommonTextInput
              id="edit-spins-target-wx"
              name="target_wx_text"
              type="number"
              className="align-center"
              value={this.state.target_wx_text}
              disabled={this.props.listsCx.loading}
              onOptionalNumericChange={(v) => {
                this.setState({
                  target_wx_text: v?.toString(),
                  target_wx_rpm: v ?? 0,
                });
              }}
            />
          </Box>
          {/* row 3 */}
          <Box>Spin Y (RPM)</Box>
          <Box>
            <CommonTextInput
              id="edit-spins-actual-wy"
              name="actual_wy_text"
              type="number"
              className="align-center"
              value={this.state.actual_wy_text}
              disabled={this.props.listsCx.loading || !this.state.overriding}
              onOptionalNumericChange={(v) => {
                this.setState({
                  actual_wy_text: v?.toString(),
                  actual_wy_rpm: v ?? 0,
                });
              }}
            />
          </Box>
          <Box>
            <CommonTextInput
              id="edit-spins-target-wy"
              name="target_wy_text"
              type="number"
              className="align-center"
              value={this.state.target_wy_text}
              disabled={this.props.listsCx.loading}
              onOptionalNumericChange={(v) => {
                this.setState({
                  target_wy_text: v?.toString(),
                  target_wy_rpm: v ?? 0,
                });
              }}
            />
          </Box>
          {/* row 4 */}
          <Box>Spin Z (RPM)</Box>
          <Box>
            <CommonTextInput
              id="edit-spins-actual-wz"
              name="actual_wz_text"
              type="number"
              className="align-center"
              value={this.state.actual_wz_text}
              disabled={this.props.listsCx.loading || !this.state.overriding}
              onOptionalNumericChange={(v) => {
                this.setState({
                  actual_wz_text: v?.toString(),
                  actual_wz_rpm: v ?? 0,
                });
              }}
            />
          </Box>
          <Box>
            <CommonTextInput
              id="edit-spins-target-wz"
              name="target_wz_text"
              type="number"
              className="align-center"
              value={this.state.target_wz_text}
              disabled={this.props.listsCx.loading}
              onOptionalNumericChange={(v) => {
                this.setState({
                  target_wz_text: v?.toString(),
                  target_wz_rpm: v ?? 0,
                });
              }}
            />
          </Box>
        </Grid>

        {(warnX || warnY || warnZ) && (
          <CommonCallout
            text={(() => {
              const axesStrings = [];
              if (warnX) {
                axesStrings.push('X');
              }
              if (warnY) {
                axesStrings.push('Y');
              }
              if (warnZ) {
                axesStrings.push('Z');
              }
              const axesString = axesStrings.join(', ');
              return `Target value differs by more than ${WARNING_THRESHOLD_RPM} for ${
                axesStrings.length > 1 ? 'Axes' : 'Axis'
              } ${axesString}`;
            })()}
          />
        )}
      </Flex>
    );
  }
}
