import { ArrowUpIcon, ResetIcon } from '@radix-ui/react-icons';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { RestartMode } from 'lib_ts/enums/admin.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { AdminMachinesService } from 'services/admin/machines.service';

const COMPONENT_NAME = 'UpgradeFirmwareDialog';

interface IProps {
  machineIDs: string[];
  onClose: (success: boolean) => void;
}

interface IState {}

export class UpgradeFirmwareDialog extends React.Component<IProps, IState> {
  state: IState = {};

  render() {
    const isSingle = this.props.machineIDs.length === 1;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          title="Upgrade Firmware"
          width={RADIX.DIALOG.WIDTH.MD}
          content={
            <ReactMarkdown
              children={[
                'Each machine will check for any firmware updates and apply them if found.',
                'Please ensure each machine is not in use before proceeding.',
                'Each machine will need to be rebooted/restarted after the updates complete.',
                'Any active or waiting users will be disconnected.',
                `The following ${
                  isSingle ? 'machine' : 'machines'
                } will be upgraded:`,
                this.props.machineIDs
                  .map((machineID) => ` - ${machineID}`)
                  .join('\n'),
              ].join('\n\n')}
            />
          }
          buttons={[
            {
              icon: <ResetIcon />,
              label: 'common.restart',
              color: RADIX.COLOR.WARNING,
              tooltip: 'This will restart the Arc process on the machine(s).',
              onClick: () => {
                AdminMachinesService.getInstance().restart(
                  RestartMode.arc,
                  this.props.machineIDs
                );
              },
            },
            {
              icon: <ResetIcon />,
              label: 'common.restart-os',
              color: RADIX.COLOR.DANGER,
              tooltip:
                'This will restart the operating system on the machine(s).',
              onClick: () => {
                AdminMachinesService.getInstance().restart(
                  RestartMode.os,
                  this.props.machineIDs
                );
              },
            },
            {
              label: 'Upgrade',
              color: RADIX.COLOR.SUCCESS,
              icon: <ArrowUpIcon />,
              onClick: () => {
                AdminMachinesService.getInstance()
                  .upgradeFirmware(this.props.machineIDs)
                  .then(() => {
                    NotifyHelper.info({
                      message_md: `Starting firmware upgrade for ${this.props.machineIDs.join(
                        ', '
                      )}...`,
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                    NotifyHelper.info({
                      message_md: `Failed to perform firmware upgrade for ${this.props.machineIDs.join(
                        ', '
                      )}, please check console.`,
                    });
                  });
              },
            },
          ]}
          onClose={() => this.props.onClose(false)}
        />
      </ErrorBoundary>
    );
  }
}
