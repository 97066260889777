import { DownloadIcon, ResetIcon, UploadIcon } from '@radix-ui/react-icons';
import { Box, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { DialogButton } from 'components/common/dialogs/button';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { CommonTabs } from 'components/common/tabs';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import React from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';

enum TabKey {
  Active = 'Active',
  Default = 'Default',
}

interface IProps {
  machineID: string;
}

interface IState {
  loading?: boolean;
  lastJSON?: string;
  nextJSON?: string;
  default?: any;
  defaultJSON?: string;

  available_presets?: IOption[];
  preset?: string;

  errors?: string[];

  activeTab: TabKey;
}

export class FirmwareConfigTab extends React.Component<IProps, IState> {
  private init = false;
  private refetchTimeout?: any;

  constructor(props: IProps) {
    super(props);

    /** make a copy to manipulate via forms */
    this.state = {
      activeTab: TabKey.Active,
    };

    this.fetchConfig = this.fetchConfig.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.renderReferenceTextarea = this.renderReferenceTextarea.bind(this);
    this.renderUpdateTextarea = this.renderUpdateTextarea.bind(this);
  }

  componentDidMount(): void {
    if (!this.init) {
      this.init = true;
      this.fetchConfig();
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.refetchTimeout);
  }

  private fetchConfig() {
    this.setState({ loading: true });
    AdminMachinesService.getInstance()
      .getMachineConfig(this.props.machineID)
      .then((result) => {
        if (!result) {
          NotifyHelper.warning({
            message_md:
              'Received an empty result when trying to get machine config. Is the machine online?',
          });
          return;
        }

        this.setState({
          default: result.default,

          defaultJSON: result.default
            ? JSON.stringify(result.default, null, 2)
            : 'Empty result from server',

          lastJSON: result.machine
            ? JSON.stringify(result.machine, null, 2)
            : 'Empty result from server',

          nextJSON:
            result.errors && result.errors.length > 0
              ? /** leave busted config intact for correction */
                this.state.nextJSON
              : result.machine
              ? JSON.stringify(result.machine, null, 2)
              : undefined,

          available_presets: result.available_presets?.map((path) => ({
            label: path.split('/').reverse()[0],
            value: path,
          })),

          errors: result.errors,
        });
      })
      .finally(() => this.setState({ loading: false }));
  }

  /** DEPRECATE: use errors from machine instead */
  private getErrors(): string[] {
    try {
      const value = this.state.nextJSON;
      if (!value) {
        return ['Value cannot be empty'];
      }

      const json = JSON.parse(value);
      if (!json) {
        return ['Value cannot be parsed into JSON'];
      }

      const recursiveCompare = (
        input: any,
        reference: any,
        key: string
      ): string[] => {
        if (!Object.keys(reference).includes(key)) {
          return [`Unrecognized key found (${key})`];
        }

        const inputType = typeof input[key];
        const refType = typeof reference[key];

        if (inputType !== refType) {
          return [
            `Type mismatch for key ${key} (expected ${refType}, found ${inputType})`,
          ];
        }

        if (typeof input[key] === 'object') {
          return Object.keys(input[key]).flatMap((nextKey) =>
            recursiveCompare(input[key], reference[key], nextKey)
          );
        }

        return [];
      };

      const errors = Object.keys(json).flatMap((key) =>
        recursiveCompare(json, this.state.default, key)
      );
      if (errors.length > 0) {
        return errors;
      }

      return [];
    } catch (e) {
      console.error(e);
      return ['Invalid JSON (see console)'];
    }
  }

  render() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        {this.renderReferenceTextarea()}
        {this.renderUpdateTextarea()}

        {this.state.available_presets && (
          <Box>
            <CommonSelectInput
              id="machine-config-preset"
              name="preset"
              label="Preset"
              options={this.state.available_presets}
              value={this.state.preset}
              onChange={(v) => this.setState({ preset: v })}
              disabled={this.state.loading}
              optional
            />
          </Box>
        )}

        {this.state.preset && (
          <CommonCallout
            text_md={[
              `Using  \`${this.state.available_presets?.find(
                (o) => o.value === this.state.preset
              )
                ?.label}\` will merge its contents with the value provided above.`,
              'The contents of the preset file will take precedence if there are any conflicts.',
            ].join('\n\n')}
          />
        )}

        {this.renderButtons()}
      </Flex>
    );
  }

  private renderUpdateTextarea() {
    return (
      <Box>
        <CommonTextareaInput
          id="machine-config-update"
          label="Update Value"
          name="config"
          rows={10}
          value={this.state.nextJSON}
          disabled={this.state.loading || this.state.nextJSON === undefined}
          onChange={(v) => this.setState({ nextJSON: v })}
          hint_md={[
            'Some changes will only take effect after the machine is restarted (e.g. connections).',
            this.state.errors?.map((err) => ` - ${err}`).join('\n'),
          ].join('\n\n')}
          monospace
        />
      </Box>
    );
  }

  private renderReferenceTextarea() {
    return (
      <CommonTabs
        value={this.state.activeTab}
        onValueChange={(value) => {
          this.setState({ activeTab: value as TabKey });
        }}
        tabs={[
          {
            value: TabKey.Active,
            label: 'Active Config',
            loading: this.state.loading,
            content: (
              <CommonTextareaInput
                id="machine-config-active"
                value={this.state.lastJSON}
                rows={10}
                monospace
                disabled
              />
            ),
          },
          {
            value: TabKey.Default,
            label: 'Default Config',
            loading: this.state.loading,
            content: (
              <CommonTextareaInput
                id="machine-config-default"
                value={this.state.defaultJSON}
                rows={10}
                monospace
                disabled
              />
            ),
          },
        ]}
      />
    );
  }

  private renderButtons() {
    return (
      <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
        <DialogButton
          label="common.download"
          icon={<DownloadIcon />}
          disabled={this.state.loading}
          onClick={() => {
            if (!this.state.lastJSON) {
              NotifyHelper.warning({
                message_md: 'Empty value, nothing to download.',
              });
              return;
            }

            MiscHelper.saveAs(
              new Blob([JSON.stringify(this.state.lastJSON, null, 2)]),
              `${this.props.machineID}.json`
            );
          }}
        />

        <DialogButton
          label="common.update"
          icon={<UploadIcon />}
          color={RADIX.COLOR.SUCCESS}
          disabled={this.state.loading}
          onClick={() => {
            const value = this.state.nextJSON;
            if (!value) {
              NotifyHelper.warning({
                message_md: 'Update value cannot be empty.',
              });
              return;
            }

            if (
              this.state.nextJSON === this.state.lastJSON &&
              !this.state.preset
            ) {
              NotifyHelper.info({
                message_md: 'Update value is unchanged.',
              });
              return;
            }

            this.setState({ loading: true });
            AdminMachinesService.getInstance()
              .setMachineConfig(
                this.props.machineID,
                JSON.parse(value),
                this.state.preset
              )
              .then((success) => {
                if (!success) {
                  this.setState({ loading: false });
                  return;
                }

                clearTimeout(this.refetchTimeout);
                this.refetchTimeout = setTimeout(() => {
                  this.fetchConfig();
                }, 5000);
              });
          }}
        />

        <DialogButton
          label="common.reset"
          icon={<ResetIcon />}
          tooltip="Reset to active config"
          color={RADIX.COLOR.WARNING}
          disabled={this.state.loading}
          onClick={() => this.setState({ nextJSON: this.state.lastJSON })}
        />
      </Flex>
    );
  }
}
