import { DragItem, DropContainer } from 'enums/dnd.enums';
import { ISidebarFolder, ISidebarPitchList } from 'interfaces/i-sidebar';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { IPitchList } from 'lib_ts/interfaces/pitches';

const SORTED_PARENT_DEFS = ['team-users', 'team-machines', 'teams'];

export class SidebarHelper {
  static parseFolder = (value: string): string[] => {
    return value
      .split('/')
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
  };

  static getFolderName = (
    folder: ISidebarFolder,
    machineID: string
  ): string => {
    if (folder.depth !== 0) {
      // regular folders, return the final path component
      return folder.pathEnd;
    }

    return folder.type
      ? this.getTypedFolderName(folder)
      : this.getRootFolderName(folder, machineID);
  };

  /** given files in a folder, find the folders that are exactly 1 level deeper */
  static getSortedDescendantPaths = (
    // current depth of the root folder where these children will reside
    depth: number,
    descendants: ISidebarPitchList[]
  ): string[][] => {
    const output: string[] = ArrayHelper.unique(
      descendants
        // skip anything that lives in the current depth
        .filter((c) => c.pathComponents.length > depth)
        .map((c) => c.pathComponents.slice(0, depth + 1).join('/'))
    );

    output.sort((a, b) => a.localeCompare(b));

    return output.map((s) => s.split('/'));
  };

  static getFilesRecursively = (
    folder: ISidebarFolder
  ): ISidebarPitchList[] => {
    return [
      ...folder.files,
      ...folder.folders.flatMap((f) => this.getFilesRecursively(f)),
    ];
  };

  static makeRootFolders = (lists: IPitchList[]): ISidebarFolder[] => {
    const files = lists
      .map((l) => {
        const safePathComps = `${l.folder}`
          .split('/')
          .map((s) => s.trim())
          .filter((s) => s.length > 0);

        const file: ISidebarPitchList = {
          type: l.type,

          _parent_def: l._parent_def,
          _parent_field: l._parent_field,
          _parent_id: l._parent_id,

          pathComponents: safePathComps,
          pathDisplay: safePathComps.join('/'),
          pathEnd: safePathComps.slice(-1)[0],

          name: l.name,
          object: l,
        };

        return file;
      })
      .sort((a, b) => {
        const aIndex = SORTED_PARENT_DEFS.findIndex((d) => d === a._parent_def);
        const bIndex = SORTED_PARENT_DEFS.findIndex((d) => d === b._parent_def);
        return aIndex < bIndex ? -1 : 1;
      });

    const foldersDict: { [key: string]: ISidebarPitchList[] } = {
      ...ArrayHelper.groupBy(
        files.filter((f) => !f.type),
        '_parent_id'
      ),
      ...ArrayHelper.groupBy(
        files.filter((f) => f.type),
        'type'
      ),
    };

    // 0 => there is no folder value
    const depth = 0;

    const folders = Object.values(foldersDict).map((values) =>
      this.makeFolder(
        depth,
        [],
        values.filter((v) => v.pathComponents.length === depth),
        values.filter((v) => v.pathComponents.length > depth)
      )
    );

    return folders;
  };

  static makeFolder = (
    depth: number,
    // corresponding to this folder
    pathComponents: string[],
    children: ISidebarPitchList[],
    descendants: ISidebarPitchList[]
  ): ISidebarFolder => {
    if (children.length + descendants.length === 0) {
      throw new Error('SidebarHelper: makeFolder called on no files');
    }

    // sort by pitch lists' names
    children.sort((a, b) => a.name.localeCompare(b.name));

    descendants.sort((a, b) => {
      const aComps = a.pathComponents.length;
      const bComps = b.pathComponents.length;

      if (aComps !== bComps) {
        // sort the less nested stuff to the top
        return aComps < bComps ? -1 : 1;
      }

      // sort by folder name
      return a.pathEnd.localeCompare(b.pathEnd);
    });

    // used for basic summary of the folder
    const sample = children[0] ?? descendants[0];

    const descPaths = this.getSortedDescendantPaths(depth, descendants);

    const descFolders: ISidebarFolder[] = [];

    descPaths.forEach((descPath) => {
      const descChildren = descendants.filter((dc) =>
        ArrayHelper.equals(descPath, dc.pathComponents)
      );

      const descDescendants = descendants.filter(
        (dc) =>
          descPath.length < dc.pathComponents.length &&
          ArrayHelper.startsWith(descPath, dc.pathComponents)
      );

      if (descChildren.length + descDescendants.length === 0) {
        return;
      }

      descFolders.push(
        this.makeFolder(depth + 1, descPath, descChildren, descDescendants)
      );
    });

    const folder: ISidebarFolder = {
      depth: depth,
      type: sample.type,

      _parent_def: sample._parent_def,
      _parent_field: sample._parent_field,
      _parent_id: sample._parent_id,

      pathComponents: pathComponents,
      pathDisplay: pathComponents.join('/'),
      pathEnd: pathComponents[depth - 1],

      files: children.sort((a, b) =>
        (a.name ?? '').localeCompare(b.name ?? '')
      ),
      folders: descFolders,
    };

    return folder;
  };

  private static getRootFolderName(folder: ISidebarFolder, machineID: string) {
    switch (folder._parent_def) {
      case 'teams': {
        return 'TEAM LISTS';
      }

      case 'team-machines': {
        return `${machineID} LISTS`;
      }

      case 'team-users': {
        return 'PERSONAL LISTS';
      }

      default: {
        return 'MISC LISTS';
      }
    }
  }

  private static getTypedFolderName(folder: ISidebarFolder) {
    switch (folder.type) {
      case PitchListExtType.Card: {
        return 'PLAYER CARDS';
      }

      case PitchListExtType.Sample: {
        return 'SAMPLE LISTS';
      }

      case PitchListExtType.Reference: {
        return 'REFERENCE LISTS';
      }

      default: {
        return 'MISC LISTS';
      }
    }
  }

  static getDragDropDef(folder: ISidebarFolder): {
    container: DropContainer;
    accept: DragItem;
    id: string;
  } {
    if (folder.type) {
      switch (folder.type) {
        case PitchListExtType.Card: {
          return {
            container: DropContainer.CardFolder,
            accept: DragItem.CardList,
            id: 'SidebarCardLists',
          };
        }

        case PitchListExtType.Sample: {
          return {
            container: DropContainer.SampleFolder,
            accept: DragItem.SampleList,
            id: 'SidebarSampleLists',
          };
        }

        /** todo: add reference lists */

        default: {
          return {
            container: DropContainer.PersonalFolder,
            accept: DragItem.PersonalList,
            id: 'SidebarPersonalLists',
          };
        }
      }
    }

    switch (folder._parent_def) {
      case 'teams': {
        return {
          container: DropContainer.TeamFolder,
          accept: DragItem.TeamList,
          id: 'SidebarTeamLists',
        };
      }
      case 'team-machines': {
        return {
          container: DropContainer.MachineFolder,
          accept: DragItem.MachineList,
          id: 'SidebarMachineLists',
        };
      }
      case 'team-users': {
        return {
          container: DropContainer.PersonalFolder,
          accept: DragItem.PersonalList,
          id: 'SidebarPersonalLists',
        };
      }
      default: {
        return {
          container: DropContainer.PersonalFolder,
          accept: DragItem.PersonalList,
          id: 'SidebarPersonalLists',
        };
      }
    }
  }
}
