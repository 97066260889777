import { ErrorBoundary } from 'components/common/error-boundary';
import { SelectCard } from 'components/sections/quick-session/steps/select-card';
import { SelectPitch } from 'components/sections/quick-session/steps/select-pitch';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import { HittersContext } from 'contexts/hitters.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import {
  IQuickSessionContext,
  QuickSessionContext,
  QuickSessionProvider,
  QuickSessionStep,
} from 'contexts/quick-session.context';
import { ISectionsContext } from 'contexts/sections.context';
import { VideosContext } from 'contexts/videos/videos.context';
import React from 'react';
import './index.scss';

const COMPONENT_NAME = 'QuickSession';

interface IProps {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  sectionsCx: ISectionsContext;
}

interface IState {}

export class QuickSession extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  private renderStep(quickCx: IQuickSessionContext) {
    switch (quickCx.step) {
      case QuickSessionStep.SelectCard: {
        return <SelectCard quickCx={quickCx} />;
      }

      case QuickSessionStep.SelectPitch: {
        return (
          <GlobalContext.Consumer>
            {(globalCx) => (
              <CookiesContext.Consumer>
                {(cookiesCx) => (
                  <HittersContext.Consumer>
                    {(hittersCx) => (
                      <VideosContext.Consumer>
                        {(videosCx) => (
                          <AimingProvider
                            machineCx={this.props.machineCx}
                            matchingCx={this.props.matchingCx}
                            videosCx={videosCx}
                          >
                            <AimingContext.Consumer>
                              {(aimingCx) => (
                                <SelectPitch
                                  authCx={this.props.authCx}
                                  machineCx={this.props.machineCx}
                                  matchingCx={this.props.matchingCx}
                                  aimingCx={aimingCx}
                                  cookiesCx={cookiesCx}
                                  globalCx={globalCx}
                                  hittersCx={hittersCx}
                                  quickCx={quickCx}
                                  videosCx={videosCx}
                                />
                              )}
                            </AimingContext.Consumer>
                          </AimingProvider>
                        )}
                      </VideosContext.Consumer>
                    )}
                  </HittersContext.Consumer>
                )}
              </CookiesContext.Consumer>
            )}
          </GlobalContext.Consumer>
        );
      }

      default: {
        return;
      }
    }
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <QuickSessionProvider
          authCx={this.props.authCx}
          sectionsCx={this.props.sectionsCx}
          machineCx={this.props.machineCx}
        >
          <QuickSessionContext.Consumer>
            {(quickCx) => this.renderStep(quickCx)}
          </QuickSessionContext.Consumer>
        </QuickSessionProvider>
      </ErrorBoundary>
    );
  }
}
