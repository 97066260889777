import { IInputBase, IInputHint, IInputLabel, IInputWrapper } from './_base';

export interface ITime {
  // 0-23
  h: number;

  // 0-59
  m: number;

  // e.g. -5 for EST
  offset: number;
}

export const getTimeFromDate = (value: Date, nearestMin: number): ITime => {
  const o: ITime = {
    h: value.getHours(),
    m: Math.floor(value.getMinutes() / nearestMin) * nearestMin,
    // convert minutes to hours
    offset: -value.getTimezoneOffset() / 60,
  };

  return o;
};

export interface IDateWidget {
  minDate?: Date;
  maxDate?: Date;
}

export interface IDateInput
  extends IInputBase,
    IInputLabel,
    IInputHint,
    IInputWrapper,
    IDateWidget {
  defaultValue?: Date;
  showTime?: boolean;

  onChange: (value: Date | undefined) => void;
}
