import { Box, Grid } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { t } from 'i18next';
import { FT_TO_INCHES } from 'lib_ts/classes/math.utilities';
import {
  BAD_MACHINE_PRECISION,
  GOOD_MACHINE_PRECISION,
} from 'lib_ts/classes/metric.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IRealMachinePerformance } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import React from 'react';
import { CommonDetails } from '../details';

interface IBreakdownDatum {
  category: string;
  mean?: number;
  median?: number;
  max?: number;
  range_bad: number;
  range_good: number;
}

interface IProps {
  timestamp?: string;
  performance: Partial<IRealMachinePerformance>;
}

interface IState {}

export class MachinePerformanceBreakdown extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.getClassName = this.getClassName.bind(this);
  }

  private getClassName(
    value: number | undefined,
    datum: IBreakdownDatum
  ): string | undefined {
    if (value === undefined) {
      return;
    }

    if (value < datum.range_good) {
      return 'text-success';
    }

    if (value > datum.range_bad) {
      return 'text-danger';
    }

    return 'text-warning';
  }

  render() {
    const [mean, median, max] = [
      this.props.performance.mean_stdev,
      this.props.performance.median_stdev,
      this.props.performance.max_stdev,
    ];

    const data: IBreakdownDatum[] = [
      {
        category: 'plx_in',
        mean: mean ? mean.plx * FT_TO_INCHES : undefined,
        median: median ? median.plx * FT_TO_INCHES : undefined,
        max: max ? max.plx * FT_TO_INCHES : undefined,
        range_bad: BAD_MACHINE_PRECISION.plx * FT_TO_INCHES,
        range_good: GOOD_MACHINE_PRECISION.plx * FT_TO_INCHES,
      },
      {
        category: 'plz_in',
        mean: mean ? mean.plz * FT_TO_INCHES : undefined,
        median: median ? median.plz * FT_TO_INCHES : undefined,
        max: max ? max.plz * FT_TO_INCHES : undefined,
        range_bad: BAD_MACHINE_PRECISION.plz * FT_TO_INCHES,
        range_good: GOOD_MACHINE_PRECISION.plz * FT_TO_INCHES,
      },
      {
        category: 'vy',
        mean: mean?.vy,
        median: median?.vy,
        max: max?.vy,
        range_bad: BAD_MACHINE_PRECISION.vy,
        range_good: GOOD_MACHINE_PRECISION.vy,
      },
      {
        category: 'wx',
        mean: mean?.wx,
        median: median?.wx,
        max: max?.wx,
        range_bad: BAD_MACHINE_PRECISION.wx,
        range_good: GOOD_MACHINE_PRECISION.wx,
      },
      {
        category: 'wy',
        mean: mean?.wy,
        median: median?.wy,
        max: max?.wy,
        range_bad: BAD_MACHINE_PRECISION.wy,
        range_good: GOOD_MACHINE_PRECISION.wy,
      },
      {
        category: 'wz',
        mean: mean?.wz,
        median: median?.wz,
        max: max?.wz,
        range_bad: BAD_MACHINE_PRECISION.wz,
        range_good: GOOD_MACHINE_PRECISION.wz,
      },
      {
        category: 'break_x_ft',
        mean: mean?.break_x_ft,
        median: median?.break_x_ft,
        max: max?.break_x_ft,
        range_bad: BAD_MACHINE_PRECISION.break_x_ft,
        range_good: GOOD_MACHINE_PRECISION.break_x_ft,
      },
      {
        category: 'break_z_ft',
        mean: mean?.break_z_ft,
        median: median?.break_z_ft,
        max: max?.break_z_ft,
        range_bad: BAD_MACHINE_PRECISION.break_z_ft,
        range_good: GOOD_MACHINE_PRECISION.break_z_ft,
      },
    ];

    return (
      <ErrorBoundary componentName="MachinePerformanceBreakdown">
        <Grid columns="3" gap={RADIX.FLEX.GAP.MD}>
          {/* header row */}
          <Box>St. Deviation</Box>
          <Box>Average</Box>
          <Box>Worst</Box>

          {/* other rows */}
          {data.map((d, i) => (
            <React.Fragment key={i}>
              <Box key={`category-${i}`}>{d.category}</Box>
              <Box
                key={`average-${i}`}
                className={this.getClassName(d.mean, d)}
              >
                <CommonTooltip
                  trigger={<>{d.mean?.toFixed(2) ?? t('common.no-data')}</>}
                  content={
                    <ul className="no-style">
                      <li className="text-success">
                        GOOD: Under {d.range_good}
                      </li>
                      <li className="text-warning">
                        NORMAL: Between {d.range_good} and {d.range_bad}
                      </li>
                      <li className="text-danger">BAD: Over {d.range_bad}</li>
                    </ul>
                  }
                />
              </Box>
              <Box key={`worst-${i}`} className={this.getClassName(d.max, d)}>
                <CommonTooltip
                  trigger={<>{d.max?.toFixed(2) ?? t('common.no-data')}</>}
                  content={
                    <ul className="no-style">
                      <li className="text-success">
                        GOOD: Under {d.range_good}
                      </li>
                      <li className="text-warning">
                        NORMAL: Between {d.range_good} and {d.range_bad}
                      </li>
                      <li className="text-danger">BAD: Over {d.range_bad}</li>
                    </ul>
                  }
                />
              </Box>
            </React.Fragment>
          ))}
        </Grid>

        <CommonDetails summary="Raw">
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </CommonDetails>
      </ErrorBoundary>
    );
  }
}
