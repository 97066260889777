import { CheckMode } from 'enums/tables';
import { ICheckable } from 'lib_ts/interfaces/mongo/_base';

export interface ICheckableTally {
  key: number;
  checked: number;
  total: number;
  mode: CheckMode;
}

export const calculateTally = (values: ICheckable[]): ICheckableTally => {
  const checked = values.filter((m) => m._checked).length;
  const total = values.length;

  const output: ICheckableTally = {
    key: Date.now(),
    checked: checked,
    total: total,
    mode:
      total === 0 || checked === 0
        ? 'none'
        : checked === total
        ? 'all'
        : 'some',
  };

  return output;
};
