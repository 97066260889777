import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Separator,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonFileUploader } from 'components/common/file-uploader';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { t } from 'i18next';
import {
  PitcherHand,
  PitcherRelease,
  PlayerLevel,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import { IPitcherCard } from 'lib_ts/interfaces/pitches/i-pitcher-card';
import React from 'react';
import { DEFAULT_AVATAR_PATH, MainService } from 'services/main.service';

const MAX_NAME_LENGTH = 30;

const MAX_AVATAR_SIZE_MB = 5;

const FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

export const DEFAULT_CARD: IPitcherCard = {
  name: '',
  avatar_path: '',
  hand: PitcherHand.RHP,
  release: PitcherRelease.ThreeQuarter,
  level: PlayerLevel.Pro,
};

interface IProps {
  list: IPitchList;
  listsCx: IPitchListsContext;
}

interface IState {
  card: IPitcherCard;

  avatar_url: string;
}

export class DetailsTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      card: props.list.card ?? DEFAULT_CARD,
      avatar_url: DEFAULT_AVATAR_PATH,
    };

    this.onAvatarChange = this.onAvatarChange.bind(this);
    this.refreshAvatarUrl = this.refreshAvatarUrl.bind(this);
  }

  componentDidMount(): void {
    this.refreshAvatarUrl();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    if (prevState.card.avatar_path !== this.state.card.avatar_path) {
      this.refreshAvatarUrl();
    }
  }

  private refreshAvatarUrl() {
    const path = this.state.card.avatar_path;

    if (!path) {
      this.setState({ avatar_url: DEFAULT_AVATAR_PATH });
      return;
    }

    MainService.getInstance()
      .signUrls([path])
      .then((dict) => {
        this.setState({ avatar_url: dict[path].url });
      });
  }

  private async onAvatarChange(files: File[]): Promise<void> {
    /** prevent/warn users before leaving if uploads are still processing */
    return this.props.listsCx
      .uploadAvatar(this.props.list._id, files)
      .then((list) => {
        if (!list || !list.card) {
          NotifyHelper.warning({
            message_md: 'Invalid response from server, image upload failed.',
          });
          return;
        }

        NotifyHelper.success({ message_md: 'Avatar image updated!' });

        this.setState({
          card: {
            ...this.state.card,
            avatar_path: list.card.avatar_path,
          },
        });
      });
  }

  render() {
    const currentValue = this.state.card;
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
        <CommonFormGrid columns={2}>
          <Flex gridColumn="span 2" justify="between">
            <Heading size={RADIX.HEADING.SIZE.SM}>Details</Heading>

            <Button
              disabled={this.props.listsCx.loading}
              onClick={() => {
                if (
                  !this.state.card.hand ||
                  !this.state.card.level ||
                  !this.state.card.name ||
                  !this.state.card.release
                ) {
                  NotifyHelper.warning({
                    message_md: t('common.check-inputs-msg'),
                  });
                  return;
                }

                const payload: Partial<IPitchList> = {
                  _id: this.props.list._id,
                  card: this.state.card,
                };

                this.props.listsCx.updateList({ payload: payload });
              }}
            >
              Save
            </Button>
          </Flex>

          <Box>
            <CommonTextInput
              id="card-details-name"
              label="common.name"
              name="card.name"
              value={this.state.card.name}
              placeholder="Type in a player name"
              maxLength={MAX_NAME_LENGTH}
              onChange={(v) =>
                this.setState({
                  card: {
                    ...currentValue,
                    name: v ?? '',
                  },
                })
              }
            />
          </Box>
          <Box>
            <CommonSelectInput
              id="card-details-level"
              name="card.level"
              label="common.level"
              options={Object.values(PlayerLevel).map((m) => ({
                label: m,
                value: m,
              }))}
              value={this.state.card.level}
              onChange={(v) =>
                this.setState({
                  card: {
                    ...currentValue,
                    level: v as PlayerLevel,
                  },
                })
              }
              optional
              skipSort
            />
          </Box>
          <Box>
            <CommonSelectInput
              id="card-details-hand"
              name="card.hand"
              label="Handedness"
              options={Object.values(PitcherHand).map((m) => ({
                label: m,
                value: m,
              }))}
              value={this.state.card.hand}
              optional
              onChange={(v) =>
                this.setState({
                  card: {
                    ...currentValue,
                    hand: v as PitcherHand,
                  },
                })
              }
            />
          </Box>
          <Box>
            <CommonSelectInput
              id="card-details-release"
              name="card.release"
              label="Release Slot"
              options={Object.values(PitcherRelease).map((m) => ({
                label: m,
                value: m,
              }))}
              value={this.state.card.release}
              optional
              onChange={(v) =>
                this.setState({
                  card: {
                    ...currentValue,
                    release: v as PitcherRelease,
                  },
                })
              }
            />
          </Box>
        </CommonFormGrid>

        <CommonFormGrid columns={2}>
          <Flex justify="center">
            <Box>
              <Avatar
                size="9"
                src={this.state.avatar_url}
                fallback={
                  this.state.card.name
                    .split(' ')
                    .map((s) => (s.length > 0 ? s.substring(0, 1) : ''))
                    .join('')
                    .toUpperCase() || 'NA'
                }
              />
            </Box>
          </Flex>
          <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
            <CommonFileUploader
              id="card-details-avatar"
              label="UPLOAD AVATAR"
              maxMB={MAX_AVATAR_SIZE_MB}
              acceptedTypes={FILE_TYPES}
              progress={0}
              notifyMode="aggregate"
              onChange={(files) => this.onAvatarChange(files)}
            />
            <Separator size="4" />
            <Text>
              For best results, choose an image that is approximately square
              with the subject in the center.
            </Text>
          </Flex>
        </CommonFormGrid>
      </Flex>
    );
  }
}
