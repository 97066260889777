import { ClipboardCopyIcon, PauseIcon, PlayIcon } from '@radix-ui/react-icons';
import { Flex, Separator, Text, TextField } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonTextInput } from 'components/common/form/text';
import { CommonLink } from 'components/common/link';
import { SettingForm } from 'components/common/settings-dialog/form';
import { SettingRow } from 'components/common/settings-dialog/row';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { REMOTE_PRESET_OPTIONS, RemoteDevice } from 'enums/remote-controls';
import { t } from 'i18next';
import { IHotkeyConfig } from 'lib_ts/interfaces/i-remote-controls';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'RemoteControlSection';

const MAX_DELAY_SEC = 10;

interface IProps {
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
}

interface IState {
  /** for displaying the last hotkey event code (while unpaused) to the user from the most recent keypress */
  lastKey?: string;

  /** for pausing after recording a keypress so subsequent keypresses don't overwrite the display */
  paused: boolean;

  selectedPreset?: RemoteDevice;

  showManualMode: boolean;
}

export class RemoteControlSection extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      paused: false,
      showManualMode: false,
    };

    this.handleKeydown = this.handleKeydown.bind(this);
    this.setHotKeyConfig = this.setHotKeyConfig.bind(this);

    this.renderMainControls = this.renderMainControls.bind(this);
    this.renderManualControls = this.renderManualControls.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  private handleKeydown(event: KeyboardEvent) {
    if (!this.state.showManualMode) {
      return;
    }

    if (this.state.paused) {
      return;
    }

    if (event.ctrlKey || event.shiftKey || event.altKey) {
      /** only listen for raw key presses without any modifiers */
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    if (event.repeat) {
      return;
    }

    if (this.state.lastKey === event.code) {
      return;
    }

    this.setState({ lastKey: event.code });
  }

  private setHotKeyConfig(config: Partial<IHotkeyConfig>) {
    const machine = this.props.machineCx.machine;

    const nextHotkeys: Partial<IHotkeyConfig> = {
      ...machine.remote_hotkeys,
      ...config,
    };

    this.props.machineCx.update({
      _id: machine._id,
      remote_hotkeys: nextHotkeys,
    });
  }

  private renderMainControls() {
    const disabled = this.state.showManualMode && !this.state.paused;
    const delay_s =
      (this.props.machineCx.machine.remote_hotkeys?.delay_ms ?? 0) / 1000;

    return (
      <>
        <SettingRow
          header="settings.device-presets"
          description={
            <>
              <p>{t('settings.device-presets-msg')}</p>
              <CommonLink
                url={t('common.intercom-url-x', {
                  x: HELP_URLS.SETTINGS_ACCESSORIES,
                })}
              >
                {t('common.read-more')}
              </CommonLink>
            </>
          }
          input={
            <CommonSelectInput
              id="remote-control-preset"
              name="selectedPreset"
              options={REMOTE_PRESET_OPTIONS.filter(
                (o) => o.value !== RemoteDevice.Empty
              )}
              value={this.state.selectedPreset}
              onChange={(v) => {
                this.setState(
                  {
                    selectedPreset: (v as RemoteDevice) || RemoteDevice.Empty,
                  },
                  () => {
                    const preset = REMOTE_PRESET_OPTIONS.find(
                      (o) => o.value === this.state.selectedPreset
                    );

                    if (!preset) {
                      return;
                    }

                    this.setHotKeyConfig(preset.mapping);
                  }
                );
              }}
              optional
            />
          }
        />

        <Separator size="4" />
        <SettingRow
          header="settings.action-send-pitch-fire-drop-ball"
          description={
            <Text>{t('settings.action-send-pitch-fire-drop-ball-msg')}</Text>
          }
          input={
            <CommonTextInput
              id="remote-control-fire"
              data-testid="fire-mapping"
              disabled={disabled}
              // 2-way bind to value so that preset menu changes will reflect right away
              value={this.props.machineCx.machine.remote_hotkeys?.fire}
              placeholder="None"
              onChange={(v) => this.setHotKeyConfig({ fire: v })}
            />
          }
        />
        <Separator size="4" />
        <SettingRow
          header="settings.action-previous"
          description={<Text>{t('settings.action-previous-msg')}</Text>}
          input={
            <CommonTextInput
              id="remote-control-previous"
              data-testid="previous-mapping"
              disabled={disabled}
              // 2-way bind to value so that preset menu changes will reflect right away
              value={this.props.machineCx.machine.remote_hotkeys?.previous}
              placeholder="None"
              onChange={(v) => this.setHotKeyConfig({ previous: v })}
            />
          }
        />
        <Separator size="4" />
        <SettingRow
          header="settings.action-next"
          description={<Text>{t('settings.action-next-msg')}</Text>}
          input={
            <CommonTextInput
              id="remote-control-next"
              data-testid="next-mapping"
              disabled={disabled}
              // 2-way bind to value so that preset menu changes will reflect right away
              value={this.props.machineCx.machine.remote_hotkeys?.next}
              placeholder="None"
              onChange={(v) => this.setHotKeyConfig({ next: v })}
            />
          }
        />
        <Separator size="4" />
        <SettingRow
          header="settings.delay-seconds"
          description={
            <Text>{t('settings.delay-seconds-msg', { s: delay_s })}</Text>
          }
          input={
            <CommonTextInput
              id="remote-control-delay"
              type="number"
              disabled={disabled}
              value={delay_s.toString()}
              onNumericChange={(v) => {
                /** value is given in seconds, saved in ms */
                if (v < 0) {
                  NotifyHelper.warning({
                    message_md: t('settings.input-non-negative-value-msg'),
                  });
                  return;
                }

                if (v > MAX_DELAY_SEC) {
                  NotifyHelper.warning({
                    message_md: t(
                      'settings.input-value-less-than-seconds-msg',
                      { x: MAX_DELAY_SEC }
                    ),
                  });
                  return;
                }

                this.setHotKeyConfig({
                  delay_ms: v * 1_000,
                });
              }}
            />
          }
        />
      </>
    );
  }

  private renderManualControls() {
    return (
      <>
        <SettingRow
          header="settings.manual-mapping-controls"
          description={<Text>{t('settings.manual-mapping-controls-msg')}</Text>}
          input={
            <Flex justify="end">
              <CommonSwitchInput
                id="remote-control-manual-mode"
                defaultChecked={this.state.showManualMode}
                onCheckedChange={(v) =>
                  this.setState({
                    showManualMode: v,
                    paused: !v,
                  })
                }
              />
            </Flex>
          }
        />

        {this.state.showManualMode && (
          <>
            <Separator size="4" />

            <SettingRow
              header="settings.key-listener"
              description={
                <ReactMarkdown children={t('settings.key-listener-msg')} />
              }
              input={
                <CommonFormGrid columns={1}>
                  <TextField.Root
                    placeholder={
                      this.state.lastKey ??
                      t('settings.press-any-key').toString()
                    }
                    readOnly
                  >
                    <TextField.Slot className="cursor-pointer" side="right">
                      {this.state.paused ? (
                        <PlayIcon
                          onClick={() => this.setState({ paused: false })}
                        />
                      ) : (
                        <PauseIcon
                          onClick={() => this.setState({ paused: true })}
                        />
                      )}
                    </TextField.Slot>
                    <TextField.Slot className="cursor-pointer" side="right">
                      <ClipboardCopyIcon
                        onClick={() => {
                          if (this.state.lastKey) {
                            navigator.clipboard
                              .writeText(this.state.lastKey)
                              .then(() =>
                                NotifyHelper.success({
                                  message_md: t(
                                    'common.copied-x-to-clipboard-msg',
                                    { x: this.state.lastKey }
                                  ),
                                })
                              );
                          }
                        }}
                      />
                    </TextField.Slot>
                  </TextField.Root>

                  <CommonCallout
                    text={t('settings.pause-key-listener-msg').toString()}
                  />
                </CommonFormGrid>
              }
            />
          </>
        )}
      </>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SettingForm>
          {this.renderMainControls()}
          <Separator size="4" />
          {this.renderManualControls()}
        </SettingForm>
      </ErrorBoundary>
    );
  }
}
