import {
  Button,
  Code,
  DataList,
  Em,
  Flex,
  Table,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { lightFormat, parseISO } from 'date-fns';
import { AdminRoute } from 'enums/route.enums';
import { t } from 'i18next';
import { ApiType } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IApiKey } from 'lib_ts/interfaces/api/key';
import React from 'react';
import { CustomerApiService } from 'services/admin/customer-api.service';

const COMPONENT_NAME = 'ApiKeysManagement';

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
}

interface IDialogs {
  dialogRevoke?: number;
}

interface IState extends IDialogs {
  loaded: boolean;

  keys: IApiKey[];

  selected?: IApiKey;
}

export class ApiKeysManagement extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      loaded: false,
      keys: [],
    };

    this.renderRevokeDialog = this.renderRevokeDialog.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    CustomerApiService.getInstance()
      .getTeamKeys()
      .then((keys) => {
        this.setState({
          loaded: true,
          keys: keys,
        });
      });
  }

  private renderRevokeDialog() {
    if (!this.state.dialogRevoke) {
      return;
    }

    const apiKey = this.state.selected;
    if (!apiKey) {
      return;
    }

    return (
      <CommonConfirmationDialog
        key={this.state.dialogRevoke}
        identifier="ConfirmRevokeApiKey"
        maxWidth={RADIX.DIALOG.WIDTH.MD}
        title="Revoke Key"
        content={
          <Text>
            This will revoke API access using <Code>{apiKey.key}</Code> and
            cannot be undone. You will need to request a new key to restore
            access. Are you sure you want to proceed?
          </Text>
        }
        action={{
          label: 'Revoke',
          color: RADIX.COLOR.DANGER,
          onClick: () => {
            CustomerApiService.getInstance()
              .revokeKey(apiKey)
              .then((success) => {
                if (success) {
                  this.setState({
                    keys: this.state.keys.filter((k) => k._id !== apiKey._id),
                  });
                }
              });
          },
        }}
      />
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
          <AdminTabNav active={AdminRoute.ApiKeys} />

          <DataList.Root>
            <DataList.Item>
              <DataList.Label>
                <Button
                  color={RADIX.COLOR.SUCCESS}
                  className="btn-block"
                  disabled={!this.state.loaded}
                  onClick={() => {
                    if (
                      this.state.keys.findIndex(
                        (k) => k.type === ApiType.Customer && !k.disabled
                      ) !== -1
                    ) {
                      NotifyHelper.warning({
                        message_md:
                          `You already have an active API key (type: ${ApiType.Customer}). ` +
                          `Please revoke existing keys before requesting a new key of the same type.`,
                      });
                      return;
                    }

                    CustomerApiService.getInstance()
                      .generateTeamKey({
                        _parent_id: this.props.authCx.current.teamID,
                        type: ApiType.Customer,
                      })
                      .then((result) => {
                        if (!result) {
                          return;
                        }

                        this.setState({
                          keys: [...this.state.keys, result],
                        });
                      });
                  }}
                >
                  Request
                </Button>
              </DataList.Label>
              <DataList.Value>
                Use this button to request API keys.&nbsp;
                <Em>Do not share these keys with anyone.</Em>
              </DataList.Value>
            </DataList.Item>
          </DataList.Root>

          {this.renderTable()}
        </Flex>

        {this.renderRevokeDialog()}
      </ErrorBoundary>
    );
  }

  private renderTable() {
    return (
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Key</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Created</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell align="center">
              Actions
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.keys.map((k, i) => (
            <Table.Row key={`api-key-${i}`}>
              <Table.Cell>
                <Code
                  className="cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(k.key);

                    NotifyHelper.success({
                      message_md: `Copied to clipboard!`,
                    });
                  }}
                >
                  *****-{k.key.split('-').slice(-1)}
                </Code>
              </Table.Cell>
              <Table.Cell className="capitalize">{k.type}</Table.Cell>
              <Table.Cell>
                {lightFormat(parseISO(k._created), 'yyyy-MM-dd')}
              </Table.Cell>
              <Table.Cell align="center">
                <Button
                  color={RADIX.COLOR.DANGER}
                  size={RADIX.BUTTON.SIZE.SM}
                  onClick={() =>
                    this.setState({
                      selected: k,
                      dialogRevoke: Date.now(),
                    })
                  }
                >
                  Revoke
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}

          {this.state.keys.length === 0 && (
            <Table.Row>
              <Table.Cell>{t('common.no-data')}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    );
  }
}
