import { Text } from '@radix-ui/themes';
import { IInputHint } from 'interfaces/forms/_base';
import { ITextBase } from 'interfaces/forms/text';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const HINT_DELIMITER = ' · ';

interface IProps extends IInputHint, ITextBase {}

export const CommonTextHint = (props: IProps) => {
  const blurbs = [
    props.hint_md,
    props.maxLength !== undefined && props.maxLength > 0
      ? `${(props.value ?? '').length} / ${props.maxLength} char. limit`
      : undefined,
  ]
    .filter((s) => s)
    .join(HINT_DELIMITER);

  if (blurbs.length === 0) {
    return <></>;
  }

  return (
    <Text size={RADIX.TEXT.SIZE.HINT} color={RADIX.COLOR.NEUTRAL}>
      {blurbs}
    </Text>
  );
};
