import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'ResetTrainingDialog';

interface IProps {
  pitches: IPitch[];
  onClose: (success: boolean) => void;
}

interface IState {}

export class ResetTrainingDialog extends React.Component<IProps, IState> {
  state: IState = {};

  render() {
    const isSingle = this.props.pitches.length === 1;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <MachineContext.Consumer>
          {(machineCx) => (
            <MatchingShotsContext.Consumer>
              {(matchingCx) => (
                <CommonDialog
                  identifier={COMPONENT_NAME}
                  width={RADIX.DIALOG.WIDTH.SM}
                  title="common.reset-training-data"
                  loading={matchingCx.loading}
                  content={
                    <ReactMarkdown
                      children={[
                        t('pl.confirm-reset-x', {
                          x: t(
                            isSingle ? 'common.pitch' : 'common.pitches'
                          ).toLowerCase(),
                        }),
                        PitchListHelper.enumeratePitches(this.props.pitches, 5),
                        t('pl.warning-reset-impact'),
                      ].join('\n\n')}
                    />
                  }
                  buttons={[
                    {
                      label: 'common.continue',
                      color: RADIX.COLOR.WARNING,
                      onClick: () => {
                        const hashes = ArrayHelper.unique(
                          this.props.pitches.map(
                            (p) =>
                              getMSFromMSDict(p, machineCx.machine).ms
                                ?.matching_hash ?? ''
                          )
                        );

                        if (hashes.length === 0) {
                          this.props.onClose(false);
                          return;
                        }

                        matchingCx
                          .archiveShots({
                            machineID: machineCx.machine.machineID,
                            ball_type: machineCx.machine.ball_type,
                            matching_hashes: hashes,
                          })
                          .then((success) => {
                            if (!success) {
                              return;
                            }

                            // reload shots for the hashes since the archive would have reset the dictionary
                            matchingCx.updateHashes({
                              matching_hashes: hashes,
                              includeHitterPresent: false,
                              includeLowConfidence: true,
                            });

                            if (
                              this.props.pitches.findIndex(
                                (p) => p._id === machineCx.lastPitchID
                              ) !== -1
                            ) {
                              machineCx.resetMSHash();
                            }

                            this.props.onClose(true);
                          });
                      },
                    },
                  ]}
                  onClose={() => this.props.onClose(false)}
                />
              )}
            </MatchingShotsContext.Consumer>
          )}
        </MachineContext.Consumer>
      </ErrorBoundary>
    );
  }
}
