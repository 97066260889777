import { Vector3 } from 'three';
import {
  ICSVRow,
  ICustomBreaks,
  ICustomSpins,
  IHawkeye,
  IPlateSpec,
  IStatCast,
} from '../interfaces/csv';
import { DEFAULT_PLATE, IPlateLoc } from '../interfaces/pitches';
import {
  IOrientation,
  ISeamOrientation,
  ISpin,
} from '../interfaces/pitches/i-base';
import { BallHelper } from './ball.helper';
import { HawkeyeHelper } from './hawkeye.helper';
import { isNumber, safeNumber } from './math.utilities';

const LIMITS = {
  PLATE: {
    HEIGHT: {
      MAX: 6,
      MIN: 0,
    },
    SIDE: {
      MAX: 2.5,
      MIN: -2.5,
    },
  },
};

const CSV_NUM_COL = [
  'SeamLat',
  'SeamLon',

  'seam_latitude',
  'seam_longitude',

  'PlateLocHeight',
  'PlateLocSide',

  /** allow any format to use hawkeye rotation matrix notation */
  'Xx',
  'Xy',
  'Xz',
  'Yx',
  'Yy',
  'Yz',
  'Zx',
  'Zy',
  'Zz',

  /** allow any format to specify breaks */
  'HorizontalBreakIn',
  'VerticalBreakIn',
];

export const CUSTOM_RELEASE_COL = [
  'ReleaseX',
  'ReleaseY',
  'ReleaseZ',
  'ReleaseV',
];

export const CUSTOM_BREAKS_NUM_COL = [
  ...CSV_NUM_COL,
  ...CUSTOM_RELEASE_COL,
  'HorizontalBreakIn',
  'VerticalBreakIn',
  'NetSpin',
];

export const CUSTOM_SPINS_NUM_COL = [
  ...CSV_NUM_COL,
  ...CUSTOM_RELEASE_COL,
  'SpinX',
  'SpinY',
  'SpinZ',
];

export const HAWKEYE_RELEASE_COL = [
  'releaseX',
  'releaseY',
  'releaseZ',
  'releaseSpeed',
];

export const HAWKEYE_NUM_COL = [
  ...CSV_NUM_COL,
  ...HAWKEYE_RELEASE_COL,
  'spinX',
  'spinY',
  'spinZ',
  'x0',
  'y0',
  'z0',
  'vx0',
  'vy0',
  'vz0',
  'ax0',
  'ay0',
  'az0',
];

export const STATCAST_RELEASE_COL = [
  'release_pos_x',
  'release_pos_y',
  'release_pos_z',
  'release_spin_rate',
];

export const STATCAST_NUM_COL = [
  ...CSV_NUM_COL,
  ...STATCAST_RELEASE_COL,
  'vx0',
  'vy0',
  'vz0',
  'ax',
  'ay',
  'az',
];

export class CSVHelper {
  static createOutput(row: ICSVRow, numericCols: string[]): any | undefined {
    try {
      const output: any = { ...row };
      const warnedColumns: string[] = [];
      Object.keys(row)
        .filter((key) => numericCols.includes(key) && output[key] !== undefined)
        .forEach((key) => {
          const maybeNum = safeNumber(output[key] as string);

          if (maybeNum !== undefined) {
            output[key] = maybeNum;
            return;
          }

          if (!warnedColumns.includes(key)) {
            console.warn(`Numeric column ${key} has NaN value, skipping...`);
            warnedColumns.push(key);
            return;
          }

          // already warned about this key, do nothing
        });

      return output;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  /** if IPlateSpec is provided via CSV, check to make sure values are usable
   * - if no IPlateSpec values are provided, return default plate
   * - if bad IPlateSpec values are provided, return an error message string
   * - if acceptable IPlateSpec values are provided, return a corresponding IPlateLoc (1:1 mapping)
   */
  static getPlateLoc(data: Partial<IPlateSpec>): IPlateLoc {
    try {
      if (data.PlateLocSide === undefined || isNaN(data.PlateLocSide)) {
        throw new Error('Plate location side is not a number.');
      }

      if (data.PlateLocHeight === undefined || isNaN(data.PlateLocHeight)) {
        throw new Error('Plate location height is not a number.');
      }

      if (data.PlateLocSide > LIMITS.PLATE.SIDE.MAX) {
        throw new Error(
          `Plate location side value (${data.PlateLocSide} ft.) is greater than the maximum value (${LIMITS.PLATE.SIDE.MAX} ft.).`
        );
      }

      if (data.PlateLocSide < LIMITS.PLATE.SIDE.MIN) {
        throw new Error(
          `Plate location side value (${data.PlateLocSide} ft.) is less than the minimum value (${LIMITS.PLATE.SIDE.MIN} ft.).`
        );
      }

      if (data.PlateLocHeight > LIMITS.PLATE.HEIGHT.MAX) {
        throw new Error(
          `Plate location height value (${data.PlateLocHeight} ft.) is greater than the maximum value (${LIMITS.PLATE.HEIGHT.MAX} ft.).`
        );
      }

      if (data.PlateLocHeight < LIMITS.PLATE.HEIGHT.MIN) {
        throw new Error(
          `Plate location height value (${data.PlateLocHeight} ft.) is less than the minimum value (${LIMITS.PLATE.HEIGHT.MIN} ft.).`
        );
      }

      return {
        plate_x: data.PlateLocSide,
        plate_z: data.PlateLocHeight,
      };
    } catch (e) {
      console.error(e);
      return DEFAULT_PLATE;
    }
  }

  static createCustomBreaks(row: ICSVRow): ICustomBreaks | undefined {
    return CSVHelper.createOutput(row, CUSTOM_BREAKS_NUM_COL);
  }

  static createCustomSpins(row: ICSVRow): ICustomSpins | undefined {
    return CSVHelper.createOutput(row, CUSTOM_SPINS_NUM_COL);
  }

  static createHawkeye(row: ICSVRow): IHawkeye | undefined {
    return CSVHelper.createOutput(row, HAWKEYE_NUM_COL);
  }

  static createStatCast(row: ICSVRow): IStatCast | undefined {
    return CSVHelper.createOutput(row, STATCAST_NUM_COL);
  }

  static hasBreakColumns(data: Partial<ICSVRow>): boolean {
    const keys = Object.keys(data);
    return ['HorizontalBreakIn', 'VerticalBreakIn'].every((m) =>
      keys.includes(m)
    );
  }

  static hasBreakDetails(data: Partial<ICSVRow>): boolean {
    return [data.HorizontalBreakIn, data.VerticalBreakIn].every((m) =>
      isNumber(m)
    );
  }

  /** same criteria as getQuaternion to detect if seam info is provided */
  static hasSeamDetails(data: Partial<ICSVRow>): boolean {
    if ([data.qw, data.qx, data.qy, data.qz].every((m) => isNumber(m))) {
      return true;
    }

    if (
      [
        data.Xx,
        data.Xy,
        data.Xz,
        data.Yx,
        data.Yy,
        data.Yz,
        data.Zx,
        data.Zy,
        data.Zz,
      ].every((m) => isNumber(m))
    ) {
      return true;
    }

    if ([data.SeamLat, data.SeamLon].every((m) => isNumber(m))) {
      return true;
    }

    if (data.SeamOrientation !== undefined) {
      return true;
    }

    return false;
  }

  static getQuatFromData(data: Partial<ICSVRow>, spin: ISpin): IOrientation {
    if ([data.qw, data.qx, data.qy, data.qz].every((m) => isNumber(m))) {
      return data as IOrientation;
    }

    const heSeams = this.getSeamsFromHawkeye(data as IHawkeye);

    if (heSeams) {
      return BallHelper.getQuatFromSeams({
        latitude_deg: heSeams.latitude_deg,
        longitude_deg: heSeams.longitude_deg,
        spin: spin,
      });
    }

    if ([data.SeamLat, data.SeamLon].every((m) => isNumber(m))) {
      return BallHelper.getQuatFromSeams({
        latitude_deg: data.SeamLat as number,
        longitude_deg: data.SeamLon as number,
        spin: spin,
      });
    }

    if (data.SeamOrientation !== undefined) {
      if (data.SeamOrientation === '2S') {
        return BallHelper.getQuatFromSeams({
          longitude_deg: 90,
          latitude_deg: 0,
          spin: spin,
        });
      }
    }

    /** default if all the above was passed over */
    return BallHelper.getQuatFromSeams({
      longitude_deg: 0,
      latitude_deg: 0,
      spin: spin,
    });
  }

  static getSeams(
    data: ICustomSpins | ICustomBreaks | IHawkeye | IStatCast
  ): ISeamOrientation | undefined {
    try {
      if (
        data.seam_latitude !== undefined &&
        data.seam_longitude !== undefined
      ) {
        return {
          latitude_deg: data.seam_latitude,
          longitude_deg: data.seam_longitude,
        };
      }

      if (data.SeamLat !== undefined && data.SeamLon !== undefined) {
        return {
          latitude_deg: data.SeamLat,
          longitude_deg: data.SeamLon,
        };
      }

      const heSeams = this.getSeamsFromHawkeye(data);

      if (heSeams) {
        return heSeams;
      }

      if (data.seamOrientation === '4S' || data.SeamOrientation === '4S') {
        return {
          latitude_deg: 0,
          longitude_deg: 0,
        };
      }

      if (data.seamOrientation === '2S' || data.SeamOrientation === '2S') {
        return {
          latitude_deg: 90,
          longitude_deg: 0,
        };
      }

      throw Error('Failed to find seams from rawData, returning undefined');
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  private static getSeamsFromHawkeye(
    data: ICustomSpins | ICustomBreaks | IHawkeye | IStatCast
  ): ISeamOrientation | undefined {
    try {
      const spin = this.getSpin(data);
      if (!spin) {
        throw Error('Failed to get spin');
      }

      const spinAxis = BallHelper.safeNormalize(
        new Vector3(spin.wx, spin.wy, spin.wz)
      );

      const heMatrix = HawkeyeHelper.getMatrix(data);
      if (!heMatrix) {
        throw Error('Failed to get Hawkeye rotation matrix');
      }

      const seams = HawkeyeHelper.getSeamsFromMatrix(heMatrix, spinAxis);

      return seams;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  static getSpin(
    data: ICustomSpins | ICustomBreaks | IHawkeye | IStatCast
  ): ISpin | undefined {
    try {
      const customData = data as ICustomSpins;

      const hasCustomSpin = [
        customData.SpinX,
        customData.SpinY,
        customData.SpinZ,
      ].every((m) => isNumber(m));

      if (hasCustomSpin) {
        return {
          wx: customData.SpinX,
          wy: customData.SpinY,
          wz: customData.SpinZ,
        };
      }

      const hawkeyeData = data as IHawkeye;

      const hasHawkeyeSpin = [
        hawkeyeData.spinX,
        hawkeyeData.spinY,
        hawkeyeData.spinZ,
      ].every((m) => isNumber(m));

      if (hasHawkeyeSpin) {
        return {
          wx: hawkeyeData.spinX,
          wy: hawkeyeData.spinY,
          wz: hawkeyeData.spinZ,
        };
      }

      throw Error('Failed to get spin');
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
