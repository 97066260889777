import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTabs } from 'components/common/tabs';
import { SectionHeader } from 'components/sections/header';
import { RapsodoShotsTable } from 'components/sections/session-history/rapsodo-shots-table';
import { SessionEventsTable } from 'components/sections/session-history/session-events-table';
import { SessionsTable } from 'components/sections/session-history/sessions-table';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import {
  ISessionEventsContext,
  SessionEventsContext,
} from 'contexts/session-events.context';
import { SessionDialogMode } from 'enums/session.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

export const ENABLE_DETAILS = false;

enum TabKey {
  Events = 'Events',
  Rapsodo = 'Rapsodo',
}

interface IProps {}

interface IState {
  activeTab: TabKey;
}

const DEFAULT_STATE: IState = {
  activeTab: TabKey.Events,
};

export class SessionHistory extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.renderSessionTabs = this.renderSessionTabs.bind(this);
  }

  private renderSessionTabs(sessionsCx: ISessionEventsContext) {
    if (!sessionsCx.selectedSession) {
      return;
    }

    return (
      <CommonTabs
        value={this.state.activeTab}
        onValueChange={(value) => {
          this.setState({ activeTab: value as TabKey });
        }}
        tabs={[
          {
            value: TabKey.Events,
            label: 'Events',
            loading: sessionsCx.loading,
            content: <SessionEventsTable sessionsCx={sessionsCx} />,
          },
          {
            value: TabKey.Rapsodo,
            label: 'Rapsodo',
            loading: sessionsCx.loading,
            content: <RapsodoShotsTable sessionsCx={sessionsCx} />,
          },
        ]}
      />
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="SessionHistory">
        <AuthContext.Consumer>
          {(authCx) => (
            <SessionEventsContext.Consumer>
              {(sessionsCx) => (
                <FlexTableWrapper
                  gap={RADIX.FLEX.GAP.SECTION}
                  header={
                    <SectionHeader
                      header={t('main.session-history')}
                      action={{
                        label: 'main.current-session',
                        onClick: () =>
                          sessionsCx.showDialog({
                            session: authCx.current.session,
                            mode: SessionDialogMode.edit,
                          }),
                      }}
                      loading={sessionsCx.loading}
                    />
                  }
                  table={
                    <CookiesContext.Consumer>
                      {(cookiesCx) => (
                        <SessionsTable
                          cookiesCx={cookiesCx}
                          authCx={authCx}
                          sessionsCx={sessionsCx}
                        />
                      )}
                    </CookiesContext.Consumer>
                  }
                  footer={ENABLE_DETAILS && this.renderSessionTabs(sessionsCx)}
                />
              )}
            </SessionEventsContext.Consumer>
          )}
        </AuthContext.Consumer>
      </ErrorBoundary>
    );
  }
}
