import { Box, Flex } from '@radix-ui/themes';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ReactNode } from 'react';

interface IProps {
  // i.e. CommonTable
  table: ReactNode;

  header?: ReactNode;
  footer?: ReactNode;

  gap: string;
}

export const FlexTableWrapper = (props: IProps) => (
  <Flex
    className={`FlexTableWrapper ${RADIX.VFLEX.WRAPPER}`}
    gap={props.gap ?? RADIX.FLEX.GAP.SM}
    direction="column"
  >
    {props.header}

    <Box flexGrow="1">{props.table}</Box>

    {props.footer}
  </Flex>
);
