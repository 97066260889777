import { Badge, Box, Code, Grid } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonTable } from 'components/common/table';
import { CommonTooltip } from 'components/common/tooltip';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { ISessionEventsContext } from 'contexts/session-events.context';
import { format } from 'date-fns-tz';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT_SHORT, LOCAL_TIMEZONE } from 'enums/env';
import { SessionDialogMode } from 'enums/session.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IDisplayCol, ITablePageable } from 'interfaces/i-tables';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISessionSummary } from 'lib_ts/interfaces/i-session-summary';
import React from 'react';
import slugify from 'slugify';

const IDENTIFIER = TableIdentifier.SessionList;

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  sessionsCx: ISessionEventsContext;
}

interface IState {
  filterSession: string[];
  filterSessionStart: string[];
  filtered: ISessionSummary[];

  selected?: ISessionSummary;

  disableNext: boolean;
  disablePrev: boolean;
}

const DEFAULT_STATE: IState = {
  filterSession: [],
  filterSessionStart: [],
  filtered: [],

  disableNext: false,
  disablePrev: false,
};

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export class SessionsTable extends React.Component<IProps, IState> {
  private tableNode?: CommonTable;

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'Visualize',
          icon: <SuperAdminIcon />,
          invisibleFn: () =>
            this.props.authCx.current.role !== UserRole.admin &&
            this.props.authCx.current.mode !== 'impostor',
          disableFn: () => this.props.sessionsCx.loading,
          onClick: (m: ISessionSummary) =>
            this.props.sessionsCx.showDialog({
              mode: SessionDialogMode.visualize,
              session: m.session,
            }),
        },
        {
          label: 'common.edit',
          disableFn: () => this.props.sessionsCx.loading,
          onClick: (m: ISessionSummary) =>
            this.props.sessionsCx.showDialog({
              mode: SessionDialogMode.edit,
              session: m.session,
            }),
        },
        {
          label: 'common.export-shots',
          disableFn: () => this.props.sessionsCx.loading,
          onClick: async (m: ISessionSummary) => {
            const data = await this.props.sessionsCx.getShotsData(m.session);

            if (data.length === 0) {
              NotifyHelper.warning({
                message_md: t('common.session-has-no-shots'),
              });
              return;
            }

            StringHelper.saveCsv(data, {
              prefix: slugify(
                [
                  m.machineID,
                  m.machine_nickname,
                  format(parseISO(m.start), LOCAL_DATETIME_FORMAT_SHORT, {
                    timeZone: LOCAL_TIMEZONE,
                  }),
                ]
                  .map((m) => m?.trim())
                  .filter((m) => m)
                  .join('-'),
                { strict: true }
              ),
            });
          },
        },
        {
          label: 'common.export-stats',
          icon: <SuperAdminIcon />,
          invisibleFn: () => this.props.authCx.current.role !== UserRole.admin,
          disableFn: () => this.props.sessionsCx.loading,
          onClick: async (m: ISessionSummary) => {
            const data = await this.props.sessionsCx.getPitchStatsData({
              session: m.session,
            });

            if (data.length === 0) {
              NotifyHelper.warning({
                message_md: t('common.session-has-no-pitch-stats'),
              });
              return;
            }

            StringHelper.saveCsv(data, {
              prefix: `session-${m.session}_stats`,
            });
          },
        },
      ],
    },
    {
      label: 'common.machine',
      key: 'machineID',
      tooltipFn: (s: ISessionSummary) =>
        s.machine_nickname ? `aka: ${s.machine_nickname}` : 'No Nickname',
      formatFn: (s: ISessionSummary) => <Badge>{s.machineID}</Badge>,
    },
    {
      label: 'common.identifier',
      key: 'session',
      sortRowsFn: (a: ISessionSummary, b: ISessionSummary, dir: number) =>
        -(a.session ?? '').localeCompare(b.session ?? '') * dir,
      formatFn: (s: ISessionSummary) => (
        <CommonTooltip
          text="Click to copy this session ID to the clipboard"
          trigger={
            <Code
              className="cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(s.session);
                NotifyHelper.success({
                  message_md: 'Session ID copied to clipboard!',
                });
              }}
            >
              {s.session}
            </Code>
          }
        />
      ),
    },
    {
      label: 'common.name',
      key: 'name',
      sortRowsFn: (a: ISessionSummary, b: ISessionSummary, dir: number) =>
        -(a.name ?? '').localeCompare(b.name ?? '') * dir,
    },
    {
      label: 'common.shots',
      key: 'fires',
      align: 'right',
    },
    {
      label: 'common.start-date',
      key: 'start',
      dataType: 'datetime',
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.afterSelectRow = this.afterSelectRow.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.resetTable = this.resetTable.bind(this);
  }

  componentDidMount(): void {
    this.resetTable();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (prevProps.sessionsCx.sessions !== this.props.sessionsCx.sessions) {
      this.resetTable();
    }
  }

  private resetTable() {
    this.setState(
      {
        filtered: (this.props.sessionsCx.sessions ?? [])
          .filter(
            (m) =>
              this.state.filterSession.length === 0 ||
              this.state.filterSession.includes(m.session)
          )
          .filter(
            (m) =>
              this.state.filterSessionStart.length === 0 ||
              this.state.filterSessionStart.includes(
                lightFormat(parseISO(m.start), 'yyyy-MM-dd')
              )
          ),
      },
      () => {
        if (this.tableNode) {
          this.tableNode.updatePage(0);
        }
      }
    );
  }

  private async afterSelectRow(config: {
    model: ISessionSummary;
    disableNext: boolean;
    disablePrev: boolean;
  }) {
    this.setState(
      {
        disableNext: config.disableNext,
        disablePrev: config.disablePrev,
      },
      () => this.props.sessionsCx.selectSession(config.model)
    );
  }

  private renderToolbar() {
    return (
      <Grid columns="3" gap={RADIX.FLEX.GAP.SM}>
        <Box gridColumn="span 2">
          <CommonSearchInput
            id="filter-session"
            placeholder="common.session"
            options={this.props.sessionsCx.sessionsOptions.session.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterSession}
            onChange={(v) => {
              this.setState({ filterSession: v }, this.resetTable);
            }}
            multiple
            strict
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="filter-session-start"
            placeholder="common.start-date"
            options={this.props.sessionsCx.sessionsOptions.start.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterSessionStart}
            onChange={(v) => {
              this.setState({ filterSessionStart: v }, this.resetTable);
            }}
            multiple
          />
        </Box>
      </Grid>
    );
  }

  render() {
    /** sessions table */
    const pagination: ITablePageable = {
      total: this.state.filtered.length,
      enablePagination: true,
      pageSize: this.props.cookiesCx.getPageSize(IDENTIFIER) ?? PAGE_SIZES[0],
      pageSizeOptions: PAGE_SIZES,
      pageSizeCallback: (value) =>
        this.props.cookiesCx.setPageSize(IDENTIFIER, value),
    };

    return (
      <CommonTable
        ref={(elem) => (this.tableNode = elem as CommonTable)}
        id="Sessions"
        displayColumns={this.BASE_COLUMNS}
        displayData={this.state.filtered}
        toolbarContent={this.renderToolbar()}
        afterSelectRow={this.afterSelectRow}
        {...pagination}
        defaultSortKey="start"
        defaultSortDir={1}
        enableSort
        vFlex
      />
    );
  }
}
