import {
  IPresetOption,
  IPresetTrainingSpec,
} from '../interfaces/training/i-preset-training-spec';

export enum TrackingDevice {
  RapsodoV3PRO = 'Rapsodo V3 PRO',
  TrackmanB1 = 'Trackman B1',
}

export enum PresetTrainingMode {
  Quick = 'Quick',
  Precision = 'Precision',
  Custom = 'Custom',
  Balanced = 'Balanced',
}

export const DEFAULT_PRESET_TRAINING_SPEC: IPresetTrainingSpec = {
  sampleSize: 3,
  iterations: 3,
  deltaSpeedMPH: 2,
  deltaSpinsRPM: 400,
  deltaBreaksInches: 4,
  useProbability: false,
};

const UNUSED_OPTIONS: IPresetOption[] = [
  {
    mode: PresetTrainingMode.Balanced,
    minPerPitch: 2,
    spec: {
      sampleSize: 6,
      iterations: 3,
      deltaSpeedMPH: 2,
      deltaBreaksInches: 4,
      deltaSpinsRPM: 400,
      useProbability: false,
    },
  },
];

// for use everywhere except beta env
export const PRESET_TRAINING_OPTIONS: IPresetOption[] = [
  {
    mode: PresetTrainingMode.Quick,
    minPerPitch: 1,
    spec: {
      sampleSize: 3,
      iterations: 0,
      deltaSpeedMPH: 3,
      deltaBreaksInches: 5,
      deltaSpinsRPM: 400,
      useProbability: false,
    },
  },
  {
    mode: PresetTrainingMode.Precision,
    minPerPitch: 2,
    spec: {
      sampleSize: 3,
      iterations: 3,
      deltaSpeedMPH: 2,
      deltaSpinsRPM: 400,
      deltaBreaksInches: 4,
      useProbability: false,
    },
    showControls: true,
    precisionTrained: true,
  },
];

// for use only in beta env
export const BETA_PRESET_TRAINING_OPTIONS: IPresetOption[] = [
  {
    mode: PresetTrainingMode.Quick,
    minPerPitch: 1,
    spec: {
      sampleSize: 3,
      iterations: 0,
      deltaSpeedMPH: 3,
      deltaBreaksInches: 5,
      deltaSpinsRPM: 400,
      useProbability: false,
    },
  },
  {
    mode: PresetTrainingMode.Precision,
    minPerPitch: 2,
    spec: {
      sampleSize: 5,
      iterations: 4,
      deltaSpeedMPH: 1.5,
      deltaBreaksInches: 4,
      deltaSpinsRPM: 400,
      useProbability: true,
    },
    precisionTrained: true,
  },
  {
    mode: PresetTrainingMode.Custom,
    spec: {
      sampleSize: 3,
      iterations: 3,
      deltaSpeedMPH: 2,
      deltaSpinsRPM: 400,
      deltaBreaksInches: 4,
      useProbability: true,
    },
    showProbability: true,
    showControls: true,
  },
];
