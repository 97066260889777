import { Button, Code, Flex, Heading } from '@radix-ui/themes';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { BallStatusMsgType, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IBallStatusMsg,
  ICalibrateResponseMsg,
  IFireResponseMsg,
  IReadyMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import { DEFAULT_MACHINE_STATE } from 'lib_ts/interfaces/i-machine-state';
import React from 'react';
import { SectionBox } from './section-box';

interface IProps {}

interface IState {}

interface ISection {
  type: WsMsgType;
  body: React.ReactNode;
}

const DEFAULT_STATE: IState = {};

const DEFAULT_R2F: IReadyMsg = {
  status: true,
  data: {
    w1: true,
    w2: true,
    w3: true,
    a1: true,
    a2: true,
    a3: true,
    qw: true,
    qx: true,
    qy: true,
    qz: true,
    tilt: true,
    yaw: true,
    px: true,
    py: true,
    pz: true,
    video_uuid: true,
    training: true,
  },
};

export class MachineMessagesDemo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;
    this.renderButtons = this.renderButtons.bind(this);
  }

  private renderButtons(type: WsMsgType): ISection {
    const content = (() => {
      let key = 0;

      switch (type) {
        case WsMsgType.M2U_CalibrationResponse: {
          const combinations = [true, false].flatMap((status) => {
            return [true, false].map((can) => {
              const msg: ICalibrateResponseMsg = {
                status: status,
                can_calibrate: can,
                calibrate_success_list: [],
                timestamp: Date.now(),
              };

              return msg;
            });
          });

          return combinations.map((msg) => (
            <Button
              key={key++}
              color={
                msg.status && msg.can_calibrate
                  ? RADIX.COLOR.SUCCESS
                  : msg.can_calibrate
                  ? RADIX.COLOR.WARNING
                  : RADIX.COLOR.DANGER
              }
              onClick={() =>
                WebSocketHelper.dispatch(WsMsgType.M2U_CalibrationResponse, msg)
              }
            >
              {`${msg.status ? 'Calibrated' : 'Not Calibrated'} (${
                msg.can_calibrate ? 'Can' : 'Cannot'
              })`}
            </Button>
          ));
        }

        case WsMsgType.M2U_FireResponse: {
          const success: IFireResponseMsg = {
            status: true,
          };

          const failure: IFireResponseMsg = {
            status: false,
            message: 'Fire failed, it must be the pixies again...',
          };

          return [
            <Button
              key={key++}
              color={RADIX.COLOR.SUCCESS}
              onClick={() =>
                WebSocketHelper.dispatch(WsMsgType.M2U_FireResponse, success)
              }
            >
              Success
            </Button>,
            <Button
              key={key++}
              color={RADIX.COLOR.DANGER}
              onClick={() =>
                WebSocketHelper.dispatch(WsMsgType.M2U_FireResponse, failure)
              }
            >
              Failure
            </Button>,
          ];
        }

        case WsMsgType.M2U_ReadyToFire: {
          return [
            <Button
              key={key++}
              color={RADIX.COLOR.NEUTRAL}
              onClick={() =>
                WebSocketHelper.dispatch(WsMsgType.M2U_ReadyToFire, {
                  ...DEFAULT_R2F,
                  status: false,
                })
              }
            >
              Loading
            </Button>,
            <Button
              key={key++}
              color={RADIX.COLOR.WARNING}
              onClick={() =>
                WebSocketHelper.dispatch(WsMsgType.M2U_ReadyToFire, {
                  ...DEFAULT_R2F,
                  status: true,
                })
              }
            >
              Ready
            </Button>,
          ];
        }

        case WsMsgType.M2U_MsCurrent: {
          return [
            <Button
              key={key++}
              onClick={() =>
                WebSocketHelper.dispatch(WsMsgType.M2U_MsCurrent, {
                  ...DEFAULT_MACHINE_STATE,
                  training: true,
                })
              }
            >
              Training
            </Button>,
            <Button
              key={key++}
              color={RADIX.COLOR.WARNING}
              onClick={() =>
                WebSocketHelper.dispatch(WsMsgType.M2U_MsCurrent, {
                  ...DEFAULT_MACHINE_STATE,
                  training: false,
                })
              }
            >
              Not Training
            </Button>,
          ];
        }

        case WsMsgType.M2U_BallStatus: {
          return [
            <Button
              key={key++}
              color={RADIX.COLOR.WARNING}
              onClick={() => {
                const payload: IBallStatusMsg = {
                  type: BallStatusMsgType.Mock,
                  ball_count: -1,
                  already_present: false,
                };

                WebSocketHelper.dispatch(WsMsgType.M2U_BallStatus, payload);
              }}
            >
              Lighting Error
            </Button>,
            <Button
              key={key++}
              color={RADIX.COLOR.DANGER}
              onClick={() => {
                const payload: IBallStatusMsg = {
                  type: BallStatusMsgType.Mock,
                  ball_count: 0,
                  already_present: false,
                };

                WebSocketHelper.dispatch(WsMsgType.M2U_BallStatus, payload);
              }}
            >
              No Ball
            </Button>,
            <Button
              key={key++}
              color={RADIX.COLOR.DANGER}
              onClick={() => {
                const payload: IBallStatusMsg = {
                  type: BallStatusMsgType.Mock,
                  ball_count: 2,
                  already_present: false,
                };

                WebSocketHelper.dispatch(WsMsgType.M2U_BallStatus, payload);
              }}
            >
              Multi Ball
            </Button>,
            <Button
              key={key++}
              color={RADIX.COLOR.SUCCESS}
              onClick={() => {
                const payload: IBallStatusMsg = {
                  type: BallStatusMsgType.Mock,
                  ball_count: 1,
                  already_present: false,
                };

                WebSocketHelper.dispatch(WsMsgType.M2U_BallStatus, payload);
              }}
            >
              Clear
            </Button>,
          ];
        }

        default: {
          return [<p key={key++}>Nothing here yet...</p>];
        }
      }
    })();

    const output: ISection = {
      type,
      body: (
        <>
          <p>
            Simulate receiving <Code>{type}</Code> events.
          </p>
          <Flex gap={RADIX.FLEX.GAP.MD}>{content}</Flex>
        </>
      ),
    };
    return output;
  }

  render() {
    const TYPES = [
      WsMsgType.M2U_CalibrationResponse,
      WsMsgType.Misc_Error,
      WsMsgType.M2U_FireResponse,
      WsMsgType.M2U_MsCurrent,
      WsMsgType.M2U_BallStatus,
      WsMsgType.M2U_ReadyToFire,
    ];

    return (
      <ErrorBoundary componentName="MachineMessagesDemo">
        <SectionBox>
          <Heading size={RADIX.HEADING.SIZE.MD}>Machine ☞ Client</Heading>

          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            {TYPES.map((e) => this.renderButtons(e as WsMsgType)).map(
              (s, i) => (
                <SectionBox key={i}>
                  <Heading size={RADIX.HEADING.SIZE.SM}>{s.type}</Heading>
                  {s.body}
                </SectionBox>
              )
            )}
          </Flex>
        </SectionBox>
      </ErrorBoundary>
    );
  }
}
