import { RouteHelper } from 'classes/helpers/route.helper';

export enum AdminRoute {
  Users = 'Users',
  Machines = 'Machines',
  Teams = 'Teams',
  MachineModels = 'Machine Models',
  ErrorTypes = 'Error Types',
  ApiKeys = 'API Keys',
  ServerControls = 'Server Controls',
}

export enum SectionName {
  // base
  Home = 'Home',
  GameInProgress = 'Game In Progress',
  NotFound = 'Not Found',
  Unavailable = 'Unavailable',

  // user
  BulkPitchUpload = 'Bulk Pitch Upload',
  HitterLibrary = 'Hitter Library',
  MlbStatsBrowse = 'Browse',
  PitchDatabase = 'Pitch Database',
  PitchDesign = 'Pitch Design',
  PitchList = 'Pitch List',
  PitchLists = 'Pitch Lists',
  QuickSession = 'Quick Session',
  Search = 'Search',
  SessionHistory = 'Session History',
  VideoLibrary = 'Video Library',

  // any admin
  AdminPortal = 'Admin Portal',
  MachineCalibration = 'Machine Calibration',

  // super admin
  FeatureDemo = 'Feature Demo',
}

/** keys are routes, values are SectionName  */
export const getRouteDict = () => {
  const dict: { [route: string]: SectionName } = {};

  Object.values(SectionName).forEach((m) => {
    dict[RouteHelper.section(m)] = m;
  });

  return dict;
};
