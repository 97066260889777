import { CameraIcon } from '@radix-ui/react-icons';
import { Card, Flex, Heading, IconButton, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonVideoPlayer } from 'components/common/video-player';
import { MachineContext } from 'contexts/machine.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { DefaultVideoID, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPreviewVideoMsg } from 'lib_ts/interfaces/i-machine-msg';
import { DEFAULT_MACHINE_STATE } from 'lib_ts/interfaces/i-machine-state';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'CommonVideoPreview';

interface IProps {
  previewPx?: number;
  previewPz?: number;

  hideHeader?: boolean;
  playback?: IVideoPlayback;

  selectConfig?: {
    px: number;
    video_id: string | undefined;
    onChange: (video_id: string | undefined) => void;
  };
}

interface IState {
  loading: boolean;
}

export class CommonVideoPreview extends React.Component<IProps, IState> {
  private input?: CommonSearchInput;
  private refreshTimeout: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
    };

    this.renderPreviewButton = this.renderPreviewButton.bind(this);
    this.getSubTitle = this.getSubTitle.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.playback !== this.props.playback) {
      // forces remount of the video player component
      this.setState({ loading: true }, () => {
        this.input?.reset();

        clearTimeout(this.refreshTimeout);

        this.refreshTimeout = setTimeout(() => {
          this.setState({ loading: false });
        }, 100);
      });
    }
  }

  private getSubTitle() {
    if (!this.props.playback) {
      return t('videos.no-video');
    }

    if (this.props.playback.video.VideoTitle) {
      return this.props.playback.video.VideoTitle;
    }

    if (this.props.playback.video.VideoFileName) {
      return this.props.playback.video.VideoFileName;
    }

    return t('videos.untitled');
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <VideosContext.Consumer>
          {(videosCx) => (
            <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
              <Flex gap={RADIX.FLEX.GAP.MD} justify="between" align="center">
                <Heading size={RADIX.HEADING.SIZE.MD}>
                  {!this.props.hideHeader && (
                    <>
                      {t('videos.video-preview')}

                      {!this.props.selectConfig && (
                        <small className="block">{this.getSubTitle()}</small>
                      )}
                    </>
                  )}
                </Heading>

                {this.renderPreviewButton()}
              </Flex>

              {this.props.selectConfig && (
                <CommonSearchInput
                  ref={(elem) => (this.input = elem as CommonSearchInput)}
                  id="video-preview-videoID"
                  name="video_id"
                  values={
                    this.props.selectConfig.video_id
                      ? [this.props.selectConfig.video_id]
                      : []
                  }
                  options={videosCx.videosByRelease(this.props.selectConfig.px)}
                  onChange={(v) => this.props.selectConfig?.onChange(v[0])}
                  optional
                />
              )}

              {!this.props.playback && (
                <Card
                  style={{
                    aspectRatio: '16 / 10',
                    textAlign: 'center',
                  }}
                >
                  <Flex height="100%" align="center" justify="center">
                    <Text color={RADIX.COLOR.NEUTRAL}>
                      {t(
                        this.state.loading
                          ? 'common.please-wait'
                          : 'videos.msg-select-video-to-preview'
                      )}
                    </Text>
                  </Flex>
                </Card>
              )}

              {!this.state.loading && this.props.playback && (
                <CommonVideoPlayer playback={this.props.playback} />
              )}
            </Flex>
          )}
        </VideosContext.Consumer>
      </ErrorBoundary>
    );
  }

  private renderPreviewButton(): React.ReactNode {
    return (
      <MachineContext.Consumer>
        {(machineCx) => (
          <IconButton
            title="Preview video using the machine's projector."
            className="btn-floating"
            color={RADIX.COLOR.NEUTRAL}
            onClick={() => {
              if (!this.props.selectConfig?.video_id) {
                NotifyHelper.warning({
                  message_md: 'Please select a video to preview first.',
                });
                return;
              }

              const px = this.props.previewPx ?? DEFAULT_MACHINE_STATE.px;
              const pz = this.props.previewPz ?? DEFAULT_MACHINE_STATE.pz;

              const data: IPreviewVideoMsg = {
                px: px,
                pz: pz,
                video_uuid:
                  this.props.selectConfig?.video_id ??
                  (px > 0
                    ? DefaultVideoID.default_LHP
                    : DefaultVideoID.default_RHP),
              };

              WebSocketService.send(
                WsMsgType.U2M_PreviewVideo,
                data,
                COMPONENT_NAME
              );

              machineCx.resetMSHash();
            }}
          >
            <CameraIcon />
          </IconButton>
        )}
      </MachineContext.Consumer>
    );
  }
}
