import { DownloadIcon } from '@radix-ui/react-icons';
import { Flex, Heading, Spinner } from '@radix-ui/themes';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { ISessionEventsContext } from 'contexts/session-events.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { IDisplayCol, ITablePageable } from 'interfaces/i-tables';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  EventCategory,
  ISessionEventDetails,
} from 'lib_ts/interfaces/i-session-event';
import React from 'react';
import { SessionEventsService } from 'services/session-events.service';

interface IOptionsDict {
  category: string[];
  tags: string[];
}

interface IProps {
  sessionsCx: ISessionEventsContext;
}

interface IState {
  filterCategory: EventCategory[];
  filterTags: string[];
  filterCreated: string[];

  disableNext: boolean;
  disablePrev: boolean;

  loading: boolean;

  data: ISessionEventDetails[];
  filterOptions: IOptionsDict;

  fileURL: string;
}

const DEFAULT_STATE: IState = {
  filterCategory: [],
  filterTags: [],
  filterCreated: [],

  disableNext: false,
  disablePrev: false,

  loading: false,

  data: [],
  filterOptions: {
    category: [],
    tags: [],
  },

  fileURL: '',
};

const PAGE_SIZE = 10;

const getOptions = (items: ISessionEventDetails[]): IOptionsDict => {
  if (items) {
    return {
      category: ArrayHelper.unique(items.map((m) => m.category)).sort(
        (a: string, b: string) => a.localeCompare(b)
      ),

      tags: ArrayHelper.unique(
        items.flatMap((m) => (m.tags ?? '').split(','))
      ).sort((a: string, b: string) => a.localeCompare(b)),
    };
  } else {
    return DEFAULT_STATE.filterOptions;
  }
};

export class SessionEventsTable extends React.Component<IProps, IState> {
  private anchorNode?: HTMLAnchorElement;
  private tableNode?: CommonTable;

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.created',
      key: '_created',
      dataType: 'datetime',
    },
    {
      label: 'common.category',
      key: 'category',
      sortRowsFn: (
        a: ISessionEventDetails,
        b: ISessionEventDetails,
        dir: number
      ) => -(a.category ?? '').localeCompare(b.category ?? '') * dir,
    },
    {
      label: 'common.tags',
      key: 'tags',
      sortRowsFn: (
        a: ISessionEventDetails,
        b: ISessionEventDetails,
        dir: number
      ) => -(a.tags ?? '').localeCompare(b.tags ?? '') * dir,
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.sessionsCx.selectedSession !==
      this.props.sessionsCx.selectedSession
    ) {
      /** clear filters since options may change */
      this.setState(DEFAULT_STATE, this.resetTable);
      this.loadData();
    }
  }

  private loadData() {
    if (this.props.sessionsCx.selectedSession) {
      this.setState({ loading: true });
      SessionEventsService.getEventsForSession(
        this.props.sessionsCx.selectedSession.session
      )
        .then((result) => {
          this.setState({
            data: result,
            filterOptions: getOptions(result),
          });
        })
        .finally(() => this.setState({ loading: false }));
    }
  }

  private resetTable() {
    if (this.tableNode) {
      this.tableNode.updatePage(0);
    }
  }

  private exportData(data: ISessionEventDetails[]) {
    if (this.props.sessionsCx.selectedSession) {
      const whitelist = ['_id', '_created'];

      const blob = new Blob(
        [
          JSON.stringify(
            data,
            (key, value) => {
              if (key.startsWith('_') && !whitelist.includes(key)) {
                return undefined;
              } else {
                return value;
              }
            },
            2
          ),
        ],
        { type: 'application/json' }
      );

      this.setState(
        {
          fileURL: URL.createObjectURL(blob),
        },
        () => {
          if (this.anchorNode) {
            this.anchorNode.click();
          }
        }
      );
    }
  }

  render() {
    const session = this.props.sessionsCx.selectedSession
      ? this.props.sessionsCx.selectedSession.session
      : 'unknown';

    const loading = this.props.sessionsCx.loading || this.state.loading;

    const all = this.state.data;
    const filtered = all
      .filter(
        (m) =>
          this.state.filterCategory.length === 0 ||
          this.state.filterCategory.includes(m.category)
      )
      .filter(
        (m) =>
          this.state.filterTags.length === 0 ||
          ArrayHelper.countIntersection(
            (m.tags ?? '').split(','),
            this.state.filterTags
          ) > 0
      )
      .filter(
        (m) =>
          this.state.filterCreated.length === 0 ||
          this.state.filterCreated.includes(
            lightFormat(parseISO(m._created), 'yyyy-MM-dd')
          )
      );

    /** sessions table */
    const pagination: ITablePageable = {
      total: filtered.length,
      enablePagination: true,
      pageSize: PAGE_SIZE,
    };

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
        <Spinner loading={loading}>
          <Heading>Session Events</Heading>
        </Spinner>

        <CommonFormGrid columns={2}>
          <CommonSearchInput
            id="filter-category"
            label="Category"
            options={this.state.filterOptions.category.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterCategory}
            onChange={(v) => {
              this.setState(
                { filterCategory: v as EventCategory[] },
                this.resetTable
              );
            }}
            multiple
          />
          <CommonSearchInput
            id="filter-tags"
            label="Tags"
            options={this.state.filterOptions.tags.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterTags}
            onChange={(v) => {
              this.setState({ filterTags: v }, this.resetTable);
            }}
            multiple
          />
        </CommonFormGrid>

        <CommonTable
          ref={(elem) => (this.tableNode = elem as CommonTable)}
          id="SessionEvents"
          displayColumns={this.BASE_COLUMNS}
          displayData={filtered}
          toolbarContent={
            <Flex gap={RADIX.FLEX.GAP.SM}>
              <CommonTableButton
                icon={<DownloadIcon />}
                label="Export All"
                disabled={loading}
                onClick={() => this.exportData(all)}
              />

              {all.length !== filtered.length && (
                <CommonTableButton
                  icon={<DownloadIcon />}
                  label="Export Filtered"
                  disabled={loading}
                  onClick={() => this.exportData(filtered)}
                />
              )}
            </Flex>
          }
          {...pagination}
          enableSort
          vFlex
        />

        <a
          ref={(elem) => (this.anchorNode = elem as HTMLAnchorElement)}
          download={`session-${session}-events.json`}
          href={this.state.fileURL}
          className="hidden"
        >
          Download
        </a>
      </Flex>
    );
  }
}
