import { ZoomInIcon } from '@radix-ui/react-icons';
import { Code, Flex, Strong } from '@radix-ui/themes';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { parseISO } from 'date-fns';
import format from 'date-fns-tz/format';
import { LOCAL_TIMEZONE } from 'enums/env';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IEvalModelResult } from 'lib_ts/interfaces/modelling/i-eval-models';
import React from 'react';

interface IProps {
  open?: boolean;

  metric: IEvalModelResult;

  /** appended to summary in details */
  index?: number;
}

interface IState {
  timestamp: string;
}

export class PrintMetric extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      timestamp: format(
        parseISO(props.metric.created),
        'yyyy-MM-dd @ hh:mm:ss a',
        { timeZone: LOCAL_TIMEZONE }
      ),
    };
  }

  render() {
    return (
      <ErrorBoundary componentName="PrintMetric">
        <CommonDetails
          className="PrintMetric foreground rounded-md"
          summaryContent={
            <Flex justify="between" gap={RADIX.FLEX.GAP.SM}>
              <Strong>
                <ZoomInIcon />{' '}
                {this.props.metric
                  ? this.props.metric.model_name
                  : `Metric #${this.props.index}`}
              </Strong>

              <Code>{this.state.timestamp}</Code>
            </Flex>
          }
          defaultOpen={this.props.open}
        >
          <pre>
            {JSON.stringify(this.props.metric.model_performance, null, 2)}
          </pre>
        </CommonDetails>
      </ErrorBoundary>
    );
  }
}
