import { ChevronUpIcon, DotsVerticalIcon } from '@radix-ui/react-icons';
import { Box, Flex, Text } from '@radix-ui/themes';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { DropHandle } from 'components/common/drag-drop';
import { CommonMenu } from 'components/common/menu';
import { PitchListItem } from 'components/main/sidebar/pitch-lists/pitch-list-item';
import { IMachineContext } from 'contexts/machine.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { ISectionsContext } from 'contexts/sections.context';
import { DragItem, DropContainer } from 'enums/dnd.enums';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';

interface IProps {
  machineCx: IMachineContext;
  sectionsCx: ISectionsContext;
  listsCx: IPitchListsContext;

  folder: ISidebarFolder;
  container: DropContainer;
  accept: DragItem;

  getFolderActions: (config: {
    folder: ISidebarFolder;
    container: DropContainer;
  }) => IMenuAction[];

  defaultOpen?: boolean;
}

export const PitchListFolder = (props: IProps) => (
  <Flex data-identifier="SidebarFolder" direction="column">
    {props.folder.files.map((file, iFile) => (
      <PitchListItem
        key={`folder-${props.folder.pathDisplay}-file-${iFile}`}
        sectionsCx={props.sectionsCx}
        listsCx={props.listsCx}
        file={file}
        accept={props.accept}
      />
    ))}

    {props.folder.folders.map((folder, iSub) => (
      <Box key={iSub}>
        <details className="SidebarPLFolderDetails" open={props.defaultOpen}>
          <summary>
            <DropHandle
              value={folder.pathDisplay}
              container={props.container}
              accept={props.accept ?? DragItem.AcceptNothing}
            >
              <Flex gap={RADIX.FLEX.GAP.SM}>
                <Box
                  flexGrow="1"
                  className="cursor-pointer"
                  title={folder.pathDisplay}
                  pl={RADIX.BOX.PAD.SM}
                  style={{
                    position: 'relative',
                    height: '26px',
                  }}
                >
                  <Text
                    color={RADIX.COLOR.NEUTRAL}
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      // border: '1px solid purple',
                    }}
                    as="div"
                    truncate
                  >
                    {SidebarHelper.getFolderName(
                      folder,
                      props.machineCx.machine.machineID
                    )}
                  </Text>
                </Box>

                <Flex
                  gap={RADIX.FLEX.GAP.XS}
                  className="SidebarPLFolderActions"
                >
                  <ChevronUpIcon className="SidebarPLFolderChevron" />
                  <CommonMenu
                    title={'Manage Folder'}
                    actions={props.getFolderActions({
                      folder: folder,
                      container: props.container,
                    })}
                    trigger={<DotsVerticalIcon />}
                    skipSort
                  />
                </Flex>
              </Flex>
            </DropHandle>
          </summary>

          <Box pl="2">
            <PitchListFolder {...props} folder={folder} />
          </Box>
        </details>
      </Box>
    ))}
  </Flex>
);
