import * as Accordion from '@radix-ui/react-accordion';
import { Box, ChevronDownIcon, Flex, Heading } from '@radix-ui/themes';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ReactNode } from 'react';

export interface IAccordionItem extends Accordion.AccordionItemProps {
  label: string;
  children?: ReactNode;
}

export const CommonAccordionItem = (props: IAccordionItem) => (
  <Accordion.Item {...props} className="AccordionItem foreground">
    <Accordion.Header className="AccordionHeader" asChild>
      <Accordion.Trigger className="AccordionTrigger" asChild>
        <Flex
          p={RADIX.BOX.PAD.MD}
          gap={RADIX.FLEX.GAP.MD}
          style={{ width: '100%' }}
        >
          <Box flexGrow="1">
            <Heading m="0" size={RADIX.HEADING.SIZE.MD}>
              {t(props.label)}
            </Heading>
          </Box>
          <Box>
            <ChevronDownIcon className="AccordionChevron" aria-hidden />
          </Box>
        </Flex>
      </Accordion.Trigger>
    </Accordion.Header>
    <Accordion.Content className="AccordionContent">
      <Box p={RADIX.BOX.PAD.MD}>{props.children}</Box>
    </Accordion.Content>
  </Accordion.Item>
);
