import { ScrollArea, Table } from '@radix-ui/themes';
import { CommonTable } from 'components/common/table';
import { IDisplayCol, ITablePageable } from 'interfaces/i-tables';
import React from 'react';

interface IProps {
  data: any[];
  simple?: boolean;
}

interface IState {
  /** columns scanned from first entry in CSV results */
  displayColumns: IDisplayCol[]; //preview
}

const DEFAULT_STATE: IState = {
  displayColumns: [],
};

const PAGE_SIZE = 10;

export class PitchUploaderCsvTable extends React.Component<IProps, IState> {
  private init = false;

  tableNode?: CommonTable;

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.updateDisplayColumns = this.updateDisplayColumns.bind(this);
  }

  private updateDisplayColumns() {
    if (this.props.data.length > 0) {
      const headers = Object.keys(this.props.data[0]);

      this.setState({
        displayColumns: headers.map((k) => {
          const col: IDisplayCol = {
            label: k,
            key: k,
          };

          return col;
        }),
      });
    } else {
      this.setState({ displayColumns: [] });
    }
  }

  componentDidMount() {
    if (!this.init) {
      this.init = true;
      this.updateDisplayColumns();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.data !== this.props.data) {
      this.updateDisplayColumns();
    }
  }

  render() {
    if (this.props.simple) {
      return (
        <ScrollArea style={{ maxHeight: '400px' }} scrollbars="both">
          <Table.Root>
            <Table.Header>
              <Table.Row>
                {this.state.displayColumns.map((col, iCol) => (
                  <Table.ColumnHeaderCell key={iCol}>
                    {col.label}
                  </Table.ColumnHeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.data.map((data, iData) => (
                <Table.Row key={iData}>
                  {this.state.displayColumns.map((col, iCol) => (
                    <Table.Cell key={iCol}>
                      {col.formatFn?.(data) ?? (data as any)[col.key]}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </ScrollArea>
      );
    }

    const pagination: ITablePageable = {
      total: this.props.data.length,
      enablePagination: true,
      pageSize: PAGE_SIZE,
    };

    return (
      <CommonTable
        ref={(elem) => (this.tableNode = elem as CommonTable)}
        id="PitchUploaderCsv"
        displayColumns={this.state.displayColumns}
        displayData={this.props.data}
        {...pagination}
        enableSort
        suspendKeyListener
      />
    );
  }
}
