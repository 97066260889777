import {
  SHOT_OPACITY_DELTA as BREAK_OPACITY_DELTA,
  BreakCanvas,
  DOT_RGB_ACTUAL,
  DOT_SIZE,
} from 'classes/break-canvas';
import { DOT_RGB_ROTATED } from 'classes/plate-canvas';
import { ErrorBoundary } from 'components/common/error-boundary';
import { METERS_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { ITrajectoryBreak } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import {
  EMPTY_RAPSODO_BREAKS,
  IRapsodoBreak,
} from 'lib_ts/interfaces/training/i-rapsodo-shot';
import React from 'react';
import Slider from 'react-input-slider';

const COMPONENT_NAME = 'BreakView';

interface IProps {
  actual_x_in: number;
  actual_z_in: number;

  target_x_in: number;
  target_z_in: number;

  shots: IMachineShot[];

  overriding: boolean;

  onUpdate: (result: ITrajectoryBreak) => void;
}

interface IState {
  /** inches */
  slider_x: number;
  /** inches */
  slider_y: number;
}

export class BreakView extends React.Component<IProps, IState> {
  private break_canvas = BreakCanvas.makeDefault();
  private mainCanvasNode?: HTMLCanvasElement;
  private shotsCanvasNode?: HTMLCanvasElement;

  constructor(props: IProps) {
    super(props);

    const DEFAULT_STATE: IState = {
      slider_x: this.props.target_x_in,
      slider_y: this.props.target_z_in,
    };

    this.state = DEFAULT_STATE;

    this.drawMain = this.drawMain.bind(this);
    this.drawBreaks = this.drawBreaks.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.plateChanged = this.plateChanged.bind(this);
  }

  componentDidMount() {
    this.drawMain('componentDidMount');
    this.drawBreaks('componentDidMount');
    this.onDragEnd();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      this.props.actual_x_in !== prevProps.actual_x_in ||
      this.props.actual_z_in !== prevProps.actual_z_in ||
      this.props.overriding !== prevProps.overriding
    ) {
      this.drawBreaks('actual or overriding changed');
    }

    if (
      this.props.target_x_in !== prevProps.target_x_in ||
      this.props.target_z_in !== prevProps.target_z_in
    ) {
      this.plateChanged();
    }
  }

  private plateChanged() {
    this.setState({
      slider_x: this.props.target_x_in,
      slider_y: this.props.target_z_in,
    });
  }

  private drawMain(source: string) {
    const canvas = this.mainCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.break_canvas.drawRulers(ctx);
  }

  private drawBreaks(source: string) {
    const canvas = this.shotsCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (this.props.overriding) {
      // only draw one green dot for the actual override
      this.break_canvas.drawBreak(
        ctx,
        {
          ...EMPTY_RAPSODO_BREAKS,
          PITCH_HBTrajectory: this.props.actual_x_in / METERS_TO_INCHES,
          PITCH_VBTrajectory: this.props.actual_z_in / METERS_TO_INCHES,
        },
        {
          color: `rgba(${DOT_RGB_ROTATED}, 1)`,
          size: DOT_SIZE,
        }
      );
      return;
    }

    // draw a yellow dot for each shot
    const breaks = this.props.shots
      .filter((s) => !!s.break)
      .map((s) => s.break) as IRapsodoBreak[];

    breaks.forEach((m, i) => {
      const opacity = 1 - i * BREAK_OPACITY_DELTA;

      this.break_canvas.drawBreak(ctx, m, {
        color: `rgba(${DOT_RGB_ACTUAL}, ${opacity})`,
        size: DOT_SIZE,
      });
    });
  }

  private onChange(pos: { x: number; y: number }) {
    this.setState({
      slider_x: pos.x,
      slider_y: pos.y,
    });
  }

  private onDragEnd() {
    this.props.onUpdate({
      xInches: this.state.slider_x,
      zInches: this.state.slider_y,
    });
  }

  render() {
    return (
      <ErrorBoundary componentName="BreakView">
        <div className="slider">
          <div
            className="sizer"
            style={{
              aspectRatio: '1.00',
              maxHeight: '400px',
              minHeight: '100px',
            }}
          >
            <canvas
              ref={(node) => (this.mainCanvasNode = node as HTMLCanvasElement)}
              width={this.break_canvas.CONFIG.canvas.width_px}
              height={this.break_canvas.CONFIG.canvas.height_px}
            />
            <canvas
              ref={(node) => (this.shotsCanvasNode = node as HTMLCanvasElement)}
              width={this.break_canvas.CONFIG.canvas.width_px}
              height={this.break_canvas.CONFIG.canvas.height_px}
            />
            <div className="slider-wrapper animate-fade">
              <Slider
                data-testid="BreakViewSlider"
                data-xcord={this.state.slider_x}
                data-ycord={this.state.slider_y}
                axis="xy"
                xstep={this.break_canvas.CONFIG.x.step}
                xmin={this.break_canvas.CONFIG.x.min_in}
                xmax={this.break_canvas.CONFIG.x.max_in}
                x={this.state.slider_x}
                ystep={this.break_canvas.CONFIG.y.step}
                ymin={this.break_canvas.CONFIG.y.min_in}
                ymax={this.break_canvas.CONFIG.y.max_in}
                y={this.state.slider_y}
                onChange={this.onChange}
                onDragEnd={this.onDragEnd}
                styles={{
                  track: {
                    backgroundColor: 'rgba(0, 0, 255, 0)',
                    width: '100%',
                    height: '100%',
                  },
                  thumb: {
                    backgroundColor: 'rgba(0, 255, 0, 0)',
                    backgroundImage: 'url(/img/target-red.png)',
                    backgroundSize: 'cover',
                    boxShadow: 'none',
                    height: 32,
                    width: 32,
                    margin: '-16px',
                  },
                }}
                yreverse
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
