import { calculateTally, ICheckableTally } from 'interfaces/i-contexts';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { ICheckable } from 'lib_ts/interfaces/mongo/_base';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';

export interface ICheckedContext {
  // when the data source changes and everything needs to reset
  dataKey: number;
  // when something within the data changes (e.g. check/uncheck 1 item) and only the item + tally needs to change
  tally: ICheckableTally;

  readonly checkAll: (value: boolean) => void;
  readonly updateTally: () => void;
}

const DEFAULT: ICheckedContext = {
  dataKey: Date.now(),
  tally: calculateTally([]),
  checkAll: () => console.debug('not init'),
  updateTally: () => console.debug('not init'),
};

export const CheckedContext = createContext(DEFAULT);

interface IProps {
  data: ICheckable[];
  children: ReactNode;
}

export const CheckedProvider: FC<IProps> = (props) => {
  const [_dataKey, _setDataKey] = useState(DEFAULT.dataKey);
  const [_tally, _setTally] = useState(DEFAULT.tally);
  const [_data, _setData] = useState(props.data);

  const state: ICheckedContext = {
    dataKey: _dataKey,
    tally: _tally,
    checkAll: (value) => {
      props.data.forEach((m) => (m._checked = value));
      _setTally(calculateTally(props.data));
    },
    updateTally: () => _setTally(calculateTally(props.data)),
  };

  useEffect(() => {
    if (ArrayHelper.equals(props.data, _data)) {
      // console.debug('data unchanged => skipping tally');
      return;
    }

    // console.debug('data changed => updating tally and dataKey');
    _setData(props.data);
    _setTally(calculateTally(props.data));
    _setDataKey(Date.now());
  }, [props.data]);

  return (
    <CheckedContext.Provider value={state}>
      {props.children}
    </CheckedContext.Provider>
  );
};
