import { PauseIcon, ResumeIcon } from '@radix-ui/react-icons';
import { Box, Code, Flex, Heading, Text } from '@radix-ui/themes';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { DialogButton } from 'components/common/dialogs/button';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { IAuthContext } from 'contexts/auth.context';
import { format } from 'date-fns-tz';
import { LOCAL_TIMEZONE } from 'enums/env';
import {
  MachineLogLevel,
  MachineProcess,
  WsMsgType,
} from 'lib_ts/enums/machine-msg.enum';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import {
  IReadLogMsg,
  IReadLogResponseMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';
import './live-logs.scss';

const LINES_PER_FILE = 50;

// separates individual log messages from one another
const TERMINATOR = '\u00A0\n';

// for parsing out the header for each message
const MSG_INDEX = {
  TIMESTAMP: 0,
  LEVEL: 1,
  FULL_PROCESS: 2,
  MESSAGE: 3,
};

const getLabelColor = (level?: MachineLogLevel): RadixColor | undefined => {
  switch (level) {
    case MachineLogLevel.ERROR:
    case MachineLogLevel.CRITICAL: {
      return RADIX.COLOR.DANGER;
    }

    case MachineLogLevel.WARNING: {
      return RADIX.COLOR.WARNING;
    }

    case MachineLogLevel.INFO: {
      return RADIX.COLOR.INFO;
    }

    case MachineLogLevel.DEBUG:
    default: {
      return;
    }
  }
};

interface ILiveLogLine {
  header: string;
  body: string;
  timestamp?: string;
  level?: MachineLogLevel;
}

interface IProps {
  machineID: string;
  authCx: IAuthContext;
}

interface IState {
  pause: boolean;
  lastUpdated?: Date;
  lastMsg?: IReadLogResponseMsg;
  liveLog: ILiveLogLine[];

  filterLevel: MachineLogLevel[];
  filterProcess: MachineProcess[];
}

export class LiveLogsTab extends React.Component<IProps, IState> {
  private readInterval?: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      pause: false,
      liveLog: [{ header: 'Loading', body: 'Please wait...' }],
      filterLevel: [],
      filterProcess: [],
    };

    this.filterReadLogResponse = this.filterReadLogResponse.bind(this);
    this.handleReadLogResponse = this.handleReadLogResponse.bind(this);
    this.readLog = this.readLog.bind(this);
  }

  componentDidMount(): void {
    WebSocketHelper.on(
      WsMsgType.M2U_ReadLogResponse,
      this.handleReadLogResponse
    );

    this.readLog();

    // because strict mode will trigger twice
    clearInterval(this.readInterval);
    this.readInterval = setInterval(() => {
      if (this.state.pause) {
        return;
      }

      this.readLog();
    }, 5_000);
  }

  componentWillUnmount(): void {
    WebSocketHelper.remove(
      WsMsgType.M2U_ReadLogResponse,
      this.handleReadLogResponse
    );

    if (this.readInterval) {
      clearInterval(this.readInterval);
    }
  }

  private handleReadLogResponse(event: CustomEvent) {
    const data: IReadLogResponseMsg = event.detail;

    this.setState(
      {
        lastMsg: data,
        lastUpdated: new Date(),
      },
      () => this.filterReadLogResponse()
    );
  }

  private filterReadLogResponse() {
    if (!this.state.lastMsg) {
      this.setState({
        liveLog: [
          {
            header: 'System',
            body: 'No data',
          },
        ],
      });
      return;
    }

    const filteredFiles = this.state.lastMsg.files.filter((f) => {
      if (this.state.filterProcess.length === 0) {
        return true;
      }

      return this.state.filterProcess.includes(f.process);
    });

    if (filteredFiles.length === 0) {
      this.setState({
        liveLog: [{ header: 'System', body: 'No filtered data (processes)' }],
      });
      return;
    }

    const logLines: ILiveLogLine[] = [];

    filteredFiles.flatMap((file) =>
      file.content
        .split(TERMINATOR)
        .filter((l) => !!l)
        .forEach((line) => {
          const msgParts = line.split(' - ');

          if (msgParts.length < 3) {
            // skip anything that might be too short
            return;
          }

          const output: ILiveLogLine = {
            // for display
            header: `${msgParts[MSG_INDEX.TIMESTAMP]} - ${
              msgParts[MSG_INDEX.LEVEL]
            } - ${msgParts[MSG_INDEX.FULL_PROCESS]}`,
            // for sorting later
            timestamp: msgParts[MSG_INDEX.TIMESTAMP],
            // for filtering later
            level: msgParts[MSG_INDEX.LEVEL] as MachineLogLevel,
            // in case the message contained " - " and there are more than 4 parts as a result
            body: msgParts
              .filter((_, i) => i >= MSG_INDEX.MESSAGE)
              .join(' - ')
              .trim(),
          };

          logLines.push(output);
        })
    );

    const filterByLevel = logLines.filter((l) => {
      if (this.state.filterLevel.length === 0) {
        return true;
      }

      return this.state.filterLevel.includes(l.level ?? MachineLogLevel.DEBUG);
    });

    if (filterByLevel.length === 0) {
      this.setState({
        liveLog: [{ header: 'System', body: 'No filtered data (levels)' }],
      });
      return;
    }

    this.setState({
      liveLog: filterByLevel.sort((a, b) =>
        (a.timestamp ?? '').localeCompare(b.timestamp ?? '')
      ),
    });
  }

  private readLog() {
    const data: IReadLogMsg = {
      machineID: this.props.machineID,
      session: this.props.authCx.current.session,
      limit: LINES_PER_FILE,
      processes:
        this.state.filterProcess.length === 0
          ? Object.values(MachineProcess)
          : this.state.filterProcess,
    };

    WebSocketService.send(WsMsgType.U2M_ReadLog, data, 'logs tab');
  }

  render() {
    return (
      <ErrorBoundary componentName="MachineDetailsLiveLogsTab">
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <CommonFormGrid columns={2}>
            <CommonSearchInput
              id="live-logs-processes"
              placeholder="Processes"
              options={Object.values(MachineProcess).map((o) => ({
                label: o,
                value: o,
              }))}
              values={this.state.filterProcess}
              onChange={(v) => {
                this.setState({ filterProcess: v as MachineProcess[] }, () =>
                  this.filterReadLogResponse()
                );
              }}
              multiple
            />
            <CommonSearchInput
              id="live-logs-levels"
              placeholder="Levels"
              options={Object.values(MachineLogLevel).map((o) => ({
                label: o,
                value: o,
              }))}
              values={this.state.filterLevel}
              onChange={(v) => {
                this.setState({ filterLevel: v as MachineLogLevel[] }, () =>
                  this.filterReadLogResponse()
                );
              }}
              multiple
            />
          </CommonFormGrid>

          <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
            <Heading size={RADIX.HEADING.SIZE.SM}>Live Log</Heading>

            <Box className="LiveLog">
              <Text size={RADIX.TEXT.SIZE.SM}>
                <ul className="no-style">
                  {this.state.liveLog.map((l, i) => (
                    <li key={i}>
                      <Code color={getLabelColor(l.level)}>{l.header}</Code>
                      <br />
                      <pre>{l.body}</pre>
                    </li>
                  ))}
                </ul>
              </Text>
            </Box>

            <Flex gap={RADIX.FLEX.GAP.MD} justify="between">
              <Box>
                {this.state.lastUpdated && (
                  <Text size={RADIX.TEXT.SIZE.SM}>
                    Last Updated:{' '}
                    {format(this.state.lastUpdated, 'yyyy-MM-dd @ HH:mm:ss z', {
                      timeZone: LOCAL_TIMEZONE,
                    })}
                  </Text>
                )}
              </Box>

              <Box>
                <DialogButton
                  icon={this.state.pause ? <ResumeIcon /> : <PauseIcon />}
                  label={this.state.pause ? 'Resume' : 'Pause'}
                  color={this.state.pause ? RADIX.COLOR.SUCCESS : undefined}
                  onClick={() => this.setState({ pause: !this.state.pause })}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </ErrorBoundary>
    );
  }
}
