import { Box, Flex, Heading, TabNav } from '@radix-ui/themes';
import { SectionsHelper } from 'classes/helpers/sections.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { AuthContext } from 'contexts/auth.context';
import { SectionsContext } from 'contexts/sections.context';
import { AdminRoute, SectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const AdminTabNav = (props: { active: AdminRoute }) => {
  return (
    <ErrorBoundary componentName="AdminTabNav">
      <Heading mt="1" mb="0">
        {t('main.admin-portal')}
      </Heading>

      <AuthContext.Consumer>
        {(authCx) => (
          <SectionsContext.Consumer>
            {(sectionsCx) => (
              <TabNav.Root>
                {SectionsHelper.getAdminTabs({ role: authCx.current.role })
                  .filter((m) => !m.invisible)
                  .map((m, i) => (
                    <TabNav.Link
                      key={i}
                      active={props.active === m.value}
                      onClick={() =>
                        sectionsCx.tryChangeSection({
                          name: SectionName.AdminPortal,
                          fragment: m.value,
                          trigger: 'AdminTabNav',
                        })
                      }
                    >
                      <Flex gap={RADIX.FLEX.GAP.XS} align="center">
                        <Box>{t(m.label)}</Box>
                        {m.icon && (
                          <Box mt="1">
                            <m.icon />
                          </Box>
                        )}
                      </Flex>
                    </TabNav.Link>
                  ))}
              </TabNav.Root>
            )}
          </SectionsContext.Consumer>
        )}
      </AuthContext.Consumer>
    </ErrorBoundary>
  );
};
