import { HeightIcon, StopwatchIcon, WidthIcon } from '@radix-ui/react-icons';
import { Badge, Box, Card, Flex, Grid, Heading, Text } from '@radix-ui/themes';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { CustomIcon } from 'components/common/custom-icon';
import { safeBreakInches } from 'components/common/dialogs/pitch-data/details.tab';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { IAuthContext } from 'contexts/auth.context';
import { CustomIconPath } from 'enums/custom.enums';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IBallState, IPitch, ITrajectory } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { IRapsodoBreak } from 'lib_ts/interfaces/training/i-rapsodo-shot';
import React from 'react';

const COMPONENT_NAME = 'PitchSummaryTab';

interface IProps {
  authCx: IAuthContext;
  pitch: IPitch;
  machine: IMachine;
  shots: IMachineShot[];
}

interface IState {
  items: ITargetVsActualItem[];
}

const DEFAULT_STATE: IState = {
  items: [],
};

interface ITargetVsActualItem {
  label: string;
  units?: string;
  expected?: string;
  actual?: string;
  icon?: JSX.Element;
  tooltip?: string;
}

const DetailCard = (props: ITargetVsActualItem) => (
  <Card>
    <Flex direction="column" gap={RADIX.FLEX.GAP.SM} p={RADIX.FLEX.PAD.MD}>
      <Flex gap={RADIX.FLEX.GAP.XS}>
        <Box>
          <Text color={RADIX.COLOR.NEUTRAL}>{props.icon}</Text>
        </Box>
        <Box>
          <Text color={RADIX.COLOR.NEUTRAL}>{t(props.label)}</Text>
        </Box>
      </Flex>

      <Flex gap={RADIX.FLEX.GAP.SM}>
        <Box className="valign-bottom">
          <CommonTooltip
            disabled={!props.tooltip}
            trigger={
              <Heading size="8">{props.actual ?? t('common.na')}</Heading>
            }
            text={props.tooltip}
          />
        </Box>

        {props.units && props.actual !== undefined && (
          <Box className="valign-bottom">
            <Heading size="5" mb="1">
              {props.units}
            </Heading>
          </Box>
        )}
      </Flex>

      {props.expected && (
        <Box>
          <Badge>
            {t('common.expected')}: {props.expected}
            &nbsp;
            {props.units}
          </Badge>
        </Box>
      )}
    </Flex>
  </Card>
);

export class PitchSummaryTab extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.getItems = this.getItems.bind(this);
  }

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;
    this.setState({ items: this.getItems() });
  }

  private getItems() {
    const pitch = this.props.pitch;

    const medianBS = MiscHelper.getMedianObject(
      this.props.shots.filter((s) => s.bs).map((s) => s.bs as IBallState)
    ) as IBallState;

    const shotsWithTraj = this.props.shots.filter((s) => s.traj);

    const medianTraj =
      shotsWithTraj.length > 0
        ? (MiscHelper.getMedianObject(
            shotsWithTraj.map((s) => s.traj as ITrajectory)
          ) as ITrajectory)
        : undefined;

    const shotsWithBreak = this.props.shots.filter((s) => s.break);

    const medianBreaks =
      shotsWithBreak.length > 0
        ? (MiscHelper.getMedianObject(
            shotsWithBreak.map((s) => s.break as IRapsodoBreak)
          ) as IRapsodoBreak)
        : undefined;

    const [d_speed, a_speed] = [pitch.bs, medianBS].map((bs) => {
      if (!bs) {
        return undefined;
      }

      if (!isNaN(bs.vnet)) {
        return bs.vnet.toFixed(0);
      }

      // fallback
      if (bs.vx !== undefined && bs.vy !== undefined && bs.vz !== undefined) {
        return BallHelper.displayMagnitude([bs.vx, bs.vy, bs.vz], 0);
      }
    });

    const [d_spinMagnitude, a_spinMagnitude] = [pitch.bs, medianBS].map(
      (bs) => {
        if (!bs) {
          return undefined;
        }

        if (!isNaN(bs.wnet)) {
          return bs.wnet.toFixed(0);
        }

        // fallback
        if (bs.wx !== undefined && bs.wy !== undefined && bs.wz !== undefined) {
          return BallHelper.displayMagnitude([bs.wx, bs.wy, bs.wz], 0);
        }
      }
    );

    const d_breaks = pitch.breaks;
    const a_breaks = safeBreakInches({
      break: medianBreaks,
      traj: medianTraj,
    });

    const output: ITargetVsActualItem[] = [
      {
        icon: <StopwatchIcon />,
        label: 'common.speed',
        units: 'MPH',
        expected: d_speed,
        actual: a_speed,
      },
      {
        icon: <CustomIcon icon={CustomIconPath.ArrowAxis} />,
        label: 'common.spin-rate',
        units: 'RPM',
        expected:
          d_spinMagnitude && pitch.priority !== BuildPriority.Breaks
            ? d_spinMagnitude
            : undefined,
        actual: a_spinMagnitude,
      },
      {
        icon: <WidthIcon />,
        label: 'common.horizontal-break',
        units: 'IN',
        expected:
          d_breaks && pitch.priority === BuildPriority.Breaks
            ? (-1 * d_breaks.xInches).toFixed(1)
            : undefined,
        actual: a_breaks.xInches?.toFixed(1),
        tooltip: PitchDesignHelper.HB_TOOLTIP_TEXT,
      },
      {
        icon: <HeightIcon />,
        label: 'common.vertical-break',
        units: 'IN',
        expected:
          d_breaks && pitch.priority === BuildPriority.Breaks
            ? d_breaks.zInches.toFixed(1)
            : undefined,
        actual: a_breaks.zInches?.toFixed(1),
      },
    ];

    return output;
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Grid columns="2" rows="2" gap={RADIX.FLEX.GAP.MD}>
          {this.state.items.map((item, index) => (
            <DetailCard key={`item-${index}`} {...item} />
          ))}
        </Grid>
      </ErrorBoundary>
    );
  }
}
