import { Flex, Separator, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import {
  MIN_MANUAL_TRAIN_SHOTS,
  MIN_QUICK_TRAIN_SHOTS,
} from 'classes/plate-canvas';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonTextInput } from 'components/common/form/text';
import { SettingForm } from 'components/common/settings-dialog/form';
import { ACTIVE_ALERT } from 'components/common/settings-dialog/main-sections/machine.section';
import { SettingRow } from 'components/common/settings-dialog/row';
import { ActiveCalibrationModelWarning } from 'components/common/warnings/active-calibration-model-warning';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getMachineActiveModelID } from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { BallType, TrainingMode } from 'lib_ts/enums/machine.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';
import { UserMachineModelsService } from 'services/machine-models.service';

const COMPONENT_NAME = 'MachineGeneralTab';

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  onClose: () => void;
  onSave: (payload: Partial<IMachine>) => void;
}

interface IState {
  value: IMachine;
  /** when not empty, the calibration warning will show */
  warningBallType?: BallType;
}

export class MachineGeneralTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      value: { ...props.machineCx.machine },
      warningBallType: props.machineCx.activeModel?.calibration_only
        ? props.machineCx.machine.ball_type
        : undefined,
    };

    this.getShotsOptions = this.getShotsOptions.bind(this);
  }

  private getShotsOptions(): IOption[] {
    const minValue = (() => {
      if (this.props.authCx.current.role === UserRole.admin) {
        return 1;
      }

      switch (this.props.authCx.current.training_mode) {
        case TrainingMode.Manual: {
          return MIN_MANUAL_TRAIN_SHOTS;
        }

        case TrainingMode.Quick:
        default: {
          return MIN_QUICK_TRAIN_SHOTS;
        }
      }
    })();

    return ArrayHelper.getIntegerOptions(
      minValue,
      env.limits.max_training_shots,
      {
        // always include the current value
        includeLabels: [this.state.value.training_threshold],
      }
    ).filter((o) => {
      const intValue = parseInt(o.value);

      // admins will see 1-20, non-admins will see 1-10
      const breakpoint =
        this.props.authCx.current.role === UserRole.admin ? 20 : 10;

      if (intValue <= breakpoint) {
        return true;
      }

      if (intValue % 25 === 0) {
        //  and then 25, 50,...
        return true;
      }

      return false;
    });
  }

  render() {
    const active = this.props.machineCx.checkActive(true);

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SettingForm>
          {!active && ACTIVE_ALERT}

          <SettingRow
            header="settings.plate-distance"
            description={
              <Text>
                <p>{t('settings.plate-distance-msg')}</p>

                {this.props.authCx.current.role !== UserRole.admin && (
                  <p>{t('settings.only-super-admins-msg')}</p>
                )}
              </Text>
            }
            input={
              <CommonTextInput
                id="machine-plate-distance"
                type="number"
                disabled={
                  !active || this.props.authCx.current.role !== UserRole.admin
                }
                value={this.state.value.plate_distance.toString()}
                onNumericChange={(v) => {
                  if (v <= 0) {
                    return;
                  }

                  this.setState(
                    {
                      value: { ...this.state.value, plate_distance: v },
                    },
                    () =>
                      this.props.onSave({
                        _id: this.state.value._id,
                        plate_distance: this.state.value.plate_distance,
                      })
                  );
                }}
              />
            }
          />
          <Separator size="4" />
          <SettingRow
            header="Training Shots"
            description={<Text>{t('settings.training-shots-msg')}</Text>}
            input={
              <CommonSelectInput
                id="machine-training-threshold"
                name="training_threshold"
                disabled={!active}
                options={this.getShotsOptions()}
                value={this.state.value.training_threshold.toString()}
                onNumericChange={(v) => {
                  if (v <= 0) {
                    return;
                  }

                  this.setState(
                    {
                      value: {
                        ...this.state.value,
                        training_threshold: v,
                      },
                    },
                    () =>
                      this.props.onSave({
                        _id: this.state.value._id,
                        training_threshold: this.state.value.training_threshold,
                      })
                  );
                }}
                skipSort
              />
            }
          />
          <Separator size="4" />
          <SettingRow
            header="common.ball-type"
            description={<Text>{t('settings.ball-type-msg')}</Text>}
            input={
              <CommonSelectInput
                id="machine-ball-type"
                name="ball_type"
                disabled={!active}
                options={(
                  this.props.machineCx.machine.ball_type_options ?? [
                    BallType.MLB,
                  ]
                ).map((t) => ({ label: t, value: t }))}
                value={this.state.value.ball_type}
                onChange={async (v) => {
                  const nextMachine: IMachine = {
                    ...this.state.value,
                    ball_type: v as BallType,
                  };

                  const nextID = getMachineActiveModelID(nextMachine);
                  if (!nextID) {
                    NotifyHelper.error({
                      message_md: t('settings.no-active-model-msg', {
                        machineID: this.state.value.machineID,
                        ball_type: v,
                      }),
                    });
                    return;
                  }

                  const nextModel =
                    await UserMachineModelsService.getInstance().getModel(
                      nextID
                    );

                  this.setState(
                    {
                      value: nextMachine,
                      warningBallType: nextModel?.calibration_only
                        ? nextMachine.ball_type
                        : undefined,
                    },
                    () =>
                      this.props.onSave({
                        _id: this.state.value._id,
                        ball_type: this.state.value.ball_type,
                      })
                  );
                }}
              />
            }
          />

          {this.state.warningBallType && (
            <ActiveCalibrationModelWarning
              overrideBallType={this.state.warningBallType}
              beforeNavCallback={async () => this.props.onClose()}
            />
          )}

          <Separator size="4" />
          <SettingRow
            header="settings.nickname"
            description={<Text>{t('settings.nickname-msg')}</Text>}
            input={
              <CommonTextInput
                id="machine-nickname"
                disabled={!active || this.props.authCx.restrictedGameStatus()}
                value={this.state.value.nickname}
                onChange={(v) =>
                  this.setState(
                    {
                      value: {
                        ...this.state.value,
                        nickname: v,
                      },
                    },
                    () =>
                      this.props.onSave({
                        _id: this.state.value._id,
                        nickname: this.state.value.nickname,
                      })
                  )
                }
                optional
              />
            }
          />

          <Separator size="4" />
          <SettingRow
            header="settings.auto-reset"
            description={<Text>{t('settings.auto-reset-msg')}</Text>}
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="machine-auto-reset"
                  checked={this.state.value.enable_auto_reset_ms}
                  onCheckedChange={(v) =>
                    this.setState(
                      {
                        value: {
                          ...this.state.value,
                          enable_auto_reset_ms: v,
                        },
                      },
                      () =>
                        this.props.onSave({
                          _id: this.state.value._id,
                          enable_auto_reset_ms:
                            this.state.value.enable_auto_reset_ms,
                        })
                    )
                  }
                />
              </Flex>
            }
          />
        </SettingForm>
      </ErrorBoundary>
    );
  }
}
