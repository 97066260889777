import { Box, Text } from '@radix-ui/themes';
import {
  DragHandle,
  DropHandle,
  IDropHandle,
} from 'components/common/drag-drop';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { ISectionsContext } from 'contexts/sections.context';
import { DragItem, DropContainer } from 'enums/dnd.enums';
import { SectionName } from 'enums/route.enums';
import { ISidebarPitchList } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches';

const ENABLE_DRAGGING = false;

interface IProps {
  sectionsCx: ISectionsContext;
  listsCx: IPitchListsContext;

  file: ISidebarPitchList;
  accept: DragItem;
}

export const PitchListItem = (props: IProps) => {
  const safePath = [props.file.pathEnd, props.file.name]
    .filter((s) => !!s)
    .join('/');

  const active =
    props.sectionsCx.active.name === SectionName.PitchList &&
    props.listsCx.active?._id === props.file.object._id;

  const content = (
    <Box
      title={safePath}
      pt={RADIX.BOX.PAD.XS}
      pb={RADIX.BOX.PAD.XS}
      pl={RADIX.BOX.PAD.MD}
      pr={RADIX.BOX.PAD.SM}
      data-testid="SidebarPitchList"
      className={
        props.listsCx.loading ? 'cursor-not-allowed' : 'cursor-pointer'
      }
      onClick={() => {
        if (props.listsCx.loading) {
          return;
        }

        if (
          !props.listsCx.active ||
          props.listsCx.active._id !== props.file.object._id ||
          props.sectionsCx.active.name !== SectionName.PitchList
        ) {
          /** move user to pitch list (invisible) section */
          props.sectionsCx.tryChangeSection({
            trigger: 'sidebar, open list',
            name: SectionName.PitchList,
            fragment: props.file.object._id,
          });
        }
      }}
    >
      <DropHandle
        value={props.file}
        container={DropContainer.PitchList}
        accept={DragItem.Pitch}
      >
        <Text
          color={active ? RADIX.COLOR.ACCENT : undefined}
          // as div + overflow hidden allows truncate to take effect
          as="div"
          truncate
        >
          {props.file.name}
        </Text>
      </DropHandle>
    </Box>
  );

  if (!ENABLE_DRAGGING) {
    return content;
  }

  return (
    <DragHandle
      value={props.file.object}
      type={props.accept}
      endFn={(item, monitor) => {
        if (!item) {
          return;
        }

        const target = monitor.getDropResult<IDropHandle>();
        if (!target) {
          return;
        }

        const list: IPitchList = item.value;
        const targetFolder: string = target.value;

        if (typeof targetFolder !== 'string') {
          return;
        }

        if (list.folder === targetFolder) {
          return;
        }

        props.listsCx.updateList({
          payload: {
            _id: list._id,
            folder: targetFolder,
          },
          successMsg: `Pitch list "${list.name}" moved to "${targetFolder}" folder!`,
        });
      }}
    >
      {content}
    </DragHandle>
  );
};
