import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons';
import { Box } from '@radix-ui/themes';

export const CommonTableSortIcon = (props: {
  colKey: string;
  disabled?: boolean;
  sort?: 'asc' | 'desc';
}) => {
  const PLACEHOLDER = <></>;

  if (props.disabled) {
    return PLACEHOLDER;
  }

  switch (props.sort) {
    case 'asc': {
      return (
        <Box style={{ marginTop: '2px' }}>
          <CaretUpIcon />
        </Box>
      );
    }

    case 'desc': {
      return (
        <Box style={{ marginTop: '2px' }}>
          <CaretDownIcon />
        </Box>
      );
    }

    default: {
      return PLACEHOLDER;
    }
  }
};
