import { Box, Code, Flex, Heading, Text } from '@radix-ui/themes';
import { CSVBall } from 'classes/csv-ball';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { BallPreview } from 'components/common/ball-preview';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GROUP_TARGET } from 'components/common/trajectory-view/helpers';
import { CommonVideoPreview } from 'components/common/video-preview';
import { IAuthContext } from 'contexts/auth.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { CSVFormat } from 'enums/csv';
import { PitchUploadStep } from 'enums/pitch-uploader';
import { t } from 'i18next';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import React from 'react';

const COMPONENT_NAME = 'PitchUploaderSidebar';

interface IProps {
  authCx: IAuthContext;
  videosCx: IVideosContext;

  step: PitchUploadStep;

  /** based on which required headers found on the first entry in the CSV results */
  format: CSVFormat;

  fileName: string;

  valid: number;
  invalid: number;

  ball?: CSVBall;

  handleVideoChange: (
    video_id: string | undefined,
    video_playback: IVideoPlayback | undefined
  ) => void;
}

interface IState {}

const DEFAULT_STATE: IState = {};

export class PitchUploaderSidebar extends React.Component<IProps, IState> {
  private preview?: BallPreview;

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.handleVideoChange = this.handleVideoChange.bind(this);
    this.renderSidebar = this.renderSidebar.bind(this);
  }

  restartAnimation(source: string) {
    this.preview?.trajView?.restartAnimation(source);
  }

  private async handleVideoChange(video_id?: string) {
    if (!video_id) {
      /** clear video details for active ball */
      this.props.handleVideoChange(undefined, undefined);
      return;
    }

    // fetch video detials for active ball
    const active = this.props.ball;
    const video = this.props.videosCx.videos.find((v) => v._id === video_id);
    if (!video || !active?.details.pitchChars) {
      // can't proceed
      return;
    }

    const warnings = VideoHelper.validateSelection({
      position: active.details.pitchChars.bs,
      video: video,
    });

    if (warnings.length > 0) {
      NotifyHelper.warning({
        message_md: warnings[0],
        inbox: true,
      });
    }

    const playback = await this.props.videosCx.getCachedPlayback(video._id);
    this.props.handleVideoChange(video._id, playback);
  }

  private renderSidebar() {
    switch (this.props.step) {
      case PitchUploadStep.upload: {
        return (
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            <Box>
              <Heading size={RADIX.HEADING.SIZE.SM}>
                {t('common.notice')}
              </Heading>
              <Text>{t('pd.accepted-upload-formats')}</Text>
            </Box>
          </Flex>
        );
      }

      case PitchUploadStep.preview: {
        const blurbs: { label: string; text: string }[] = [];

        if (this.props.fileName) {
          blurbs.push({
            label: 'common.file-name',
            text: this.props.fileName,
          });
        }

        if (this.props.format) {
          blurbs.push({
            label: 'pd.detected-format',
            text: this.props.format,
          });
        }

        blurbs.push({
          label: 'pd.valid-pitches',
          text: this.props.valid.toFixed(0),
        });

        if (this.props.invalid > 0) {
          blurbs.push({
            label: 'pd.invalid-pitches',
            text: this.props.invalid.toFixed(0),
          });
        }

        return (
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            {blurbs.map((b, i) => (
              <Box key={`upload-summary-${i}`}>
                <Heading size={RADIX.HEADING.SIZE.SM}>{t(b.label)}</Heading>
                <Code>{b.text}</Code>
              </Box>
            ))}
          </Flex>
        );
      }

      case PitchUploadStep.design: {
        const details = this.props.ball?.details;
        if (!details?.pitchChars) {
          return;
        }

        return (
          <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
            <Heading size={RADIX.HEADING.SIZE.MD}>
              {t('pd.ball-preview')}
            </Heading>

            <BallPreview
              ref={(elem) => (this.preview = elem as BallPreview)}
              authCx={this.props.authCx}
              spin={details.pitchChars.bs}
              orientation={{
                latitude_deg: details.pitchChars?.seams?.latitude_deg ?? 0,
                longitude_deg: details.pitchChars?.seams?.longitude_deg ?? 0,
              }}
              trajModels={[
                {
                  guid: 'main',
                  description: 'common.target-trajectory-of-the-pitch',
                  button_group: GROUP_TARGET,
                  color: GROUP_TARGET.color,
                  traj: details.pitchChars.traj,
                  bs: details.pitchChars.bs,
                },
              ]}
            />

            <CommonVideoPreview
              previewPx={details.pitchChars?.ms.px}
              previewPz={details.pitchChars?.ms.pz}
              playback={details.video_playback}
              selectConfig={{
                px: details.pitchChars.traj.px,
                video_id: details.video_id,
                onChange: this.handleVideoChange,
              }}
            />
          </Flex>
        );
      }

      default: {
        return;
      }
    }
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        {this.renderSidebar()}
      </ErrorBoundary>
    );
  }
}
