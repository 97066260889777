import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Box } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GameStatus } from 'lib_ts/enums/mlb.enums';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'GameStatusBanner';

const TEXT_LOOKUP: {
  [status: string]: {
    header: string;
    color: RadixColor;
    title: string;
  };
} = {
  [GameStatus.InProgress]: {
    header: 'Live Game In Progress',
    color: RADIX.COLOR.SUCCESS,
    title:
      'Your team is currently participating in a home game. Certain areas and features of the application will be disabled until the game ends.',
  },

  [GameStatus.Unknown]: {
    header: 'Unknown Status',
    color: RADIX.COLOR.WARNING,
    title:
      "Certain areas and features of the application may be disabled until we can determine your team's game status.",
  },

  [GameStatus.Unreachable]: {
    header: 'Server Offline',
    color: RADIX.COLOR.DANGER,
    title:
      'Certain areas and features of the application may be disabled until we can reach the game status server.',
  },
};

interface IProps {
  status?: GameStatus;
}

interface IState {}

const DEFAULT_STATE: IState = {};

export class GameStatusBanner extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;
  }

  render() {
    if (!this.props.status) {
      return;
    }

    const statusDef = TEXT_LOOKUP[this.props.status];

    if (!statusDef) {
      return;
    }

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Box title={statusDef.title}>
          <CommonCallout
            icon={<InfoCircledIcon />}
            color={statusDef.color}
            text={statusDef.header}
          />
        </Box>
      </ErrorBoundary>
    );
  }
}
