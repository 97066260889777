import { Box, Flex, Grid, Heading } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { getPovTooltip, PlateView } from 'components/common/plate-view';
import { ReleaseView } from 'components/common/release-view';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBuildPitchChars, IPlateLoc } from 'lib_ts/interfaces/pitches';
import React from 'react';

const COMPONENT_NAME = 'BallFlightDesigner';

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;

  chars: Partial<IBuildPitchChars>;

  onUpdatePlate: (plate: IPlateLoc) => void;
  onUpdateRelease: (pos: { px: number; pz: number }) => void;
}

interface IState {}

export class BallFlightDesigner extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    if (!this.props.chars.traj) {
      return;
    }

    const plate = TrajHelper.getPlateLoc(this.props.chars.traj);

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Grid columns={{ initial: '1', md: '2' }} gap={RADIX.FLEX.GAP.MD}>
          <Box className="rounded-md foreground">
            <Flex gap={RADIX.FLEX.GAP.MD} justify="between">
              <Heading size={RADIX.HEADING.SIZE.SM}>
                {t('pd.plate-location')}
              </Heading>

              {getPovTooltip()}
            </Flex>

            <PlateView
              cookiesCx={this.props.cookiesCx}
              authCx={this.props.authCx}
              machineCx={this.props.machineCx}
              plate_x={plate.plate_x}
              plate_z={plate.plate_z}
              onUpdate={this.props.onUpdatePlate}
            />
          </Box>
          <Box className="rounded-md foreground">
            <Heading size={RADIX.HEADING.SIZE.SM}>
              {t('pd.release-position')}
            </Heading>

            <ReleaseView
              px={this.props.chars.traj.px}
              pz={this.props.chars.traj.pz}
              onUpdate={this.props.onUpdateRelease}
            />
          </Box>
        </Grid>
      </ErrorBoundary>
    );
  }
}
