import { EnvelopeClosedIcon } from '@radix-ui/react-icons';
import { Badge, Box, Flex, Heading, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { SettingForm } from 'components/common/settings-dialog/form';
import { SettingRow } from 'components/common/settings-dialog/row';
import { CommonTabs } from 'components/common/tabs';
import { IAuthContext } from 'contexts/auth.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { t } from 'i18next';
import { UserRole, UserStatus } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IUser } from 'lib_ts/interfaces/i-user';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import React from 'react';
import { AdminPitchListsService } from 'services/admin/pitch-lists.service';
import { AuthService } from 'services/auth.service';
import { SettingButton } from '../button';

const COMPONENT_NAME = 'UserSection';

export enum UserSettingsTabKey {
  ReassignPitchLists = 'Reassign Pitch Lists',
  TeamAdmins = 'Team Admins',
}

interface IProps {
  onClose: () => void;
  authCx: IAuthContext;
  listsCx: IPitchListsContext;
  initialTab?: UserSettingsTabKey;
}

interface IState {
  payload: IPitchListPutManyRequest;

  admins: IUser[];

  defOptions: IOption[];
  idOptions: IOption[];

  activeTab: UserSettingsTabKey;
}

const MAX_ADMINS_DISPLAYED = 3;

export class UserSection extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    const defOptions: IOption[] = [];

    if (props.authCx.reassignOptions.users.length > 0) {
      defOptions.push({
        label: t('common.personal'),
        value: 'team-users',
      });
    }

    if (
      this.props.authCx.current?.machine_lists &&
      props.authCx.reassignOptions.machines.length > 0
    ) {
      defOptions.push({
        label: t('common.machine'),
        value: 'team-machines',
      });
    }

    if (
      this.props.authCx.current?.team_lists &&
      props.authCx.reassignOptions.teams.length > 0
    ) {
      defOptions.push({
        label: t('common.team'),
        value: 'teams',
      });
    }

    const refPayload: IPitchListPutManyRequest = {
      filter: {
        _parent_id: props.authCx.current.userID,
        _parent_def: 'team-users',
      },
      update: {
        processed: new Date(),
        process_notes: `reassigned from settings user section by ${this.props.authCx.current.email}`,
      },
    };

    this.state = {
      payload: refPayload,

      defOptions: defOptions,
      idOptions: PitchListHelper.getReassignToIdOptions({
        options: props.authCx.reassignOptions,
        role: props.authCx.current.role,
        teamID: props.authCx.current.teamID,
        parentDef: refPayload.filter._parent_def ?? '',
        parentId: refPayload.filter._parent_id ?? '',
      }),

      admins: [],

      activeTab: props.initialTab ?? UserSettingsTabKey.ReassignPitchLists,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderContact = this.renderContact.bind(this);
    this.renderReassign = this.renderReassign.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    AuthService.getInstance()
      .getTeamAdmins()
      .then((admins) => {
        this.setState({ admins: admins });
      });
  }

  private handleSubmit() {
    if (
      !this.state.payload.filter._parent_id ||
      !this.state.payload.update._parent_def ||
      !this.state.payload.update._parent_id
    ) {
      NotifyHelper.error({
        message_md: t('common.check-inputs-msg'),
      });
      return;
    }

    if (this.props.authCx.current.role === UserRole.basic) {
      /** not copying -- check permissions to overwrite/modify */
      if (
        this.state.payload.filter._parent_def !== 'team-users' ||
        this.state.payload.filter._parent_id !==
          this.props.authCx.current.userID
      ) {
        NotifyHelper.warning({
          message_md:
            'You cannot reassign pitch lists that do not belong to you as a regular user.',
        });
        return;
      }

      /** run the update via regular pitch list service -- can only bulk reassign things belonging to self as user */
      this.props.listsCx.updateLists(this.state.payload).then((success) => {
        if (success) {
          this.props.listsCx.refreshLists(true);
          this.props.onClose();
        }
      });
      return;
    }

    /** run the update via admin service -- can only bulk reassign things belonging to own team as team admin */
    if (
      [UserRole.admin, UserRole.team_admin].includes(
        this.props.authCx.current.role
      )
    ) {
      AdminPitchListsService.getInstance()
        .putManyLists(this.state.payload)
        .then((success) => {
          if (success) {
            this.props.listsCx.refreshLists(true);
            this.props.onClose();
          }
        });
      return;
    }
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex
          className={RADIX.VFLEX.GROW}
          direction="column"
          gap={RADIX.FLEX.GAP.MD}
          justify="between"
        >
          <Box>
            <Flex gap={RADIX.FLEX.GAP.SM}>
              <Heading size={RADIX.HEADING.SIZE.MD}>
                {t('settings.user')}
              </Heading>
              <Badge>{this.props.authCx.current.email}</Badge>
            </Flex>

            <CommonTabs
              value={this.state.activeTab}
              onValueChange={(value) => {
                this.setState({ activeTab: value as UserSettingsTabKey });
              }}
              tabs={[
                {
                  value: UserSettingsTabKey.ReassignPitchLists,
                  label: 'settings.reassign-pitch-lists',
                  content: this.renderReassign(),
                },
                {
                  value: UserSettingsTabKey.TeamAdmins,
                  label: 'settings.team-admins',
                  content: this.renderContact(),
                },
              ]}
            />
          </Box>
        </Flex>
      </ErrorBoundary>
    );
  }

  private renderContact() {
    return (
      <SettingForm>
        <Box>{t('settings.api-keys-msg')}</Box>

        {this.state.admins
          .filter((a) => a.status === UserStatus.verified)
          .filter((_, i) => i < MAX_ADMINS_DISPLAYED)
          .map((a, i) => (
            <SettingRow
              key={i}
              header={a.email}
              description={
                <Text className="text-titlecase">
                  {a.role.split('-').join(' ')}
                </Text>
              }
              input={
                <SettingButton
                  icon={<EnvelopeClosedIcon />}
                  label="settings.contact"
                  onClick={() => window.open(`mailto:${a.email}`)}
                />
              }
            />
          ))}
      </SettingForm>
    );
  }

  private renderReassign() {
    return (
      <SettingForm>
        <Box>{t('settings.reassign-lists-msg')}</Box>

        <SettingRow
          header="Type"
          description={
            <Text>
              <p>{t('settings.reassign-type-msg')}</p>
              <p>{t('settings.reassign-type-example-msg')}</p>
            </Text>
          }
          input={
            <CommonSelectInput
              id="reassign-parent-def"
              name="_parent_def"
              options={this.state.defOptions}
              value={this.state.payload.update._parent_def}
              onChange={(v) => {
                const parentDef = v;
                const nextPayload = { ...this.state.payload };
                nextPayload.update = {
                  _parent_def: parentDef,
                  _parent_id: undefined,
                };

                this.setState({
                  payload: nextPayload,
                  idOptions: PitchListHelper.getReassignToIdOptions({
                    options: this.props.authCx.reassignOptions,
                    role: this.props.authCx.current.role,
                    teamID: this.props.authCx.current.teamID,
                    parentDef: parentDef,
                    parentId: '',
                  }),
                });
              }}
              optional
            />
          }
        />

        <SettingRow
          header="Owner"
          description={<Text>{t('settings.reassign-owner-msg')}</Text>}
          input={
            <CommonSearchInput
              id="reassign-parent"
              name="_parent_id"
              disabled={!this.state.payload.update._parent_def}
              options={this.state.idOptions}
              values={
                this.state.payload.update._parent_id
                  ? [this.state.payload.update._parent_id]
                  : []
              }
              onChange={(v) => {
                const parentId = v[0];

                if (!parentId) {
                  NotifyHelper.warning({
                    message_md: t('common.check-inputs-msg'),
                  });
                  return;
                }

                const nextPayload = { ...this.state.payload };
                nextPayload.update._parent_id = parentId;

                this.setState(
                  {
                    payload: nextPayload,
                  },
                  () => this.handleSubmit()
                );
              }}
              optional
            />
          }
        />
      </SettingForm>
    );
  }
}
