import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Separator,
  Strong,
  Text,
} from '@radix-ui/themes';
import { CSVBall, QualityError } from 'classes/csv-ball';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { CommonSingleAccordion } from 'components/common/accordion/single';
import { CommonCallout } from 'components/common/callouts';
import { HelpCallout } from 'components/common/callouts/help';
import { CopyPitchesDialog } from 'components/common/dialogs/copy-pitches';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFileUploader } from 'components/common/file-uploader';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonTextInput } from 'components/common/form/text';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { CommonLink } from 'components/common/link';
import { CommonProgress } from 'components/common/progress';
import { CommonRadio } from 'components/common/radio';
import { CommonTabs } from 'components/common/tabs';
import { PresetTrainingDialog } from 'components/machine/dialogs/preset-training';
import { TrainingDialog } from 'components/machine/dialogs/training';
import { SectionHeader } from 'components/sections/header';
import { BallFlightDesigner } from 'components/sections/pitch-design/ball-flight-designer';
import { PitchUploaderBallTable } from 'components/sections/pitch-uploader/ball-table';
import { PitchUploaderCsvTable } from 'components/sections/pitch-uploader/csv-table';
import { PitchUploaderSidebar } from 'components/sections/pitch-uploader/sidebar';
import env from 'config';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { PitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { DirtyForm, ISectionsContext } from 'contexts/sections.context';
import { TrainingContext, TrainingProvider } from 'contexts/training.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { CookieKey } from 'enums/cookies.enums';
import { CSVFormat } from 'enums/csv';
import { PitchUploadStep } from 'enums/pitch-uploader';
import { t } from 'i18next';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import {
  BuildPriority,
  PITCH_TYPE_OPTIONS,
  PitchType,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IBallDetailsError,
  IPitch,
  IPlateLoc,
} from 'lib_ts/interfaces/pitches';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MainService } from 'services/main.service';
import { SessionEventsService } from 'services/session-events.service';
import { StateTransformService } from 'services/state-transform.service';

const COMPONENT_NAME = 'PitchUploader';

/** ideally this should be a multiple of the PYTHON_BATCH_SIZE */
const UPLOADER_BATCH_SIZE = 20;

enum TabKey {
  Errors = 'Errors',
  BallData = 'BallData',
  CsvData = 'CsvData',
}

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  sectionsCx: ISectionsContext;
  videosCx: IVideosContext;
}

interface IErrorTabState {
  /** calculated from csvData, one or more errors */
  invalidBallData: CSVBall[];
  invalidBallIndex: number;
  invalidBall?: CSVBall;
}

interface IBallTabState {
  /** calculated from csvData, no errors */
  validBallData: CSVBall[];
  /** index of pitch shown in designer */
  validBallIndex: number;
  /** ball from validBallData, set when validBallIndex changes */
  validBall?: CSVBall;
}

interface IDialogs {
  dialogSave?: number;
  dialogTraining?: number;
}

interface IState extends IBallTabState, IErrorTabState, IDialogs {
  step: PitchUploadStep;

  fileName: string;

  /** based on which required headers found on the first entry in the CSV results */
  detectedFormat: CSVFormat;

  /** control and reset preview results active tab */
  activeTab: TabKey;

  selectedPitches: Partial<IPitch>[];
  autoFixProgress: number;
}

const DEFAULT_STATE: IState = {
  step: PitchUploadStep.upload,

  fileName: 'None',

  detectedFormat: CSVFormat.Unknown,

  activeTab: TabKey.Errors,

  validBallData: [],
  validBallIndex: 0,

  invalidBallData: [],
  invalidBallIndex: 0,

  selectedPitches: [],
  /** -1 hides the progress bar, otherwise value will correspond to progress of fixes */
  autoFixProgress: -1,
};

const FILE_TYPES = ['text/csv'];

const getAlertNoCsv = () => (
  <CommonCallout text="common.upload-a-csv-file-first" />
);
const getAlertNoValidBalls = () => <CommonCallout text="pd.no-valid-pitches" />;
const getAlertInvalidIndex = () => (
  <CommonCallout color={RADIX.COLOR.DANGER} text="pd.invalid-pitch-index" />
);

const WARNING_BREAKS_MD = `In order to control for breaks, please include \`HorizontalBreakIn\` and \`VerticalBreakIn\` fields for each pitch.`;
const WARNING_SEAMS_MD = `No seam orientation specified. For more accurate pitch replication, please provide seam orientation details (e.g. in Hawkeye format using \`Xx...Zz\` columns).`;
const WARNING_STATCAST_MD = `This upload format does not fully specify 3D spin data. It is highly recommended to use a different format, such as Hawkeye.`;

export class PitchUploader extends React.Component<IProps, IState> {
  private selectInvalid?: CommonSelectInput;
  private selectType?: CommonSelectInput;
  private selectPitch?: CommonSelectInput;
  private sidebar?: PitchUploaderSidebar;

  fileUpload?: CommonFileUploader;
  csvTabNode?: PitchUploaderCsvTable;
  ballTabNode?: PitchUploaderBallTable;

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.handleAutoFixAll = this.handleAutoFixAll.bind(this);
    this.handleAutoFixOne = this.handleAutoFixOne.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTrainAll = this.handleTrainAll.bind(this);
    this.handleTrainCurrent = this.handleTrainCurrent.bind(this);
    this.setActive = this.setActive.bind(this);

    this.renderBody = this.renderBody.bind(this);
    this.renderDesigner = this.renderDesigner.bind(this);
    this.renderTrainingDialog = this.renderTrainingDialog.bind(this);
    this.renderErrorTab = this.renderErrorTab.bind(this);
    this.renderPreview = this.renderPreview.bind(this);
    this.renderPreviewTabs = this.renderPreviewTabs.bind(this);
    this.renderUploadControls = this.renderUploadControls.bind(this);
  }

  componentDidUpdate(_: IProps, prevState: Readonly<IState>) {
    const nextState: Partial<IState> = {};

    if (prevState.validBallIndex !== this.state.validBallIndex) {
      this.sidebar?.restartAnimation(`${COMPONENT_NAME} > changed ball`);
    }

    /** automatically move to balls tab if there are no more balls with errors */
    if (
      prevState.invalidBallData.length !== this.state.invalidBallData.length
    ) {
      nextState.activeTab =
        this.state.invalidBallData.length > 0 ? TabKey.Errors : TabKey.BallData;
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState as any);
    }
  }

  private async handleSave(config: { machineID: string; mode: 'one' | 'all' }) {
    switch (config.mode) {
      case 'all': {
        const validPitches = this.state.validBallData.map((ball) => {
          return ball.prepForSave();
        });

        const incompleteIndex = validPitches.findIndex((p) => !p.name);
        if (incompleteIndex !== -1) {
          NotifyHelper.error({
            message_md:
              'Each pitch must have a name. Please check your inputs and try again.',
          });

          this.setState({
            validBallIndex: incompleteIndex,
            validBall: this.state.validBallData[incompleteIndex],
          });
          return;
        }

        this.setState({
          dialogSave: Date.now(),
          selectedPitches: validPitches,
        });
        return;
      }

      case 'one': {
        if (this.state.validBall) {
          this.setState({
            dialogSave: Date.now(),
            selectedPitches: [this.state.validBall.prepForSave()],
          });
        }
        return;
      }

      default: {
        return;
      }
    }
  }

  private async handleTrainCurrent() {
    this.setState({
      dialogTraining: Date.now(),
      selectedPitches: [
        this.state.validBallData[this.state.validBallIndex].getPartialPitch(),
      ],
    });
  }

  private async handleTrainAll() {
    this.setState({
      dialogTraining: Date.now(),
      selectedPitches: this.state.validBallData.map((b) => b.getPartialPitch()),
    });
  }

  /** triggered by file upload selection */
  private async onChange(files: File[]) {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append('csv', files[0]);

    const fileName = files[0].name;

    const jsonData = await MainService.getInstance().convertCSVToJSON(formData);

    const format = CSVBall.detectFormat(jsonData?.[0]);

    const videosDict = this.props.videosCx.getVideosDict('VideoTitle');

    const balls = jsonData.map((data, index) => {
      return new CSVBall({
        format: format,
        data: data,
        index: index,
        machine: this.props.machineCx.machine,
        plate_distance: this.props.machineCx.machine.plate_distance,
        videosDict: videosDict,
        priority: this.props.cookiesCx.app.pitch_upload_options.priority,
      });
    });

    for (const chunk of ArrayHelper.chunkArray(balls, UPLOADER_BATCH_SIZE)) {
      await StateTransformService.getInstance()
        .buildPitches({
          machine: this.props.machineCx.machine,
          notifyError: false,
          pitches: chunk.map((pb) => pb.rawChars),
          mixinBalls: chunk,
        })
        .then(() => {
          // do nothing
        })
        .catch((e) => {
          console.error(e);
          NotifyHelper.error({
            message_md: `Encountered one or more errors while processing your file. ${ERROR_MSGS.CONTACT_SUPPORT}`,
          });
        });
    }

    const options = this.props.cookiesCx.app.pitch_upload_options;

    /** if any pitch upload options were selected, perform them after chars have been set for each ball */
    if (env.enable.pitch_upload_avg_release && options.average_release) {
      CSVBall.updateAverageReleasesByPitcher(balls);
    }

    if (env.enable.pitch_upload_avg_chars && options.average_pitch) {
      const avgPitches = await CSVBall.getAveragesByPitcherAndType({
        balls: balls,
        machine: this.props.machineCx.machine,
        priority: this.props.cookiesCx.app.pitch_upload_options.priority,
      });

      switch (options.average_pitch) {
        case 'replace': {
          /** delete existing entries before replacing */
          balls.splice(0, balls.length, ...avgPitches);
          break;
        }

        case 'append': {
          /** append averages to the end of the list */
          balls.push(...avgPitches);
          break;
        }

        default: {
          break;
        }
      }
    }

    /** might still contain errors */
    const nonEmptyBalls = balls.filter((b) => !!b.details.pitchChars);

    /** notify if any CSV rows failed to parse entirely */
    const excluded = balls.length - nonEmptyBalls.length;
    if (excluded > 0) {
      NotifyHelper.warning({
        message_md: `Excluded ${excluded} invalid rows from results`,
      });
    }

    // double-check that the requested seams for each ball approximately lines up with the seams implied by the built ball state
    nonEmptyBalls.forEach((b) => b.checkSeamsDeltas());

    const validBallData = nonEmptyBalls.filter((m) => m.errors.length === 0);
    const invalidBallData = nonEmptyBalls.filter((m) => m.errors.length > 0);

    this.setState(
      {
        step: PitchUploadStep.preview,

        fileName: fileName,
        detectedFormat: format,

        validBallData: validBallData,
        validBallIndex: 0,
        /** fix: when user is on index 0 and upload a new file, validBallIndex won't change but validBall should */
        validBall: validBallData.length > 0 ? validBallData[0] : undefined,

        invalidBallData: invalidBallData,
        invalidBallIndex: 0,
        /** fix: when user is on index 0 and upload a new file, invalidBallIndex won't change but invalidBall should */
        invalidBall:
          invalidBallData.length > 0 ? invalidBallData[0] : undefined,

        activeTab: invalidBallData.length > 0 ? TabKey.Errors : TabKey.BallData,
      },
      () => {
        /** ensure we start at the first of the pitches and trigger playback to load */
        this.setDesignIndex(0);
      }
    );

    /** reset tables to first page */
    if (this.csvTabNode && this.csvTabNode.tableNode) {
      this.csvTabNode.tableNode.updatePage(0);
    }

    if (this.ballTabNode && this.ballTabNode.tableNode) {
      this.ballTabNode.tableNode.updatePage(0);
    }

    /** sectionsCx will block navigation with confirmation */
    this.props.sectionsCx.markDirtyForm(DirtyForm.PitchUploader);

    SessionEventsService.postEvent({
      category: 'pitch',
      tags: 'uploader',
      data: {
        action: 'convert',
        file: fileName,
        format: format,
        valid: validBallData.length,
        invalid: invalidBallData.length,
      },
    });
  }

  /** only necessary to auto-fetch and set video preview */
  private setDesignIndex(newIndex: number) {
    const resetSelects = () => {
      this.selectType?.reset();
      this.selectPitch?.reset();
    };

    const newActive = this.state.validBallData[newIndex];

    if (
      !newActive ||
      !newActive.details.video_id ||
      newActive.details.video_playback
    ) {
      // no way or need to get playback details, just change the index
      this.setState(
        {
          validBallIndex: newIndex,
          validBall: this.state.validBallData[newIndex],
        },
        () => resetSelects()
      );
      return;
    }

    // get playback details first, before updating the index and data at the same time
    this.props.videosCx
      .getCachedPlayback(newActive.details.video_id)
      .then((playback) => {
        const balls = [...this.state.validBallData];
        newActive.details.video_playback = playback;
        balls.splice(newIndex, 1, newActive);

        this.setState(
          {
            validBallIndex: newIndex,
            validBall: this.state.validBallData[newIndex],
            validBallData: balls,
          },
          () => resetSelects()
        );
      });
  }

  private incrementDesignIndex(incr: 1 | -1) {
    const newIndex = this.state.validBallIndex + incr;
    this.setDesignIndex(newIndex);
    this.selectPitch?.reset();
  }

  /** edit the active ball and update balls list */
  private setActive(config: {
    release?: { px: number; pz: number };
    plate?: IPlateLoc;
  }) {
    const active = this.state.validBall;
    if (!active?.details?.pitchChars) {
      return;
    }

    const currentRelease = {
      px: active.details.pitchChars.traj.px,
      pz: active.details.pitchChars.traj.pz,
    };

    const rotated = AimingHelper.aimWithoutShots({
      chars: active.details.pitchChars,
      release: config.release ?? currentRelease,
      plate_location: config.plate ?? active.details.pitchChars.plate,
    });

    active.details.pitchChars = rotated;

    this.setState(
      {
        validBall: active,
      },
      () => this.sidebar?.restartAnimation(`${COMPONENT_NAME} > setActive`)
    );
  }

  /** mutates the original ball */
  private async handleAutoFixOne(config: {
    ball: CSVBall;
    error: IBallDetailsError;
    withToast: boolean;
  }): Promise<void> {
    const ball = config.ball;

    try {
      if (!config.error.fix?.autoFixFn) {
        throw Error('Error cannot have empty auto-fix function.');
      }

      const chars = ball.details.pitchChars;

      if (!chars) {
        throw Error('Pitch characteristics cannot be empty.');
      }

      /** run the fix on active, bs will be modified */
      chars.bs = config.error.fix.autoFixFn(chars.bs);

      /** request a new pitch from python server */
      const results = await StateTransformService.getInstance().buildPitches({
        machine: this.props.machineCx.machine,
        notifyError: false,
        pitches: [
          {
            bs: chars.bs,
            plate: chars.plate,
            seams: chars.seams,
            breaks: chars.breaks,
            priority: this.props.cookiesCx.app.pitch_upload_options.priority,
          },
        ],
      });

      if (results.length > 0) {
        const newChars = results[0];

        if (!newChars.bs) {
          throw Error(`Build result cannot have empty bs`);
        }

        chars.bs = newChars.bs;

        if (!newChars.ms) {
          throw Error(`Build result cannot have empty ms`);
        }

        chars.ms = newChars.ms;

        if (!newChars.traj) {
          throw Error(`Build result cannot have empty traj`);
        }

        chars.traj = newChars.traj;

        /** record the auto-fix request and resulting objects */
        SessionEventsService.postEvent({
          category: 'pitch',
          tags: 'uploader',
          data: {
            action: 'auto-fix',
            error: config.error.msg,
            fix: config.error.fix.description,
            updated: chars,
          },
        });
      }

      /** recalculate errors for active */
      ball.populateErrors(this.props.machineCx.machine.plate_distance);

      if (config.withToast) {
        NotifyHelper.success({
          message_md: `Your pitch has been fixed.`,
        });
      }

      ball.checkSeamsDeltas();
    } catch (e) {
      if (config.withToast) {
        NotifyHelper.error({
          message_md: `There was a problem fixing your pitch. See console for details.`,
        });
      }

      console.error(e);
    }
  }

  private renderErrorTab() {
    const content = (() => {
      if (this.state.autoFixProgress !== -1) {
        return (
          <Heading className="align-center mt-4">
            Please wait while fixes are being applied...
          </Heading>
        );
      } else {
        const index = this.state.invalidBallIndex;
        const data = this.state.invalidBallData;
        const active = this.state.invalidBall;

        /** render the pitch */
        const hasPrev = index > 0;
        const hasNext = index + 1 < data.length;

        const header = !active ? undefined : (
          <Flex gap={RADIX.FLEX.GAP.SM}>
            <Box flexGrow="1">
              <Heading size={RADIX.HEADING.SIZE.SM}>
                Invalid Pitch
                {active.details.name && (
                  <small className="block">{active.details.name}</small>
                )}
              </Heading>
            </Box>
            <Box>
              <CommonSelectInput
                ref={(elem) => (this.selectInvalid = elem as CommonSelectInput)}
                id="pitch-uploader-invalid-index"
                name="invalidBallIndex"
                value={index.toString()}
                options={ArrayHelper.getIntegerOptions(1, data.length, {
                  valueOffset: -1,
                }).map((o) => ({
                  label: `${o.value + 1}/${data.length}`,
                  value: o.value,
                }))}
                onNumericChange={(v) => {
                  this.setState({
                    invalidBallIndex: v,
                    invalidBall: this.state.invalidBallData[v],
                  });
                }}
                skipSort
                as="select"
              />
            </Box>
            <Box>
              <Button
                onClick={() => {
                  const nextIndex = this.state.invalidBallIndex - 1;
                  this.setState(
                    {
                      invalidBallIndex: nextIndex,
                      invalidBall: this.state.invalidBallData[nextIndex],
                    },
                    () => this.selectInvalid?.reset()
                  );
                }}
                disabled={!hasPrev}
              >
                <ChevronLeftIcon />
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  const nextIndex = this.state.invalidBallIndex + 1;
                  this.setState(
                    {
                      invalidBallIndex: nextIndex,
                      invalidBall: this.state.invalidBallData[nextIndex],
                    },
                    () => this.selectInvalid?.reset()
                  );
                }}
                disabled={!hasNext}
              >
                <ChevronRightIcon />
              </Button>
            </Box>
          </Flex>
        );

        const body = !active ? undefined : (
          <>
            {active.errors.map((e, i) => (
              <React.Fragment key={`p-${index}-e-${i}`}>
                <Separator size="4" />

                <Box>
                  <Heading size={RADIX.HEADING.SIZE.SM}>Issue</Heading>
                  <Text>{e.msg}</Text>
                </Box>

                {e.fix && (
                  <Flex gap={RADIX.FLEX.GAP.LG}>
                    <Box flexGrow="1">
                      <Heading size={RADIX.HEADING.SIZE.SM}>
                        Suggested Fix
                      </Heading>

                      <Text>{e.fix.description}</Text>
                    </Box>

                    {e.fix.autoFixFn && (
                      <Box>
                        <Button
                          size={RADIX.BUTTON.SIZE.SM}
                          color={RADIX.COLOR.WARNING}
                          onClick={async () => {
                            await this.handleAutoFixOne({
                              ball: active,
                              error: e,
                              withToast: true,
                            });

                            /** update state lists */
                            if (active.errors.length > 0) {
                              /** newActive remains on invalidBallData because of another error
                               * just update the invalidBall with the new error list
                               */
                              this.setState({
                                invalidBall: active,
                              });
                            } else {
                              /** remove newActive from invalid list */
                              const invalid = [...this.state.invalidBallData];
                              invalid.splice(index, 1);

                              /** send newActive to validBallData */
                              const valid = [...this.state.validBallData];
                              valid.push(active);

                              /** since invalid list is shrinking, index can't exceed total length */
                              const safeNextIndex = Math.min(
                                index,
                                invalid.length - 1
                              );

                              const hasErrors = invalid.length > 0;

                              this.setState(
                                {
                                  validBallData: valid,
                                  validBall: this.state.validBall
                                    ? this.state.validBall
                                    : valid[0],

                                  invalidBallData: invalid,
                                  invalidBallIndex: safeNextIndex,
                                  invalidBall:
                                    invalid.length > safeNextIndex
                                      ? invalid[safeNextIndex]
                                      : undefined,
                                },
                                () => {
                                  if (hasErrors) {
                                    NotifyHelper.success({
                                      message_md: `All errors for "${active.details.name}" resolved, moving to next invalid pitch...`,
                                    });
                                  } else {
                                    NotifyHelper.success({
                                      message_md:
                                        'There are no more invalid pitches, moving to pitch design...',
                                    });
                                  }
                                }
                              );
                            }
                          }}
                        >
                          Auto-Fix
                        </Button>
                      </Box>
                    )}
                  </Flex>
                )}
              </React.Fragment>
            ))}
          </>
        );

        return (
          <>
            {header}
            {body}
          </>
        );
      }
    })();

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        {content}
      </Flex>
    );
  }

  private renderTrainingDialog() {
    if (!this.state.dialogTraining) {
      return;
    }

    if (this.state.selectedPitches.length === 0) {
      return;
    }

    const mode = this.props.authCx.effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      return (
        <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
          <TrainingContext.Consumer>
            {(trainingCx) => (
              <TrainingDialog
                key={this.state.dialogTraining}
                identifier="BPU-TrainingDialog"
                machineCx={this.props.machineCx}
                trainingCx={trainingCx}
                pitches={this.state.selectedPitches}
                threshold={this.props.machineCx.machine.training_threshold}
                onClose={() => this.setState({ dialogTraining: undefined })}
              />
            )}
          </TrainingContext.Consumer>
        </TrainingProvider>
      );
    }

    return (
      <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
        <TrainingContext.Consumer>
          {(trainingCx) => (
            <PresetTrainingDialog
              key={this.state.dialogTraining}
              identifier="BPU-PT-TrainingDialog"
              machineCx={this.props.machineCx}
              trainingCx={trainingCx}
              pitches={this.state.selectedPitches}
              onClose={() => this.setState({ dialogTraining: undefined })}
            />
          )}
        </TrainingContext.Consumer>
      </TrainingProvider>
    );
  }

  private renderDesigner() {
    if (
      this.state.validBallData.length === 0 &&
      this.state.invalidBallData.length === 0
    ) {
      return getAlertNoCsv();
    }

    if (this.state.validBallData.length === 0) {
      return getAlertNoValidBalls();
    }

    if (this.state.validBallData.length > this.state.validBallIndex) {
      const BTN_CLASS = 'text-titlecase';

      const index = this.state.validBallIndex;
      const data = this.state.validBallData;

      /** render the pitch */
      const hasPrev = index > 0;
      const hasNext = index + 1 < data.length;

      const multiple = data.length > 1;
      const active = this.state.validBall;

      const header = active ? (
        <Flex gap={RADIX.FLEX.GAP.SM}>
          <Box flexGrow="1">
            <CommonTextInput
              id="pitch-uploader-name"
              value={active.details.name}
              placeholder="Type in new name"
              onChange={(e) => {
                active.details.name = e ?? '';
                this.setState({
                  validBall: active,
                });
              }}
            />
          </Box>
          <Box width="160px">
            <CommonSelectInput
              ref={(elem) => (this.selectType = elem as CommonSelectInput)}
              id="pitch-uploader-type"
              name="type"
              options={PITCH_TYPE_OPTIONS}
              value={active.details.type}
              onChange={(v) => {
                active.details.type = v as PitchType;
                this.setState({
                  validBall: active,
                });
              }}
              optional
              as="select"
            />
          </Box>
          <Box width="80px">
            <CommonSelectInput
              ref={(elem) => (this.selectPitch = elem as CommonSelectInput)}
              id="pitch-uploader-valid-index"
              name="validBallIndex"
              value={index.toString()}
              options={ArrayHelper.getIntegerOptions(1, data.length, {
                valueOffset: -1,
              }).map((o) => ({
                label: `${parseInt(o.value) + 1}/${data.length}`,
                value: o.value,
              }))}
              onNumericChange={(v) => this.setDesignIndex(v)}
              skipSort
              as="select"
            />
          </Box>
          <Box>
            <Button
              onClick={() => this.incrementDesignIndex(-1)}
              disabled={!hasPrev}
            >
              <ChevronLeftIcon />
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => this.incrementDesignIndex(1)}
              disabled={!hasNext}
            >
              <ChevronRightIcon />
            </Button>
          </Box>
        </Flex>
      ) : undefined;

      const body =
        active && active.details.pitchChars ? (
          <>
            <BallFlightDesigner
              cookiesCx={this.props.cookiesCx}
              authCx={this.props.authCx}
              machineCx={this.props.machineCx}
              chars={active.details.pitchChars}
              onUpdatePlate={(plate: IPlateLoc) =>
                this.setActive({ plate: plate })
              }
              onUpdateRelease={(pos: { px: number; pz: number }) =>
                this.setActive({ release: pos })
              }
            />

            <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
              <Box>
                <Button
                  className={BTN_CLASS}
                  color={RADIX.COLOR.INFO}
                  onClick={() =>
                    this.handleSave({
                      machineID: this.props.machineCx.machine.machineID,
                      mode: 'one',
                    })
                  }
                >
                  {t('pu.save-current')}
                </Button>
              </Box>

              {multiple && (
                <Box>
                  <Button
                    className={BTN_CLASS}
                    color={RADIX.COLOR.INFO}
                    onClick={() =>
                      this.handleSave({
                        machineID: this.props.machineCx.machine.machineID,
                        mode: 'all',
                      })
                    }
                  >
                    {t('pu.save-all')}
                  </Button>
                </Box>
              )}

              <MatchingShotsContext.Consumer>
                {(matchingCx) => (
                  <>
                    <Box>
                      <Button
                        className={BTN_CLASS}
                        color={RADIX.COLOR.TRAIN_PITCH}
                        disabled={!matchingCx.readyToTrain()}
                        onClick={() => this.handleTrainCurrent()}
                      >
                        {t('pu.train-current')}
                      </Button>
                    </Box>

                    {multiple && (
                      <Box>
                        <Button
                          className={BTN_CLASS}
                          color={RADIX.COLOR.TRAIN_PITCH}
                          disabled={!matchingCx.readyToTrain()}
                          onClick={() => this.handleTrainAll()}
                        >
                          {t('pu.train-all')}
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </MatchingShotsContext.Consumer>
            </Flex>
          </>
        ) : undefined;

      return (
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Heading size={RADIX.HEADING.SIZE.SM}>Review Pitch</Heading>
          {header}
          {body}
        </Flex>
      );
    }

    return getAlertInvalidIndex();
  }

  private countFixableErrors(): number {
    return this.state.invalidBallData.flatMap((b) =>
      b.errors.filter((e) => !!e.fix?.autoFixFn)
    ).length;
  }

  private async handleAutoFixAll() {
    /** remember this value for progress bar */
    const totalFixable = this.countFixableErrors();

    if (totalFixable === 0) {
      NotifyHelper.info({
        message_md: t('pu.cannot-auto-fix-msg'),
      });
      return;
    }

    this.setState({ autoFixProgress: 0 });

    NotifyHelper.info({
      message_md: t('pu.please-wait-auto-fix-msg'),
    });

    for (const ball of this.state.invalidBallData) {
      // since errors array will change as they are fixed, this prevents weirdness
      const currentErrors = ball.errors.filter((m) => !!m.fix?.autoFixFn);

      for (const error of currentErrors) {
        await this.handleAutoFixOne({
          ball: ball,
          error: error,
          withToast: false,
        });

        /** increase progress */
        const remainingFixable = this.countFixableErrors();

        this.setState({
          autoFixProgress:
            ((totalFixable - remainingFixable) / totalFixable) * 100,
        });
      }
    }

    /** upon completion, hide progress bar after a delay */
    setTimeout(() => {
      this.setState({
        autoFixProgress: -1,
      });
    }, 3_000);

    /** everything should be fixed by now, reconcile lists */
    const valid = this.state.validBallData;

    /** move completely fixed invalid balls over */
    valid.push(
      ...this.state.invalidBallData.filter((b) => b.errors.length === 0)
    );

    const newInvalid = this.state.invalidBallData.filter(
      (b) => b.errors.length > 0
    );
    const safeNextIndex = Math.min(
      this.state.invalidBallIndex,
      newInvalid.length - 1
    );

    this.setState(
      {
        validBallData: valid,
        validBall: this.state.validBall ? this.state.validBall : valid[0],

        invalidBallData: newInvalid,
        invalidBallIndex: safeNextIndex,
        invalidBall:
          newInvalid.length > safeNextIndex
            ? newInvalid[safeNextIndex]
            : undefined,
      },
      () => {
        NotifyHelper.success({
          message_md: t('pu.x-errors-fixed-msg', { x: totalFixable }),
        });

        if (newInvalid.length > 0) {
          NotifyHelper.warning({
            message_md: t('pu.x-error-pitches-remaining-msg', {
              x: newInvalid.length,
            }),
          });
        }
      }
    );
  }

  private renderUploadControls() {
    const avgPitchTooltip = (() => {
      switch (this.props.cookiesCx.app.pitch_upload_options.average_pitch) {
        case 'append': {
          return ['pu.append-msg-1', 'pu.append-msg-2', 'pu.append-msg-3']
            .map((l) => `- ${t(l)}`)
            .join('\n');
        }

        case 'replace': {
          return ['pu.replace-msg-1', 'pu.replace-msg-2', 'pu.replace-msg-3']
            .map((l) => `- ${t(l)}`)
            .join('\n');
        }

        default: {
          return undefined;
        }
      }
    })();

    return (
      <Grid columns="3" gap={RADIX.FLEX.GAP.MD}>
        <Box gridColumn="span 3">
          <Heading size={RADIX.HEADING.SIZE.SM}>
            {t('pu.input-priority')}
          </Heading>
        </Box>

        <Box gridColumn="span 2">
          <p>{t('pu.input-priority-msg')}</p>

          {this.props.cookiesCx.app.pitch_upload_options.priority ===
            BuildPriority.Breaks && (
            <ReactMarkdown children={t('pu.break-priority-msg')} />
          )}
        </Box>
        <Box>
          <CommonRadio
            id="pitch-uploader-build-priority"
            name="priority"
            options={[
              { value: BuildPriority.Spins, label: 'Spin' },
              { value: BuildPriority.Breaks, label: 'Break' },
            ]}
            value={this.props.cookiesCx.app.pitch_upload_options.priority}
            onChange={(e) => {
              const current = this.props.cookiesCx.app.pitch_upload_options;
              this.props.cookiesCx.setCookie(CookieKey.app, {
                pitch_upload_options: {
                  ...current,
                  priority: e.target.value,
                },
              });
            }}
          />
        </Box>

        {env.enable.pitch_upload_avg_release && (
          <>
            <Box gridColumn="span 3">
              <Separator size="4" />
            </Box>

            <Box gridColumn="span 3">
              <Heading size={RADIX.HEADING.SIZE.SM}>
                {t('pu.average-release-slot')}
              </Heading>
            </Box>

            <Box gridColumn="span 2">
              <Text>{t('pu.average-release-slot-msg')}</Text>
            </Box>
            <Box>
              <CommonSwitchInput
                id="pitch-uploader-avg-release"
                checked={
                  this.props.cookiesCx.app.pitch_upload_options.average_release
                }
                onCheckedChange={(v) => {
                  const current = this.props.cookiesCx.app.pitch_upload_options;
                  this.props.cookiesCx.setCookie(CookieKey.app, {
                    pitch_upload_options: {
                      ...current,
                      average_release: v,
                    },
                  });
                }}
              />
            </Box>
          </>
        )}

        {env.enable.pitch_upload_avg_chars && (
          <>
            <Box gridColumn="span 3">
              <Separator size="4" />
            </Box>

            <Box gridColumn="span 3">
              <Heading size={RADIX.HEADING.SIZE.SM}>
                {t('pu.average-pitch-characteristics')}
              </Heading>
            </Box>

            <Box gridColumn="span 2">
              <ReactMarkdown
                children={t('pu.average-pitch-characteristics-msg')}
              />
            </Box>
            <Box>
              <CommonRadio
                id="pitch-uploader-avg-pitch"
                name="average_pitch"
                title={avgPitchTooltip}
                options={[
                  { label: t('pd.append'), value: 'append' },
                  { label: t('pd.replace'), value: 'replace' },
                ]}
                value={
                  this.props.cookiesCx.app.pitch_upload_options.average_pitch
                }
                onChange={(e) => {
                  const current = this.props.cookiesCx.app.pitch_upload_options;
                  this.props.cookiesCx.setCookie(CookieKey.app, {
                    pitch_upload_options: {
                      ...current,
                      average_pitch: e.target.value,
                    },
                  });
                }}
                placeholder="Skip"
                optional
              />
            </Box>
          </>
        )}

        <Box gridColumn="span 3">
          <Separator size="4" />
        </Box>

        <Box gridColumn="span 3">
          <Heading size={RADIX.HEADING.SIZE.SM}>{t('pu.select-file')}</Heading>
        </Box>

        <Box gridColumn="span 2">
          <CommonLink
            url={t('common.intercom-url-x', {
              x: HELP_URLS.PITCH_UPLOADER,
            })}
          >
            {t('pu.download-the-file-template')}
          </CommonLink>
        </Box>
        <Box>
          <CommonFileUploader
            ref={(ref) => (this.fileUpload = ref as CommonFileUploader)}
            id="pitch-uploader-upload-progress"
            progress={0}
            acceptedTypes={FILE_TYPES}
            notifyMode="each"
            onChange={(files) => this.onChange(files)}
          />
        </Box>
      </Grid>
    );
  }

  private renderBody() {
    return (
      <CommonSingleAccordion
        type="single"
        value={this.state.step}
        onValueChange={(v) => this.setState({ step: v as PitchUploadStep })}
        items={[
          {
            label: 'common.file-upload',
            value: PitchUploadStep.upload,
            children: this.renderUploadControls(),
          },
          {
            label: 'common.preview-results',
            value: PitchUploadStep.preview,
            children: this.renderPreview(),
          },
          {
            label: 'main.pitch-design',
            value: PitchUploadStep.design,
            children: this.renderDesigner(),
          },
        ]}
      />
    );
  }

  private renderPreview() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
        {(() => {
          if (
            this.state.validBallData.length === 0 &&
            this.state.invalidBallData.length === 0
          ) {
            return getAlertNoCsv();
          }

          const warnings: QualityError[] = ArrayHelper.unique(
            [
              ...this.state.validBallData,
              ...this.state.invalidBallData,
            ].flatMap((ball) => ball.qualityWarnings)
          );

          return (
            <>
              <Box>
                {warnings.length > 0 && (
                  <CommonCallout
                    content={
                      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                        <Box>
                          <Text>
                            <Strong>
                              {t(
                                'pd.warning-low-quality-data-detected-in-uploaded-file'
                              )}
                            </Strong>
                          </Text>
                        </Box>

                        {warnings.includes(QualityError.BreakDetails) && (
                          <Box>
                            <ReactMarkdown children={WARNING_BREAKS_MD} />
                          </Box>
                        )}

                        {warnings.includes(QualityError.SeamDetails) && (
                          <Box>
                            <ReactMarkdown children={WARNING_SEAMS_MD} />
                          </Box>
                        )}

                        {warnings.includes(QualityError.Statcast) && (
                          <Box>
                            <ReactMarkdown children={WARNING_STATCAST_MD} />
                          </Box>
                        )}

                        <Box>
                          <CommonLink
                            url={t('common.intercom-url-x', {
                              x: HELP_URLS.PITCH_UPLOADER,
                            })}
                          >
                            {t('common.more-info')}
                          </CommonLink>
                        </Box>
                      </Flex>
                    }
                  />
                )}

                {this.renderPreviewTabs()}
              </Box>

              {this.state.autoFixProgress !== -1 && (
                <Box>
                  <CommonProgress
                    value={this.state.autoFixProgress}
                    label={
                      this.state.autoFixProgress >= 100
                        ? t('common.complete').toString()
                        : `${this.state.autoFixProgress.toFixed(0)}%`
                    }
                  />
                </Box>
              )}

              <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
                {this.state.activeTab === TabKey.Errors && (
                  <Box>
                    <Button
                      color={RADIX.COLOR.WARNING}
                      onClick={this.handleAutoFixAll}
                    >
                      {t('pd.auto-fix-all').toUpperCase()}
                    </Button>
                  </Box>
                )}

                <Box>
                  <Button
                    onClick={() =>
                      this.setState({ step: PitchUploadStep.design })
                    }
                  >
                    {t('common.continue').toUpperCase()}
                  </Button>
                </Box>
              </Flex>
            </>
          );
        })()}
      </Flex>
    );
  }

  private renderPreviewTabs() {
    return (
      <CommonTabs
        value={this.state.activeTab}
        onValueChange={(value) => {
          this.setState({ activeTab: value as TabKey });
        }}
        tabs={[
          {
            value: TabKey.Errors,
            label: 'common.errors',
            invisible: this.state.invalidBallData.length === 0,
            content: this.renderErrorTab(),
          },
          {
            value: TabKey.BallData,
            label: 'Ball Data',
            content: (
              <PitchUploaderBallTable
                cookiesCx={this.props.cookiesCx}
                data={this.state.validBallData.map((m) => m.details)}
                simple
              />
            ),
          },
          {
            value: TabKey.CsvData,
            label: 'CSV Data',
            content: (
              <PitchUploaderCsvTable
                data={[
                  ...this.state.validBallData.map((b) => b.rawData),
                  ...this.state.invalidBallData.map((b) => b.rawData),
                ]}
                simple
              />
            ),
          },
        ]}
      />
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="PitchUploader">
        <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
          <SectionHeader header={t('main.bulk-pitch-upload')} />

          <CommonContentWithSidebar
            left={
              <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
                {this.renderBody()}
                <HelpCallout
                  url={t('common.intercom-url-x', {
                    x: HELP_URLS.PITCH_UPLOADER,
                  })}
                />
              </Flex>
            }
            right={
              <PitchUploaderSidebar
                ref={(elem) => (this.sidebar = elem as PitchUploaderSidebar)}
                authCx={this.props.authCx}
                videosCx={this.props.videosCx}
                step={this.state.step}
                format={this.state.detectedFormat}
                fileName={this.state.fileName}
                valid={this.state.validBallData.length}
                invalid={this.state.invalidBallData.length}
                ball={this.state.validBall}
                handleVideoChange={(video_id, video_playback) => {
                  const ball = this.state.validBall;
                  if (!ball) {
                    return;
                  }

                  /** update active ball */
                  ball.details.video_id = video_id;
                  ball.details.video_playback = video_playback;

                  this.setState({
                    validBall: ball,
                  });
                }}
              />
            }
          />
        </Flex>

        {this.renderTrainingDialog()}

        {this.state.dialogSave && (
          <AuthContext.Consumer>
            {(authCx) => (
              <PitchListsContext.Consumer>
                {(listsCx) => (
                  <CopyPitchesDialog
                    key={this.state.dialogSave}
                    identifier="BPU-SavePitchesDialog"
                    authCx={authCx}
                    listsCx={listsCx}
                    description={t('pu.create-pitches-form-msg').toString()}
                    pitches={this.state.selectedPitches}
                    onCreated={() => {
                      /** sectionsCx won't block navigation anymore */
                      this.props.sectionsCx.clearDirtyForm(
                        DirtyForm.PitchUploader
                      );

                      SessionEventsService.postEvent({
                        category: 'pitch',
                        tags: 'uploader',
                        data: {
                          action: 'save',
                          count: this.state.selectedPitches.length,
                        },
                      });

                      this.setState({ dialogSave: undefined });
                    }}
                    onClose={() => this.setState({ dialogSave: undefined })}
                  />
                )}
              </PitchListsContext.Consumer>
            )}
          </AuthContext.Consumer>
        )}
      </ErrorBoundary>
    );
  }
}
