export enum CustomIconPath {
  ArrowAxis = 'ArrowAxis',
  Beta = 'Beta',
  BetaBeaker = 'BetaBeaker',
  CheckCircledFilled = 'CheckCircledFilled',
  RepeatOne = 'RepeatOne',
  SuperAdmin = 'SuperAdmin',
  SwitchOff = 'SwitchOff',
  SwitchOn = 'SwitchOn',
  TeamAdmin = 'TeamAdmin',
  Void = 'Void',
}
