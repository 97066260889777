import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import LogRocket from 'logrocket';
import { createContext, FC, useEffect, useState } from 'react';

const CONTEXT_NAME = 'LogRocketContext';

const APP_ID = 's9iwvp/trajekt';

interface IProps {
  authCx: IAuthContext;
}

export interface ILogRocketContext {}

const DEFAULT: ILogRocketContext = {};

export const LogRocketContext = createContext(DEFAULT);

/** for tracking misc details across entire app */
export const LogRocketProvider: FC<IProps> = (props) => {
  const [init, setInit] = useState(false);

  const state: ILogRocketContext = {};

  useEffect(() => {
    if (!props.authCx.current.auth) {
      return;
    }

    if (init) {
      LogRocket.startNewSession();
    } else {
      LogRocket.init(APP_ID, {
        release: `${env.version} @ ${env.getBuildStamp()}`,
      });

      setInit(true);
    }

    LogRocket.identify(props.authCx.current.email, {
      env: env.identifier,
      session: props.authCx.current.session,
    });
  }, [
    props.authCx.current.auth,
    props.authCx.current.session,
    props.authCx.current.email,
  ]);

  return <LogRocketContext.Provider value={state} />;
};
