import {
  DownloadIcon,
  PlusIcon,
  UpdateIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import { Badge, Grid } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSimpleFileUploader } from 'components/common/file-uploader';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
} from 'contexts/layout/checked.context';
import { AdminRoute } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IDisplayCol, ITablePageable } from 'interfaces/i-tables';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  DEFAULT_ERROR_TYPE,
  IErrorType,
} from 'lib_ts/interfaces/common/i-error-type';
import React from 'react';
import { AdminErrorTypesService } from 'services/admin/error-types.service';
import { ErrorTypeEditorDialog } from './editor';

const IDENTIFIER = TableIdentifier.AdminErrorTypeList;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

interface IProps {
  cookiesCx: ICookiesContext;
}

interface IState extends Partial<ITablePageable> {
  types: IErrorType[];
  selected?: Partial<IErrorType>;
  dialog?: number;
}

export class ErrorTypesTable extends React.Component<IProps, IState> {
  private init = false;

  private fileInput?: CommonSimpleFileUploader;

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'common.edit',
          onClick: (m: IErrorType) => {
            this.setState({
              selected: m,
              dialog: Date.now(),
            });
          },
        },
        {
          label: 'common.preview',
          color: RADIX.COLOR.SUCCESS,
          onClick: (m: IErrorType) => {
            NotifyHelper.userError(m);
          },
        },
        {
          label: 'Duplicate',
          color: RADIX.COLOR.WARNING,
          onClick: (m: IErrorType) => {
            const cloned: Partial<IErrorType> = {
              ...m,
              _id: undefined,
              errorID: `${m.errorID} (copy)`,
            };

            this.setState({
              selected: cloned,
              dialog: Date.now(),
            });
          },
        },
      ],
    },
    {
      label: 'ErrorID',
      key: 'errorID',
    },
    {
      label: 'Type',
      key: 'type',
    },
    {
      label: 'Category',
      key: 'category',
    },
    {
      label: 'Sender',
      key: 'sender',
    },
    {
      label: 'Level',
      key: 'level',
      align: 'center',
      formatFn: (et: IErrorType) => {
        const color = NotifyHelper.getColorFromLevel(et.level);
        return <Badge color={color}>{et.level.toUpperCase()}</Badge>;
      },
    },
    {
      label: 'Internal',
      key: 'internal',
      formatFn: (et: IErrorType) => {
        return et.internal ? 'Yes' : 'No';
      },
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      types: [],
    };

    this.refresh = this.refresh.bind(this);

    this.renderEditorDialog = this.renderEditorDialog.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;
    this.refresh();
  }

  private refresh() {
    AdminErrorTypesService.getInstance()
      .getAll()
      .then((result) => this.setState({ types: result }));
  }

  private renderEditorDialog() {
    if (!this.state.dialog) {
      return;
    }

    return (
      <ErrorTypeEditorDialog
        key={this.state.dialog}
        input={this.state.selected}
        onClose={(refresh) => {
          if (refresh) {
            this.refresh();
          }

          // close the dialog regardless
          this.setState({ dialog: undefined });
        }}
      />
    );
  }

  render() {
    const data: IErrorType[] = this.state.types;

    const pagination: ITablePageable = {
      total: data.length,
      enablePagination: true,
      pageSize: this.props.cookiesCx.getPageSize(IDENTIFIER) ?? PAGE_SIZES[0],
      pageSizeOptions: PAGE_SIZES,
      pageSizeCallback: (value) =>
        this.props.cookiesCx.setPageSize(IDENTIFIER, value),
    };

    return (
      <ErrorBoundary componentName="ErrorTypesTable">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={<AdminTabNav active={AdminRoute.ErrorTypes} />}
          table={
            <GlobalContext.Consumer>
              {(globalCx) => (
                <CheckedProvider data={data}>
                  <CheckedContext.Consumer>
                    {(checkedCx) => (
                      <CommonTable
                        id="AdminErrorTypes"
                        checkedCx={checkedCx}
                        suspendKeyListener={globalCx.dialogs.length > 0}
                        toolbarContent={this.renderToolbar()}
                        displayColumns={this.BASE_COLUMNS}
                        displayData={data}
                        afterSelectRow={async (config) =>
                          this.setState({ selected: config.model })
                        }
                        {...pagination}
                        defaultSortKey="errorID"
                        defaultSortDir={-1}
                        enableSort
                        vFlex
                      />
                    )}
                  </CheckedContext.Consumer>
                </CheckedProvider>
              )}
            </GlobalContext.Consumer>
          }
        />

        {this.renderEditorDialog()}

        <CommonSimpleFileUploader
          ref={(elem) => (this.fileInput = elem as CommonSimpleFileUploader)}
          id="error-types-uploader"
          acceptedTypes={['application/json']}
          onChange={async (files) => {
            const success =
              await AdminErrorTypesService.getInstance().importJSON(files);

            if (success) {
              this.refresh();
            }
          }}
          hidden
        />
      </ErrorBoundary>
    );
  }

  private renderToolbar() {
    return (
      <Grid columns="4" gap={RADIX.FLEX.GAP.SM}>
        <CommonTableButton
          label="common.refresh"
          color={RADIX.COLOR.NEUTRAL}
          icon={<UpdateIcon />}
          onClick={this.refresh}
        />

        <CommonTableButton
          label="common.create"
          color={RADIX.COLOR.SUCCESS}
          icon={<PlusIcon />}
          onClick={() => {
            this.setState({
              selected: undefined,
              dialog: Date.now(),
            });
          }}
        />

        <CommonTableButton
          label="common.export"
          icon={<DownloadIcon />}
          onClick={() => {
            const types: Partial<IErrorType>[] = this.state.types.map((m) => {
              const o: Partial<IErrorType> = {
                ...DEFAULT_ERROR_TYPE,
                ...m,
              };

              if (typeof o.resolution === 'string') {
                o.resolution = (o.resolution as string).split(',');
              }

              if (typeof o.patch === 'string') {
                o.patch = (o.patch as string).split(',');
              }

              delete o._id;
              delete o._created;
              delete o._createdby;
              delete o._changed;
              delete o._changedby;

              return o;
            });

            MiscHelper.saveAs(
              new Blob([JSON.stringify(types, null, 2)]),
              `error-types.json`
            );
          }}
        />

        <CommonTableButton
          label="common.import"
          icon={<UploadIcon />}
          onClick={() => {
            if (this.fileInput) {
              this.fileInput.handleClick();
            } else {
              console.warn('no file input element');
            }
          }}
        />
      </Grid>
    );
  }
}
