import { Flex, Heading, Strong, Table, Text } from '@radix-ui/themes';
import { ModelPerformanceHelper } from 'classes/helpers/model-performance.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { PassFail } from 'components/sections/machine-calibration/steps/review-metric';
import { t } from 'i18next';
import { MachinePerformanceHelper } from 'lib_ts/classes/machine-performance.helper';
import { PassResult } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import React from 'react';

const COMPONENT_NAME = 'MetricScorecard';

const COL_WIDTH = '150px';

const TOOLTIP_TEXT =
  'This table is only visible to super admins (e.g. technicians).';

interface IRowConfig {
  label: string;
  units: string;
  checkResult: PassResult;
  mean: number;
  max: number;
  precision: number;
}

const Pass = (text: string) => <Text color={RADIX.COLOR.SUCCESS}>{text}</Text>;

const Fail = (text: string) => (
  <Text color={RADIX.COLOR.DANGER}>
    <Strong>{text}</Strong>
  </Text>
);

interface IProps {
  metric: IRealMachineMetric;
}

interface IState {}

export class MetricScorecard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.renderMachinePerformance = this.renderMachinePerformance.bind(this);
    this.renderModelPerformance = this.renderModelPerformance.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  private renderModelPerformance() {
    const performance = this.props.metric.model_performance;

    if (!performance) {
      return;
    }

    const overallPass = ModelPerformanceHelper.checkOverall(performance);

    const rows: IRowConfig[] = [
      {
        label: 'common.speed',
        units: 'mph',
        checkResult: ModelPerformanceHelper.checkSpeedY(performance),
        mean: performance.mean_absolute_err.vy,
        max: performance.max_absolute_err.vy,
        precision: 1,
      },
      {
        label: 'common.spin-x',
        units: 'rpm',
        checkResult: ModelPerformanceHelper.checkSpinDimension(
          performance.mean_absolute_err.wx,
          performance.max_absolute_err.wx
        ),
        mean: performance.mean_absolute_err.wx,
        max: performance.max_absolute_err.wx,
        precision: 0,
      },
      {
        label: 'common.spin-y',
        units: 'rpm',
        checkResult: ModelPerformanceHelper.checkSpinDimension(
          performance.mean_absolute_err.wy,
          performance.max_absolute_err.wy
        ),
        mean: performance.mean_absolute_err.wy,
        max: performance.max_absolute_err.wy,
        precision: 0,
      },
      {
        label: 'common.spin-z',
        units: 'rpm',
        checkResult: ModelPerformanceHelper.checkSpinDimension(
          performance.mean_absolute_err.wz,
          performance.max_absolute_err.wz
        ),
        mean: performance.mean_absolute_err.wz,
        max: performance.max_absolute_err.wz,
        precision: 0,
      },
    ];

    return (
      <>
        <Heading size={RADIX.HEADING.SIZE.SM}>
          {t('common.existing-model-performance')}: {PassFail(overallPass)}
        </Heading>

        <CommonTooltip
          trigger={
            <Table.Root>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell>
                    {t('common.field')}
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell width={COL_WIDTH}>
                    {t('common.success')}
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell width={COL_WIDTH} align="right">
                    {t('common.mean')}
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell width={COL_WIDTH} align="right">
                    {t('common.max')}
                  </Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rows.map((r, i) => this.renderRow(r, i))}
              </Table.Body>
            </Table.Root>
          }
          text_md={TOOLTIP_TEXT}
        />

        {!overallPass && (
          <CommonCallout
            text_md={[
              `Model performance is not within specifications.`,
              `Please [calibrate Rapsodo](${t('common.intercom-url-x', {
                x: HELP_URLS.RAPSODO_HOW_TO,
              }).toString()}).`,
              `Next, complete the **Ball Type Calibration** list (3 shots per pitch).`,
              `This will take up to 2 hours.`,
            ].join(' ')}
          />
        )}
      </>
    );
  }

  private renderRow(config: IRowConfig, index: number) {
    return (
      <Table.Row key={index}>
        <Table.Cell>
          {t(config.label)}{' '}
          <Text size={RADIX.TEXT.SIZE.SM} color={RADIX.COLOR.SECONDARY}>
            {config.units}
          </Text>
        </Table.Cell>
        <Table.Cell>
          {config.checkResult === PassResult.Pass ? Pass('PASS') : Fail('FAIL')}
        </Table.Cell>
        <Table.Cell align="right">
          {([PassResult.Mean, PassResult.Both].includes(config.checkResult)
            ? Fail
            : Pass)(config.mean.toFixed(config.precision))}
        </Table.Cell>
        <Table.Cell align="right">
          {([PassResult.Max, PassResult.Both].includes(config.checkResult)
            ? Fail
            : Pass)(config.max.toFixed(config.precision))}
        </Table.Cell>
      </Table.Row>
    );
  }

  private renderMachinePerformance() {
    const performance = this.props.metric.machine_performance;

    if (!performance) {
      return;
    }

    const overallPass = MachinePerformanceHelper.checkOverall(performance);

    const rows: IRowConfig[] = [
      {
        label: 'common.speed',
        units: 'mph',
        checkResult: MachinePerformanceHelper.checkSpeedY(performance),
        mean: performance.mean_stdev.vy,
        max: performance.max_stdev.vy,
        precision: 1,
      },
      {
        label: 'common.spin-x',
        units: 'rpm',
        checkResult: MachinePerformanceHelper.checkSpinDimension(
          performance.mean_stdev.wx,
          performance.max_stdev.wx
        ),
        mean: performance.mean_stdev.wx,
        max: performance.max_stdev.wx,
        precision: 0,
      },
      {
        label: 'common.spin-y',
        units: 'rpm',
        checkResult: MachinePerformanceHelper.checkSpinDimension(
          performance.mean_stdev.wy,
          performance.max_stdev.wy
        ),
        mean: performance.mean_stdev.wy,
        max: performance.max_stdev.wy,
        precision: 0,
      },
      {
        label: 'common.spin-z',
        units: 'rpm',
        checkResult: MachinePerformanceHelper.checkSpinDimension(
          performance.mean_stdev.wz,
          performance.max_stdev.wz
        ),
        mean: performance.mean_stdev.wz,
        max: performance.max_stdev.wz,
        precision: 0,
      },
      {
        label: 'common.plate-x',
        units: 'in',
        checkResult: MachinePerformanceHelper.checkPlateDimension(
          performance.mean_stdev.plx,
          performance.max_stdev.plx
        ),
        mean: performance.mean_stdev.plx,
        max: performance.max_stdev.plx,
        precision: 1,
      },
      {
        label: 'common.plate-z',
        units: 'in',
        checkResult: MachinePerformanceHelper.checkPlateDimension(
          performance.mean_stdev.plz,
          performance.max_stdev.plz
        ),
        mean: performance.mean_stdev.plz,
        max: performance.max_stdev.plz,
        precision: 1,
      },
    ];

    return (
      <>
        <Heading size={RADIX.HEADING.SIZE.SM}>
          {t('common.machine-repeatability')}: {PassFail(overallPass)}
        </Heading>

        <CommonTooltip
          trigger={
            <Table.Root>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell>
                    {t('common.field')}
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell width={COL_WIDTH}>
                    {t('common.success')}
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell width={COL_WIDTH} align="right">
                    {t('common.mean')}
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell width={COL_WIDTH} align="right">
                    {t('common.max')}
                  </Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rows.map((r, i) => this.renderRow(r, i))}
              </Table.Body>
            </Table.Root>
          }
          text_md={TOOLTIP_TEXT}
        />

        {!overallPass && (
          <CommonCallout text_md="Machine performance did not meet specifications." />
        )}
      </>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          {this.renderMachinePerformance()}
          {this.renderModelPerformance()}
        </Flex>
      </ErrorBoundary>
    );
  }
}
