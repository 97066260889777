import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RAD_FULL_ROTATION } from 'lib_ts/classes/ball.helper';
import { METERS_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { IBreakConfig } from 'lib_ts/interfaces/i-break-config';
import { IRapsodoBreak } from 'lib_ts/interfaces/training/i-rapsodo-shot';

export const SHOT_OPACITY_DELTA = 0.1;

export const DOT_SIZE = 5;
export const DOT_SIZE_LG = 5;

export const DOT_RGB_ACTUAL = '255, 193, 6';

export class BreakCanvas {
  static makeDefault() {
    return new BreakCanvas({
      aspectRatio: 1,
      height_px: 400,
      min_height_in: -35,
      max_height_in: 35,
    });
  }

  readonly CONFIG: IBreakConfig;

  private readonly CONSTANTS_PX: {
    origin_x: number;
    origin_y: number;
  };

  constructor(config: {
    aspectRatio: number;
    height_px: number;
    min_height_in: number;
    max_height_in: number;
    step?: number;
  }) {
    const total_y_in = config.max_height_in - config.min_height_in;
    const abs_x_in = (total_y_in * config.aspectRatio) / 2;

    this.CONFIG = {
      canvas: {
        width_px: config.height_px * config.aspectRatio,
        height_px: config.height_px,
      },
      x: {
        min_in: -abs_x_in,
        max_in: abs_x_in,
        step: config.step ?? 0.00001,
      },
      y: {
        min_in: config.min_height_in,
        max_in: config.max_height_in,
        step: config.step ?? 0.00001,
      },
    };

    /** must be after plate is defined */
    this.CONSTANTS_PX = {
      origin_x: this.inchToPx(0),
      origin_y: this.inchToPxY(0),
    };
  }

  private inchToPxY(y_ft: number) {
    /** convert y_ft into a px value that works for the canvas by translating so that y.min_ft is the same as 0 for the canvas */
    const total_ft = Math.abs(this.CONFIG.y.max_in - this.CONFIG.y.min_in);
    const y_perc = (y_ft - this.CONFIG.y.min_in) / total_ft;

    /** canvas y is inverted relative to slider */
    return this.CONFIG.canvas.height_px * (1 - y_perc);
  }

  private inchToPx(x_ft: number) {
    /** convert x_ft into a px value that works for the canvas by translating so that x.min_ft is the same as 0 for the canvas */
    const total_ft = Math.abs(this.CONFIG.x.max_in - this.CONFIG.x.min_in);
    const x_perc = (x_ft - this.CONFIG.x.min_in) / total_ft;
    return this.CONFIG.canvas.width_px * x_perc;
  }

  drawRulers(ctx: CanvasRenderingContext2D, color = '#FFFFFF') {
    ctx.strokeStyle = color;
    ctx.fillStyle = color;

    /** draw x ruler */
    const x_ruler_pos_y = Math.min(
      this.CONSTANTS_PX.origin_y,
      this.inchToPxY(this.CONFIG.y.min_in)
    );
    ctx.moveTo(0, x_ruler_pos_y);
    ctx.lineTo(this.CONFIG.canvas.width_px, x_ruler_pos_y);
    ctx.stroke();

    /** draw x ruler tick marks per foot */
    ArrayHelper.getIntegerOptions(
      Math.ceil(this.CONFIG.x.min_in),
      Math.floor(this.CONFIG.x.max_in)
    ).forEach((o) => {
      const intValue = parseInt(o.value);

      if (intValue % 10 !== 0) {
        return;
      }

      const x = this.inchToPx(intValue);
      ctx.moveTo(x, x_ruler_pos_y);
      ctx.lineTo(x, x_ruler_pos_y - 5);
      ctx.stroke();

      if (intValue !== 0) {
        ctx.fillText(`${o.value}"`, x - 3, x_ruler_pos_y - 10);
      }
    });

    /** draw y ruler down the middle */
    const y_ruler_pos_x = Math.min(
      this.CONSTANTS_PX.origin_x,
      this.inchToPxY(this.CONFIG.x.min_in)
    );

    ctx.moveTo(y_ruler_pos_x, this.inchToPxY(this.CONFIG.y.min_in));
    ctx.lineTo(y_ruler_pos_x, this.inchToPxY(this.CONFIG.y.max_in));
    ctx.stroke();

    const ticks = ArrayHelper.getIntegerOptions(
      Math.ceil(this.CONFIG.y.min_in),
      Math.floor(this.CONFIG.y.max_in)
    );
    ticks.forEach((o) => {
      const intValue = parseInt(o.value);

      if (intValue % 10 !== 0) {
        return;
      }

      const y = this.inchToPxY(intValue);
      ctx.moveTo(y_ruler_pos_x, y);
      ctx.lineTo(y_ruler_pos_x + 5, y);
      ctx.stroke();

      if (intValue !== 0) {
        ctx.fillText(`${intValue}"`, y_ruler_pos_x + 10, y + 3);
      }
    });
  }

  drawBreak(
    ctx: CanvasRenderingContext2D,
    loc: IRapsodoBreak,
    config: { color: string; size: number; label?: string }
  ) {
    const xInches = loc.PITCH_HBTrajectory * METERS_TO_INCHES;
    if (xInches < this.CONFIG.x.min_in || xInches > this.CONFIG.x.max_in) {
      return;
    }

    const zInches = loc.PITCH_VBTrajectory * METERS_TO_INCHES;
    if (zInches < this.CONFIG.y.min_in || zInches > this.CONFIG.y.max_in) {
      return;
    }

    ctx.strokeStyle = 'rgba(0,0,0,0)';
    ctx.fillStyle = config.color;
    ctx.beginPath();
    ctx.ellipse(
      this.inchToPx(xInches),
      this.inchToPxY(zInches),
      config.size,
      config.size,
      0,
      0,
      RAD_FULL_ROTATION
    );
    ctx.fill();
    ctx.stroke();

    if (config.label) {
      ctx.fillText(
        config.label,
        this.inchToPx(xInches) + 15,
        this.inchToPxY(zInches) + config.size / 2
      );
    }
  }
}
