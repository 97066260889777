import { LinkBreak1Icon, PauseIcon, PlayIcon } from '@radix-ui/react-icons';
import { Box, Flex, Heading, Text } from '@radix-ui/themes';
import { MachineContextHelper } from 'classes/helpers/machine-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { IMachineContext } from 'contexts/machine.context';
import { addSeconds, isFuture, isPast } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_TIMEZONE } from 'enums/env';
import { IBaseDialog, IFullDialog } from 'interfaces/i-dialogs';
import {
  CameraSource,
  DefaultVideoID,
  WsMsgType,
} from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IBallStatusMsg,
  ICameraStreamRequestMsg,
  ICameraStreamResponseMsg,
  IReadyMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';
import './index.scss';

const COMPONENT_NAME = 'MachineInspectionDialog';

const ENABLE_SOURCE_SELECT = false;

const FALLBACK_IMG_FORMAT = 'png';

const EJECT_BALL_RPM = 400;

const EJECT_BALL_YAW = 2;

// how many seconds into the future should the app wait before acting upon any r2f from the machine after sending the ejection mstarget
const MIN_WAIT_BEFORE_EJECTING_SEC = 2;

const TOGGLE_PLAY_KEY = 'Space';

interface IProps extends IBaseDialog {
  // provide for admins inspecting others' machines
  machineID?: string;
  // provide for users inspecting their active machine
  machineCx?: IMachineContext;
}

interface IState {
  machineID: string;

  source: CameraSource;
  paused: boolean;
  ejecting: boolean;
  data?: ICameraStreamResponseMsg;

  // prevents additional drop ball triggers until a ball-status is received
  awaiting_status: boolean;
}

export class MachineInspectionDialog extends React.Component<IProps, IState> {
  private closeTimeout?: any;
  private ignoreR2FDate = new Date();

  constructor(props: IProps) {
    super(props);

    if (props.machineID && props.machineCx) {
      NotifyHelper.warning({
        message_md: `Both \`machineID\` and \`machineCx\` should not be provided; using \`machineCx\` for inspection dialog.`,
      });
    }

    this.state = {
      machineID: props.machineCx?.machine.machineID ?? props.machineID ?? '',
      source: CameraSource.BI_R,
      paused: false,
      ejecting: false,
      awaiting_status: false,
    };

    this.handleBallStatus = this.handleBallStatus.bind(this);
    this.handleCameraStream = this.handleCameraStream.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleReadyToFire = this.handleReadyToFire.bind(this);
    this.renderBlinker = this.renderBlinker.bind(this);
    this.startEjectRoutine = this.startEjectRoutine.bind(this);
    this.toggleSource = this.toggleSource.bind(this);
  }

  componentDidMount() {
    this.props.machineCx?.setSpecialMode('inspect-machine');

    WebSocketHelper.on(WsMsgType.M2U_BallStatus, this.handleBallStatus);
    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.on(WsMsgType.M2U_CameraStream, this.handleCameraStream);

    document.addEventListener('keydown', this.handleKeyDown);

    this.toggleSource(this.state.source, true, 'mount');
  }

  componentWillUnmount() {
    this.props.machineCx?.setSpecialMode(undefined);

    WebSocketHelper.remove(WsMsgType.M2U_BallStatus, this.handleBallStatus);
    WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.remove(WsMsgType.M2U_CameraStream, this.handleCameraStream);

    document.removeEventListener('keydown', this.handleKeyDown);

    this.toggleSource(this.state.source, false, 'unmount');

    clearTimeout(this.closeTimeout);
  }

  private handleReadyToFire(event: CustomEvent) {
    if (!this.props.machineCx) {
      return;
    }

    if (!this.state.ejecting) {
      return;
    }

    if (isFuture(this.ignoreR2FDate)) {
      return;
    }

    const r2f: IReadyMsg = event.detail;

    if (!r2f.data) {
      return;
    }

    if (!r2f.data.w1 || !r2f.data.w2 || !r2f.data.w3) {
      return;
    }

    if (!r2f.data.px || !r2f.data.pz) {
      return;
    }

    if (!r2f.data.yaw) {
      return;
    }

    NotifyHelper.warning({
      message_md: 'Attempting to eject ball(s) from inserter, please wait...',
    });

    this.setState({ ejecting: false }, () => {
      WebSocketService.send(WsMsgType.U2S_EjectBalls, {}, COMPONENT_NAME);
    });
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (![TOGGLE_PLAY_KEY].includes(event.code)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.repeat) {
      return;
    }

    switch (event.code) {
      case TOGGLE_PLAY_KEY: {
        if (this.state.data) {
          this.setState({ paused: !this.state.paused });
        }
        return;
      }

      default: {
        return;
      }
    }
  }

  private handleCameraStream(event: CustomEvent) {
    if (this.state.paused) {
      return;
    }

    const data: ICameraStreamResponseMsg = event.detail;

    if (data.source !== this.state.source) {
      return;
    }

    this.setState({
      data: data,
    });
  }

  private handleBallStatus(event: CustomEvent) {
    if (this.state.ejecting) {
      // ignore ball status updates while ejecting
      return;
    }

    const data: IBallStatusMsg = event.detail;

    this.setState({
      awaiting_status: false,
    });

    if (data.ball_count !== 1) {
      return;
    }

    this.closeTimeout = setTimeout(() => {
      this.props.onClose();
    }, 3_000);
  }

  private toggleSource(source: CameraSource, enable: boolean, trigger: string) {
    console.debug(`toggling camera source (${trigger})`);

    const msg: ICameraStreamRequestMsg = {
      machineID: this.state.machineID,
      source: source,
      enable: enable,
    };

    WebSocketService.send(WsMsgType.U2M_CameraStream, msg, COMPONENT_NAME);
    this.setState({ source: source, data: undefined });
  }

  private renderBlinker() {
    const disconnected = this.state.paused
      ? false
      : this.state.data
      ? isPast(addSeconds(new Date(this.state.data.timestamp), 3))
      : true;

    const label = disconnected
      ? 'CONNECTING...'
      : this.state.paused
      ? 'PAUSED'
      : 'LIVE';

    const color = disconnected
      ? RADIX.COLOR.WARNING
      : this.state.paused
      ? undefined
      : RADIX.COLOR.SUCCESS;

    const icon = disconnected ? (
      <LinkBreak1Icon />
    ) : this.state.paused ? (
      <PauseIcon />
    ) : (
      <PlayIcon />
    );

    return (
      <Heading className="blinker" color={color} size={RADIX.HEADING.SIZE.MD}>
        <Text className={this.state.paused ? undefined : 'animate-blink'}>
          {icon}&nbsp;{label}
        </Text>
      </Heading>
    );
  }

  private async startEjectRoutine() {
    if (!this.props.machineCx) {
      return;
    }

    if (this.state.ejecting) {
      return;
    }

    const data = MachineContextHelper.getTroubleshootingMS(
      this.props.machineCx.machine,
      {
        id: 'eject-balls',
        video_uuid: DefaultVideoID.screensaver,
        wheels: {
          w1: EJECT_BALL_RPM,
          w2: EJECT_BALL_RPM,
          w3: EJECT_BALL_RPM,
        },
        yaw: EJECT_BALL_YAW,
        rapid: true,
      }
    );

    this.ignoreR2FDate = addSeconds(new Date(), MIN_WAIT_BEFORE_EJECTING_SEC);

    const sendResult = await this.props.machineCx.sendTarget({
      source: COMPONENT_NAME,
      pitch: data.pitch,
      msMsg: data.msg,
      force: true,
    });

    if (!sendResult.success) {
      NotifyHelper.warning({
        message_md:
          'There was a problem while getting ready to eject balls. Please try again or contact support.',
      });
      return;
    }

    this.setState({ ejecting: true });
  }

  private renderContent() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG} className="LiveView">
        {ENABLE_SOURCE_SELECT && (
          <Box>
            <CommonSelectInput
              id="ms-inspector-source"
              name="source"
              label="Source"
              options={Object.values(CameraSource).map((s) => ({
                label: s,
                value: s,
              }))}
              value={this.state.source}
              onChange={(v) =>
                this.toggleSource(v as CameraSource, true, 'dropdown')
              }
            />
          </Box>
        )}

        {this.state.data && (
          <Box>
            <div className="sizer">
              {this.renderBlinker()}
              <img
                className="camera-feed-wide select-none"
                alt={
                  this.state.data
                    ? `${this.state.data.source} @ ${this.state.data.timestamp}`
                    : 'loading'
                }
                src={
                  this.state.data
                    ? `data:image/${
                        this.state.data.format || FALLBACK_IMG_FORMAT
                      };base64, ${this.state.data.image}`
                    : './img/video/loading.svg'
                }
              />
            </div>

            <div className="align-right">
              <small>
                Last Updated:{' '}
                {this.state.data?.timestamp
                  ? format(
                      new Date(this.state.data.timestamp),
                      'yyyy-MM-dd @ hh:mm:ss a',
                      { timeZone: LOCAL_TIMEZONE }
                    )
                  : 'Unknown'}
              </small>
            </div>
          </Box>
        )}

        {!this.state.data && (
          <Box>
            <Text>
              Please verify that there are no balls in the machine before
              dropping a ball.
            </Text>
          </Box>
        )}

        {this.state.ejecting && (
          <CommonCallout text="Ejecting balls, please wait..." />
        )}
      </Flex>
    );
  }

  render() {
    const DEFAULT_PROPS: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.LG,
      title: 'main.inspect-machine',
      content: this.renderContent(),
      buttons: [
        {
          label: 'main.drop-ball',
          color: RADIX.COLOR.WARNING,
          disabled: !this.props.machineCx,
          onClick: () => {
            if (this.state.awaiting_status) {
              // prevent spamming of drop ball
              return;
            }

            this.setState({ awaiting_status: true }, () => {
              MachineContextHelper.sendDropBall({
                machineID: this.state.machineID,
                source: COMPONENT_NAME,
              });

              NotifyHelper.info({
                message_md: 'Attempting to drop ball, please wait...',
              });
            });
          },
        },
        {
          label: 'main.eject-balls',
          color: RADIX.COLOR.WARNING,
          disabled: !this.props.machineCx,
          invisible: !this.state.data,
          onClick: () => this.startEjectRoutine(),
        },
        {
          icon: this.state.paused ? <PlayIcon /> : <PauseIcon />,
          label: this.state.paused ? 'Resume' : 'Pause',
          color: this.state.paused ? RADIX.COLOR.SUCCESS : RADIX.COLOR.NEUTRAL,
          invisible: !this.state.data,
          onClick: () => this.setState({ paused: !this.state.paused }),
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...DEFAULT_PROPS} />
      </ErrorBoundary>
    );
  }
}
