import { Button, Flex } from '@radix-ui/themes';
import { RouteHelper } from 'classes/helpers/route.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { LoginFormAuth0 } from 'components/main/landing/forms/auth0';
import { LoginFormLegacy } from 'components/main/landing/forms/legacy';
import { ResetPasswordForm } from 'components/main/landing/forms/reset-password';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { LoginMethod } from 'enums/env';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

interface IProps {
  authCx: IAuthContext;
}

interface IState {
  method: LoginMethod;
}

export class Login extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      method: env.login_methods[0],
    };
  }

  render() {
    return (
      <ErrorBoundary componentName="Login" hideIntercom>
        <Routes>
          {env.login_methods.includes(LoginMethod.Auth0) && (
            <Route
              path={RouteHelper.login(LoginMethod.Auth0)}
              element={<LoginFormAuth0 authCx={this.props.authCx} />}
            />
          )}

          {env.login_methods.includes(LoginMethod.Legacy) && (
            <Route
              path={RouteHelper.login(LoginMethod.Legacy)}
              element={<LoginFormLegacy authCx={this.props.authCx} />}
            />
          )}

          {env.login_methods.includes(LoginMethod.Legacy) && (
            <Route
              path="/reset-password"
              element={<ResetPasswordForm authCx={this.props.authCx} />}
            />
          )}

          <Route
            path="*"
            element={
              <>
                {this.state.method === LoginMethod.Auth0 && (
                  <LoginFormAuth0 authCx={this.props.authCx} />
                )}

                {this.state.method === LoginMethod.Legacy && (
                  <LoginFormLegacy authCx={this.props.authCx} />
                )}

                <Flex
                  gap={RADIX.FLEX.GAP.LG}
                  justify="between"
                  style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                  pt="2"
                  pb="2"
                  pl="4"
                  pr="4"
                >
                  <Flex gap={RADIX.FLEX.GAP.SM}>
                    {env.login_methods.map((m, i) => (
                      <Button
                        key={`login-method-${i}`}
                        className="btn-floating"
                        variant="soft"
                        color={RADIX.COLOR.NEUTRAL}
                        onClick={() => this.setState({ method: m })}
                      >
                        {m}
                      </Button>
                    ))}
                  </Flex>
                </Flex>
              </>
            }
          />
        </Routes>
      </ErrorBoundary>
    );
  }
}
