export type MachineMode = 'empty-carousel' | 'inspect-machine';

export enum CalibrationStep {
  Setup,
  CollectData,
  ReviewMetric,
  TrainError,
}

export enum MachineButtonMode {
  Calibrate = 'Calibrate',
  Fire = 'Fire',
  None = 'None',
  Refresh = 'Refresh',
  Send = 'Send',
  Train = 'Train',
  Unavailable = 'Unavailable',
}
