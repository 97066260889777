import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import {
  IPitchListLink,
  IPitchListPutManyRequest,
} from 'lib_ts/interfaces/pitches';
import { BaseRESTService } from 'services/_base-rest.service';

export class AdminPitchListsService extends BaseRESTService {
  private static instance: AdminPitchListsService;
  static getInstance(): AdminPitchListsService {
    if (!AdminPitchListsService.instance) {
      AdminPitchListsService.instance = new AdminPitchListsService();
    }

    return AdminPitchListsService.instance;
  }

  private constructor() {
    super({
      controller: 'admin-pitch-lists',
    });
  }

  async refreshLists(
    parent_def: 'teams' | 'team-machines' | 'team-users',
    parent_id: string,
    options: {
      traj: boolean;
      ms: boolean;
    }
  ): Promise<void> {
    return await this.get({
      uri: `refresh/${parent_def}/${parent_id}`,
      params: { traj: options.traj, ms: options.ms } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'There was a problem refreshing lists.',
          });
        } else {
          NotifyHelper.success({
            message_md:
              'Pitch list refresh started, you will be notified upon completion.',
          });
        }
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was a problem refreshing lists.',
        });
      });
  }

  async putManyLists(payload: IPitchListPutManyRequest): Promise<boolean> {
    return await this.put(
      {
        uri: 'many',
      },
      payload
    );
  }

  async createLink(payload: Partial<IPitchListLink>): Promise<boolean> {
    return await this.post(
      {
        uri: 'link',
      },
      payload
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'There was a problem granting access.',
          });
          return false;
        }

        return true;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was a problem granting access.',
        });
        return false;
      });
  }

  async updateLink(
    payload: Partial<IPitchListLink>
  ): Promise<IPitchListLink | undefined> {
    if (!payload._id) {
      throw new Error('Cannot update link without _id');
    }

    return await this.put(
      {
        uri: `link/${payload._id}`,
      },
      payload
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ?? 'There was a problem updating the link.',
          });
          return undefined;
        }

        return result.data as IPitchListLink;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was a problem updating the link.',
        });
        return undefined;
      });
  }

  async getLinksForLists(list_ids: string[]): Promise<IPitchListLink[]> {
    return await this.post(
      {
        uri: 'links',
      },
      list_ids
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'There was a problem fetching links.',
          });
          return [];
        }

        return result.data as IPitchListLink[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was a problem fetching links.',
        });
        return [];
      });
  }

  async deleteLinks(ids: string[]): Promise<boolean> {
    return await this.post(
      {
        uri: 'link/delete',
      },
      ids
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'There was a problem removing access.',
          });
          return false;
        }
        return true;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was a problem removing access.',
        });
        return false;
      });
  }
}
