import {
  ExclamationTriangleIcon,
  PlusIcon,
  UpdateIcon,
} from '@radix-ui/react-icons';
import { Badge, Box, Code, Flex, Grid, Link, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import {
  AdminIcon,
  SuperAdminIcon,
} from 'components/common/custom-icon/shorthands';
import { AnnouncementDialog } from 'components/common/dialogs/announcement';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { CommonTooltip } from 'components/common/tooltip';
import { MachineInspectionDialog } from 'components/machine/dialogs/inspection';
import { RefreshListsDialog } from 'components/sections/admin-portal/dialogs/refresh-lists';
import {
  ArchiveShotsDialog,
  ConfirmHWChangedDialog,
  ExportSessionsDialog,
  MachineDetailsDialog,
  MachineLogsDialog,
  MachinePerformanceDialog,
  ResetConnectionsDialog,
  SoftRebootDialog,
  SystemRestartDialog,
} from 'components/sections/admin-portal/machines/dialogs';
import { UpgradeFirmwareDialog } from 'components/sections/admin-portal/machines/dialogs/upgrade-firmware';
import { MachineEditorDialog } from 'components/sections/admin-portal/machines/editor';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import env from 'config';
import { MachineModelsContext } from 'contexts/admin/machine-models.context';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { ITeamsContext } from 'contexts/admin/teams.context';
import { IUsersContext } from 'contexts/admin/users.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
  ICheckedContext,
} from 'contexts/layout/checked.context';
import { PitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import format from 'date-fns-tz/format';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { AdminRoute } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { IDisplayCol, ITablePageable } from 'interfaces/i-tables';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMachineActiveModelID, getModelKey } from 'lib_ts/classes/ms.helper';
import { RestartMode } from 'lib_ts/enums/admin.enums';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IAnnouncement } from 'lib_ts/interfaces/common/i-announcement';
import { IAdminMachine, IMachine } from 'lib_ts/interfaces/i-machine';
import { IMachineUser } from 'lib_ts/interfaces/i-machine-summary';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import React from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';
import { getShortenedSession } from 'services/session-events.service';

const IDENTIFIER = TableIdentifier.AdminMachineList;

const MAX_ERRORS_IN_TOOLTIP = 3;

const ENABLE_FILTER_CREATED_DATE = false;

const PAGE_SIZES = TABLES.PAGE_SIZES.LG;

/** provide callback to do something when username is clicked, otherwise open a mailto link as the default behaviour */
export const renderMachineUser = (
  u: IMachineUser,
  callback?: (m: IMachineUser) => void
) => {
  const trigger = callback ? (
    <Link onClick={() => callback(u)}>{u.email}</Link>
  ) : (
    <Link href={`mailto:${u.email}`}>{u.email}</Link>
  );

  return (
    <CommonTooltip
      trigger={trigger}
      content={
        <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
          <Box>
            Session: <Code>{getShortenedSession(u.session)}</Code>
          </Box>
          <Box>
            Connected:{' '}
            {format(parseISO(u.connectDate), LOCAL_DATETIME_FORMAT, {
              timeZone: LOCAL_TIMEZONE,
            })}
          </Box>
        </Flex>
      }
    />
  );
};

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  teamsCx: ITeamsContext;
  machinesCx: IMachinesContext;
  usersCx: IUsersContext;
}

interface IDialogs {
  dialogAnnouncement?: number;
  dialogArchiveShots?: number;
  dialogDetails?: number;
  dialogEditor?: number;
  dialogExportSessions?: number;
  dialogFWUpgradeDate?: number;
  dialogHWChanged?: number;
  dialogInspection?: number;
  dialogLogs?: number;
  dialogPerformance?: number;
  dialogReassign?: number;
  dialogRefreshList?: number;
  dialogResetConnections?: number;
  dialogSoftReboot?: number;
  dialogSystemRestart?: number;
}

interface IFilters {
  filterMachineIDs: string[];
  filterTeams: string[];
  filterCreated: string[];
  filterFirmware: string[];
  filterSandbox?: boolean;
  filterConnected?: boolean;
  filterActiveUser?: boolean;
}

interface IState extends IFilters, IDialogs {
  selected?: IAdminMachine;

  /** for confirmations (e.g. reset connections, archive shots) */
  menuMachineID?: string;
  manageMachine?: IAdminMachine;
  manageMachines?: IAdminMachine[];
  reassignListsPayload: IPitchListPutManyRequest;

  announcementModel?: Partial<IAnnouncement>;
}

export class MachinesTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filterMachineIDs: [],
      filterTeams: [],
      filterCreated: [],
      filterFirmware: [],
      filterConnected: env.production ? true : undefined,

      reassignListsPayload: { filter: {}, update: {} },
    };

    this.getFiltered = this.getFiltered.bind(this);

    this.getCheckedMenuActions = this.getCheckedMenuActions.bind(this);
    this.renderDialogs = this.renderDialogs.bind(this);
    this.renderTableToolbar = this.renderTableToolbar.bind(this);
  }

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'main.inspect-machine',
          color: RADIX.COLOR.SUCCESS,
          invisibleFn: (m: IAdminMachine) => !m.connected,
          onClick: (m: IAdminMachine) => {
            this.setState({
              menuMachineID: m.machineID,
              dialogInspection: Date.now(),
            });
          },
        },
        {
          label: 'common.view-details',
          onClick: (m: IAdminMachine) => {
            this.props.machinesCx.loadDetails(m.machineID).then(() => {
              if (this.props.machinesCx.details) {
                this.setState({
                  dialogDetails: Date.now(),
                });
              }
            });
          },
        },
        {
          label: 'admin.view-performance',
          onClick: (m: IAdminMachine) => {
            this.setState({
              dialogPerformance: Date.now(),
              manageMachine: m,
            });
          },
        },
        {
          label: 'common.edit',
          onClick: (m: IAdminMachine) => {
            this.setState({
              manageMachine: m,
              dialogEditor: Date.now(),
            });
          },
        },
        {
          label: 'common.logs',
          invisibleFn: () => this.props.authCx.current.role !== UserRole.admin,
          icon: <SuperAdminIcon />,
          onClick: (m: IAdminMachine) => {
            this.setState({
              dialogLogs: Date.now(),
              manageMachine: m,
            });
          },
        },
        {
          label: 'Export Sessions',
          onClick: (m: IAdminMachine) => {
            this.setState({
              dialogExportSessions: Date.now(),
              manageMachines: [m],
            });
          },
        },
        {
          label: 'admin.refresh-lists',
          color: RADIX.COLOR.WARNING,
          onClick: (m: IAdminMachine) => {
            this.setState({
              manageMachine: m,
              dialogRefreshList: Date.now(),
            });
          },
        },
        {
          label: 'common.reassign-lists',
          onClick: (m: IAdminMachine) => {
            const payload: IPitchListPutManyRequest = {
              filter: {
                _parent_id: m._id,
                _parent_def: 'team-machines',
              },
              update: {
                processed: new Date(),
                process_notes: `reassigned from machine ${m.machineID} by admin ${this.props.authCx.current.email}`,
                _parent_def: 'team-machines',
              },
            };

            this.setState({
              dialogReassign: Date.now(),
              reassignListsPayload: payload,
            });
          },
          color: RADIX.COLOR.WARNING,
        },
        {
          label: 'admin.reset-connections',
          color: RADIX.COLOR.WARNING,
          invisibleFn: (m: IAdminMachine) => !m.connected,
          onClick: (m: IAdminMachine) => {
            this.setState({
              menuMachineID: m.machineID,
              dialogResetConnections: Date.now(),
            });
          },
        },
        {
          label: 'Check Firmware',
          color: RADIX.COLOR.WARNING,
          icon: <SuperAdminIcon />,
          invisibleFn: (m: IAdminMachine) =>
            this.props.authCx.current.role !== UserRole.admin || !m.connected,
          onClick: (m: IAdminMachine) =>
            AdminMachinesService.getInstance().checkFirmware(m.machineID),
        },
        {
          label: 'Upgrade Firmware',
          color: RADIX.COLOR.WARNING,
          icon: <SuperAdminIcon />,
          invisibleFn: (m: IAdminMachine) =>
            this.props.authCx.current.role !== UserRole.admin || !m.connected,
          onClick: (m: IAdminMachine) => {
            if (!m.connected) {
              NotifyHelper.warning({
                message_md: `Please ensure ${m.machineID} is connected and try again.`,
              });
              return;
            }

            this.setState({
              manageMachines: [m],
              dialogFWUpgradeDate: Date.now(),
            });
          },
        },
        {
          label: 'Hardware Changed',
          color: RADIX.COLOR.WARNING,
          onClick: (m: IAdminMachine) => {
            this.setState({
              manageMachines: [m],
              dialogHWChanged: Date.now(),
            });
          },
        },
        {
          label: 'common.restart',
          color: RADIX.COLOR.WARNING,
          invisibleFn: (m: IAdminMachine) => !m.connected,
          onClick: (m: IAdminMachine) =>
            AdminMachinesService.getInstance().restart(RestartMode.arc, [
              m.machineID,
            ]),
        },
        {
          label: 'common.restart-os',
          color: RADIX.COLOR.DANGER,
          icon: <SuperAdminIcon />,
          invisibleFn: (m: IAdminMachine) =>
            this.props.authCx.current.role !== UserRole.admin || !m.connected,
          onClick: (m: IAdminMachine) =>
            AdminMachinesService.getInstance().restart(RestartMode.os, [
              m.machineID,
            ]),
        },
        {
          label: 'common.reset-training-data',
          color: RADIX.COLOR.DANGER,
          onClick: (m: IAdminMachine) => {
            this.setState({
              manageMachine: m,
              dialogArchiveShots: Date.now(),
            });
          },
        },
      ],
    },
    {
      label: 'common.issues',
      key: '_issues',
      sortRowsFn: (a: IMachine, b: IMachine, dir: number) => {
        const aErr = MachineHelper.getErrors(a);
        const bErr = MachineHelper.getErrors(b);
        return dir * (aErr.length > bErr.length ? -1 : 1);
      },
      formatFn: (m: IMachine) => {
        const errors = MachineHelper.getErrors(m);
        if (errors.length === 0) {
          return '';
        }

        const tooltip = (
          <Text>
            <p>Errors:</p>

            <ol>
              {errors
                .filter((_, i) => i < MAX_ERRORS_IN_TOOLTIP)
                .map((errorMsg, i) => (
                  <li key={`machine-${m._id}-error-${i}`}>{errorMsg}</li>
                ))}
            </ol>

            {errors.length > MAX_ERRORS_IN_TOOLTIP && (
              <p>...and {errors.length - MAX_ERRORS_IN_TOOLTIP} more</p>
            )}
          </Text>
        );

        return (
          <CommonTooltip
            trigger={<ExclamationTriangleIcon />}
            content={tooltip}
          />
        );
      },
    },
    {
      label: 'common.machine',
      key: 'machineID',
      tooltipFn: (m: IMachine) => {
        if (m.super) {
          return 'Super machines can access every video';
        }
      },
      /** machineID (nickname, if applicable), * for super flag */
      formatFn: (m: IMachine) => (
        <Flex direction="column" gap={RADIX.FLEX.GAP.XS} pt="1" pb="1">
          <Box>
            <Badge>
              {m.machineID} {m.super ? '*' : undefined}
            </Badge>
          </Box>
          {m.nickname && (
            <Box>
              <Text size={RADIX.TEXT.SIZE.XS} color={RADIX.COLOR.SECONDARY}>
                {m.nickname}
              </Text>
            </Box>
          )}
        </Flex>
      ),
    },
    {
      label: 'common.sandbox',
      key: 'sandbox',
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
        (a.sandbox ? 1 : 0) > (b.sandbox ? 1 : 0) ? -dir : dir,
      formatFn: (m: IAdminMachine) => (m.sandbox ? 'Yes' : 'No'),
    },
    {
      label: 'common.plate',
      subLabel: 'ft',
      key: 'plate_distance',
      formatFn: (m: IMachine) => (m.plate_distance ?? 0).toFixed(1),
    },
    {
      label: 'common.training',
      subLabel: '#',
      key: 'training_threshold',
      align: 'right',
    },
    {
      label: 'common.rapsodo-serial',
      key: 'rapsodo_serial',
      tooltipFn: (m: IMachine) => {
        if (m.sandbox && m.machineID !== m.rapsodo_serial) {
          return 'Sandbox MachineID does not match Rapsodo Serial.';
        }
      },
      formatFn: (m: IMachine) => {
        return (
          <Code
            color={
              m.sandbox && m.machineID !== m.rapsodo_serial
                ? RADIX.COLOR.DANGER
                : undefined
            }
          >
            {m.rapsodo_serial}
          </Code>
        );
      },
      sortRowsFn: (a: IMachine, b: IMachine, dir: number) =>
        (a.rapsodo_serial ?? '').localeCompare(b.rapsodo_serial ?? '') * -dir,
    },
    {
      label: 'Firmware',
      key: 'last_firmware',
      formatFn: (a: IMachine) => {
        if (!a.last_firmware) {
          return undefined;
        }

        const isBeta = a.last_firmware.startsWith('beta');

        return (
          <Code
            title={a.last_firmware}
            className="cursor-pointer"
            onClick={() => {
              if (!a.last_firmware) {
                return;
              }

              navigator.clipboard.writeText(a.last_firmware);

              NotifyHelper.success({
                message_md: t('common.x-copied-to-clipboard', {
                  x: a.last_firmware,
                }),
              });
            }}
            color={isBeta ? RADIX.COLOR.BETA : undefined}
          >
            {a.last_firmware}
          </Code>
        );
      },
    },
    {
      label: 'Last Updated',
      key: 'last_firmware_updated',
      dataType: 'datetime',
    },
    {
      label: 'admin.active-model',
      key: '_active_model',
      disableSort: true,
      tooltipFn: (m: IMachine) => {
        const activeKey = getModelKey(m);
        const activeID = getMachineActiveModelID(m);
        const activeModel = this.props.machinesCx.machineModels.find(
          (model) => model._id === activeID
        );

        if (activeModel) {
          return [
            `Name: ${activeModel.name}`,
            `Key: ${activeKey}`,
            `ID: \`${activeID ?? 'none'}\``,
          ].join('\n\n');
        }

        return 'No active model';
      },
      formatFn: (m: IMachine) => {
        const activeID = getMachineActiveModelID(m);
        const activeModel = this.props.machinesCx.machineModels.find(
          (model) => model._id === activeID
        );

        if (activeModel) {
          return activeModel.name;
        }

        return 'None';
      },
    },
    {
      label: 'admin.last-connected',
      labelTooltip: 'The domain that this machine connected to last',
      key: 'last_domain',
      sortRowsFn: (a: IMachine, b: IMachine, dir: number) =>
        (a.last_domain ?? '').localeCompare(b.last_domain ?? '') * dir,
    },
    {
      label: 'admin.status',
      labelTooltip:
        'The current status of this machine according to this environment',
      key: '_connection_status',
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) => {
        return (a.connected ? 1 : 0) > (b.connected ? 1 : 0) ? -dir : dir;
      },
      formatFn: (m: IAdminMachine) => {
        return m.connected ? 'Online' : 'Offline';
      },
    },
    {
      label: 'admin.status-date',
      key: '_connection_date',
      labelTooltip: 'The most recent moment when connection status changed',
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
        (() => {
          if (a.connected && !b.connected) {
            return -1;
          }

          if (!a.connected && b.connected) {
            return 1;
          }

          return 1;
        })() * dir,
      formatFn: (m: IAdminMachine) => {
        if (m.connected) {
          if (m.connect_date) {
            return `${format(parseISO(m.connect_date), LOCAL_DATETIME_FORMAT, {
              timeZone: LOCAL_TIMEZONE,
            })}`;
          }

          return '';
        } else {
          if (m.disconnect_date) {
            return `${format(
              parseISO(m.disconnect_date),
              LOCAL_DATETIME_FORMAT,
              {
                timeZone: LOCAL_TIMEZONE,
              }
            )}`;
          }

          return '';
        }
      },
    },
    {
      label: 'common.active-user',
      key: 'active',
      formatFn: (m: IAdminMachine) =>
        m.active
          ? renderMachineUser(m.active, (u) => {
              this.setState({
                dialogAnnouncement: Date.now(),
                announcementModel: {
                  filter: { email: u.email },
                  message: `RE: ${m.machineID}`,
                },
              });
            })
          : 'None',
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
        (a.active?.email ?? '').localeCompare(b.active?.email ?? '') * -dir,
    },
    {
      label: 'admin.waiting',
      key: 'waiting',
      formatFn: (m: IAdminMachine) => {
        if (m.waiting.length === 0) {
          return 'None';
        }

        return (
          <ul className="no-style">
            {m.waiting.map((user) => (
              <li key={user.session}>
                {renderMachineUser(user, (u) => {
                  this.setState({
                    dialogAnnouncement: Date.now(),
                    announcementModel: {
                      filter: { email: u.email },
                      message: `RE: ${m.machineID}`,
                    },
                  });
                })}
              </li>
            ))}
          </ul>
        );
      },
      sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
        (a.waiting.length > b.waiting.length ? 1 : -1) * dir,
    },
  ];

  private getCheckedMenuActions(): IMenuAction[] {
    const checked = this.props.machinesCx.machines.filter((p) => p._checked);
    const connectedChecked = checked.filter((m) => m.connected);

    const actions: IMenuAction[] = [];

    actions.push(
      {
        label: 'Download Config',
        invisible: connectedChecked.length === 0,
        onClick: () => {
          connectedChecked.forEach((machine) => {
            AdminMachinesService.getInstance()
              .getMachineConfig(machine.machineID)
              .then((result) => {
                if (!result?.machine) {
                  NotifyHelper.warning({
                    message_md: `Empty value for ${machine.machineID}, nothing to download.`,
                  });
                  return;
                }

                MiscHelper.saveAs(
                  new Blob([JSON.stringify(result.machine, null, 2)]),
                  `${machine.machineID}.json`
                );
              });
          });
        },
      },
      {
        label: 'Export Sessions',
        icon: <AdminIcon />,
        invisible: ![UserRole.admin, UserRole.team_admin].includes(
          this.props.authCx.current.role
        ),
        onClick: () => {
          this.setState({
            dialogExportSessions: Date.now(),
            manageMachines: checked,
          });
        },
      },
      {
        label: 'common.restart',
        color: RADIX.COLOR.WARNING,
        invisible: connectedChecked.length === 0,
        onClick: () => {
          this.setState({
            manageMachines: connectedChecked,
            dialogSystemRestart: Date.now(),
          });
        },
      },
      {
        label: 'common.restart-os',
        icon: <SuperAdminIcon />,
        color: RADIX.COLOR.DANGER,
        invisible:
          this.props.authCx.current.role !== UserRole.admin ||
          connectedChecked.length === 0,
        onClick: () => {
          this.setState({
            manageMachines: connectedChecked,
            dialogSoftReboot: Date.now(),
          });
        },
      },
      {
        label: 'Upgrade Firmware',
        color: RADIX.COLOR.WARNING,
        invisible: connectedChecked.length === 0,
        onClick: () => {
          this.setState({
            manageMachines: connectedChecked,
            dialogFWUpgradeDate: Date.now(),
          });
        },
      },
      {
        label: 'Hardware Changed',
        color: RADIX.COLOR.WARNING,
        onClick: () => {
          this.setState({
            manageMachines: checked,
            dialogHWChanged: Date.now(),
          });
        },
      }
    );

    return actions;
  }

  private renderTableToolbar(checkedCx: ICheckedContext) {
    return (
      <Grid
        columns={ENABLE_FILTER_CREATED_DATE ? '7' : '6'}
        gap={RADIX.FLEX.GAP.SM}
      >
        <Box>
          <CommonSelectInput
            id="machines-connected"
            placeholder="Connected"
            name="filterConnected"
            options={[
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
            value={this.state.filterConnected?.toString()}
            onOptionalBooleanChange={(v) => {
              checkedCx.checkAll(false);
              this.setState({ filterConnected: v });
            }}
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="machines-machineID"
            placeholder="common.machine"
            options={this.props.machinesCx.machines.map((o) => ({
              label: `${o.machineID} (${o.nickname ?? 'no nickname'})`,
              value: o.machineID,
            }))}
            values={this.state.filterMachineIDs}
            onChange={(v) => {
              checkedCx.checkAll(false);
              this.setState({
                filterMachineIDs: v,
              });
            }}
            multiple
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="machines-team"
            placeholder="common.team"
            options={this.props.teamsCx.options.teams}
            values={this.state.filterTeams}
            onChange={(v) => {
              checkedCx.checkAll(false);
              this.setState({
                filterTeams: v,
              });
            }}
            multiple
            optional
          />
        </Box>

        {ENABLE_FILTER_CREATED_DATE && (
          <Box>
            <CommonSearchInput
              id="machines-created"
              placeholder="common.date-added"
              options={this.props.machinesCx.options._created.map((o) => ({
                label: o,
                value: o,
              }))}
              values={this.state.filterCreated}
              onChange={(v) => {
                checkedCx.checkAll(false);
                this.setState({ filterCreated: v });
              }}
              multiple
              reverseSort
              optional
            />
          </Box>
        )}

        <Box>
          <CommonSearchInput
            id="machines-firmware"
            placeholder="Firmware"
            options={this.props.machinesCx.options.firmware.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterFirmware}
            onChange={(v) => {
              checkedCx.checkAll(false);
              this.setState({ filterFirmware: v });
            }}
            multiple
            optional
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="machines-active"
            placeholder="Active User"
            name="filterActiveUser"
            options={[
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
            value={this.state.filterActiveUser?.toString()}
            onOptionalBooleanChange={(v) => {
              checkedCx.checkAll(false);
              this.setState({ filterActiveUser: v });
            }}
            optional
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="machines-sandbox"
            placeholder="Sandbox"
            name="filterSandbox"
            options={[
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
            value={this.state.filterSandbox?.toString()}
            onOptionalBooleanChange={(v) => {
              checkedCx.checkAll(false);
              this.setState({ filterSandbox: v });
            }}
            optional
          />
        </Box>

        <CommonTableButton
          color={RADIX.COLOR.NEUTRAL}
          icon={<UpdateIcon />}
          label="common.refresh"
          disabled={this.props.machinesCx.loading}
          onClick={() => {
            checkedCx.checkAll(false);
            this.props.machinesCx.refresh();
          }}
        />

        <AuthContext.Consumer>
          {(authCx) => {
            if (authCx.current.role === UserRole.admin) {
              return (
                <CommonTableButton
                  color={RADIX.COLOR.SUCCESS}
                  icon={<PlusIcon />}
                  label="common.create"
                  disabled={this.props.machinesCx.loading}
                  onClick={() => {
                    this.setState({
                      manageMachine: undefined,
                      dialogEditor: Date.now(),
                    });
                  }}
                />
              );
            }
          }}
        </AuthContext.Consumer>
      </Grid>
    );
  }

  private getFiltered(): IAdminMachine[] {
    return this.props.machinesCx.machines
      .filter(
        (m) =>
          this.state.filterConnected === undefined ||
          m.connected === this.state.filterConnected
      )
      .filter(
        (m) =>
          this.state.filterActiveUser === undefined ||
          !m.active === !this.state.filterActiveUser
      )
      .filter(
        (m) =>
          this.state.filterMachineIDs.length === 0 ||
          this.state.filterMachineIDs.includes(m.machineID)
      )
      .filter(
        (m) =>
          this.state.filterTeams.length === 0 ||
          this.state.filterTeams.includes(m._parent_id)
      )
      .filter(
        (m) =>
          this.state.filterCreated.length === 0 ||
          (m._created &&
            this.state.filterCreated.includes(
              lightFormat(parseISO(m._created), 'yyyy-MM-dd')
            ))
      )
      .filter(
        (m) =>
          this.state.filterFirmware.length === 0 ||
          (m.last_firmware &&
            this.state.filterFirmware.includes(m.last_firmware.split(' ')[0]))
      )
      .filter(
        (m) =>
          this.state.filterSandbox === undefined ||
          !!m.sandbox === this.state.filterSandbox
      );
  }

  private renderDialogs() {
    return (
      <>
        {this.state.dialogReassign && (
          <PitchListsContext.Consumer>
            {(listsCx) => (
              <ReassignListsDialog
                key={this.state.dialogReassign}
                identifier="MachinesTableReassignListsDialog"
                authCx={this.props.authCx}
                listsCx={listsCx}
                onClose={() => this.setState({ dialogReassign: undefined })}
                refPayload={this.state.reassignListsPayload}
              />
            )}
          </PitchListsContext.Consumer>
        )}

        {this.state.dialogPerformance && this.state.manageMachine && (
          <MachinePerformanceDialog
            key={this.state.dialogPerformance}
            machine={this.state.manageMachine}
            onClose={() => this.setState({ dialogPerformance: undefined })}
          />
        )}

        {this.state.dialogExportSessions && this.state.manageMachines && (
          <ExportSessionsDialog
            key={this.state.dialogExportSessions}
            machineIDs={this.state.manageMachines.map((m) => m.machineID)}
            onClose={() => this.setState({ dialogExportSessions: undefined })}
          />
        )}

        {this.state.dialogInspection && (
          <MachineInspectionDialog
            key={this.state.dialogInspection}
            identifier="AdminInspectDialog"
            machineID={this.state.menuMachineID}
            onClose={() => this.setState({ dialogInspection: undefined })}
          />
        )}

        {this.state.dialogEditor && (
          <MachineModelsContext.Consumer>
            {(modelsCx) => (
              <MachineEditorDialog
                key={this.state.dialogEditor}
                id={this.state.manageMachine?._id}
                authCx={this.props.authCx}
                teamsCx={this.props.teamsCx}
                machinesCx={this.props.machinesCx}
                modelsCx={modelsCx}
                onClose={() => this.setState({ dialogEditor: undefined })}
              />
            )}
          </MachineModelsContext.Consumer>
        )}

        {this.state.dialogDetails && (
          <MachineDetailsDialog
            key={this.state.dialogDetails}
            machinesCx={this.props.machinesCx}
            onClose={() => this.setState({ dialogDetails: undefined })}
          />
        )}

        {this.state.dialogLogs && this.state.manageMachine && (
          <MachineLogsDialog
            key={this.state.dialogLogs}
            machine={this.state.manageMachine}
            machinesCx={this.props.machinesCx}
            onClose={() => this.setState({ dialogLogs: undefined })}
          />
        )}

        {this.state.dialogSystemRestart && this.state.manageMachines && (
          <SystemRestartDialog
            key={this.state.dialogSystemRestart}
            machineIDs={ArrayHelper.unique(
              this.state.manageMachines.map((m) => m.machineID)
            )}
            onClose={() => this.setState({ dialogSystemRestart: undefined })}
          />
        )}

        {this.state.dialogSoftReboot && this.state.manageMachines && (
          <SoftRebootDialog
            key={this.state.dialogSoftReboot}
            machineIDs={ArrayHelper.unique(
              this.state.manageMachines.map((m) => m.machineID)
            )}
            onClose={() => this.setState({ dialogSoftReboot: undefined })}
          />
        )}

        {this.state.dialogResetConnections && this.state.menuMachineID && (
          <ResetConnectionsDialog
            key={this.state.dialogResetConnections}
            machineID={this.state.menuMachineID}
            onClose={() => this.setState({ dialogResetConnections: undefined })}
          />
        )}

        {this.state.dialogRefreshList && this.state.manageMachine && (
          <RefreshListsDialog
            key={this.state.dialogRefreshList}
            name={this.state.manageMachine.machineID}
            parent_def="team-machines"
            parent_id={this.state.manageMachine._id}
            onClose={() => this.setState({ dialogRefreshList: undefined })}
          />
        )}

        {this.state.dialogFWUpgradeDate && this.state.manageMachines && (
          <UpgradeFirmwareDialog
            key={this.state.dialogFWUpgradeDate}
            machineIDs={ArrayHelper.unique(
              this.state.manageMachines.map((m) => m.machineID)
            )}
            onClose={() => this.setState({ dialogFWUpgradeDate: undefined })}
          />
        )}

        {this.state.dialogAnnouncement && (
          <AnnouncementDialog
            key={this.state.dialogAnnouncement}
            identifier="MachinesTableAnnouncementDialog"
            authCx={this.props.authCx}
            teamsCx={this.props.teamsCx}
            machinesCx={this.props.machinesCx}
            usersCx={this.props.usersCx}
            defaultModel={this.state.announcementModel}
            onClose={() =>
              this.setState({
                dialogAnnouncement: undefined,
                announcementModel: undefined,
              })
            }
          />
        )}

        {this.state.dialogArchiveShots && this.state.manageMachine && (
          <ArchiveShotsDialog
            key={this.state.dialogArchiveShots}
            machine={this.state.manageMachine}
            onClose={() => this.setState({ dialogArchiveShots: undefined })}
          />
        )}
      </>
    );
  }

  render() {
    /** add the action column */
    const filtered = this.getFiltered();

    const pagination: ITablePageable = {
      total: filtered.length,
      enablePagination: true,
      pageSize: this.props.cookiesCx.getPageSize(IDENTIFIER) ?? PAGE_SIZES[0],
      pageSizeOptions: PAGE_SIZES,
      pageSizeCallback: (value) =>
        this.props.cookiesCx.setPageSize(IDENTIFIER, value),
    };

    return (
      <ErrorBoundary componentName="MachinesTable">
        <CheckedProvider data={filtered}>
          <CheckedContext.Consumer>
            {(checkedCx) => (
              <FlexTableWrapper
                gap={RADIX.FLEX.GAP.SECTION}
                header={<AdminTabNav active={AdminRoute.Machines} />}
                table={
                  <GlobalContext.Consumer>
                    {(globalCx) => (
                      <CommonTable
                        id="AdminMachines"
                        data-testid="AdminMachines"
                        checkedCx={checkedCx}
                        checkedMenuActions={this.getCheckedMenuActions()}
                        suspendKeyListener={globalCx.dialogs.length > 0}
                        toolbarContent={this.renderTableToolbar(checkedCx)}
                        displayColumns={this.BASE_COLUMNS}
                        displayData={filtered}
                        afterSelectRow={async (config) =>
                          this.setState({ selected: config.model })
                        }
                        checkboxColumnIndex={0}
                        {...pagination}
                        defaultSortKey="machineID"
                        defaultSortDir={-1}
                        enableSort
                        vFlex
                      />
                    )}
                  </GlobalContext.Consumer>
                }
              />
            )}
          </CheckedContext.Consumer>
        </CheckedProvider>

        {this.renderDialogs()}

        {this.state.dialogHWChanged && this.state.manageMachines && (
          <ConfirmHWChangedDialog
            key={this.state.dialogHWChanged}
            machines={this.state.manageMachines}
          />
        )}
      </ErrorBoundary>
    );
  }
}
