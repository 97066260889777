export type RadixTextAlign = 'left' | 'center' | 'right';

export type RadixFlexAlign = 'start' | 'center' | 'end';

export type RadixSide = 'top' | 'bottom' | 'left' | 'right';

export type RadixBtnSize = '1' | '2' | '3' | '4';

export type RadixTextSize = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

export type RadixCalloutVariant = 'soft' | 'surface' | 'outline';

export type RadixContainerSize = '1' | '2' | '3' | '4';

export type RadixSelectVariant = 'classic' | 'soft' | 'surface' | 'ghost';

export type RadixFlexJustify = 'start' | 'center' | 'end' | 'between';

export type RadixFlexGap =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9';

export type RadixBtnVariant =
  | 'classic'
  | 'solid'
  | 'soft'
  | 'surface'
  | 'outline'
  | 'ghost';

export type RadixColor =
  | 'amber'
  | 'blue'
  | 'bronze'
  | 'brown'
  | 'crimson'
  | 'cyan'
  | 'gold'
  | 'grass'
  | 'gray'
  | 'green'
  | 'indigo'
  | 'iris'
  | 'jade'
  | 'lime'
  | 'mint'
  | 'orange'
  | 'pink'
  | 'plum'
  | 'purple'
  | 'red'
  | 'ruby'
  | 'sky'
  | 'teal'
  | 'tomato'
  | 'violet'
  | 'yellow';

export type RadixTextType =
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'hidden'
  | 'month'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

// this is so that typescript doesn't constantly complain unless we cast the constants
interface RadixConstants {
  VFLEX: {
    WRAPPER: string;
    COLUMN: string;
    GROW: string;
  };
  BOX: {
    PAD: {
      XS: string;
      SM: string;
      MD: string;
      LG: string;
    };
  };
  BUTTON: {
    VARIANT: {
      PRIMARY: RadixBtnVariant;
      SECONDARY: RadixBtnVariant;
      SELECTED: RadixBtnVariant;
      NOT_SELECTED: RadixBtnVariant;
      BORDERLESS: RadixBtnVariant;
      TABLE: RadixBtnVariant;
    };
    SIZE: {
      TABLE: RadixBtnSize;
      MODAL: RadixBtnSize;
      XS: RadixBtnSize;
      SM: RadixBtnSize;
      MD: RadixBtnSize;
      LG: RadixBtnSize;
    };
  };
  DIALOG: {
    WIDTH: {
      SM: string;
      MD: string;
      LG: string;
      XL: string;
      MIN: string;
      MAX: string;
    };
    HEIGHT: {
      SM: string;
      MD: string;
      LG: string;
      XL: string;
      MIN: string;
      MAX: string;
    };
  };
  HEADING: {
    SIZE: {
      XS: RadixTextSize;
      SM: RadixTextSize;
      MD: RadixTextSize;
      LG: RadixTextSize;
      XL: RadixTextSize;
      MODAL_TITLE: RadixTextSize;
    };
  };
  TEXT: {
    SIZE: {
      HINT: RadixTextSize;
      XS: RadixTextSize;
      SM: RadixTextSize;
      MD: RadixTextSize;
      LG: RadixTextSize;
    };
  };
  COLOR: {
    ACCENT: RadixColor;
    SECONDARY: RadixColor;
    NEUTRAL: RadixColor;
    DANGER: RadixColor;
    WARNING: RadixColor;
    SUCCESS: RadixColor;
    INFO: RadixColor;
    HINT: RadixColor;
    BETA: RadixColor;
    ANY_ADMIN?: RadixColor;
    SUPER_ADMIN?: RadixColor;
    TRAIN_PITCH: RadixColor;
    SEND_PITCH: RadixColor;
    FIRE_PITCH: RadixColor;
    LEFT: RadixColor;
    RIGHT: RadixColor;
  };
  FLEX: {
    PAD: {
      MAIN: string;
      XS: string;
      SM: string;
      MD: string;
      LG: string;
    };
    GAP: {
      SIDEBAR: RadixFlexGap;
      SECTION: RadixFlexGap;
      MAIN_MENU: RadixFlexGap;
      INPUT: RadixFlexGap;
      FORM: RadixFlexGap;
      NONE: RadixFlexGap;
      XS: RadixFlexGap;
      SM: RadixFlexGap;
      MD: RadixFlexGap;
      LG: RadixFlexGap;
    };
  };
  CONTAINER: {
    PAD: {
      XS: string;
      SM: string;
      MD: string;
      LG: string;
    };
    SIZE: {
      MODAL: RadixContainerSize;
    };
  };

  ICON: {
    TABLE_MT: string;
  };
}

const COLORIZE_ADMIN_STUFF = true;

export const RADIX: RadixConstants = {
  ICON: {
    TABLE_MT: '3px',
  },
  VFLEX: {
    WRAPPER: 'rt-VertFlexWrapper',
    COLUMN: 'rt-VertFlexColumn',
    GROW: 'rt-VertFlexGrow',
  },
  BOX: {
    PAD: {
      XS: '1',
      SM: '2',
      MD: '4',
      LG: '6',
    },
  },
  BUTTON: {
    VARIANT: {
      PRIMARY: 'solid',
      SECONDARY: 'soft',
      SELECTED: 'solid',
      NOT_SELECTED: 'soft',
      BORDERLESS: 'soft',
      TABLE: 'soft',
    },
    SIZE: {
      TABLE: '2',
      MODAL: '2',
      XS: '1',
      SM: '2',
      MD: '3',
      LG: '4',
    },
  },
  DIALOG: {
    WIDTH: {
      SM: '448px',
      MD: '688px',
      LG: '880px',
      XL: '1136px',
      MIN: '20vw',
      MAX: '80vw',
    },
    HEIGHT: {
      SM: '200px',
      MD: '60vh',
      LG: '70vh',
      XL: '80vh',
      MIN: '10vh',
      MAX: '80vh',
    },
  },
  COLOR: {
    ACCENT: 'blue',
    SECONDARY: 'gray',
    NEUTRAL: 'gray',
    DANGER: 'red',
    WARNING: 'amber',
    SUCCESS: 'green',
    INFO: 'blue',
    HINT: 'gray',
    BETA: 'teal',
    ANY_ADMIN: COLORIZE_ADMIN_STUFF ? 'purple' : undefined,
    SUPER_ADMIN: COLORIZE_ADMIN_STUFF ? 'pink' : undefined,
    TRAIN_PITCH: 'amber',
    SEND_PITCH: 'blue',
    FIRE_PITCH: 'green',
    LEFT: 'pink',
    RIGHT: 'blue',
  },
  HEADING: {
    SIZE: {
      XS: '1',
      SM: '2',
      MD: '4',
      LG: '6',
      XL: '8',
      MODAL_TITLE: '6',
    },
  },
  TEXT: {
    SIZE: {
      HINT: '1',
      XS: '1',
      SM: '2',
      MD: '4',
      LG: '6',
    },
  },
  FLEX: {
    PAD: {
      MAIN: '5',
      XS: '1',
      SM: '2',
      MD: '4',
      LG: '6',
    },
    GAP: {
      SIDEBAR: '5',
      SECTION: '3',
      MAIN_MENU: '1',
      INPUT: '2',
      FORM: '4',
      NONE: '0',
      XS: '1',
      SM: '2',
      MD: '4',
      LG: '6',
    },
  },
  CONTAINER: {
    SIZE: {
      MODAL: '4',
    },
    PAD: {
      XS: '1',
      SM: '2',
      MD: '4',
      LG: '6',
    },
  },
};

// for easy tracking wherever we specify an empty option w/o using optional flag
export const EMPTY_SELECT_VALUE = '';
