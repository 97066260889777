import { Auth0Provider } from '@auth0/auth0-react';
import { Main } from 'components/main';
import env from 'config';
import { AuthContext, AuthProvider } from 'contexts/auth.context';
import {
  CookiesContext,
  CookiesProvider,
  ICookiesContext,
} from 'contexts/cookies.context';
import {
  GlobalContext,
  GlobalProvider,
  IGlobalContext,
} from 'contexts/global.context';
import { InboxProvider } from 'contexts/inbox';
import { LogRocketProvider } from 'contexts/log-rocket.context';
import { DragDropEngine } from 'enums/cookies.enums';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { withTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import './translations/i18n';

const App = () => {
  if (!env.debug_messages) {
    console.debug = () => {};
  }

  if (!env.production) {
    console.warn(
      'Render events will be logged twice when running in dev mode (see React.StrictMode)'
    );
  }

  return (
    <BrowserRouter>
      <GlobalProvider>
        <GlobalContext.Consumer>
          {(globalCx) => (
            <Auth0Provider
              domain={env.integrations.auth0.domain}
              clientId={env.integrations.auth0.clientId}
              authorizationParams={{
                redirect_uri: window.location.origin,
                audience: env.integrations.auth0.audience,
              }}
            >
              {provideCookies(globalCx)}
            </Auth0Provider>
          )}
        </GlobalContext.Consumer>
      </GlobalProvider>
    </BrowserRouter>
  );

  function provideCookies(globalCx: IGlobalContext) {
    return (
      <CookiesProvider>
        <CookiesContext.Consumer>
          {(cookiesCx) => provideAuth(cookiesCx, globalCx)}
        </CookiesContext.Consumer>
      </CookiesProvider>
    );
  }

  function provideAuth(cookiesCx: ICookiesContext, globalCx: IGlobalContext) {
    return (
      <AuthProvider cookiesCx={cookiesCx}>
        <AuthContext.Consumer>
          {(authCx) => {
            const DND_BACKEND = (() => {
              switch (cookiesCx.app.drag_drop_engine) {
                case DragDropEngine.touch: {
                  return TouchBackend;
                }

                default: {
                  return HTML5Backend;
                }
              }
            })();

            return (
              <InboxProvider>
                <DndProvider backend={DND_BACKEND}>
                  <Main
                    globalCx={globalCx}
                    authCx={authCx}
                    cookiesCx={cookiesCx}
                  />

                  {env.identifier !== 'local' && (
                    <LogRocketProvider authCx={authCx} />
                  )}
                </DndProvider>
              </InboxProvider>
            );
          }}
        </AuthContext.Consumer>
      </AuthProvider>
    );
  }
};

export default withTranslation()(App);
