import { EllipseName } from '../enums/ellipses';
import { HitterSafety, HitterSide } from '../enums/hitters.enums';
import {
  EMPTY_PLATE_LOC_COV,
  ICovarianceSummary,
  IEllipse,
  IEllipseLimits,
  IEllipseScaling,
} from '../interfaces/i-ellipses';
import { IHitter } from '../interfaces/i-hitter';
import { IMachineState } from '../interfaces/i-machine-state';
import {
  IPlateLoc,
  IPlateLocExt,
  ISafeLocation,
  ITrajectory,
} from '../interfaces/pitches';
import { IAziAltCoordinate } from '../interfaces/pitches/i-base';
import { IMachineShot } from '../interfaces/training/i-machine-shot';
import { DEG2RAD, MIN_SHOTS_TO_ROTATE, RAD_RIGHT_ANGLE } from './ball.helper';
import { FT_TO_INCHES, getEchelonForm_2x2, mean } from './math.utilities';
import { TrajHelper } from './trajectory.helper';

export const SZ_WIDTH_FT = 17 / 12;

export class EllipseHelper {
  static MAX_SCALING: IEllipseScaling = {
    name: EllipseName.Conf95,
    factor: 5.991,
    opacity: 0.3,
  };

  static MID_SCALING: IEllipseScaling = {
    name: EllipseName.Conf85,
    factor: 3.79,
    opacity: 0.2,
  };

  static MIN_SCALING: IEllipseScaling = {
    name: EllipseName.Conf50,
    factor: 1.386,
    opacity: 0.1,
  };

  // for use with hitter safety tolerances
  static SAFETY_SCALING: IEllipseScaling = {
    name: EllipseName.Conf99,
    factor: 9.21,
    opacity: 0,
    stroke: true,
  };

  static NON_EMPTY_SCALINGS = [
    this.MIN_SCALING,
    this.MID_SCALING,
    this.MAX_SCALING,
    this.SAFETY_SCALING,
  ];

  static rotatePoint = (config: {
    origin: IPlateLoc;
    point: IPlateLoc;
    angle_radians: number;
  }): IPlateLoc => {
    const transPoint: IPlateLoc = {
      plate_x: config.point.plate_x - config.origin.plate_x,
      plate_z: config.point.plate_z - config.origin.plate_z,
    };

    const rotated: IPlateLoc = {
      plate_x:
        transPoint.plate_x * Math.cos(config.angle_radians) -
        transPoint.plate_z * Math.sin(config.angle_radians),
      plate_z:
        transPoint.plate_z * Math.cos(config.angle_radians) +
        transPoint.plate_x * Math.sin(config.angle_radians),
    };

    const output: IPlateLoc = {
      plate_x: rotated.plate_x + config.origin.plate_x,
      plate_z: rotated.plate_z + config.origin.plate_z,
    };

    return output;
  };

  /** result will be <1 if point is inside, 1 if point is on, or >1 if point is outside */
  static checkPointInEllipse = (config: {
    origin: IPlateLoc;
    /** location post rotation */
    point: IPlateLoc;
    ellipse: IEllipse;
    /** used for scaling ellipse size */
    factor: number;
    /** if true, point needs to be rotated about the origin by the negative of ellipse rotation before comparing to the unrotated version of the ellipse */
    isPointRotated: boolean;
  }): number => {
    const urPoint = config.isPointRotated
      ? this.rotatePoint({
          origin: config.origin,
          point: config.point,
          angle_radians: -config.ellipse.angle_radians,
        })
      : config.point;

    /** check if un-rotated point is in un-rotated ellipse */
    const result =
      Math.pow(urPoint.plate_x - config.origin.plate_x, 2) /
        (config.ellipse.majorRadius * config.factor) +
      Math.pow(urPoint.plate_z - config.origin.plate_z, 2) /
        (config.ellipse.minorRadius * config.factor);

    return result;
  };

  static getEllipseContainingPoint = (config: {
    origin: IPlateLoc;
    point: IPlateLoc;
    ellipse: IEllipse;
    /** if true, point will be rotated about the origin by the negative of ellipse rotation before comparing to the unrotated version of the ellipse at different scalings */
    isPointRotated: boolean;
  }): IEllipseScaling | undefined => {
    const [min, mid, max] = [
      this.MIN_SCALING,
      this.MID_SCALING,
      this.MAX_SCALING,
    ].map((scaling) =>
      this.checkPointInEllipse({
        origin: config.origin,
        point: config.point,
        ellipse: config.ellipse,
        isPointRotated: config.isPointRotated,
        factor: scaling.factor,
      })
    );

    if (min <= 1) {
      return this.MIN_SCALING;
    }

    if (mid <= 1) {
      return this.MID_SCALING;
    }

    if (max <= 1) {
      return this.MAX_SCALING;
    }

    /** point is outside of every scaled ellipse */
    return;
  };

  /** flip anything in the quadrant 1 or 4, leave everything else alone */
  static flipQ1AndQ4 = (radians: number): number => {
    if (Math.abs(radians) < RAD_RIGHT_ANGLE) {
      return -radians;
    }

    return radians;
  };

  static getEllipseLimits = (
    ell: IEllipse,
    scaling: number
  ): IEllipseLimits => {
    const cosAngle = Math.cos(ell.angle_radians);
    const sinAngle = Math.sin(ell.angle_radians);

    const majorScaled = Math.sqrt(ell.majorRadius * scaling);
    const minorScaled = Math.sqrt(ell.minorRadius * scaling);

    return {
      absMaxX: Math.sqrt(
        Math.pow(majorScaled, 2) * Math.pow(cosAngle, 2) +
          Math.pow(minorScaled, 2) * Math.pow(sinAngle, 2)
      ),
      absMaxY: Math.sqrt(
        Math.pow(majorScaled, 2) * Math.pow(sinAngle, 2) +
          Math.pow(minorScaled, 2) * Math.pow(cosAngle, 2)
      ),
    };
  };

  /** absolute value, in feet */
  static getSafeHitterInnerX(buffer?: HitterSafety): number | undefined {
    switch (buffer) {
      case HitterSafety.high: {
        return 15 / 12;
      }

      case HitterSafety.medium: {
        return 16.5 / 12;
      }

      case HitterSafety.low: {
        return 18 / 12;
      }

      default: {
        return undefined;
      }
    }
  }

  // returning without any warning message => nothing changed, result can be ignored
  static getSafeHitterLoc = (config: {
    input: IPlateLoc;
    hitter: IHitter;
    limits: IEllipseLimits;
  }): ISafeLocation => {
    const noChange: ISafeLocation = {
      location: config.input,
    };

    if (!config.hitter.side) {
      return noChange;
    }

    if (!config.hitter.safety_buffer) {
      return noChange;
    }

    const maxAbsX = this.getSafeHitterInnerX(config.hitter.safety_buffer);
    if (maxAbsX === undefined) {
      return noChange;
    }

    if (config.hitter.side === HitterSide.LHH) {
      // hitter is on right side of plate view, no problem if plate_x + ellipse limit x is less than (left of) +ve maxAbsX
      if (config.input.plate_x + config.limits.absMaxX < maxAbsX) {
        return noChange;
      }
    }

    if (config.hitter.side === HitterSide.RHH) {
      // hitter is on left side of plate view, no problem if plate_x - ellipse limit x is greater than (right of) -ve maxAbsX
      if (config.input.plate_x - config.limits.absMaxX > -maxAbsX) {
        return noChange;
      }
    }

    const sliderInches = config.input.plate_x * FT_TO_INCHES;
    return {
      // todo: update the following if we need to recommend a safe alternative (i.e. if we need to snap)
      location: config.input,
      warning: `Potentially dangerous horizontal plate location detected (${sliderInches.toFixed(
        0
      )}" with a ${config.hitter.side}).`,
    };
  };

  /** determine if position adjustment needs to be recommended to avoid spray exceeding horizontal limits of strike zone */
  static getSafeEllipseLoc = (config: {
    input: IPlateLoc;
    limits: IEllipseLimits;
  }): ISafeLocation => {
    /** i.e. 17 inches for home plate, halved */
    const HALF_PLATE_FT = 0.71;

    const exceedsRight =
      config.input.plate_x + config.limits.absMaxX > HALF_PLATE_FT;
    const exceedsLeft =
      config.input.plate_x - config.limits.absMaxX < -HALF_PLATE_FT;

    /** both safePosition and limits should be given in feet */
    if (exceedsRight && exceedsLeft) {
      /** middle is safest since the ellipse is too wide */
      const result: ISafeLocation = {
        location: {
          plate_x: 0,
          plate_z: config.input.plate_z,
        },
        warning:
          'Ellipse overlaps both the left and right edges of the strike zone.',
      };
      return result;
    }

    if (exceedsRight) {
      const nextX = HALF_PLATE_FT - config.limits.absMaxX;

      const result: ISafeLocation = {
        location: {
          // avoid over correction by going to middle
          plate_x: nextX - config.limits.absMaxX < -HALF_PLATE_FT ? 0 : nextX,
          plate_z: config.input.plate_z,
        },
        warning: 'Ellipse overlaps the right edge of the strike zone.',
      };
      return result;
    }

    if (exceedsLeft) {
      const nextX = -HALF_PLATE_FT + config.limits.absMaxX;

      const result: ISafeLocation = {
        location: {
          // avoid over correction by going to middle
          plate_x: nextX + config.limits.absMaxX > HALF_PLATE_FT ? 0 : nextX,
          plate_z: config.input.plate_z,
        },
        warning: 'Ellipse overlaps the left edge of the strike zone.',
      };
      return result;
    }

    const noChange: ISafeLocation = {
      location: config.input,
    };

    return noChange;
  };

  static getSafeOverallLoc = (
    config: {
      input: IPlateLoc;
      // whether to check ellipse-only warnings, i.e. if there are no hitter warnings
      ellipseWarnings: boolean;
      ellipse?: IEllipse;
      hitter?: IHitter;
    },
    /** used for scaling ellipse size */
    factor: number = EllipseHelper.SAFETY_SCALING.factor
  ): ISafeLocation => {
    const noChange: ISafeLocation = {
      location: config.input,
    };

    if (!config.ellipse) {
      // nothing can be checked if ellipse is not provided
      return noChange;
    }

    const limits = this.getEllipseLimits(config.ellipse, factor);

    if (config.hitter) {
      const safeHitter = this.getSafeHitterLoc({
        input: config.input,
        hitter: config.hitter,
        limits: limits,
      });

      if (safeHitter.warning) {
        return safeHitter;
      }
    }

    if (config.ellipseWarnings) {
      const safeEllipse = this.getSafeEllipseLoc({
        input: config.input,
        limits: limits,
      });

      if (safeEllipse.warning) {
        return safeEllipse;
      }
    }

    // fallback
    return noChange;
  };

  static getRotatedShots = (config: {
    ms: IMachineState;
    traj: ITrajectory;
    shots: IMachineShot[];
    plate_distance: number;
  }): IMachineShot[] => {
    const refTilt = config.ms.tilt;
    const refYaw = config.ms.yaw;

    return config.shots.map((shot) => {
      const oTraj: ITrajectory = {
        ...shot.traj,
        px: config.ms.px,
        pz: config.ms.pz,
        py: config.plate_distance,
      };

      const delta: IAziAltCoordinate = {
        altitude_rad: (refTilt - shot.actual_ms.tilt) * DEG2RAD,
        azimuth_rad: (refYaw - shot.actual_ms.yaw) * DEG2RAD,
      };

      const rTraj = TrajHelper.getRotatedTrajectory(oTraj, delta);
      const rShot: IMachineShot = {
        ...shot,
        traj: rTraj,
      };

      return rShot;
    });
  };

  static getRotatedLocations = (config: {
    ms: IMachineState;
    traj: ITrajectory;
    shots: IMachineShot[];
    plate_distance: number;
  }): IPlateLocExt[] => {
    return this.getRotatedShots(config).map((s) => {
      const rPlate: IPlateLocExt = {
        ...TrajHelper.getPlateLoc(s.traj),
        _created: s._created,
        _id: s._id,
      };

      return rPlate;
    });
  };

  static getCovarianceSummary = (config: {
    ms: IMachineState;
    traj: ITrajectory;
    shots: IMachineShot[];
    plate_distance: number;
  }): ICovarianceSummary => {
    if (config.shots.length < MIN_SHOTS_TO_ROTATE) {
      return {
        ...EMPTY_PLATE_LOC_COV,
        mean_location: TrajHelper.getPlateLoc(config.traj),
      };
    }

    const rotatedLocs: IPlateLoc[] = EllipseHelper.getRotatedLocations(config);

    const meanX = mean(rotatedLocs.map((l) => l.plate_x));
    const meanZ = mean(rotatedLocs.map((l) => l.plate_z));

    const xDeltas = rotatedLocs.map((l) => l.plate_x - meanX);
    const zDeltas = rotatedLocs.map((l) => l.plate_z - meanZ);

    const nMinus1 = rotatedLocs.length - 1;

    const varXX = mean(
      xDeltas.map((_, i) => xDeltas[i] * xDeltas[i]),
      nMinus1
    );
    const varXZ = mean(
      xDeltas.map((_, i) => xDeltas[i] * zDeltas[i]),
      nMinus1
    );
    const varZZ = mean(
      xDeltas.map((_, i) => zDeltas[i] * zDeltas[i]),
      nMinus1
    );

    const output: ICovarianceSummary = {
      covariance: [
        [varXX, varXZ],
        [varXZ, varZZ],
      ],
      std_location: {
        plate_x: Math.sqrt(varXX),
        plate_z: Math.sqrt(varZZ),
      },
      mean_location: {
        plate_x: meanX,
        plate_z: meanZ,
      },
    };

    const qA = 1;
    const qB = -varXX - varZZ;
    const qC = varXX * varZZ - varXZ * varXZ;

    // discriminant for eigen values
    const eigDisc = Math.pow(qB, 2) - 4 * qA * qC;

    if (eigDisc < 0) {
      console.warn({
        event:
          'getCovarianceSummary encountered a negative e-discriminant, skipping ellipse computation',
        varXX,
        varXZ,
        varZZ,
        eDisc: eigDisc,
      });

      return output;
    }

    // first is max, second is min
    const sortedEigens = [
      (-qB + Math.sqrt(eigDisc)) / 2,
      (-qB - Math.sqrt(eigDisc)) / 2,
    ];

    // getEchelonForm_2x2 will return undefined if it fails for any reason
    const echelonMxs = sortedEigens.map((value) =>
      getEchelonForm_2x2([
        [varXX - value, varXZ],
        [varXZ, varZZ - value],
      ])
    );

    const maxEigVector = echelonMxs[0]?.[0];

    if (!maxEigVector) {
      console.warn({
        event:
          'getCovarianceSummary failed to compute echelon form, skipping ellipse computation',
        varXX,
        varXZ,
        varZZ,
        eigDisc,
        sortedEigens,
        echelonMxs,
      });

      return output;
    }

    // descending absolute value
    maxEigVector.sort((a, b) => (Math.abs(a) > Math.abs(b) ? -1 : 1));

    // covariance: [
    //   [a = varXX, b = varXZ],
    //   [b = varXZ, c = varZZ],
    // ],
    const covDisc = Math.pow((varXX - varZZ) / 2, 2) + Math.pow(varXZ, 2);
    const lambda1 = (varXX + varZZ) / 2 + Math.sqrt(covDisc);

    // note: lambda2 is not used but provided for reference
    // const lambda2 = (varXX + varZZ) / 2 - Math.sqrt(disc);

    const angle_radians = ((): number => {
      if (varXZ === 0) {
        return varXX >= varZZ ? 0 : Math.PI / 2;
      }

      const angle = Math.atan2(lambda1 - varXX, varXZ);

      if (varXZ < 0) {
        // turn counter-clockwise
        return -angle;
      }

      // turn clockwise
      return angle;
    })();

    output.ellipse = {
      angle_radians: angle_radians,
      majorRadius: sortedEigens[0],
      minorRadius: sortedEigens[1],
      eigVector: maxEigVector,
    };

    return output;
  };
}
