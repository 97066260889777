import { NotifyHelper } from 'classes/helpers/notify.helper';
import { EditSessionDialog } from 'components/common/sessions/dialogs/edit-session';
import { VisualizeSessionDialog } from 'components/common/sessions/dialogs/visualize-session';
import { ISettingsDialog } from 'components/common/settings-dialog';
import { IAuthContext } from 'contexts/auth.context';
import format from 'date-fns-tz/format';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { LOCAL_TIMEZONE } from 'enums/env';
import { SessionDialogMode } from 'enums/session.enums';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import {
  METERS_TO_FT,
  METERS_TO_INCHES,
  MPS_TO_MPH,
} from 'lib_ts/classes/math.utilities';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { ICombinedData } from 'lib_ts/interfaces/csv/exports/i-session-fires';
import {
  IPitchStat,
  IPitchStatsFilters,
} from 'lib_ts/interfaces/data/i-pitch-stats';
import { IMSTargetEventData } from 'lib_ts/interfaces/i-session-event';
import { ISessionSummary } from 'lib_ts/interfaces/i-session-summary';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { IRapsodoShot } from 'lib_ts/interfaces/training/i-rapsodo-shot';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { DataService } from 'services/data.service';
import {
  MAX_USER_SESSIONS,
  SessionEventsService,
} from 'services/session-events.service';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm:ss.SSS z';

interface IDialogConfig {
  session: string;
  mode: SessionDialogMode;
}

export interface ISessionEventsContext {
  sessions: ISessionSummary[];
  sessionsOptions: {
    [key: string]: string[];
  };

  selectedSession?: ISessionSummary;

  loading: boolean;

  fired: number;
  readonly increaseFired: () => void;

  /** open settings from anywhere, providing an undefined config will close it */
  readonly setSettingsDialog: (config?: ISettingsDialog) => void;

  /** undefined value =>  hidden */
  settingsDialog?: ISettingsDialog;

  /** pops the session details editor from anywhere */
  readonly showDialog: (config: IDialogConfig) => void;

  readonly getShotsData: (
    session: string,
    silently?: boolean
  ) => Promise<ICombinedData[]>;

  readonly getPitchStatsData: (
    filters: IPitchStatsFilters,
    silenty?: boolean
  ) => Promise<IPitchStat[]>;

  readonly refresh: () => void;

  readonly selectSession: (session: ISessionSummary) => void;
}

const DEFAULT: ISessionEventsContext = {
  sessions: [],
  sessionsOptions: {},

  loading: false,

  fired: 0,
  increaseFired: () => console.debug('not init'),

  setSettingsDialog: () => console.debug('not init'),

  showDialog: () => console.debug('not init'),
  getShotsData: () => new Promise(() => console.debug('not init')),
  getPitchStatsData: () => new Promise(() => console.debug('not init')),
  refresh: () => console.debug('not init'),
  selectSession: () => console.debug('not init'),
};

const convertUnits = (factor: number, value?: number) => {
  if (value !== undefined && !isNaN(value)) {
    return factor * value;
  } else {
    console.warn(
      `Non-numeric value cannot be converted, returning original value: ${value}`
    );
    return value;
  }
};

export const SessionEventsContext = createContext(DEFAULT);

const getSessionsOptions = (
  items: ISessionSummary[]
): {
  [key: string]: any[];
} => {
  if (items) {
    return {
      name: ArrayHelper.unique(items.map((m) => m.name)).sort(
        (a: string, b: string) => a.localeCompare(b)
      ),

      session: ArrayHelper.unique(items.map((m) => m.session)).sort(
        (a: string, b: string) => a.localeCompare(b)
      ),

      start: ArrayHelper.unique(
        items.map((m) => lightFormat(parseISO(m.start), 'yyyy-MM-dd'))
      ).sort((a: string, b: string) => a.localeCompare(b)),
    };
  } else {
    return {};
  }
};

interface IProps {
  authCx: IAuthContext;
  children: ReactNode;
}

export const SessionEventsProvider: FC<IProps> = (props) => {
  const [_sessions, _setSessions] = useState(DEFAULT.sessions);
  const [_sessionsOptions, _setSessionsOptions] = useState(
    getSessionsOptions(DEFAULT.sessions)
  );

  const [_selectedSession, _setSelectedSession] = useState(
    DEFAULT.selectedSession
  );

  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_lastFetched, _setLastFetched] = useState<Date | undefined>();

  const [_settingsDialog, _setSettingsDialog] = useState<
    ISettingsDialog | undefined
  >();
  const [_dialog, _setDialog] = useState<IDialogConfig | undefined>();

  /** used to count fire events sent for the current session */
  const [_fired, _setFired] = useState(DEFAULT.fired);

  const state: ISessionEventsContext = {
    sessions: _sessions,
    sessionsOptions: _sessionsOptions,

    selectedSession: _selectedSession,

    loading: _loading,

    settingsDialog: _settingsDialog,

    setSettingsDialog: (config) => {
      _setSettingsDialog(config);
    },

    showDialog: (config) => {
      if (config.mode === SessionDialogMode.none) {
        _setDialog(undefined);
        return;
      }

      _setDialog(config);
    },

    fired: _fired,
    increaseFired: () => {
      _setFired(_fired + 1);

      /** notify user after every 50th shot */
      if (_fired !== 0 && _fired % 50 === 0) {
        NotifyHelper.info({
          message_md: t('common.you-have-fired-x-shots', { x: _fired }),
        });
        NotifyHelper.haveFun();
      }
    },

    getShotsData: async (session, silently) => {
      try {
        _setLoading(true);

        if (!silently) {
          NotifyHelper.info({
            message_md: t('common.please-wait-request-processing'),
          });
        }

        const mstargets =
          await SessionEventsService.getExportShotsForSession(session);

        const output: ICombinedData[] = [];

        mstargets
          .filter((m) => m.fires && m.fires.length > 0)
          .forEach((target) => {
            const targetData = target.data as Partial<IMSTargetEventData>;

            const seams = targetData.bs
              ? BallHelper.getBallStateSeams(targetData.bs)
              : undefined;

            const targetCreated = parseISO(target._created);

            const baseRow: ICombinedData = {
              Trajekt: {
                MachineID: target.machine,
                Session: session,
                Date: format(targetCreated, DATE_FORMAT, {
                  timeZone: LOCAL_TIMEZONE,
                }),
                Timestamp: format(targetCreated, TIME_FORMAT, {
                  timeZone: LOCAL_TIMEZONE,
                }),
                ShotNumber: 0, // will be overwritten later

                BallType: targetData.ms?.ball_type,
                BuildPriority: targetData.build_priority,

                InGame: 'N',
                Training: 'N',
                Valid: 'Y', // will be overwritten later as per fire event
                ErrorMsg: '', // will be overwritten later as per fire event

                PitchList: targetData.list,
                PitchTitle: targetData.pitch_title,
                PitchType: targetData.pitch_type,
                PitcherFullName: targetData.pitcher,
                VideoTitle: targetData.video_title,

                /** pitch and video details from target */
                ReleaseX: targetData.bs?.px,
                ReleaseY: targetData.bs?.py,
                ReleaseZ: targetData.bs?.pz,
                ReleaseV: targetData.bs ? -targetData.bs?.vy : undefined,

                SpinX: targetData.bs?.wx,
                SpinY: targetData.bs?.wy,
                SpinZ: targetData.bs?.wz,

                SeamLat: seams?.latitude_deg,
                SeamLon: seams?.longitude_deg,

                TargetPlateX: targetData.plate?.plate_x,
                TargetPlateZ: targetData.plate?.plate_z,

                Hitter: undefined,

                RapsodoValidated: undefined,
              },

              /** ensures the column is printed/accounted for in CSV output, even if first row was only from mstarget */
              Rapsodo: {
                PITCH_GyroDegree: undefined,
                PITCH_HBSpin: undefined,
                PITCH_HBTrajectory: undefined,
                PITCH_HorizontalAngle: undefined,
                PITCH_PlayerID: undefined,
                PITCH_ReleaseExtension: undefined,
                PITCH_ReleaseHeight: undefined,
                PITCH_ReleaseSide: undefined,
                PITCH_ReleaseTime: undefined,
                PITCH_Speed: undefined,
                PITCH_SpinAxis: undefined,
                PITCH_SpinEfficiency: undefined,
                PITCH_Strike: undefined,
                PITCH_StrikeZoneHeight: undefined,
                PITCH_StrikeZoneSide: undefined,
                PITCH_StrikeZoneTime: undefined,
                PITCH_TotalSpin: undefined,
                PITCH_TrueSpin: undefined,
                PITCH_VBSpin: undefined,
                PITCH_VBTrajectory: undefined,
                PITCH_VerticalAngle: undefined,
                PITCH_ZoneTime: undefined,

                HIT_Distance: undefined,
                HIT_LaunchAngle: undefined,
                HIT_ExitSpeed: undefined,
              },
            };

            /** fallback for no rapsodo data, check if machine was fired */
            const fires = target.fires ? target.fires : [];

            /** always create a record for each fire event, using shot details where possible */
            fires.forEach((f) => {
              const rapsodo = f.rapsodo
                ? (f.rapsodo[0] as IRapsodoShot)
                : undefined;
              const fireCreated = parseISO(f._created);

              const shot = f.shot ? (f.shot[0] as IMachineShot) : undefined;

              const targetBreaks =
                targetData.breaks ??
                (targetData.traj
                  ? TrajHelper.getBreaks(targetData.traj)
                  : undefined);

              const actualBreaks = (() => {
                if (!shot?.traj) {
                  return undefined;
                }

                return TrajHelper.getBreaks(shot.traj);
              })();

              const row: ICombinedData = {
                Trajekt: {
                  ...baseRow.Trajekt,

                  /** overwrite mstarget date + time with shot's date + time */
                  Date: format(fireCreated, DATE_FORMAT, {
                    timeZone: LOCAL_TIMEZONE,
                  }),
                  Timestamp: format(fireCreated, TIME_FORMAT, {
                    timeZone: LOCAL_TIMEZONE,
                  }),

                  InGame: f.data.in_game ? 'Y' : 'N',
                  Training: f.data.training ? 'Y' : 'N',
                  Valid: f.data.rejected ? 'N' : 'Y',
                  ErrorMsg: f.data.rejected_msg ?? '',

                  TargetVB: targetBreaks?.zInches,
                  TargetHB: targetBreaks ? -targetBreaks.xInches : undefined,

                  ActualVB: actualBreaks?.zInches,
                  ActualHB: actualBreaks ? -actualBreaks.xInches : undefined,

                  Hitter: f.data.hitterExt?.name,

                  RapsodoValidated:
                    rapsodo &&
                    [
                      rapsodo.PITCH_StrikePositionSideConfidence,
                      rapsodo.PITCH_StrikePositionHeightConfidence,
                      rapsodo.PITCH_SpinConfidence,
                    ].every((v) => v > 0.9)
                      ? 'Y'
                      : 'N',
                },

                Rapsodo: rapsodo
                  ? {
                      /** rapsodo details */
                      PITCH_GyroDegree: rapsodo.PITCH_GyroDegree,
                      PITCH_HBSpin: convertUnits(
                        METERS_TO_INCHES,
                        rapsodo.PITCH_HBSpin
                      ),
                      PITCH_HBTrajectory: convertUnits(
                        METERS_TO_INCHES,
                        rapsodo.PITCH_HBTrajectory
                      ),
                      PITCH_HorizontalAngle: rapsodo.PITCH_HorizontalAngle,
                      PITCH_PlayerID: rapsodo.PITCH_PlayerID,
                      PITCH_ReleaseExtension: convertUnits(
                        METERS_TO_FT,
                        rapsodo.PITCH_ReleaseExtension
                      ),
                      PITCH_ReleaseHeight: convertUnits(
                        METERS_TO_FT,
                        rapsodo.PITCH_ReleaseHeight
                      ),
                      PITCH_ReleaseSide: convertUnits(
                        METERS_TO_FT,
                        rapsodo.PITCH_ReleaseSide
                      ),
                      PITCH_ReleaseTime: rapsodo.PITCH_ReleaseTime,
                      PITCH_Speed: convertUnits(
                        MPS_TO_MPH,
                        rapsodo.PITCH_Speed
                      ),
                      PITCH_SpinAxis: rapsodo.PITCH_SpinAxis,
                      PITCH_SpinEfficiency: rapsodo.PITCH_SpinEfficiency,
                      PITCH_Strike: rapsodo.PITCH_Strike,
                      PITCH_StrikeZoneHeight: convertUnits(
                        METERS_TO_INCHES,
                        rapsodo.PITCH_StrikeZoneHeight
                      ),
                      PITCH_StrikeZoneSide: convertUnits(
                        METERS_TO_INCHES,
                        rapsodo.PITCH_StrikeZoneSide
                      ),
                      PITCH_StrikeZoneTime: rapsodo.PITCH_StrikeZoneTime,
                      PITCH_TotalSpin: rapsodo.PITCH_TotalSpin,
                      PITCH_TrueSpin: rapsodo.PITCH_TrueSpin,
                      PITCH_VBSpin: convertUnits(
                        METERS_TO_INCHES,
                        rapsodo.PITCH_VBSpin
                      ),
                      PITCH_VBTrajectory: convertUnits(
                        METERS_TO_INCHES,
                        rapsodo.PITCH_VBTrajectory
                      ),
                      PITCH_VerticalAngle: rapsodo.PITCH_VerticalAngle,
                      PITCH_ZoneTime: rapsodo.PITCH_ZoneTime,

                      HIT_Distance: rapsodo.HIT_Distance
                        ? convertUnits(METERS_TO_FT, rapsodo.HIT_Distance)
                        : undefined,
                      HIT_LaunchAngle: rapsodo.HIT_LaunchAngle,
                      HIT_ExitSpeed: rapsodo.HIT_ExitSpeed
                        ? convertUnits(MPS_TO_MPH, rapsodo.HIT_ExitSpeed)
                        : undefined,
                    }
                  : baseRow.Rapsodo,
              };

              output.push(row);
            });
          });

        /** fix shot numbers */
        output.forEach((row, i) => (row.Trajekt.ShotNumber = i + 1));

        return output;
      } catch (e) {
        console.error(e);
        NotifyHelper.error({
          message_md:
            'Encountered an error while processing your request. Please try again later.',
        });

        return [];
      } finally {
        _setLoading(false);
      }
    },

    getPitchStatsData: async (filters, silently) => {
      try {
        _setLoading(true);

        if (!silently) {
          NotifyHelper.info({
            message_md: t('common.please-wait-request-processing'),
          });
        }

        return await DataService.getPitchStats(filters);
      } catch (e) {
        console.error(e);
        NotifyHelper.error({
          message_md:
            'Encountered an error while processing your request. Please try again later.',
        });
        return [];
      } finally {
        _setLoading(false);
      }
    },
    refresh: () => _setLastFetched(new Date()),
    selectSession: (session) => _setSelectedSession(session),
  };

  useEffect(() => {
    if (props.authCx.current.auth) {
      /** set fire count from db result when session changes */
      SessionEventsService.getFireCount(props.authCx.current.session).then(
        (count) => _setFired(count)
      );
    }
  }, [props.authCx.current.auth, props.authCx.current.session]);

  /** fetch the data at load */
  useEffect(() => {
    if (!_lastFetched) {
      return;
    }

    (async (): Promise<void> => {
      _setLoading(true);

      const sessions = await SessionEventsService.getUserSessions(
        props.authCx.current.userID,
        MAX_USER_SESSIONS
      );

      if (sessions) {
        _setSessions(sessions);
      } else {
        console.warn('Session events failed to load');
        _setSessions([]);
      }

      _setLoading(false);
    })();
  }, [_lastFetched]); // dependency list => run whenever lastFetched is changed

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh only once logged in/successfully resumed */
    if (props.authCx.current.auth && props.authCx.current.session) {
      _setLastFetched(new Date());
    }
  }, [props.authCx.current.auth, props.authCx.current.session]);

  useEffect(() => {
    _setSessionsOptions(getSessionsOptions(_sessions));
  }, [_sessions]); // dependency list => run whenever sessions is changed

  return (
    <SessionEventsContext.Provider value={state}>
      {props.children}

      {_dialog?.mode === SessionDialogMode.edit && (
        <EditSessionDialog
          identifier="EditSessionDialog"
          session={_dialog.session}
          fires={
            props.authCx.current.session === _dialog.session
              ? _fired
              : _sessions.find((s) => s.session === _dialog.session)?.fires ?? 0
          }
          onChanged={() => _setLastFetched(new Date())}
          onClose={() => _setDialog(undefined)}
        />
      )}

      {_dialog?.mode === SessionDialogMode.visualize && (
        <VisualizeSessionDialog
          identifier="VisualizeSessionDialog"
          session={_dialog.session}
          onClose={() => _setDialog(undefined)}
        />
      )}
    </SessionEventsContext.Provider>
  );
};
