import { RouteHelper } from 'classes/helpers/route.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ApiKeysManagement } from 'components/sections/admin-portal/api-keys';
import { ErrorTypesTable } from 'components/sections/admin-portal/error-types/table';
import { MachineModelsTable } from 'components/sections/admin-portal/machine-models/table';
import { MachinesTable } from 'components/sections/admin-portal/machines/table';
import { ServerControlsTab } from 'components/sections/admin-portal/server-controls';
import { TeamsTable } from 'components/sections/admin-portal/teams/table';
import { UsersTable } from 'components/sections/admin-portal/users/table';
import {
  MachineModelsContext,
  MachineModelsProvider,
} from 'contexts/admin/machine-models.context';
import {
  IMachinesContext,
  MachinesContext,
  MachinesProvider,
} from 'contexts/admin/machines.context';
import {
  ITeamsContext,
  TeamsContext,
  TeamsProvider,
} from 'contexts/admin/teams.context';
import {
  IUsersContext,
  UsersContext,
  UsersProvider,
} from 'contexts/admin/users.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { AdminRoute, SectionName } from 'enums/route.enums';
import { UserRole } from 'lib_ts/enums/auth.enums';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

enum TabKey {
  Users = 'Users',
  Machines = 'Machines',
  Teams = 'Teams',
  MachineModels = 'MachineModels',
  ErrorTypes = 'ErrorTypes',
  ApiKeys = 'ApiKeys',
  ServerControls = 'ServerControls',
}

interface IProps {}

interface IState {}

const DEFAULT_STATE: IState = {};

export class AdminPortal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.renderTabs = this.renderTabs.bind(this);
    this.provideEverything = this.provideEverything.bind(this);
  }

  private renderTabs(
    cookiesCx: ICookiesContext,
    authCx: IAuthContext,
    teamsCx: ITeamsContext,
    machinesCx: IMachinesContext,
    usersCx: IUsersContext
  ) {
    const isSuperAdmin = authCx.current.role === UserRole.admin;

    const redirect = (
      <Navigate
        to={RouteHelper.section(SectionName.AdminPortal, AdminRoute.Users)}
        replace
      />
    );

    return (
      <Routes>
        <Route path="" element={redirect} />

        <Route
          path={RouteHelper.fragment(AdminRoute.Users)}
          element={
            <UsersTable
              cookiesCx={cookiesCx}
              authCx={authCx}
              teamsCx={teamsCx}
              machinesCx={machinesCx}
              usersCx={usersCx}
            />
          }
        />

        <Route
          path={RouteHelper.fragment(AdminRoute.Machines)}
          element={
            <MachinesTable
              cookiesCx={cookiesCx}
              authCx={authCx}
              teamsCx={teamsCx}
              machinesCx={machinesCx}
              usersCx={usersCx}
            />
          }
        />

        <Route
          path={RouteHelper.fragment(AdminRoute.Teams)}
          element={
            isSuperAdmin ? (
              <TeamsTable
                cookiesCx={cookiesCx}
                authCx={authCx}
                teamsCx={teamsCx}
                machinesCx={machinesCx}
                usersCx={usersCx}
              />
            ) : (
              redirect
            )
          }
        />

        <Route
          path={RouteHelper.fragment(AdminRoute.MachineModels)}
          element={
            isSuperAdmin ? (
              <MachineModelsContext.Consumer>
                {(modelsCx) => (
                  <MachineModelsTable
                    cookiesCx={cookiesCx}
                    authCx={authCx}
                    machinesCx={machinesCx}
                    machineModelsCx={modelsCx}
                  />
                )}
              </MachineModelsContext.Consumer>
            ) : (
              redirect
            )
          }
        />

        <Route
          path={RouteHelper.fragment(AdminRoute.ErrorTypes)}
          element={
            isSuperAdmin ? <ErrorTypesTable cookiesCx={cookiesCx} /> : redirect
          }
        />

        <Route
          path={RouteHelper.fragment(AdminRoute.ApiKeys)}
          element={<ApiKeysManagement cookiesCx={cookiesCx} authCx={authCx} />}
        />

        <Route
          path={RouteHelper.fragment(AdminRoute.ServerControls)}
          element={
            isSuperAdmin ? <ServerControlsTab authCx={authCx} /> : redirect
          }
        />

        <Route path="*" element={redirect} />
      </Routes>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="AdminPortal">
        {this.provideEverything()}
      </ErrorBoundary>
    );
  }

  private provideEverything() {
    return (
      <CookiesContext.Consumer>
        {(cookiesCx) => (
          <AuthContext.Consumer>
            {(authCx) => (
              <TeamsProvider authCx={authCx}>
                <TeamsContext.Consumer>
                  {(teamsCx) => (
                    <MachinesProvider authCx={authCx}>
                      <MachinesContext.Consumer>
                        {(machinesCx) => (
                          <UsersProvider authCx={authCx}>
                            <UsersContext.Consumer>
                              {(usersCx) => (
                                <MachineModelsProvider
                                  authCx={authCx}
                                  machinesCx={machinesCx}
                                >
                                  {this.renderTabs(
                                    cookiesCx,
                                    authCx,
                                    teamsCx,
                                    machinesCx,
                                    usersCx
                                  )}
                                </MachineModelsProvider>
                              )}
                            </UsersContext.Consumer>
                          </UsersProvider>
                        )}
                      </MachinesContext.Consumer>
                    </MachinesProvider>
                  )}
                </TeamsContext.Consumer>
              </TeamsProvider>
            )}
          </AuthContext.Consumer>
        )}
      </CookiesContext.Consumer>
    );
  }
}
