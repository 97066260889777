import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Box, Button, Callout, Flex, Heading, Text } from '@radix-ui/themes';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import {
  RADIX,
  RadixCalloutVariant,
  RadixColor,
  RadixFlexJustify,
} from 'lib_ts/enums/radix-ui';
import ReactMarkdown from 'react-markdown';

interface IProps {
  header?: string;

  /** will be rendered via Callout.Text */
  text?: string;
  /** will be rendered via react markdown in a box */
  text_md?: string;
  content?: React.ReactNode;
  icon?: JSX.Element;
  color?: RadixColor;
  variant?: RadixCalloutVariant;
  hideIcon?: boolean;
  maxWidth?: boolean;

  actions?: IMenuAction[];

  justifyIcon?: RadixFlexJustify;
}

export const CommonCallout = (props: IProps) => (
  <Callout.Root
    variant={props.variant}
    color={props.color ?? RADIX.COLOR.WARNING}
    style={props.maxWidth ? { maxWidth: '600px' } : undefined}
  >
    <Flex gap={RADIX.FLEX.GAP.MD}>
      {!props.hideIcon && (
        <Flex
          direction="column"
          justify={props.justifyIcon ?? 'start'}
          pt="1"
          pb="1"
        >
          {props.icon ?? <ExclamationTriangleIcon />}
        </Flex>
      )}

      <Flex
        flexGrow="1"
        direction="column"
        gap={RADIX.FLEX.GAP.SM}
        justify="start"
      >
        {props.header && (
          <Heading size={RADIX.HEADING.SIZE.SM}>{t(props.header)}</Heading>
        )}

        {props.text && <Text>{t(props.text)}</Text>}

        {props.text_md && <ReactMarkdown>{t(props.text_md)}</ReactMarkdown>}

        {props.content}
      </Flex>

      {props.actions && (
        <Flex direction="column" gap={RADIX.FLEX.GAP.SM} justify="center">
          {props.actions.map((a, i) => (
            <Box key={i}>
              <Button
                title={a.tooltip}
                disabled={a.disabled}
                color={a.color ?? props.color ?? RADIX.COLOR.NEUTRAL}
                variant={a.variant ?? 'soft'}
                className="btn-block"
                onClick={a.onClick}
              >
                {t(a.label)}
              </Button>
            </Box>
          ))}
        </Flex>
      )}
    </Flex>
  </Callout.Root>
);
