import { Box, Flex, Text } from '@radix-ui/themes';
import { ModelPredictionHelper } from 'classes/helpers/model-prediction.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { lightFormat, parseISO } from 'date-fns';
import { MODEL_COLORS } from 'enums/charts.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IModelPrediction } from 'lib_ts/interfaces/modelling/i-eval-models';
import React from 'react';
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from 'recharts';
import { CommonDetails } from '../details';

interface IRadarDatum {
  category: string;
  best90: number;
  median: number;
  worst10: number;
}

interface IProps {
  timestamp?: string;
  prediction?: IModelPrediction;
  showRaw?: boolean;
}

interface IState {
  data: IRadarDatum[];
}

export class ModelPredictionRadar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const pred = this.props.prediction;

    const [best90, median, worst10] = [
      ModelPredictionHelper.getPercent(pred?.mean_best_90p),
      ModelPredictionHelper.getPercent(pred?.median_absolute_err),
      ModelPredictionHelper.getPercent(pred?.mean_worst_10p),
    ];

    const modelData: IRadarDatum[] = [
      {
        category: 'vx',
        best90: Math.round(best90.vx * 10_000) / 100,
        median: Math.round(median.vx * 10_000) / 100,
        worst10: Math.round(worst10.vx * 10_000) / 100,
      },
      {
        category: 'vy',
        best90: Math.round(best90.vy * 10_000) / 100,
        median: Math.round(median.vy * 10_000) / 100,
        worst10: Math.round(worst10.vy * 10_000) / 100,
      },
      {
        category: 'vz',
        best90: Math.round(best90.vz * 10_000) / 100,
        median: Math.round(median.vz * 10_000) / 100,
        worst10: Math.round(worst10.vz * 10_000) / 100,
      },
      {
        category: 'wx',
        best90: Math.round(best90.wx * 10_000) / 100,
        median: Math.round(median.wx * 10_000) / 100,
        worst10: Math.round(worst10.wx * 10_000) / 100,
      },
      {
        category: 'wy',
        best90: Math.round(best90.wy * 10_000) / 100,
        median: Math.round(median.wy * 10_000) / 100,
        worst10: Math.round(worst10.wy * 10_000) / 100,
      },
      {
        category: 'wz',
        best90: Math.round(best90.wz * 10_000) / 100,
        median: Math.round(median.wz * 10_000) / 100,
        worst10: Math.round(worst10.wz * 10_000) / 100,
      },
      {
        category: 'break_x',
        best90:
          best90.break_x_ft >= 0
            ? Math.round(best90.break_x_ft * 10_000) / 100
            : 0,
        median:
          median.break_x_ft >= 0
            ? Math.round(median.break_x_ft * 10_000) / 100
            : 0,
        worst10:
          worst10.break_x_ft >= 0
            ? Math.round(worst10.break_x_ft * 10_000) / 100
            : 0,
      },
      {
        category: 'break_z',
        best90:
          best90.break_z_ft >= 0
            ? Math.round(best90.break_z_ft * 10_000) / 100
            : 0,
        median:
          median.break_z_ft >= 0
            ? Math.round(median.break_z_ft * 10_000) / 100
            : 0,
        worst10:
          worst10.break_z_ft >= 0
            ? Math.round(worst10.break_z_ft * 10_000) / 100
            : 0,
      },
    ];

    this.state = {
      data: modelData,
    };
  }

  render() {
    return (
      <ErrorBoundary componentName="ModelPerformanceRadar">
        <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
          <Flex gap={RADIX.FLEX.GAP.LG}>
            <Box flexGrow="1">
              <ResponsiveContainer width="100%" height={300}>
                <RadarChart data={this.state.data}>
                  <PolarGrid />

                  <PolarAngleAxis dataKey="category" />

                  <PolarRadiusAxis domain={[0, 100]} />

                  <Radar
                    name="Best 90%"
                    dataKey="best90"
                    stroke={MODEL_COLORS[0]}
                    fill={MODEL_COLORS[0]}
                    fillOpacity={0.5}
                  />

                  <Radar
                    name="Median Error"
                    dataKey="median"
                    stroke={MODEL_COLORS[2]}
                    fill={MODEL_COLORS[2]}
                    fillOpacity={0.5}
                  />

                  <Radar
                    name="Worst 10%"
                    dataKey="worst10"
                    stroke={MODEL_COLORS[1]}
                    fill={MODEL_COLORS[1]}
                    fillOpacity={0.5}
                  />

                  <Legend />
                </RadarChart>
              </ResponsiveContainer>

              {this.props.timestamp && (
                <Text size={RADIX.TEXT.SIZE.SM}>
                  * as of{' '}
                  {lightFormat(parseISO(this.props.timestamp), 'yyyy-MM-dd')}
                </Text>
              )}
            </Box>
            <Box>
              <Text>
                The model performance metric details how accurately the
                machine's control model replicates the desired pitches. Vy shows
                how accurately we control the pitch's output speed. This is the
                most important aspect and ideally should be well under 1 mph.
                Wx, Wy, and Wz show how accurately we replicate spin. These are
                also important and should be under 300 rpm. Vx and Vz are the
                least important aspects since they are corrected for in
                training.
              </Text>

              {this.props.showRaw && (
                <Text>See "Raw Data" for specific details.</Text>
              )}
            </Box>
          </Flex>

          {this.props.showRaw && (
            <CommonDetails summary="Raw Data">
              <pre>{JSON.stringify(this.state.data, null, 2)}</pre>
            </CommonDetails>
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
