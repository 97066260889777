import { Button } from '@radix-ui/themes';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { IButtonInput } from 'interfaces/forms/button';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { CommonInputLabel } from './label';

export const CommonFormButton = (props: IButtonInput) => (
  <CommonInputWrapper {...props}>
    <CommonInputLabel label=" " />

    <Button
      size={RADIX.BUTTON.SIZE.TABLE}
      title={props.tooltip}
      disabled={props.disabled}
      className={props.className}
      color={props.inputColor}
      variant={props.variant}
      onClick={props.onClick}
    >
      {props.icon} {props.label && t(props.label)} {props.suffixIcon}
    </Button>

    <CommonInputHint {...props} />
  </CommonInputWrapper>
);
