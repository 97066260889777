export enum HitterSide {
  LHH = 'LHH',
  RHH = 'RHH',
}

export enum HitterSafety {
  high = 'High',
  medium = 'Medium',
  low = 'Low',
}
