import { Box, Heading, Separator } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonChecklist } from 'components/common/checklist';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonTextInput } from 'components/common/form/text';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { ITeamsContext } from 'contexts/admin/teams.context';
import { IUsersContext } from 'contexts/admin/users.context';
import { IAuthContext } from 'contexts/auth.context';
import { IFullDialog } from 'interfaces/i-dialogs';
import { LoginDomain, UserRole, UserStatus } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IUserPermissions } from 'lib_ts/interfaces/i-permissions';
import { IUser } from 'lib_ts/interfaces/i-user';
import React from 'react';

const COMPONENT_NAME = 'EditUserDialog';

const TRAJEKT_DOMAINS = ['trajektsports.com'];

const getEmailDomain = (email?: string) => {
  try {
    if (!email) {
      throw new Error('Invalid email: cannot be empty');
    } else {
      const atIndex = email.lastIndexOf('@');
      if (atIndex === -1) {
        throw new Error('Invalid email: does not contain "@" symbol');
      } else {
        return email.substring(atIndex + 1);
      }
    }
  } catch (e) {
    console.error(e);
    return '';
  }
};

const LOGIN_DOMAINS: LoginDomain[] = [
  LoginDomain.Local,
  LoginDomain.Dev,
  LoginDomain.Prod,
  LoginDomain.Staging,
];

const USER_STATUSES: UserStatus[] = [
  UserStatus.unverified,
  UserStatus.verified,
];

interface IProps {
  /** undefined means we're creating a new user */
  id?: string;

  authCx: IAuthContext;
  teamsCx: ITeamsContext;
  machinesCx: IMachinesContext;
  usersCx: IUsersContext;

  onClose: () => void;
}

type SharedListParent = 'teams' | 'team-machines';

const SHARED_LIST_PARENTS: IOption[] = [
  { label: 'Team Lists', value: 'teams' },
  { label: 'Machine Lists', value: 'team-machines' },
];

interface IState {
  user: Partial<IUser>;
  lists: SharedListParent[];
  permissions: Partial<IUserPermissions>;
}

export class EditUserDialog extends React.Component<IProps, IState> {
  readonly USER_ROLES: IOption[];

  constructor(props: IProps) {
    super(props);

    const user: Partial<IUser> = (() => {
      if (props.id) {
        /** updating an existing user */
        const found = props.usersCx.users.find((u) => u._id === props.id);
        if (!found) {
          throw new Error(`Could not find user with _id ${props.id}`);
        } else {
          found.password = '';
          return found;
        }
      } else {
        /** creating a new user, limit the domain to prod by default */
        return {
          domains: [LoginDomain.Prod],
        };
      }
    })();

    const roles: IOption[] = [
      {
        value: UserRole.basic,
        label: 'settings.user',
      },
      {
        value: UserRole.team_admin,
        label: 'settings.team-admin',
      },
    ];

    if (props.authCx.current.role === UserRole.admin) {
      /** only super admins can see/use the super admin option */
      if (
        user.role === UserRole.admin ||
        TRAJEKT_DOMAINS.includes(getEmailDomain(user.email))
      ) {
        /** the user is already a super admin; otherwise only Trajekt employees are eligible to become super-admins */
        roles.push({
          value: UserRole.admin,
          label: 'settings.super-admin',
        });
      }
    }

    this.USER_ROLES = roles;

    const lists: SharedListParent[] = [];

    if (user.permissions?.machine_lists) {
      lists.push('team-machines');
    }

    if (user.permissions?.team_lists) {
      lists.push('teams');
    }

    /** make a copy to manipulate via forms */
    this.state = {
      user: {
        ...user,
      },

      lists: lists,

      permissions: user.permissions ?? {},
    };

    this.isNew = this.isNew.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderSuperAdminInputs = this.renderSuperAdminInputs.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);
  }

  private isNew(): boolean {
    if (this.state.user) {
      return !this.state.user._id;
    }

    return true;
  }

  private updateMetadata(model: Partial<IUser>) {
    const user = {
      ...this.state.user,
      ...model,
    };

    this.setState({ user });
  }

  private renderContent() {
    return (
      <CommonFormGrid columns={2}>
        <CommonTextInput
          id="user-username"
          label="Username"
          type="email"
          placeholder="email@domain.com"
          value={this.state.user.email}
          onChange={(v) => this.updateMetadata({ email: v })}
        />

        <CommonTextInput
          id="user-password"
          label="Password"
          placeholder="*********"
          onChange={(v) => this.updateMetadata({ password: v })}
          optional={!this.isNew()}
        />

        <CommonSearchInput
          id="user-team"
          name="_parent_id"
          label="common.team"
          options={this.props.teamsCx.teams.map((t) => {
            return {
              label: t.name,
              value: t._id,
            };
          })}
          values={
            this.state.user._parent_id ? [this.state.user._parent_id] : []
          }
          onChange={(v) =>
            this.updateMetadata({
              _parent_id: v[0],
              _parent_def: 'teams',
              _parent_field: 'users',
            })
          }
          optional
        />
        <CommonSearchInput
          id="user-machineID"
          label="common.machine"
          options={this.props.machinesCx.machines
            .filter((m) => m._parent_id === this.state.user._parent_id)
            .map((o) => ({
              label: `${o.machineID} (${o.nickname ?? 'no nickname'})`,
              value: o.machineID,
            }))}
          values={this.state.user.machineID ? [this.state.user.machineID] : []}
          onChange={(machineIDs) => {
            this.updateMetadata({
              machineID: machineIDs[0],
            });
          }}
        />
        <CommonSelectInput
          id="user-role"
          name="role"
          label="Role"
          options={this.USER_ROLES}
          value={this.state.user.role}
          onChange={(v) => this.updateMetadata({ role: v as UserRole })}
          optional
        />
        <CommonSelectInput
          id="user-status"
          name="status"
          label="Status"
          options={USER_STATUSES.map((s) => {
            return { label: s, value: s };
          })}
          value={this.state.user.status}
          onChange={(v) => this.updateMetadata({ status: v as UserStatus })}
          optional
        />

        <Box gridColumn="span 2">
          <Separator size="4" />
        </Box>

        <CommonChecklist
          id="user-list-access"
          name="lists"
          label="Pitch List Access"
          values={this.state.lists}
          options={SHARED_LIST_PARENTS}
          onChange={(selected) => {
            this.setState({
              lists: selected,
            });
          }}
        />

        <Box>
          Select one or more to grant the user access to shared pitch lists
          (personal lists are always enabled).
        </Box>

        {this.props.authCx.current.role === UserRole.admin && (
          <>
            <Box gridColumn="span 2">
              <Separator size="4" />
            </Box>
            {this.renderSuperAdminInputs()}
          </>
        )}
      </CommonFormGrid>
    );
  }

  private renderSuperAdminInputs() {
    return (
      <>
        <Box>
          <CommonChecklist
            id="user-domains"
            label="Login Domains"
            labelIcon={<SuperAdminIcon />}
            name="domains"
            values={this.state.user.domains ?? []}
            options={LOGIN_DOMAINS.map((d) => ({
              label: d === 'trajektsports.app' ? 'prod' : d.split('.')[0],
              value: d,
            }))}
            onChange={(selected) => {
              this.setState({
                user: {
                  ...this.state.user,
                  domains: selected,
                },
              });
            }}
          />
        </Box>

        <Box>
          Select one or more Login Domains above to limit the user's login
          access to the selected environments. If no domains are selected, the
          user will have access to all environments.
        </Box>

        <Box gridColumn="span 2">
          <Heading size={RADIX.HEADING.SIZE.SM}>Permissions</Heading>
        </Box>

        <Box>
          <CommonSwitchInput
            id="user-quick-session"
            name="quick_session"
            label="Quick Session"
            labelIcon={<SuperAdminIcon />}
            defaultChecked={this.state.permissions.quick_session}
            onCheckedChange={(v) =>
              this.setState({
                permissions: {
                  ...this.state.permissions,
                  quick_session: v,
                },
              })
            }
          />
        </Box>
      </>
    );
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.MD,
      loading: this.props.usersCx.loading,
      title: this.isNew() ? 'Create User' : 'Update User',
      content: this.renderContent(),
      buttons: [
        {
          label: this.isNew() ? 'Create' : 'Update',
          color: RADIX.COLOR.INFO,
          onClick: () => {
            if (!this.state.user._parent_id) {
              NotifyHelper.error({
                message_md: 'Please select a team and try again.',
              });
              return;
            }

            if (!this.state.user.machineID) {
              NotifyHelper.error({
                message_md: 'Please select a machine and try again.',
              });
              return;
            }

            if (!this.state.user.role) {
              NotifyHelper.error({
                message_md: 'Please select a role and try again.',
              });
              return;
            }

            if (!this.state.user.status) {
              NotifyHelper.error({
                message_md: 'Please select a status and try again.',
              });
              return;
            }

            /** convert selected shared list parents into enable flags */
            const perms: Partial<IUserPermissions> = {
              ...this.state.permissions,
              team_lists: this.state.lists.includes('teams'),
              machine_lists: this.state.lists.includes('team-machines'),
            };

            const payload: Partial<IUser> = {
              ...this.state.user,
              permissions: perms,
            };

            if (this.isNew()) {
              if (!payload.password) {
                NotifyHelper.error({
                  message_md: 'Please provide a password and try again.',
                });
                return;
              }

              this.props.usersCx
                .createUser(payload)
                .then(() => this.props.onClose());
              return;
            }

            this.props.usersCx
              .updateUser(payload)
              .then(() => this.props.onClose());
          },
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
