import { NotifyHelper } from 'classes/helpers/notify.helper';
import lightFormat from 'date-fns/lightFormat';
import { CSVFormat } from 'enums/csv';
import { CSV_FORMAT_DEFS } from 'interfaces/i-csv';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { CSVHelper } from 'lib_ts/classes/csv.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import {
  FTPS_TO_MPH,
  FT_TO_INCHES,
  GRAVITY_FT_PER_SEC,
  MPH_TO_FTPS,
  getLocalizeMatrix,
  mean,
} from 'lib_ts/classes/math.utilities';
import { getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { MS_LIMITS } from 'lib_ts/enums/machine.enums';
import { BuildPriority, lookupPitchType } from 'lib_ts/enums/pitches.enums';
import {
  ICustomBreaks,
  ICustomSpins,
  IHawkeye,
  IStatCast,
} from 'lib_ts/interfaces/csv';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import {
  DEFAULT_PLATE,
  IBallDetails,
  IBallDetailsError,
  IBallState,
  IBasePitchChars,
  IBuildPitchChars,
  IPitch,
  IPlateLoc,
  ITrajectory,
  ITrajectoryBreak,
} from 'lib_ts/interfaces/pitches';
import { IPosition } from 'lib_ts/interfaces/pitches/i-base';
import { StateTransformService } from 'services/state-transform.service';
import { Vector3 } from 'three';
import { v4 } from 'uuid';

const MAX_SEAMS_DELTA_DEG = 5;

// todo: confirm that hawkeye isn't already local and deprecate accordingly
const HAWKEYE_SPIN_IS_LOCALIZED = false;

/** returns undefined if the operation fails for whatever reason */
export const meanObject = (objects: any[], silent?: boolean) => {
  try {
    const tokenObject = objects[0];
    if (!tokenObject) {
      throw new Error('MEAN OBJECT: no values to calculate mean of');
    }

    /** should be the same type as input */
    const output: any = { ...tokenObject };

    /** only calculate mean of numeric fields */
    const numKeys = Object.keys(tokenObject).filter(
      (key) => typeof tokenObject[key] === 'number'
    );
    numKeys.forEach((key) => {
      const values = objects
        .filter((o) => typeof o[key] === 'number')
        .map((o) => o[key] as number);
      output[key] = mean(values);
    });

    return output;
  } catch (e) {
    if (!silent) {
      console.error(e);
    }
    return undefined;
  }
};

export enum QualityError {
  /** if format is statcast */
  Statcast,

  /** if breaks are missing */
  BreakDetails,

  /** if seam lat, lon, or orientation are missing */
  SeamDetails,
}

/** for use in assembling average value */
interface IReleaseValue {
  bs: IPosition;
  ms: IPosition;
  traj: IPosition;
}

export class CSVBall {
  static detectFormat(firstRow: any) {
    const keys = Object.keys(firstRow);

    const found = CSV_FORMAT_DEFS.find((def) => {
      const missingKeys = def.requiredHeaders.filter((h) => !keys.includes(h));
      return missingKeys.length === 0;
    });

    if (found) {
      return found.format;
    }

    return CSVFormat.Unknown;
  }

  static getAverageRelease(balls: CSVBall[]): IReleaseValue {
    const values: IReleaseValue[] = balls
      .filter((b) => b.details.pitchChars)
      .map((b) => {
        if (!b.details.pitchChars) {
          return undefined;
        }

        const bsPos: IPosition = {
          px: b.details.pitchChars.bs.px,
          py: b.details.pitchChars.bs.py,
          pz: b.details.pitchChars.bs.pz,
        };

        const msPos: IPosition = {
          px: b.details.pitchChars.ms.px,
          py: b.details.pitchChars.ms.py,
          pz: b.details.pitchChars.ms.pz,
        };

        const trajPos: IPosition = {
          px: b.details.pitchChars.traj.px,
          py: b.details.pitchChars.traj.py,
          pz: b.details.pitchChars.traj.pz,
        };

        return {
          bs: bsPos,
          ms: msPos,
          traj: trajPos,
        };
      })
      .filter((pos) => !!pos) as IReleaseValue[];

    const output: IReleaseValue = {
      bs: {
        px: mean(values.map((pos) => pos.bs.px)),
        py: mean(values.map((pos) => pos.bs.py)),
        pz: mean(values.map((pos) => pos.bs.pz)),
      },
      ms: {
        px: mean(values.map((pos) => pos.ms.px)),
        py: mean(values.map((pos) => pos.ms.py)),
        pz: mean(values.map((pos) => pos.ms.pz)),
      },
      traj: {
        px: mean(values.map((pos) => pos.traj.px)),
        py: mean(values.map((pos) => pos.traj.py)),
        pz: mean(values.map((pos) => pos.traj.pz)),
      },
    };

    return output;
  }

  static setAverageRelease(position: IReleaseValue, balls: CSVBall[]): void {
    balls.forEach((b) => {
      if (b.details.pitchChars) {
        b.details.pitchChars.bs.px = position.bs.px;
        b.details.pitchChars.bs.py = position.bs.py;
        b.details.pitchChars.bs.pz = position.bs.pz;

        b.details.pitchChars.ms.px = position.ms.px;
        b.details.pitchChars.ms.py = position.ms.py;
        b.details.pitchChars.ms.pz = position.ms.pz;

        b.details.pitchChars.traj.px = position.traj.px;
        b.details.pitchChars.traj.py = position.traj.py;
        b.details.pitchChars.traj.pz = position.traj.pz;

        b.details.pitchChars = AimingHelper.aimWithoutShots({
          chars: b.details.pitchChars,
          release: b.details.pitchChars.traj,
          plate_location: b.details.pitchChars.plate,
        });
      }
    });
  }

  /** groups balls by pitcher (only)
   * updates each ball's release position (on details > pitchChars) with the average values for each pitcher
   * only works on balls where ms is defined
   */
  static updateAverageReleasesByPitcher(balls: CSVBall[]): void {
    try {
      const pitchers = Array.from(
        new Set(balls.map((b) => b.details.pitcher).filter((p) => !!p))
      );

      pitchers.forEach((pitcher) => {
        /** all balls for this pitcher */
        const pBalls = balls.filter((b) => b.details.pitcher === pitcher);
        const avgPos = CSVBall.getAverageRelease(pBalls);

        /** update balls' pitch chars with avg value */
        CSVBall.setAverageRelease(avgPos, pBalls);
      });
    } catch (e) {
      NotifyHelper.error({
        message_md:
          'There was a problem calculating average release positions for your CSV.',
      });
      console.error(e);
    }
  }

  /** groups balls by pitcher, computes the average release for each pitcher
   *  groups balls by pitcher + type, computes an average bs for each group
   *  builds pitches from the bs via state transform
   *  returns a list of CSV balls corresponding to the averages
   */
  static async getAveragesByPitcherAndType(config: {
    balls: CSVBall[];
    machine: IMachine;
    priority: BuildPriority;
  }): Promise<CSVBall[]> {
    try {
      const releaseDict: { [pitcher: string]: IReleaseValue } = {};

      const averages: { ball: CSVBall; chars: Partial<IBuildPitchChars> }[] =
        [];

      const pitchers = Array.from(
        new Set(config.balls.map((b) => b.details.pitcher).filter((p) => !!p))
      );
      pitchers.forEach((pitcher) => {
        /** all balls for this pitcher */
        const pBalls = config.balls.filter(
          (b) => b.details.pitcher === pitcher
        );

        /** average the releases for this pitcher and store it in the dictionary */
        releaseDict[pitcher] = CSVBall.getAverageRelease(pBalls);

        /** unique pitch types from this pitcher's balls */
        const pTypes = Array.from(new Set(pBalls.map((b) => b.details.type)));

        pTypes.forEach((type) => {
          /** all balls for this pitcher + pitch type combination */
          const ptBalls = pBalls.filter((ball) => ball.details.type === type);

          if (ptBalls.length === 0) {
            return;
          }

          /** base output entry loosely on the first ball of this combination */
          const wholeBall = ptBalls.find(
            (ball) => ball.rawChars.bs && ball.rawChars.ms && ball.rawChars.traj
          );
          if (!wholeBall) {
            throw Error(
              `Could not find a ball with bs, ms, and traj for ${pitcher} + ${type}`
            );
          }

          // pick a candidate to use for non-average-able attributes, e.g. seams, plate location
          const wholeChars = wholeBall.details.pitchChars;

          /** compute average and update each ball's attributes */
          const bss: IBallState[] = ptBalls
            .map((m) => m.rawChars.bs)
            .filter((m) => !!m) as IBallState[];

          const breaks: ITrajectoryBreak[] = ptBalls
            .map((m) => m.rawChars.breaks)
            .filter((m) => !!m) as ITrajectoryBreak[];

          const avgChars: Partial<IBuildPitchChars> = {
            priority: config.priority,
            bs: meanObject(bss),
            breaks: meanObject(breaks),
            seams: wholeChars?.seams,
            plate: DEFAULT_PLATE,
            // plate: wholeChars?.plate,
          };

          const avgBall = wholeBall.clone();

          /** additional mappings for avg values not in bs need to go here */
          avgBall.details.name = `${pitcher} ${type} (Average)`;

          averages.push({ ball: avgBall, chars: avgChars });
        });
      });

      /** build pitches for each avg bs, results will be incorporated via mixin */
      await StateTransformService.getInstance().buildPitches({
        machine: config.machine,
        notifyError: false,
        pitches: averages.map((avg) => avg.chars),
        mixinBalls: averages.map((avg) => avg.ball),
      });

      /** replace the release positions with averages */
      averages.forEach((avg) => {
        CSVBall.setAverageRelease(releaseDict[avg.ball.details.pitcher], [
          avg.ball,
        ]);
      });

      return averages.map((avg) => avg.ball);
    } catch (e) {
      NotifyHelper.error({
        message_md:
          'There was a problem calculating average pitches for your CSV.',
      });
      console.error(e);
      return [];
    }
  }

  private format: CSVFormat;

  /** for uniquely identifying this CSV record in matching context */
  private temp_id: string;

  /** for checking whether the original inputs were reasonable to begin with */
  rawChars: Partial<IBuildPitchChars>;

  private machine: IMachine;
  private plate_distance: number;

  /** CSV record that informed this instance, track this for reporting errors with data */
  rawData: any;

  /** numeric columns need to be parsed into floats from the strings */
  parsedData?: ICustomSpins | ICustomBreaks | IHawkeye | IStatCast;

  /** track the desired plate location for the pitch (if provided), defaults to DEFAULT_PLATE */
  plate: IPlateLoc = DEFAULT_PLATE;

  details: IBallDetails;
  errors: IBallDetailsError[];
  qualityWarnings: QualityError[] = [];

  constructor(config: {
    format: CSVFormat;
    data: any;
    index: number;
    machine: IMachine;
    priority: BuildPriority;
    plate_distance: number;
    videosDict?: { [title: string]: string };
  }) {
    this.temp_id = v4();
    this.format = config.format;
    this.rawData = config.data;

    this.machine = config.machine;
    this.plate_distance = config.plate_distance;

    this.rawChars = {
      priority: config.priority,
    };

    this.errors = [];

    const output: Partial<IBallDetails> = {};

    output.name =
      config.data.PitchTitle ??
      `${lightFormat(new Date(), 'yyyy-MM-dd')} Custom Pitch ${(
        config.index + 1
      )
        .toFixed(0)
        .padStart(4, '0')}`;
    output.type = lookupPitchType(config.data.PitchType);
    output.year = config.data.PitchYear;
    output.pitcher = config.data.PitcherFullName ?? 'Custom';

    /** try to auto-select video based on first match by VideoTitle, then by VideoID */
    if (config.videosDict) {
      output.video_id =
        config.videosDict[`${config.data.VideoID}`.trim()] ??
        config.videosDict[`${config.data.VideoTitle}`.trim()] ??
        '';
    }

    this.details = output as IBallDetails;

    // do not use any rawData numeric attributes until after parseData is run
    this.parseData();
    this.setWarnings();
    this.prepareData();
  }

  /** returns an instance that's a deep copy of the original */
  clone(): CSVBall {
    const output = new CSVBall({
      format: this.format,
      data: this.rawData,
      index: -1,
      machine: this.machine,
      plate_distance: this.plate_distance,
      priority: this.rawChars.priority ?? BuildPriority.Spins,
    });

    if (this.details.video_id) {
      output.details.video_id = this.details.video_id;
    }

    output.details.pitchChars = this.details.pitchChars;

    return output;
  }

  private parseData() {
    try {
      switch (this.format) {
        case CSVFormat.CustomBreaks: {
          this.parsedData = CSVHelper.createCustomBreaks(this.rawData);
          break;
        }

        case CSVFormat.CustomSpins: {
          this.parsedData = CSVHelper.createCustomSpins(this.rawData);
          break;
        }

        case CSVFormat.Hawkeye: {
          this.parsedData = CSVHelper.createHawkeye(this.rawData);
          break;
        }

        case CSVFormat.Statcast: {
          this.parsedData = CSVHelper.createStatCast(this.rawData);
          break;
        }

        default: {
          throw Error(`Invalid format detected: ${this.format}`);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  private setWarnings() {
    try {
      if (!this.parsedData) {
        throw Error('setWarnings cannot proceed without parsedData');
      }

      /** warnings */
      if (this.format === 'Statcast') {
        this.qualityWarnings.push(QualityError.Statcast);
      }

      if (!CSVHelper.hasSeamDetails(this.parsedData)) {
        this.qualityWarnings.push(QualityError.SeamDetails);
      }

      if (
        this.rawChars.priority === BuildPriority.Breaks &&
        CSVHelper.hasBreakColumns(this.parsedData) &&
        !CSVHelper.hasBreakDetails(this.parsedData)
      ) {
        // break columns exist but valid breaks data was not found
        this.qualityWarnings.push(QualityError.BreakDetails);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private prepareData() {
    try {
      if (!this.parsedData) {
        throw Error('prepareData cannot proceed without parsedData');
      }

      if (
        this.rawChars.priority === BuildPriority.Breaks &&
        CSVHelper.hasBreakDetails(this.parsedData)
      ) {
        this.rawChars.breaks = {
          // CSV input data is already in Hawkeye reference. Don't flip the sign.
          xInches: this.parsedData.HorizontalBreakIn ?? 0,
          zInches: this.parsedData.VerticalBreakIn ?? 0,
        };
      }

      this.rawChars.seams = CSVHelper.getSeams(this.parsedData);

      /** plate location */
      const plate = CSVHelper.getPlateLoc(this.parsedData);

      /** set chars */
      switch (this.format) {
        case CSVFormat.CustomSpins: {
          this.prepareCustomSpins(this.parsedData as ICustomSpins, plate);
          break;
        }

        case CSVFormat.CustomBreaks: {
          this.prepareCustomBreaks(this.parsedData as ICustomBreaks, plate);
          break;
        }

        case CSVFormat.Hawkeye: {
          this.prepareHawkeye(this.parsedData as IHawkeye, plate);
          break;
        }

        case CSVFormat.Statcast: {
          this.prepareStatcast(this.parsedData as IStatCast, plate);
          break;
        }

        default: {
          throw Error(
            `prepareData encountered unexpected format: ${this.format}`
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  /** performs format-specific updates based on chars from Transform and calculates errors */
  mixinChars(chars: IBuildPitchChars, plate_distance: number) {
    try {
      if (!this.parsedData) {
        throw Error('mixinChars cannot be run before parsedData is defined');
      }

      this.rawChars.priority = chars.priority;

      if (CSVHelper.hasBreakDetails(this.parsedData)) {
        this.rawChars.breaks = {
          // CSV input data is already in Hawkeye reference. Don't flip the sign.
          xInches: this.parsedData.HorizontalBreakIn ?? 0,
          zInches: this.parsedData.VerticalBreakIn ?? 0,
        };
      }

      switch (this.format) {
        case CSVFormat.CustomSpins: {
          this.rawChars.traj = chars.traj;
          this.rawChars.ms = chars.ms;
          break;
        }

        case CSVFormat.Hawkeye: {
          this.rawChars.ms = chars.ms;
          break;
        }

        case CSVFormat.CustomBreaks:
        case CSVFormat.Statcast: {
          const quat = CSVHelper.getQuatFromData(this.parsedData, {
            wx: chars.bs.wx,
            wy: chars.bs.wy,
            wz: chars.bs.wz,
          });

          this.rawChars.bs = {
            ...chars.bs,
            ...quat,
          };

          this.rawChars.ms = {
            ...chars.ms,
            ...quat,
          };

          if (
            this.rawChars.priority === BuildPriority.Breaks &&
            !CSVHelper.hasBreakDetails(this.parsedData) &&
            chars.breaks
          ) {
            this.rawChars.breaks = chars.breaks;
          }
          break;
        }

        default: {
          throw Error(
            `mixinChars encountered unexpected format: ${this.format}`
          );
        }
      }

      if (this.rawChars.ms && this.rawChars.bs && this.rawChars.traj) {
        const rotated = AimingHelper.aimWithoutShots({
          chars: this.rawChars as IBasePitchChars,
          release: this.rawChars.bs,
          plate_location: this.plate,
        });

        this.details.pitchChars = {
          ...rotated,
          seams:
            this.rawChars.seams ?? BallHelper.getBallStateSeams(rotated.bs),
        };
      }

      this.populateErrors(plate_distance);
    } catch (e) {
      console.error(e);
    }
  }

  populateErrors(plate_distance: number) {
    try {
      const errors: IBallDetailsError[] = [];

      /** validations for raw chars/CSV data */
      if (!this.rawChars.traj) {
        errors.push({
          msg: `The ball does not contain any trajectory data. Please contact support.`,
        });
      } else {
        /** plate distance */
        if (this.rawChars.traj.py < MS_LIMITS.PITCH_PLATE_DISTANCE.MIN) {
          const TARGET = MS_LIMITS.PITCH_PLATE_DISTANCE.MIN;
          errors.push({
            msg: `The distance to the plate (currently ${this.rawChars.traj.py.toFixed(
              4
            )}ft) must be at least ${TARGET.toFixed(
              1
            )}ft. Please correct this in the data file and try again.`,
          });
        }

        if (this.rawChars.traj.py > MS_LIMITS.PITCH_PLATE_DISTANCE.MAX) {
          const TARGET = MS_LIMITS.PITCH_PLATE_DISTANCE.MAX;
          errors.push({
            msg: `The distance to the plate (currently ${this.rawChars.traj.py.toFixed(
              4
            )}ft) must be at most ${TARGET.toFixed(
              1
            )}ft. Please correct this in the data file and try again.`,
          });
        }
      }

      /** don't bother with additional errors if the raw chars produce errors */
      if (errors.length === 0) {
        /** validations for chars after extrapolation, rotation, etc... */
        if (this.details.pitchChars) {
          errors.push(
            ...MachineHelper.checkBallDetails({
              ms: this.details.pitchChars.ms,
              plate_distance: plate_distance,
            })
          );
        } else {
          errors.push({ msg: 'Empty pitch characteristics.' });
        }
      }

      this.errors = errors;
    } catch (e) {
      console.error(e);
    }
  }

  /** StateTransformService > buildPitches should be called after this */
  private prepareHawkeye(data: IHawkeye, plate: IPlateLoc) {
    try {
      this.plate = plate;

      const traj: ITrajectory = {
        px: data.x0,
        py: data.y0,
        pz: data.z0,
        vx: data.vx0,
        vy: data.vy0,
        vz: data.vz0,
        ax: data.ax0,
        ay: data.ay0,
        az: data.az0,
        wnet: BallHelper.getNetSpin({
          wx: data.spinX,
          wy: data.spinY,
          wz: data.spinZ,
        }),
      };

      const exTraj = TrajHelper.extrapolateTrajectory(
        traj,
        this.plate_distance
      );

      const spin = (() => {
        if (HAWKEYE_SPIN_IS_LOCALIZED) {
          return {
            wx: data.spinX,
            wy: data.spinY,
            wz: data.spinZ,
          };
        }

        const mx = getLocalizeMatrix(
          new Vector3(exTraj.vx, exTraj.vy, exTraj.vz)
        );

        const globalSpin = new Vector3(data.spinX, data.spinY, data.spinZ);
        const localSpin = globalSpin.applyMatrix3(mx);

        return {
          wx: localSpin.x,
          wy: localSpin.y,
          wz: localSpin.z,
        };
      })();

      const quat = CSVHelper.getQuatFromData(data, spin);

      const bs: IBallState = {
        px: exTraj.px,
        py: exTraj.py,
        pz: exTraj.pz,

        vx: exTraj.vx * FTPS_TO_MPH,
        vy: exTraj.vy * FTPS_TO_MPH,
        vz: exTraj.vz * FTPS_TO_MPH,
        vnet: BallHelper.getMagnitude([
          exTraj.vx * FTPS_TO_MPH,
          exTraj.vy * FTPS_TO_MPH,
          exTraj.vz * FTPS_TO_MPH,
        ]),

        ...spin,
        wnet: BallHelper.getMagnitude([spin.wx, spin.wy, spin.wz]),

        ...quat,
      };

      this.rawChars.bs = bs;
      this.rawChars.traj = exTraj;
    } catch (e) {
      console.error(e);
    }
  }

  /** StateTransformService > buildPitches should be called after this */
  private prepareCustomSpins(data: ICustomSpins, plate: IPlateLoc) {
    try {
      this.plate = plate;

      const quat = CSVHelper.getQuatFromData(data, {
        wx: data.SpinX,
        wy: data.SpinY,
        wz: data.SpinZ,
      });

      const bs: IBallState = {
        px: data.ReleaseX,
        py: this.plate_distance,
        pz: data.ReleaseZ,

        vx: 0,
        vy: -data.ReleaseV,
        vz: 0,
        vnet: BallHelper.getMagnitude([0, -data.ReleaseV, 0]),

        wx: data.SpinX,
        wy: data.SpinY,
        wz: data.SpinZ,
        wnet: BallHelper.getMagnitude([data.SpinX, data.SpinY, data.SpinZ]),

        ...quat,
      };

      this.rawChars.bs = bs;
    } catch (e) {
      console.error(e);
    }
  }

  /** StateTransformService > buildPitches should be called after this */
  private prepareCustomBreaks(data: ICustomBreaks, plate: IPlateLoc) {
    try {
      this.plate = plate;

      // Simple predict Ay
      const vy_ftps = -data.ReleaseV * MPH_TO_FTPS;
      const ay_ftps_sq = (38 / 150 / 150) * Math.pow(vy_ftps, 2);

      // time of flight
      const tf =
        (-vy_ftps -
          Math.sqrt(vy_ftps * vy_ftps - 2 * ay_ftps_sq * this.plate_distance)) /
        ay_ftps_sq;

      const tf_sq = Math.pow(tf, 2);

      // Use HB/VB to find accel, assume ay with heuristic, add NetSpin to CSV reqments
      const factor = 2 / FT_TO_INCHES / tf_sq;

      // HB = 0.5 * ax * tf * tf;
      const ax_ftps_sq = factor * data.HorizontalBreakIn;

      // VB = 0.5 * (az_ftps2 + 32.174) * tf * tf;
      const az_ftps_sq = factor * data.VerticalBreakIn - GRAVITY_FT_PER_SEC;

      this.rawChars.traj = {
        px: data.ReleaseX,
        py: this.plate_distance,
        pz: data.ReleaseZ,

        vx: 0,
        vy: vy_ftps,
        vz: 0,

        ax: ax_ftps_sq,
        ay: ay_ftps_sq,
        az: az_ftps_sq,

        wnet: data.NetSpin,
      };
    } catch (e) {
      console.error(e);
    }
  }

  /** StateTransformService > buildPitches should be called after this */
  private prepareStatcast(data: IStatCast, plate: IPlateLoc) {
    try {
      this.plate = plate;

      const vx50 = data.vx0; // In FTPS
      const vy50 = data.vy0; // In FTPS
      const vz50 = data.vz0; // In FTPS
      const tr = TrajHelper.calc_tr({
        vy: vy50,
        ay: data.ay,
        py: 50,
        new_py: data.release_pos_y,
      });
      const vxRel = vx50 + data.ax * tr;
      const vyRel = vy50 + data.ay * tr;
      const vzRel = vz50 + data.az * tr;

      const traj: ITrajectory = {
        px: data.release_pos_x,
        py: data.release_pos_y,
        pz: data.release_pos_z,
        vx: vxRel,
        vy: vyRel,
        vz: vzRel,
        ax: data.ax,
        ay: data.ay,
        az: data.az,
        wnet: data.release_spin_rate,
      };
      const exTraj = TrajHelper.extrapolateTrajectory(
        traj,
        this.plate_distance
      );

      this.rawChars.traj = exTraj;
    } catch (e) {
      console.error(e);
    }
  }

  /** reliably generates a temp _id from view_id and temp_index from rawChars */
  getPartialPitch(): Partial<IPitch> {
    if (!this.details.pitchChars) {
      return {};
    }

    const ms = this.details.pitchChars.ms;

    // always ensures that hashes are up to date before being saved
    ms.full_hash = MachineHelper.getMSHash('full', ms);
    ms.matching_hash = MachineHelper.getMSHash('matching', ms);

    return {
      _id: `temp-${this.temp_id}`,
      name: this.details.name,

      bs: this.details.pitchChars.bs,
      traj: this.details.pitchChars.traj,
      msDict: getMergedMSDict(this.machine, [ms]),

      priority: this.details.pitchChars.priority,
      breaks: this.details.pitchChars.breaks,
      seams: this.details.pitchChars.seams,
    };
  }

  /** add extra attributes, e.g. before saving */
  prepForSave(): Partial<IPitch> {
    const pitch = this.getPartialPitch();

    pitch.name = this.details.name;
    pitch.year = this.details.year;

    pitch.video_id = this.details.video_id;

    /** server will handle converting this via reverse lookup for a formal pitch type, defaulting to FF */
    pitch.type = this.details.type;

    return pitch;
  }

  // should only be called after building the pitch
  checkSeamsDeltas() {
    const uploadSeams = this.details.pitchChars?.seams;

    if (!uploadSeams) {
      console.warn({
        event: `CSVBall: seams should not be empty from upload`,
        ball: this,
      });
      return;
    }

    const bs = this.details.pitchChars?.bs;

    if (!bs) {
      console.warn({
        event: `CSVBall: ball state should not be empty after building`,
        ball: this,
      });
      return;
    }

    const bsSeams = BallHelper.getBallStateSeams(bs);

    const delta_lat_deg = uploadSeams.latitude_deg - bsSeams.latitude_deg;
    const delta_lon_deg = uploadSeams.longitude_deg - bsSeams.longitude_deg;

    if (
      Math.abs(delta_lat_deg) > MAX_SEAMS_DELTA_DEG ||
      Math.abs(delta_lon_deg) > MAX_SEAMS_DELTA_DEG
    ) {
      console.warn({
        event: `CSVBall: large delta between uploaded and constructed seams`,
        delta_lat_deg,
        delta_lon_deg,
        bs: this.details.pitchChars?.bs,
        uploadSeams,
        bsSeams,
      });
    }
  }
}
