import { SectionHeader } from 'components/sections/header';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { IMenuAction } from 'interfaces/i-menus';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { IPitchList } from 'lib_ts/interfaces/pitches';

interface IProps {
  list: IPitchList | undefined;
  menu: IMenuAction[] | undefined;
  action: IButton | undefined;

  loading?: boolean;
}

const getBadge = (props: IProps): string | undefined => {
  if (props.loading) {
    return;
  }

  if (!props.list) {
    return;
  }

  if (props.list.type) {
    switch (props.list.type) {
      case PitchListExtType.Card: {
        return 'pl.player-card';
      }

      case PitchListExtType.Reference: {
        if (props.list.live) {
          return 'pl.reference-list-live';
        }

        return 'pl.reference-list';
      }

      case PitchListExtType.Sample: {
        return 'pl.sample-list';
      }

      default: {
        break;
      }
    }
  }

  switch (props.list._parent_def) {
    case 'teams': {
      return 'pl.team-list';
    }

    case 'team-users': {
      return 'pl.personal-list';
    }

    case 'team-machines': {
      return 'pl.machine-list';
    }

    default: {
      return;
    }
  }
};

export const Header = (props: IProps) => {
  const badge = getBadge(props);

  return (
    <SectionHeader
      header={props.list?.name ?? t('pl.unnamed-pitch-list')}
      loading={props.loading || !props.list}
      badge={badge ? t(badge) : undefined}
      menu={props.menu}
      action={props.action}
    />
  );
};
