import {
  ArchiveIcon,
  ClockIcon,
  DoubleArrowUpIcon,
  ExclamationTriangleIcon,
  EyeOpenIcon,
  GearIcon,
  HomeIcon,
  KeyboardIcon,
  LightningBoltIcon,
  MagicWandIcon,
  MagnifyingGlassIcon,
  PersonIcon,
  PlayIcon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { AdminRoute, SectionName } from 'enums/route.enums';
import { IAdminTab, ISection } from 'interfaces/i-section';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { SessionMode } from 'lib_ts/interfaces/i-auth-token';
import { ICombinedPermissions } from 'lib_ts/interfaces/i-permissions';

interface IUserSectionConfig {
  /** game status */
  restricted: boolean;
  permissions: ICombinedPermissions;
}

export class SectionsHelper {
  static isAnyAdmin(role: UserRole): boolean {
    if ([UserRole.team_admin, UserRole.admin].includes(role)) {
      return true;
    }

    return false;
  }

  static isSuperAdmin(role: UserRole): boolean {
    if (UserRole.admin === role) {
      return true;
    }

    return false;
  }

  private static BASE_SECTIONS: ISection[] = [
    {
      value: SectionName.Home,
      label: 'main.home',
      icon: HomeIcon,
    },
    {
      value: SectionName.GameInProgress,
      label: 'main.game-in-progress',
      icon: ExclamationTriangleIcon,
      invisible: true,
    },
    {
      value: SectionName.NotFound,
      label: 'main.not-found',
      icon: QuestionMarkCircledIcon,
      invisible: true,
    },
    {
      value: SectionName.Unavailable,
      label: 'main.unavailable',
      icon: QuestionMarkCircledIcon,
      invisible: true,
    },
  ];

  static getAdminSections(config: {
    role: UserRole;
    restricted: boolean;
    mode: SessionMode;
  }): ISection[] {
    return [
      {
        value: SectionName.AdminPortal,
        label: 'main.admin-portal',
        icon: KeyboardIcon,
        invisible:
          // hide from non-admin users
          !this.isAnyAdmin(config.role) ||
          // hide from team admins during a game
          (config.role === UserRole.team_admin && config.restricted),
      },
      {
        value: SectionName.FeatureDemo,
        label: 'main.feature-demo',
        icon: EyeOpenIcon,
        invisible: !SectionsHelper.isSuperAdmin(config.role),
      },
    ];
  }

  static getUserSections(config: IUserSectionConfig): ISection[] {
    return [
      ...this.BASE_SECTIONS,
      {
        value: SectionName.Search,
        label: 'main.search',
        icon: MagnifyingGlassIcon,
      },
      {
        value: SectionName.MlbStatsBrowse,
        label: 'main.browse',
        icon: ArchiveIcon,
        invisible: !config.permissions.mlb_stats_api || config.restricted,
      },
      {
        value: SectionName.QuickSession,
        label: 'main.quick-session',
        icon: LightningBoltIcon,
        invisible: !config.permissions.quick_session,
      },
      {
        value: SectionName.PitchDesign,
        label: 'main.pitch-design',
        icon: MagicWandIcon,
        invisible: config.restricted,
      },
      {
        value: SectionName.BulkPitchUpload,
        label: 'main.bulk-pitch-upload',
        icon: DoubleArrowUpIcon,
        invisible: config.restricted,
      },

      {
        value: SectionName.HitterLibrary,
        label: 'main.hitter-library',
        icon: PersonIcon,
        invisible: config.restricted,
      },
      {
        value: SectionName.VideoLibrary,
        label: 'main.video-library',
        icon: PlayIcon,
        invisible: config.restricted,
      },
      {
        value: SectionName.SessionHistory,
        label: 'main.session-history',
        icon: ClockIcon,
        invisible: config.restricted,
      },
      {
        value: SectionName.MachineCalibration,
        label: 'main.machine-calibration',
        icon: GearIcon,
        invisible: config.restricted,
      },
    ];
  }

  static getAdminTabs(config: { role: UserRole }): IAdminTab[] {
    return [
      {
        value: AdminRoute.Users,
        label: 'admin.users',
      },
      {
        value: AdminRoute.Machines,
        label: 'admin.machines',
      },
      {
        value: AdminRoute.Teams,
        label: 'admin.teams',
        invisible: !this.isSuperAdmin(config.role),
        icon: SuperAdminIcon,
      },
      {
        value: AdminRoute.MachineModels,
        label: 'admin.machine-models',
        invisible: !this.isSuperAdmin(config.role),
        icon: SuperAdminIcon,
      },
      {
        value: AdminRoute.ErrorTypes,
        label: 'admin.error-types',
        invisible: !this.isSuperAdmin(config.role),
        icon: SuperAdminIcon,
      },

      {
        value: AdminRoute.ApiKeys,
        label: 'admin.api-keys',
      },

      {
        value: AdminRoute.ServerControls,
        label: 'admin.server-controls',
        invisible: !this.isSuperAdmin(config.role),
        icon: SuperAdminIcon,
      },
    ];
  }
}
