import { DotsVerticalIcon } from '@radix-ui/react-icons';
import {
  Badge,
  Box,
  Flex,
  Heading,
  IconButton,
  Spinner,
} from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonMenu } from 'components/common/menu';
import { DefaultTFuncReturn } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { IMenuAction } from 'interfaces/i-menus';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { SectionHeaderActionButton } from './action-button';

interface IProps {
  /** header text, won't be translated */
  header: string | DefaultTFuncReturn;

  /** optional inline tiny text after header */
  badge?: string | DefaultTFuncReturn;

  /** optional, will be rendered immediately to the right of the header */
  menu?: IMenuAction[];

  /** optional, will be rendered on the right side opposite the header */
  action?: IButton;

  loading?: boolean;
}

interface IState {}

export class SectionHeader extends React.Component<IProps, IState> {
  state: IState = {};

  render() {
    return (
      <ErrorBoundary componentName="SectionHeader">
        <Flex gap={RADIX.FLEX.GAP.LG} justify="between">
          {/* left cluster */}
          <Flex gap={RADIX.FLEX.GAP.MD} className="valign-center">
            <Box className="valign-center">
              <Heading mt="1" mb="0">
                {this.props.header}
              </Heading>
            </Box>

            {this.props.loading && (
              <Box className="valign-center">
                <Spinner size="3" />
              </Box>
            )}

            {this.props.badge && (
              <Box className="valign-center">
                <Badge color={RADIX.COLOR.NEUTRAL}>{this.props.badge}</Badge>
              </Box>
            )}

            {!this.props.loading && this.props.menu && (
              <Box pt="1" className="valign-center">
                <CommonMenu
                  title="Actions"
                  trigger={
                    <IconButton
                      className="btn-floating"
                      variant={RADIX.BUTTON.VARIANT.BORDERLESS}
                      color={RADIX.COLOR.NEUTRAL}
                      size={RADIX.BUTTON.SIZE.XS}
                    >
                      <DotsVerticalIcon />
                    </IconButton>
                  }
                  actions={this.props.menu.filter((a) => !a.invisible)}
                />
              </Box>
            )}
          </Flex>

          {/* right cluster */}
          {this.props.action && (
            <Box>
              <SectionHeaderActionButton {...this.props.action} />
            </Box>
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
