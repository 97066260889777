import { IMongoBase } from '../mongo/_base';

export interface IRapsodoConfidence {
  /** between 0.0-1.0 where 1.0 === 100% */
  PITCH_ReleaseSpeedConfidence: number;
  /** between 0.0-1.0 where 1.0 === 100% */
  PITCH_SpinConfidence: number;
  /** between 0.0-1.0 where 1.0 === 100% */
  PITCH_StrikePositionHeightConfidence: number;
  /** between 0.0-1.0 where 1.0 === 100% */
  PITCH_StrikePositionSideConfidence: number;
}

export interface IRapsodoBreak {
  /** reported in meters, converted to inches in export */
  PITCH_HBSpin: number;
  PITCH_HBSpinConfidence: number;
  /** reported in meters, converted to inches in export */
  PITCH_HBTrajectory: number;
  PITCH_HBTrajectoryConfidence: number;
  /** reported in meters, converted to inches in export */
  PITCH_VBSpin: number;
  PITCH_VBSpinConfidence: number;
  /** reported in meters, converted to inches in export */
  PITCH_VBTrajectory: number;
  PITCH_VBTrajectoryConfidence: number;
}

export const EMPTY_RAPSODO_BREAKS: IRapsodoBreak = {
  PITCH_HBSpin: 0,
  PITCH_HBSpinConfidence: 0,
  PITCH_HBTrajectory: 0,
  PITCH_HBTrajectoryConfidence: 0,
  PITCH_VBSpin: 0,
  PITCH_VBSpinConfidence: 0,
  PITCH_VBTrajectory: 0,
  PITCH_VBTrajectoryConfidence: 0,
};

export interface IRapsodoDebug {
  DEBUG_appVersion: number;
  DEBUG_Battery: number;
  DEBUG_HardwareModel: string;
  DEBUG_SerialNumber: string;
}

export interface IRapsodoSeamsOrientation {
  PITCH_SOLatitude: number;
  PITCH_SOLatLongConfidence: number;
  PITCH_SOLongitude: number;
  PITCH_SORotMatConfidence: number;
  PITCH_SOTimestamp: number;
  PITCH_SOXx: number;
  PITCH_SOXy: number;
  PITCH_SOXz: number;
  PITCH_SOYx: number;
  PITCH_SOYy: number;
  PITCH_SOYz: number;
  PITCH_SOZx: number;
  PITCH_SOZy: number;
  PITCH_SOZz: number;
}

export interface IRapsodoPitch
  extends IRapsodoConfidence,
    IRapsodoBreak,
    IRapsodoSeamsOrientation {
  PITCH_Date: number;
  PITCH_GyroDegree: number;

  PITCH_HorizontalAngle: number;
  PITCH_HorizontalAngleConfidence: number;

  PITCH_PitchLength: number;
  PITCH_PitchType: string;
  PITCH_PlayerID: number;
  /** reported in meters, converted to feet in export */
  PITCH_ReleaseExtension: number;
  PITCH_ReleaseExtentionConfidence: number;
  /** reported in meters, converted to feet in export */
  PITCH_ReleaseHeight: number;
  PITCH_ReleaseHeightConfidence: number;
  /** reported in meters, converted to feet in export */
  PITCH_ReleaseSide: number;
  PITCH_ReleaseSideConfidence: number;
  PITCH_ReleaseTime: number;
  /** reported in meters per second, converted to miles per hour in export */
  PITCH_Speed: number;

  PITCH_SpinAxis: string;
  PITCH_SpinConfidence: number;

  PITCH_SpinEfficiency: number;
  PITCH_Strike: boolean;
  /** reported in meters, converted to inches in export */
  PITCH_StrikeZoneHeight: number;
  /** reported in meters, converted to inches in export */
  PITCH_StrikeZoneSide: number;
  PITCH_StrikeZoneTime: number;
  PITCH_TotalSpin: number;
  PITCH_TrueSpin: number;

  PITCH_VerticalAngle: number;
  PITCH_VerticalAngleConfidence: number;
  PITCH_ZoneTime: number;
}

export interface IRapsodoHit {
  HIT_Date: number;
  // in degrees, yaw, where 0 points at 2nd base
  HIT_Direction: number;
  // in meters
  HIT_Distance: number;
  HIT_DistanceConfidence: number;

  HIT_ExitDirectionConfidence: number;

  // in km/h
  HIT_ExitSpeed: number;
  HIT_ExitSpeedConfidence: number;

  HIT_HitID: number;
  HIT_HitMode: string;

  HIT_IncomingSpeedConfidence: number;

  // in degrees, tilt
  HIT_LaunchAngle: number;
  HIT_LaunchAngleConfidence: number;

  // like 'single' / 'double' / 'triple' / 'home run' / 'field out'
  HIT_OUTCOME: string;

  HIT_PitchBallSpeed: number;
  HIT_PlayerID: number;
  HIT_Spin: number;
  HIT_SpinConfidence: number;
  HIT_StrikePositionHeightConfidence: number;
  HIT_StrikePositionSideConfidence: number;
  HIT_strikeZoneX: number;
  HIT_strikeZoneY: number;
}

interface IRapsodoDeprecated {
  PITCH_SpeedConfidence: number;
}

export interface IRapsodoShot
  extends IMongoBase,
    IRapsodoDebug,
    IRapsodoPitch,
    Partial<IRapsodoHit>,
    Partial<IRapsodoDeprecated> {
  session: string;
}
