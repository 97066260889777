import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Box, Flex, Separator, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { AuthContext } from 'contexts/auth.context';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MenuMode } from 'lib_ts/enums/auth.enums';
import { RADIX, RadixSide } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { MenuAsDialogButton } from './dialog-button';
import './index.scss';

export const ALLOW_MENU_AS_MODAL = false;

const SEPARATOR_LABEL = '---SEPARATOR---';

interface IProps {
  trigger: React.ReactNode;
  actions: IMenuAction[];
  side?: RadixSide;
  sideOffset?: number;

  /** header while using dialog mode */
  title: string;

  skipSort?: boolean;
}

interface IState {
  open: boolean;
}

export class CommonMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
    };

    this.getGroupedActions = this.getGroupedActions.bind(this);

    this.renderDialog = this.renderDialog.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
  }

  private getGroupedActions(): IMenuAction[] {
    const groupDict = ArrayHelper.groupBy(
      // prevents groups from being created where no actions would be shown
      this.props.actions.filter((a) => !a.invisible),
      'group'
    );

    return Object.keys(groupDict).flatMap((key, i) => {
      const output: IMenuAction[] = [];

      // insert an entry for the group header (if key is not empty or a separator key starting with _)
      if (key && groupDict[key].length > 0) {
        // print a separator before the group starts
        output.push({
          label: SEPARATOR_LABEL,
          group: key,
          disabled: true,
          onClick: () => {
            // do nothing
          },
        });

        if (!key.startsWith('_')) {
          // this is a regular group name, print it as a disabled option
          output.push({
            label: key,
            group: key,
            disabled: true,
            className: 'DropdownMenuGroupLabel',
            onClick: () => {
              // do nothing
            },
          });
        }
      }

      const values = groupDict[key];

      if (!this.props.skipSort) {
        // sort options within a group alphabetically
        values.sort((a, b) => a.label.localeCompare(b.label));
      }

      output.push(...values);

      return output;
    });
  }

  // still necessary for cases where a menu wouldn't work (e.g. sidebar nav getting chopped)
  private renderDialog() {
    const actions = this.getGroupedActions();

    return (
      <>
        <div onClick={() => this.setState({ open: true })}>
          {this.props.trigger}
        </div>

        {this.state.open && (
          <CommonDialog
            identifier="MenuAsDialog"
            width={RADIX.DIALOG.WIDTH.MD}
            title={this.props.title ?? 'Actions'}
            onClose={() => this.setState({ open: false })}
            content={
              <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
                {actions.map((a, i) => {
                  if (a.label === SEPARATOR_LABEL) {
                    if (i === 0) {
                      // skip leading separators
                      return <></>;
                    }

                    return (
                      <Box key={i}>
                        <Separator size="4" />
                      </Box>
                    );
                  }

                  return (
                    <Box key={i}>
                      <MenuAsDialogButton
                        {...a}
                        label={a.label}
                        icon={a.icon}
                        afterClick={() => this.setState({ open: false })}
                      />
                    </Box>
                  );
                })}
              </Flex>
            }
            // this is mainly so that training controls don't skip over training messages while someone opens a menu
            ignore
          />
        )}
      </>
    );
  }

  private renderDropdown() {
    const actions = this.getGroupedActions();

    if (actions.length === 0) {
      return this.props.trigger;
    }

    return (
      <DropdownMenu.Root
        open={this.state.open}
        onOpenChange={(open) => this.setState({ open: open })}
      >
        <DropdownMenu.Trigger asChild>
          {this.props.trigger}
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="DropdownMenuContent scroll-hover"
            side={this.props.side ?? 'right'}
            sideOffset={this.props.sideOffset ?? 4}
          >
            {actions.map((a, i) =>
              a.label === SEPARATOR_LABEL ? (
                <DropdownMenu.Separator
                  key={i}
                  data-group={a.group}
                  className="DropdownMenuSeparator"
                  // avoid starting a menu with a separator
                  hidden={i === 0}
                />
              ) : (
                <DropdownMenu.Item
                  key={i}
                  className={StringHelper.classNames([
                    'DropdownMenuItem',
                    a.className,
                  ])}
                  data-group={a.group}
                  title={a.tooltip ? t(a.tooltip).toString() : undefined}
                  disabled={a.disabled}
                  onClick={a.onClick}
                >
                  <Box className="valign-center">
                    <Text
                      color={a.disabled ? RADIX.COLOR.SECONDARY : a.color}
                      title={t(a.label).toString()}
                      truncate
                    >
                      {t(a.label)}
                    </Text>
                  </Box>

                  {a.icon && (
                    <Box
                      className="valign-center"
                      style={{
                        marginLeft: '8px',
                        paddingTop: '2px',
                      }}
                    >
                      {a.icon}
                    </Box>
                  )}
                </DropdownMenu.Item>
              )
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="CommonMenu">
        {ALLOW_MENU_AS_MODAL ? (
          <AuthContext.Consumer>
            {(authCx) => {
              switch (authCx.current.menu_mode) {
                case MenuMode.modal: {
                  return this.renderDialog();
                }

                case MenuMode.default:
                default: {
                  return this.renderDropdown();
                }
              }
            }}
          </AuthContext.Consumer>
        ) : (
          this.renderDropdown()
        )}
      </ErrorBoundary>
    );
  }
}
