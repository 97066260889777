import * as Tooltip from '@radix-ui/react-tooltip';
import { Theme } from '@radix-ui/themes';
import env from 'config';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const target = document.getElementById('app');
const root = createRoot(target!);

const core = (
  <CookiesProvider>
    <Theme id="ThemeWrapper" accentColor={RADIX.COLOR.ACCENT} appearance="dark">
      <Tooltip.Provider delayDuration={50}>
        <App />
      </Tooltip.Provider>
    </Theme>
  </CookiesProvider>
);

if (env.strict) {
  root.render(<React.StrictMode>{core}</React.StrictMode>);
} else {
  root.render(core);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (env.enable.vitals) {
  reportWebVitals(console.debug);
}
