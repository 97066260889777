import { MagnifyingGlassIcon, ResetIcon } from '@radix-ui/react-icons';
import { Box, Heading } from '@radix-ui/themes';
import { getPitchYearOptions } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import {
  IPitchListsContext,
  SEARCH_ID,
} from 'contexts/pitch-lists/pitch-lists.context';
import { t } from 'i18next';
import {
  PITCH_TYPE_OPTIONS,
  PitchType,
  PitcherHand,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { ISearchPitches } from 'lib_ts/interfaces/pitches/i-search-pitches';
import React from 'react';

const COMPONENT_NAME = 'SearchPitchesDialog';

const ENABLE_SORTING = false;

const SUBMIT_KEY = 'Enter';

const LIST_PARENT_OPTIONS: IOption[] = [
  { label: 'Personal', value: 'team-users' },
  { label: 'Machine', value: 'team-machines' },
  { label: 'Team', value: 'teams' },
];

// assuming grid with 2 columns
const SPAN = 'span 2';

interface IProps {
  listsCx: IPitchListsContext;
  onClose: () => void;
}

interface IState extends ISearchPitches {}

export class SearchPitchesDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      ...props.listsCx.search,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.submit = this.submit.bind(this);

    this.renderPitchFilters = this.renderPitchFilters.bind(this);
    this.renderListFilters = this.renderListFilters.bind(this);
    this.renderSortControls = this.renderSortControls.bind(this);
  }

  componentDidMount(): void {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (event.code !== SUBMIT_KEY) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.repeat) {
      return;
    }

    switch (event.code) {
      case SUBMIT_KEY: {
        this.submit();
        return;
      }

      default: {
        return;
      }
    }
  }

  private async submit() {
    // update search criteria
    await this.props.listsCx.setSearch(this.state);

    // dialog close should only happen after search criteria are updated
    this.props.onClose();
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier="PitchListSearchModal"
          width={RADIX.DIALOG.WIDTH.MD}
          title="common.search"
          buttons={[
            {
              label: 'common.search',
              icon: <MagnifyingGlassIcon />,
              color: RADIX.COLOR.INFO,
              onClick: () => this.submit(),
            },
            {
              label: 'common.reset',
              icon: <ResetIcon />,
              color: RADIX.COLOR.NEUTRAL,
              onClick: () => {
                // clear search criteria
                this.props.listsCx.setSearch(undefined);
                this.props.onClose();
              },
            },
          ]}
          onClose={() => this.props.onClose()}
          content={
            <CommonFormGrid columns={2}>
              {this.renderPitchFilters()}
              {this.renderListFilters()}
              {this.renderSortControls()}
            </CommonFormGrid>
          }
        />
      </ErrorBoundary>
    );
  }

  private renderPitchFilters() {
    return (
      <>
        <Box gridColumn={SPAN}>
          <Heading size={RADIX.HEADING.SIZE.SM}>
            {t('common.pitch-details')}
          </Heading>
        </Box>

        <Box>
          <CommonTextInput
            id="search-pitch-name"
            label="common.name"
            name="name"
            value={this.state.name}
            disabled={this.props.listsCx.loading}
            onChange={(v) => this.setState({ name: v })}
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="search-pitch-year"
            name="year"
            label="common.year"
            options={getPitchYearOptions()}
            value={this.state.year}
            onChange={(v) => this.setState({ year: v })}
            disabled={this.props.listsCx.loading}
            optional
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="search-pitch-type"
            name="type"
            label="common.type"
            options={PITCH_TYPE_OPTIONS}
            value={this.state.type}
            onChange={(v) => this.setState({ type: v as PitchType })}
            disabled={this.props.listsCx.loading}
            optional
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="search-pitch-hand"
            name="hand"
            label="common.side"
            options={Object.values(PitcherHand).map((m) => ({
              label: m,
              value: m,
            }))}
            value={this.state.hand}
            onChange={(v) => this.setState({ hand: v as PitcherHand })}
            disabled={this.props.listsCx.loading}
            optional
          />
        </Box>
        <Box>
          <CommonTextInput
            id="search-pitch-min-speed"
            label="common.min-speed-mph"
            name="minVnet"
            type="number"
            value={this.state.minVnet?.toString()}
            disabled={this.props.listsCx.loading}
            onNumericChange={(v) => this.setState({ minVnet: v })}
          />
        </Box>
        <Box>
          <CommonTextInput
            id="search-pitch-max-speed"
            label="common.max-speed-mph"
            name="maxVnet"
            type="number"
            value={this.state.maxVnet?.toString()}
            disabled={this.props.listsCx.loading}
            onNumericChange={(e) => this.setState({ maxVnet: e })}
          />
        </Box>
        <Box>
          <CommonTextInput
            id="search-pitch-min-spin"
            label="common.min-spin-rpm"
            name="minWnet"
            type="number"
            value={this.state.minWnet?.toString()}
            disabled={this.props.listsCx.loading}
            onNumericChange={(v) => this.setState({ minWnet: v })}
          />
        </Box>
        <Box>
          <CommonTextInput
            id="search-pitch-max-spin"
            label="common.max-spin-rpm"
            name="maxWnet"
            type="number"
            value={this.state.maxWnet?.toString()}
            disabled={this.props.listsCx.loading}
            onNumericChange={(v) => this.setState({ maxWnet: v })}
          />
        </Box>
      </>
    );
  }

  private renderSortControls(): React.ReactNode {
    if (!ENABLE_SORTING) {
      return;
    }

    return (
      <>
        <Box gridColumn={SPAN}>
          <Heading size={RADIX.HEADING.SIZE.SM}>Filter</Heading>
        </Box>

        <Box>
          <CommonSelectInput
            id="search-pitch-sort-by"
            name="sortKey"
            label="common.sort-by"
            options={[
              { label: 'Created', value: '_created' },
              { label: 'Changed', value: '_changed' },
            ]}
            value={this.state.sortKey}
            onChange={(v) => this.setState({ sortKey: v })}
            disabled={this.props.listsCx.loading}
            optional
          />
        </Box>

        <Box>
          <CommonSelectInput
            id="search-pitch-sort-direction"
            name="sortDir"
            label="common.sort-direction"
            options={[
              { label: 'Ascending', value: 'asc' },
              { label: 'Descending', value: 'desc' },
            ]}
            value={this.state.sortDir}
            onChange={(v) => this.setState({ sortDir: v as 'asc' | 'desc' })}
            disabled={this.props.listsCx.loading}
            optional
          />
        </Box>
      </>
    );
  }

  private renderListFilters(): React.ReactNode {
    if (this.props.listsCx.active?._id !== SEARCH_ID) {
      return;
    }

    return (
      <>
        <Box gridColumn={SPAN}>
          <Heading size={RADIX.HEADING.SIZE.SM}>List Details</Heading>
        </Box>

        <Box>
          <CommonTextInput
            id="search-pitch-list-name"
            label="common.name"
            name="listName"
            value={this.state.listName}
            disabled={this.props.listsCx.loading}
            onChange={(v) => this.setState({ listName: v })}
          />
        </Box>

        <Box>
          <CommonTextInput
            id="search-pitch-list-folder"
            label="pl.folder"
            name="name"
            value={this.state.listFolder}
            disabled={this.props.listsCx.loading}
            onChange={(v) => this.setState({ listFolder: v })}
          />
        </Box>

        <Box gridColumn={SPAN}>
          <CommonSelectInput
            id="search-pitch-list-parent"
            name="listParent"
            label="common.visibility"
            options={LIST_PARENT_OPTIONS}
            value={this.state.listParent}
            onChange={(v) => this.setState({ listParent: v })}
            disabled={this.props.listsCx.loading}
            optional
          />
        </Box>
      </>
    );
  }
}
