import { Box, Grid } from '@radix-ui/themes';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  vFlex?: boolean;

  // for temporarily hiding the sidebar (and expanding the main content)
  hideSidebar?: boolean;
}

const TOTAL_COLUMNS = 9;

interface IColSpec {
  initial: number;
  md: number;
  lg: number;
}

const LEFT_COLUMNS: IColSpec = {
  initial: 5,
  md: 6,
  lg: 7,
};

const RIGHT_COLUMNS: IColSpec = {
  initial: TOTAL_COLUMNS - LEFT_COLUMNS.initial,
  md: TOTAL_COLUMNS - LEFT_COLUMNS.md,
  lg: TOTAL_COLUMNS - LEFT_COLUMNS.lg,
};

export const CommonContentWithSidebar = (props: IProps) => {
  return (
    <Grid
      data-identifier="CommonContentWithSidebar"
      className={props.vFlex ? RADIX.VFLEX.WRAPPER : undefined}
      gap={RADIX.FLEX.GAP.SIDEBAR}
      columns={props.hideSidebar ? '1' : TOTAL_COLUMNS.toString()}
    >
      <Box
        data-identifier="CommonContentLeft"
        gridColumn={
          props.hideSidebar
            ? undefined
            : {
                initial: `span ${LEFT_COLUMNS.initial}`,
                md: `span ${LEFT_COLUMNS.md}`,
                lg: `span ${LEFT_COLUMNS.lg}`,
              }
        }
        overflowX="hidden"
        // this being tall doesn't affect the other column
        overflowY="auto"
      >
        {props.left}
      </Box>

      {!props.hideSidebar && (
        <Box
          data-identifier="CommonContentRight"
          gridColumn={{
            initial: `span ${RIGHT_COLUMNS.initial}`,
            md: `span ${RIGHT_COLUMNS.md}`,
            lg: `span ${RIGHT_COLUMNS.lg}`,
          }}
          overflowX="hidden"
          // this being tall doesn't affect the other column
          overflowY="auto"
        >
          {props.right}
        </Box>
      )}
    </Grid>
  );
};
