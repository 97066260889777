import { Code, Heading, Table, Text } from '@radix-ui/themes';
import { CustomIcon } from 'components/common/custom-icon';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CustomIconPath } from 'enums/custom.enums';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { SectionBox } from './section-box';

const COLORS: RadixColor[] = [
  RADIX.COLOR.SUCCESS,
  RADIX.COLOR.WARNING,
  RADIX.COLOR.DANGER,
  RADIX.COLOR.NEUTRAL,
  RADIX.COLOR.ACCENT,
  RADIX.COLOR.ANY_ADMIN,
  RADIX.COLOR.SUPER_ADMIN,
].filter((c) => c) as RadixColor[];

export const CustomIconsDemo = () => (
  <ErrorBoundary componentName="CustomIconsDemo">
    <SectionBox>
      <Heading size={RADIX.HEADING.SIZE.MD}>Custom Icons</Heading>

      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
            {COLORS.map((c, iColor) => (
              <Table.ColumnHeaderCell
                key={iColor}
                data-color={c}
                align="center"
              >
                <Code color={c}>{c}</Code>
              </Table.ColumnHeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.values(CustomIconPath).map((p, iPath) => (
            <Table.Row key={iPath}>
              <Table.Cell>{p}</Table.Cell>
              {COLORS.map((c, iColor) => (
                <Table.Cell key={iColor} align="center">
                  <Text color={c}>
                    <CustomIcon icon={p as CustomIconPath} />
                  </Text>
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </SectionBox>
  </ErrorBoundary>
);
