import { Box, Button, Flex, Grid } from '@radix-ui/themes';
import { SpeedControlView } from 'components/common/animation/speed-ctrl';
import { ErrorBoundary } from 'components/common/error-boundary';
import {
  FPS,
  Perspective,
} from 'components/common/trajectory-view/helpers/constants';
import { t } from 'i18next';
import { BallHelper, DEG2RAD } from 'lib_ts/classes/ball.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISeamOrientation, ISpin } from 'lib_ts/interfaces/pitches/i-base';
import { Component } from 'react';
import {
  ACESFilmicToneMapping,
  AmbientLight,
  CylinderGeometry,
  Group,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Quaternion,
  Scene,
  Vector3,
  WebGLRenderer,
  sRGBEncoding,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

interface IProps extends ISeamOrientation, ISpin {}

interface IState {
  speed: number;
  perspective: Perspective;
}

const SCENE = {
  disc_thickness: 0.01,
  disc_radius: 0.2,
  disc_color: 0x0000ff,
  disc_opacity: 0.5,
  axis_height: 0.4,
  axis_radius: 0.02,
  axis_color: 0xff0000,
  axis_opacity: 0.5,
};

export class SpinView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      speed: 5,
      perspective: 'batter',
    };

    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.initializeScene = this.initializeScene.bind(this);
    this.animate = this.animate.bind(this);
  }

  private node?: HTMLDivElement;

  /** workaround for React strict mode re-executing componentDidMount */
  private initAttempts = 0;

  private animationInterval?: any;
  private rotation = 0;
  private width = 350;
  private height = 350;
  private lastTime = performance.now();
  private camera = new PerspectiveCamera();
  private scene = new Scene();
  private ball: any = null;
  private decoration = new Group();
  private renderer = new WebGLRenderer({ antialias: true, alpha: true });

  componentDidMount() {
    this.initializeScene();
    this.rotation = 0;
    this.lastTime = performance.now();

    window.addEventListener('resize', this.handleWindowResize);

    clearInterval(this.animationInterval);
    this.animationInterval = setInterval(this.animate, 1000 / FPS);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);

    clearInterval(this.animationInterval);
  }

  private initializeScene = () => {
    this.initAttempts++;

    if (this.initAttempts > 1) {
      return false;
    }

    if (!this.node) {
      console.warn(
        `SPIN VIEW: node was not defined, abandoning attempt ${this.initAttempts}`
      );
      return false;
    }

    this.width = this.node.clientWidth;
    this.height = this.node.clientHeight;

    this.camera = new PerspectiveCamera(45, this.width / this.height, 0.25, 20);

    this.scene = new Scene();
    this.scene.add(new AmbientLight(0xffffff));

    new GLTFLoader().load(
      process.env.PUBLIC_URL + '/glb/smallest_ball.glb',
      (gltf: any) => {
        gltf.scene.position.set(0, 0, 0);
        this.ball = gltf.scene;
        this.scene.add(this.ball);
      }
    );

    const disc_geometry = new CylinderGeometry(
      SCENE.disc_radius,
      SCENE.disc_radius,
      SCENE.disc_thickness,
      32
    );
    const disc_material = new MeshBasicMaterial({
      color: SCENE.disc_color,
      opacity: SCENE.disc_opacity,
      transparent: true,
    });
    const axis_geometry = new CylinderGeometry(
      SCENE.axis_radius,
      SCENE.axis_radius,
      SCENE.axis_height,
      32
    );
    const axis_material = new MeshBasicMaterial({
      color: SCENE.axis_color,
      opacity: SCENE.axis_opacity,
      transparent: true,
    });

    this.decoration = new Group();
    this.decoration.add(new Mesh(disc_geometry, disc_material));
    this.decoration.add(new Mesh(axis_geometry, axis_material));
    this.scene.add(this.decoration);

    this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.toneMapping = ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1;
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.setClearColor(0x000000, 0);
    this.node.appendChild(this.renderer.domElement);

    this.setPerspective(this.state.perspective);
    this.handleWindowResize();

    return true;
  };

  setPerspective = (newPerspective: Perspective) => {
    this.setState({ ...this.state, perspective: newPerspective });

    switch (newPerspective) {
      case 'batter':
        {
          this.camera.position.set(0, 0, 1);
          const R_camera = new Quaternion();
          this.camera.quaternion.copy(R_camera);
        }
        break;

      case 'pitcher-2':
        {
          this.camera.position.set(0, 0, -1);
          const R_camera = new Quaternion();
          R_camera.setFromAxisAngle(new Vector3(0, 1, 0), Math.PI);
          this.camera.quaternion.copy(R_camera);
        }
        break;
    }
  };

  private animate = () => {
    // update rotation accumulator
    const now = performance.now();
    const dt = now - this.lastTime;
    this.lastTime = now;

    this.rotation += (this.state.speed * dt) / 1000;

    let wx = this.props.wx;
    const wy = this.props.wy;
    const wz = this.props.wz;
    const alt = this.props.latitude_deg;
    const az = this.props.longitude_deg;

    let w_len = BallHelper.getNetSpin({ wx, wy, wz });

    // To Prevent issues with zero spin
    if (Math.abs(w_len) < 1) {
      wx = -1;
      w_len = BallHelper.getNetSpin({ wx, wy, wz });
    }

    // Calculate rotations for the decoration
    const Rx_decor = new Quaternion();
    Rx_decor.setFromAxisAngle(
      new Vector3(1, 0, 0),
      Math.atan2(Math.sqrt(wx * wx + wy * wy), wz)
    );

    const Ry_decor = new Quaternion();
    Ry_decor.setFromAxisAngle(new Vector3(0, 1, 0), Math.atan2(wx, -wy));

    const R_decor = Ry_decor.multiply(Rx_decor);
    this.decoration.quaternion.copy(R_decor);

    // Calculate rotations for the ball (if it has been loaded)
    if (this.ball) {
      const Rx1_ball = new Quaternion();
      Rx1_ball.setFromAxisAngle(
        new Vector3(1, 0, 0),
        Math.atan2(Math.sqrt(wx * wx + wy * wy), wz)
      );

      const Ry1_ball = new Quaternion();
      Ry1_ball.setFromAxisAngle(new Vector3(0, 1, 0), Math.atan2(wx, -wy));

      const Rx2_ball = new Quaternion();
      Rx2_ball.setFromAxisAngle(new Vector3(1, 0, 0), (alt + 90) * DEG2RAD);

      const Rz2_ball = new Quaternion();
      Rz2_ball.setFromAxisAngle(new Vector3(0, 1, 0), DEG2RAD * az);

      let R_ball = Ry1_ball.multiply(
        Rx1_ball.multiply(Rx2_ball.multiply(Rz2_ball))
      );

      if (w_len > 0) {
        const ball_rotation = new Quaternion();
        ball_rotation.setFromAxisAngle(
          new Vector3(wx / w_len, wz / w_len, -wy / w_len),
          this.rotation
        );

        R_ball = ball_rotation.multiply(R_ball);
      }

      this.ball.quaternion.copy(R_ball);
    }
    this.renderer.render(this.scene, this.camera);
  };

  private handleWindowResize = () => {
    if (this.node) {
      this.camera.aspect = this.node.clientWidth / this.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.node.clientWidth, this.node.clientHeight);
    }
  };

  render() {
    return (
      <ErrorBoundary componentName="SpinView">
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Grid columns="2" gap={RADIX.FLEX.GAP.XS}>
            <Box>
              <Button
                color={RADIX.COLOR.NEUTRAL}
                value="batter"
                className="btn-block"
                variant={
                  this.state.perspective === 'batter'
                    ? RADIX.BUTTON.VARIANT.SELECTED
                    : RADIX.BUTTON.VARIANT.NOT_SELECTED
                }
                onClick={() => this.setPerspective('batter')}
              >
                {t('common.batter')}
              </Button>
            </Box>
            <Box>
              <Button
                color={RADIX.COLOR.NEUTRAL}
                value="pitcher-2"
                className="btn-block"
                variant={
                  this.state.perspective === 'pitcher-2'
                    ? RADIX.BUTTON.VARIANT.SELECTED
                    : RADIX.BUTTON.VARIANT.NOT_SELECTED
                }
                onClick={() => this.setPerspective('pitcher-2')}
              >
                {t('common.pitcher')}
              </Button>
            </Box>
          </Grid>

          <Box>
            <div
              style={{
                height: '350px',
              }}
              ref={(elem) => (this.node = elem as HTMLDivElement)}
            />
          </Box>

          <SpeedControlView
            speed={this.state.speed}
            onChange={(value) => this.setState({ speed: value })}
          />
        </Flex>
      </ErrorBoundary>
    );
  }
}
