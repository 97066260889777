import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  DragHandleDots2Icon,
} from '@radix-ui/react-icons';
import {
  Box,
  Flex,
  Heading,
  ScrollArea,
  Skeleton,
  Strong,
  Table,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonReorderDialog } from 'components/common/dialogs/reorder';
import { DragHandle } from 'components/common/drag-drop';
import { CommonSelectInput } from 'components/common/form/select';
import {
  ActionsCell,
  BasicCell,
  CheckboxCell,
} from 'components/common/table/body-cell';
import { CommonTableCheckedMenu } from 'components/common/table/checked-menu';
import { CommonTableHeaderCell } from 'components/common/table/header-cell';
import { CommonTablePaginationButton } from 'components/common/table/pagination-button';
import { DragItem } from 'enums/dnd.enums';
import {
  CHECKBOX_KEY,
  DRAGDROP_KEY,
  PaginationMode,
  TABLES,
} from 'enums/tables';
import { t } from 'i18next';
import {
  IDisplayCol,
  IOnKeyActionDict,
  ITableCheckable,
  ITableDraggable,
  ITablePageable,
  ITableSelectable,
  ITableSortable,
} from 'interfaces/i-tables';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  ICheckable,
  IMongoBase,
  ISortable,
} from 'lib_ts/interfaces/mongo/_base';
import React from 'react';
import { MainService } from 'services/main.service';

const MIN_PAGE_SIZE = 2;

/** note: only triggers if the relevant modifier is pressed while none others are pressed
 * Ctrl is required so as to not conflict with raw PageUp/PageDown events sent by presentation remotes
 */
const NAV_PREV_ITEM = 'ArrowUp';
const NAV_NEXT_ITEM = 'ArrowDown';
const NAV_PREV_PAGE = 'PageUp';
const NAV_NEXT_PAGE = 'PageDown';

const NAV_KEY_CODES = [
  NAV_NEXT_ITEM,
  NAV_PREV_ITEM,
  NAV_NEXT_PAGE,
  NAV_PREV_PAGE,
];

interface IPageCoordinates {
  page: number;
  index: number;
}

/** for safely calculating the next index given a desired pagination mode */
export const nextIndex = (config: {
  mode: PaginationMode;
  pageSize: number;
  /** current page */
  current: number;
  /** total number of items */
  total: number;
}) => {
  if (config.pageSize > 0) {
    const lastIndex = Math.ceil(config.total / config.pageSize) - 1;

    if (config.current >= 0 && config.current <= lastIndex) {
      switch (config.mode) {
        case 'first': {
          return 0;
        }

        case 'last': {
          return lastIndex;
        }

        case 'prev': {
          return Math.max(0, config.current - 1);
        }

        case 'next': {
          return Math.min(config.current + 1, lastIndex);
        }

        default: {
          throw new Error(`Unexpected pagination mode: ${config.mode}`);
        }
      }
    } else {
      NotifyHelper.warning({
        message_md: `Invalid current page provided to nextIndex; not between 0 and ${lastIndex}, value: ${config.current}`,
      });
    }
  } else {
    NotifyHelper.warning({
      message_md: `Invalid page size provided to nextIndex; not greater than 0, value: ${config.pageSize}`,
    });
  }
};

interface IProps
  extends Partial<ITableCheckable>,
    Partial<ITableDraggable>,
    Partial<ITablePageable>,
    Partial<ITableSortable>,
    Partial<ITableSelectable> {
  height?: string;
  vFlex?: boolean;

  /** id to be used for #id CSS selection, useful for writing PW tests */
  id: string;

  /** will appear above table, next to save order/reorder/etc... (if enabled) */
  toolbarContent?: React.ReactNode;

  /** will appear above table, below toolbar and/or save order/reorder/etc... (if enabled) */
  extraToolbarContent?: React.ReactNode;

  /** applied to <table> */
  className?: string;

  /** applied to each <tr> based on each row's value */
  rowClassNameFn?: (row: any) => string;

  /** applied to each <tr> based on each row's value */
  rowTestLocatorFn?: (row: any) => string;

  displayColumns: IDisplayCol[];
  displayData: any[];
  footerRow?: any;

  /** (temporarily) suspend key listener
   * (e.g. if multiple tables are visible at once or while other operations are processing)
   */
  suspendKeyListener?: boolean;

  /** define actions that should be executed upon specific keys being pressed
   * navigation keys (e.g. arrows, page up/down) provided here will be ignored
   * selected row's item (if any) will be provided to the function as a parameter
   */
  onKeyActions?: IOnKeyActionDict;

  disableScrolling?: boolean;

  noDataHeader?: string;
  noDataBody?: string;

  loading?: boolean;
}

interface IState {
  key: number;

  sortKey?: string;

  sortDir?: 1 | -1;

  /** current page, 0-indexed */
  page: number;

  /** initially equal to pageSize from props, can be updated if pageSizeOptions are provided */
  pageSize: number;

  /** if table is selectable, highlights selected row, reset on pagination, relative to current page */
  selectedIndex: number;

  sortedData: IMongoBase[];
  pageData: IMongoBase[];

  /** if reordering is allowed, i.e. orderCollection is provided */
  reorderDialog: boolean;

  displayColumns: IDisplayCol[];
}

export class CommonTable extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    if (props.pageSize !== undefined && props.pageSize < MIN_PAGE_SIZE) {
      NotifyHelper.warning({
        message_md: `Page size (if provided) must be at least ${MIN_PAGE_SIZE}.`,
      });
    }

    const displayColumns = [...props.displayColumns];

    if (props.checkboxColumnIndex !== undefined) {
      displayColumns.splice(props.checkboxColumnIndex, 0, {
        label: '#',
        key: CHECKBOX_KEY,
      });
    }

    if (this.props.dragType) {
      displayColumns.splice(0, 0, {
        label: ' ',
        key: DRAGDROP_KEY,
      });
    }

    this.state = {
      key: Date.now(),

      page: 0,
      pageSize: props.pageSize ?? TABLES.PAGE_SIZES.MD[0],
      pageData: [],

      sortDir: props.defaultSortDir,
      sortKey: props.defaultSortKey,
      sortedData: [],

      selectedIndex: -1,

      reorderDialog: false,
      displayColumns: displayColumns.filter((c) => !c.invisible),
    };

    this.updatePage = this.updatePage.bind(this);
    this.setCoordinates = this.setCoordinates.bind(this);
    this.getSelectedData = this.getSelectedData.bind(this);

    this.handleShiftClickRow = this.handleShiftClickRow.bind(this);

    this.goToNextPrevious = this.goToNextPrevious.bind(this);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCustomKey = this.handleCustomKey.bind(this);
    this.handleNavKey = this.handleNavKey.bind(this);

    this.handlePaginationControls = this.handlePaginationControls.bind(this);

    this.getCoordinatesByLookup = this.getCoordinatesByLookup.bind(this);

    this.applySort = this.applySort.bind(this);
    this.onSort = this.onSort.bind(this);

    this.showReorder = this.showReorder.bind(this);
    this.saveOrder = this.saveOrder.bind(this);

    this.renderFooterSummary = this.renderFooterSummary.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.renderLoadingBody = this.renderLoadingBody.bind(this);
    this.renderPageSelector = this.renderPageSelector.bind(this);
    this.renderReorderDialog = this.renderReorderDialog.bind(this);
    this.renderRowSelector = this.renderRowSelector.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    /** listen to keydown so we can cancel the scrolling effect */
    document.addEventListener('keydown', this.handleKeyDown);

    if (!this.init) {
      this.init = true;
      this.applySort();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const nextState: Partial<IState> = {};

    if (!ArrayHelper.equals(prevProps.displayData, this.props.displayData)) {
      nextState.key = Date.now();
    }

    /** check if current page is too large, e.g. after filtering while on the final page */
    if (this.state.page > this.lastPageIndex()) {
      nextState.page = this.lastPageIndex();
    }

    if (Object.keys(nextState).length > 0) {
      // console.debug(`state keys changed: ${Object.keys(nextState).join(', ')}`);
      this.setState(nextState as any, () => {
        if (nextState.key !== undefined || nextState.page !== undefined) {
          this.applySort();
        }
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  applySort() {
    const sorted = this.props.displayData;
    const sortColumn = this.state.displayColumns.find(
      (c) => c.key === this.state.sortKey
    );

    if (sortColumn && this.state.sortDir && this.state.sortKey) {
      sorted.sort((a, b) =>
        CommonTable.handleSort({
          a,
          b,
          column: sortColumn,
          dir: this.state.sortDir ?? 1,
          key: this.state.sortKey ?? '',
        })
      );
    } else {
      /** sort by _sort column by default */
      sorted.sort((a: ISortable, b: ISortable) => {
        return (a._sort ?? -1) < (b._sort ?? -1) ? -1 : 1;
      });
    }

    const iStart = this.props.enablePagination
      ? this.state.page * this.state.pageSize
      : 0;

    const iEnd = this.props.enablePagination
      ? (this.state.page + 1) * this.state.pageSize
      : undefined;

    const sliced = sorted.slice(iStart, iEnd);

    this.setState({
      sortedData: sorted,
      pageData: sliced,
    });
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (this.props.suspendKeyListener) {
      return;
    }

    if (
      !event.ctrlKey &&
      !event.shiftKey &&
      event.altKey &&
      NAV_KEY_CODES.includes(event.code)
    ) {
      this.handleNavKey(event);
      return;
    }

    if (
      this.props.onKeyActions &&
      Object.keys(this.props.onKeyActions).includes(event.code)
    ) {
      this.handleCustomKey(event);
      return;
    }
  }

  private handleCustomKey(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.repeat) {
      return;
    }

    this.props.onKeyActions?.[event.code]?.(this.getSelectedData());
  }

  private handleNavKey(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.repeat) {
      return;
    }

    /** index as per entire sorted data set, not current page */
    const currentGlobalIndex =
      this.state.selectedIndex !== -1
        ? this.state.pageSize * this.state.page + this.state.selectedIndex
        : -1;

    const currentCoord = this.getCoordinatesByIndex(currentGlobalIndex);

    const nextCoord: IPageCoordinates = (() => {
      switch (event.code) {
        case NAV_PREV_ITEM: {
          if (currentCoord.index <= 0) {
            if (currentCoord.page <= 0) {
              /** can't go any further, return current (i.e. do nothing) */
              return currentCoord;
            } else {
              /** go to previous page, last row */
              return {
                page: currentCoord.page - 1,
                index: this.lastIndexOnPage(currentCoord.page - 1),
              };
            }
          } else {
            /** stay on same page, one row above */
            return {
              page: currentCoord.page,
              index: currentCoord.index - 1,
            };
          }
        }

        case NAV_NEXT_ITEM: {
          if (currentCoord.index >= this.lastIndexOnPage(currentCoord.page)) {
            if (currentCoord.page >= this.lastPageIndex()) {
              /** can't go any further, return current (i.e. do nothing) */
              return currentCoord;
            } else {
              /** go to next page, first row */
              return {
                page: currentCoord.page + 1,
                index: 0,
              };
            }
          } else {
            /** stay on same page, one row below */
            return {
              page: currentCoord.page,
              index: currentCoord.index + 1,
            };
          }
        }

        case NAV_PREV_PAGE: {
          if (currentCoord.page <= 0) {
            /** go to start of current page */
            return {
              page: currentCoord.page,
              index: 0,
            };
          } else {
            /** go to previous page, last row */
            return {
              page: currentCoord.page - 1,
              index: this.lastIndexOnPage(currentCoord.page - 1),
            };
          }
        }

        case NAV_NEXT_PAGE: {
          if (currentCoord.page >= this.lastPageIndex()) {
            /** go to end of current page */
            return {
              page: currentCoord.page,
              index: this.lastIndexOnPage(currentCoord.page),
            };
          } else {
            /** go to next page, first row */
            return {
              page: currentCoord.page + 1,
              index: 0,
            };
          }
        }

        default: {
          return currentCoord;
        }
      }
    })();

    this.setCoordinates({
      target: nextCoord,
      cascade: false,
    });
  }

  updatePage(index: number) {
    if (index >= 0 && index <= this.lastPageIndex()) {
      this.setState({ page: index });
    } else {
      NotifyHelper.warning({
        message_md: 'Invalid page index: out of bounds.',
      });
    }
  }

  /** used by parent components to directly change which row is selected by coordinates */
  setCoordinates(config: {
    target: IPageCoordinates | undefined;
    cascade: boolean;
  }): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const safeCoordinates: IPageCoordinates = config.target ?? {
        page: 0,
        index: -1,
      };

      this.setState(
        {
          page: safeCoordinates.page,
          selectedIndex: safeCoordinates.index,
        },
        async () => {
          const disablePrev: boolean =
            safeCoordinates.index <= 0 && safeCoordinates.page <= 0;

          const disableNext: boolean =
            safeCoordinates.index >=
              this.lastIndexOnPage(safeCoordinates.page) &&
            safeCoordinates.page >= this.lastPageIndex();

          await this.props.afterSelectRow?.({
            model: this.getSelectedData(),
            disableNext: disableNext,
            disablePrev: disablePrev,
            cascade: config.cascade,
          });

          resolve(true);
        }
      );
    });
  }

  getSelectedData(): any | undefined {
    const data =
      this.state.selectedIndex === -1
        ? undefined
        : this.state.sortedData[
            this.state.page * this.state.pageSize + this.state.selectedIndex
          ];
    return data;
  }

  goToNextPrevious(direction: number, cascade: boolean) {
    if (this.props.blockSelection) {
      return;
    }

    /** index as per entire sorted data set, not current page */
    const currentGlobalIndex =
      this.state.selectedIndex !== -1
        ? this.state.pageSize * this.state.page + this.state.selectedIndex
        : -1;

    const currentCoord = this.getCoordinatesByIndex(currentGlobalIndex);

    const nextCoord: IPageCoordinates = (() => {
      switch (direction) {
        case -1: {
          if (currentCoord.index <= 0) {
            if (currentCoord.page <= 0) {
              /** can't go any further, return current (i.e. do nothing) */
              return currentCoord;
            } else {
              /** go to previous page, last row */
              return {
                page: currentCoord.page - 1,
                index: this.lastIndexOnPage(currentCoord.page - 1),
              };
            }
          } else {
            /** stay on same page, one row above */
            return {
              page: currentCoord.page,
              index: currentCoord.index - 1,
            };
          }
        }

        case 1: {
          if (currentCoord.index >= this.lastIndexOnPage(currentCoord.page)) {
            if (currentCoord.page >= this.lastPageIndex()) {
              /** can't go any further, return current (i.e. do nothing) */
              return currentCoord;
            } else {
              /** go to next page, first row */
              return {
                page: currentCoord.page + 1,
                index: 0,
              };
            }
          } else {
            /** stay on same page, one row below */
            return {
              page: currentCoord.page,
              index: currentCoord.index + 1,
            };
          }
        }

        default: {
          return currentCoord;
        }
      }
    })();

    this.setCoordinates({
      target: nextCoord,
      cascade: cascade,
    });
  }

  private lastIndexOnPage(page: number) {
    const start = page * this.state.pageSize;
    const pageData = this.state.sortedData.slice(
      start,
      start + this.state.pageSize
    );
    return pageData.length - 1;
  }

  /** for paging via the buttons in footer of table */
  private handlePaginationControls(mode: PaginationMode) {
    const targetPage = (() => {
      switch (mode) {
        case 'first': {
          return 0;
        }

        case 'last': {
          return this.lastPageIndex();
        }

        case 'next': {
          return this.state.page + 1;
        }

        case 'prev': {
          return this.state.page - 1;
        }
      }
    })();

    this.setState(
      {
        selectedIndex: -1, //also reset active row
        page: targetPage,
      },
      () => this.applySort()
    );
  }

  /** 0-indexed, provide forPageSize to determine the would-be last page index before switching */
  private lastPageIndex(forPageSize?: number): number {
    if (!this.props.enablePagination) {
      return 0;
    }

    if (this.props.total === undefined) {
      return 0;
    }

    return Math.max(
      0,
      Math.ceil(this.props.total / (forPageSize ?? this.state.pageSize)) - 1
    );
  }

  /** filters sortedData for items with _id value, maps all _id values to an array as is, updates order via generic function */
  private saveOrder() {
    if (this.props.orderCollection) {
      const sortedIDs = this.state.sortedData
        .filter((d) => !!d._id)
        .map((d) => d._id);

      if (sortedIDs.length > 0) {
        MainService.getInstance()
          .updateOrder({
            collection: this.props.orderCollection,
            ids: sortedIDs,
          })
          .then(() => {
            /** update _sort value locally */
            this.state.sortedData.forEach((d: ISortable, i) => (d._sort = i));
          });
      }
    }
  }

  /** can only show the dialog if an order collection is provided for saving the results */
  showReorder() {
    if (this.props.orderCollection) {
      this.setState({ reorderDialog: true });
    }
  }

  /** result should be 1 or -1 */
  static handleSort(config: {
    a: any;
    b: any;
    column: IDisplayCol;
    dir: 1 | -1;
    key: string;
  }): number {
    try {
      if (config.column.sortRowsFn) {
        /** special columns with unusual display values, like formatColumn/formatRow functions */
        return config.column.sortRowsFn(config.a, config.b, config.dir);
      } else {
        const dataType = typeof config.a[config.key];

        if (dataType === 'string') {
          const aStr = (config.a[config.key] ?? '').toLowerCase();
          const bStr = (config.b[config.key] ?? '').toLowerCase();

          return (
            config.dir *
            -aStr.localeCompare(bStr, 'en', { ignorePunctuation: true })
          );
        } else {
          /** best attempt */
          return (
            config.dir * (config.a[config.key] > config.b[config.key] ? -1 : 1)
          );
        }
      }
    } catch (e) {
      console.error(e);
      return 1;
    }
  }

  private async clickRow(config: { index: number; model: any }) {
    if (this.props.blockSelection) {
      return;
    }

    if (!this.props.afterSelectRow) {
      return;
    }

    this.setState(
      {
        selectedIndex: config.index,
      },
      async () => {
        const iGlobal =
          this.state.selectedIndex !== -1
            ? this.state.pageSize * this.state.page + this.state.selectedIndex
            : -1;

        const currCoord = this.getCoordinatesByIndex(iGlobal);

        const dPrev: boolean = currCoord.index <= 0 && currCoord.page <= 0;

        const dNext: boolean =
          currCoord.index >= this.lastIndexOnPage(currCoord.page) &&
          currCoord.page >= this.lastPageIndex();

        /** pass model back to parent for more actions */
        await this.props.afterSelectRow?.({
          model: config.model,
          disableNext: dNext,
          disablePrev: dPrev,
          cascade: false,
        });
      }
    );
  }

  /** provide key (like _id) and value, returns the page that the item would be found on along with index of the item from that slice */
  getCoordinatesByLookup(key: string, value: any): IPageCoordinates {
    const globalIndex = this.state.sortedData.findIndex(
      (m: any) => m[key] === value
    );
    return this.getCoordinatesByIndex(globalIndex);
  }

  getCoordinatesByIndex(globalIndex: number): IPageCoordinates {
    if (globalIndex === -1) {
      /** default behaviour, go to first page, no selection */
      return {
        page: 0,
        index: -1,
      };
    } else {
      const page = Math.floor(globalIndex / this.state.pageSize);
      return {
        page: page,
        index: globalIndex - page * this.state.pageSize,
      };
    }
  }

  private renderReorderDialog() {
    if (this.state.reorderDialog) {
      return (
        <CommonReorderDialog
          identifier={this.props.id + 'ReorderDialog'}
          items={this.state.sortedData.map((data, i) => {
            if (this.props.reorderItemFn) {
              return this.props.reorderItemFn(data);
            } else {
              return { id: `no-id-${i}`, label: `no-label-${i}` };
            }
          })}
          onReorder={(items) => {
            if (items.length !== this.state.sortedData.length) {
              NotifyHelper.error({
                message_md: `Invalid result from reorder operation, expected ${this.state.sortedData.length} items but received ${items.length} instead.`,
              });
              return;
            }
            /** in the order provided by results */
            const reorderedData: any[] = [];

            /** anything we can't find in the results */
            const unorderedData: any[] = [];

            this.state.sortedData.forEach((data: IMongoBase) => {
              /** original data */
              const index = items.findIndex((i) => i.id === data._id);
              if (index !== -1) {
                reorderedData[index] = data;
              } else {
                unorderedData.push(data);
              }
            });

            this.setState(
              {
                sortedData: [...reorderedData, ...unorderedData],
                reorderDialog: false,
              },
              () => this.saveOrder()
            );
          }}
          onClose={() => this.setState({ reorderDialog: false })}
        />
      );
    }
  }

  private handleShiftClickRow(config: { model: ICheckable; index: number }) {
    // deselect any selected/highlighted UI elements
    document.getSelection()?.removeAllRanges();

    const nextChecked = !config.model._checked;
    const indexBefore = this.state.selectedIndex;

    if (indexBefore === -1) {
      return;
    }

    // update every row between the selected row and the row being clicked
    const iStart = Math.min(indexBefore, config.index);
    const iEnd = Math.max(indexBefore, config.index);

    this.state.pageData
      .filter((_, i) => i >= iStart && i <= iEnd)
      .forEach((row: ICheckable) => {
        row._checked = nextChecked;
      });

    this.props.checkedCx?.updateTally();
  }

  private renderToolbar() {
    /** conditions go here */
    const showToolbar =
      this.props.toolbarContent !== undefined ||
      this.props.extraToolbarContent !== undefined ||
      this.props.orderCollection !== undefined;

    if (!showToolbar) {
      return;
    }

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        {this.props.toolbarContent}
        {this.props.extraToolbarContent}
        {this.renderReorderDialog()}
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        data-identifier="CommonTable"
        direction="column"
        gap={RADIX.FLEX.GAP.SM}
        style={
          this.props.vFlex
            ? {
                height: '100%',
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
              }
            : undefined
        }
      >
        {this.renderToolbar()}

        {this.props.disableScrolling ? (
          this.renderTable()
        ) : (
          <ScrollArea data-identifier="CommonTableScrollBox" scrollbars="both">
            {this.renderTable()}
          </ScrollArea>
        )}

        {this.renderFooter()}
      </Flex>
    );
  }

  private async onSort(key: string) {
    if (!this.props.enableSort) {
      return;
    }

    const direction =
      this.state.sortKey !== key
        ? // first mode
          1
        : this.state.sortDir === 1
        ? // second mode
          -1
        : // third mode
          undefined;

    await this.props.beforeSortFn?.(key, direction);

    this.setState(
      {
        sortKey: direction === undefined ? undefined : key,
        sortDir: direction,
      },
      () => this.applySort()
    );
  }

  private renderTable() {
    if (!this.props.loading && this.state.pageData.length === 0) {
      return (
        <Flex
          className="rt-TablePlaceholder"
          style={
            this.props.vFlex
              ? {
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  paddingBottom: '20%',
                }
              : {
                  height: '480px',
                }
          }
          direction="column"
          justify="center"
          align="center"
          gap="2"
        >
          <img
            className="select-none"
            src={`${window.origin}/img/no-data.svg`}
            alt="no-data"
            style={{
              width: '80%',
              maxWidth: '462px',
              height: 'auto',
            }}
          />

          <Text size="3">
            <Strong>
              {t(this.props.noDataHeader ?? 'common.no-data-to-display')}
            </Strong>
          </Text>

          <Text>{t(this.props.noDataBody ?? 'common.no-data-msg')}</Text>
        </Flex>
      );
    }

    return (
      <Table.Root
        // redraw whenever the data changes
        key={this.props.checkedCx?.dataKey ?? this.state.key}
        id={this.props.id}
        data-testid={this.props.id}
        className={this.props.className}
        style={
          this.props.vFlex
            ? {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }
            : undefined
        }
      >
        <Table.Header>
          <Table.Row>
            {this.state.displayColumns.map((col, iCol) => (
              <CommonTableHeaderCell
                key={iCol}
                col={col}
                onSort={this.onSort}
                sort={
                  !this.props.enableSort
                    ? undefined
                    : this.state.sortKey !== col.key
                    ? undefined
                    : this.state.sortDir === undefined
                    ? undefined
                    : this.state.sortDir === 1
                    ? 'asc'
                    : 'desc'
                }
                afterCheckAll={this.props.afterCheckAll}
              />
            ))}
          </Table.Row>
        </Table.Header>

        {this.props.loading && this.renderLoadingBody()}

        {!this.props.loading && (
          <Table.Body>
            {this.state.pageData.map((item, iRow) =>
              this.renderRow(iRow, item)
            )}
          </Table.Body>
        )}

        {!this.props.loading && this.props.footerRow && (
          <tfoot>{this.renderRow(-1, this.props.footerRow)}</tfoot>
        )}
      </Table.Root>
    );
  }

  private renderLoadingBody() {
    return (
      <Table.Body>
        {this.props.loading &&
          [1, 2, 3, 4, 5, 6].map((row) => (
            <Table.Row key={`skeleton-${row}`}>
              {this.state.displayColumns.map((_, cell) => (
                <Table.Cell key={`skeleton-${row}-${cell}`}>
                  <Skeleton />
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
      </Table.Body>
    );
  }

  private renderFooter() {
    if (!this.props.enablePagination && !this.props.checkedCx) {
      return;
    }

    return (
      <Flex gap={RADIX.FLEX.GAP.LG} justify="end">
        {this.renderFooterSummary()}
        {this.renderRowSelector()}
        {this.renderPageSelector()}
      </Flex>
    );
  }

  private renderFooterSummary() {
    return (
      <Flex flexGrow="1" direction="column" gap={RADIX.FLEX.GAP.SM}>
        {this.props.checkedCx && (
          <Flex gap={RADIX.FLEX.GAP.SM}>
            <Heading size={RADIX.HEADING.SIZE.MD} mb="1">
              {this.props.checkedCx.tally.checked}&nbsp;
              {t('common.selected').toLowerCase()}
            </Heading>
            {this.props.checkedMenuActions &&
              this.props.checkedCx.tally.checked > 0 && (
                <Box ml="1" className="valign-center">
                  <CommonTableCheckedMenu
                    actions={this.props.checkedMenuActions}
                  />
                </Box>
              )}
          </Flex>
        )}
      </Flex>
    );
  }

  private renderPageSelector() {
    if (!this.props.enablePagination) {
      return;
    }

    const safePage = this.state.page ?? 0;

    const isFirstPage = safePage === 0;
    const isLastPage = safePage === this.lastPageIndex();

    const iFirst = (this.state.page ?? 0) * this.state.pageSize + 1;
    const iLast =
      iFirst + Math.min(this.state.pageSize, this.state.pageData.length) - 1;

    const summary =
      this.state.pageData.length === 0
        ? t('common.no-data')
        : t('common.table-rows-summary', {
            first: iFirst,
            last: iLast,
            total: this.state.sortedData.length,
          });

    return (
      <Flex gap={RADIX.FLEX.GAP.MD}>
        <Flex gap={RADIX.FLEX.GAP.SM}>
          <CommonTablePaginationButton
            disabled={isFirstPage}
            tooltip={t('common.first-page').toString()}
            onClick={() => this.handlePaginationControls('first')}
            icon={<DoubleArrowLeftIcon />}
          />

          <CommonTablePaginationButton
            disabled={isFirstPage}
            tooltip={t('common.previous-page').toString()}
            onClick={() => this.handlePaginationControls('prev')}
            icon={<ChevronLeftIcon />}
          />
        </Flex>

        <Box className="valign-center">{summary}</Box>

        <Flex gap={RADIX.FLEX.GAP.XS}>
          <CommonTablePaginationButton
            disabled={isLastPage}
            tooltip={t('common.next-page').toString()}
            onClick={() => this.handlePaginationControls('next')}
            icon={<ChevronRightIcon />}
          />

          <CommonTablePaginationButton
            disabled={isLastPage}
            tooltip={t('common.last-page').toString()}
            onClick={() => this.handlePaginationControls('last')}
            icon={<DoubleArrowRightIcon />}
          />
        </Flex>
      </Flex>
    );
  }

  private renderRowSelector() {
    if (!this.props.enablePagination) {
      return;
    }

    if (!this.props.pageSizeOptions) {
      return;
    }

    return (
      <Flex gap={RADIX.FLEX.GAP.SM}>
        <Box className="valign-center">
          <Text>{t('common.rows-per-page')}:</Text>
        </Box>
        <Box>
          <CommonSelectInput
            id="common-table-page-size"
            name="pageSize"
            inputColor={RADIX.COLOR.NEUTRAL}
            variant="soft"
            options={this.props.pageSizeOptions.map((n) => {
              const o: IOption = {
                label: n.toString(),
                value: n.toString(),
              };
              return o;
            })}
            value={this.state.pageSize.toFixed()}
            onNumericChange={(v) => {
              if (v < MIN_PAGE_SIZE) {
                return;
              }

              /** compute whether page needs to change too */
              const nextLast = this.lastPageIndex(v);
              this.setState(
                {
                  pageSize: v,
                  page: Math.min(nextLast, this.state.page),
                },
                () => {
                  this.applySort();

                  if (this.props.pageSizeCallback) {
                    this.props.pageSizeCallback(v);
                  }
                }
              );
            }}
            skipSort
          />
        </Box>
      </Flex>
    );
  }

  private renderRow(iRow: number, item: any) {
    const rowClasses = [
      this.props.blockSelection
        ? 'cursor-not-allowed'
        : this.props.afterSelectRow
        ? 'cursor-pointer'
        : undefined,
      this.props.rowClassNameFn ? this.props.rowClassNameFn(item) : undefined,
    ];

    const menuID = `${this.props.id}-menu-${iRow}`;

    return (
      <Table.Row
        key={iRow}
        className={rowClasses.filter((c) => !!c).join(' ')}
        data-checked={(item as ICheckable)._checked}
        data-active={this.state.selectedIndex === iRow}
        data-testid={iRow}
        data-testlocator={
          this.props.rowTestLocatorFn
            ? this.props.rowTestLocatorFn(item)
            : undefined
        }
        onClick={(event) => {
          if (event.shiftKey) {
            this.handleShiftClickRow({
              index: iRow,
              model: item,
            });
            return;
          }

          this.clickRow({
            index: iRow,
            model: item,
          });
        }}
      >
        {this.state.displayColumns.map((col, iCol) => {
          switch (col.key) {
            case DRAGDROP_KEY: {
              return (
                <Table.Cell>
                  <DragHandle
                    value={item}
                    type={this.props.dragType ?? DragItem.DragNothing}
                    endFn={this.props.dragEndFn}
                  >
                    <DragHandleDots2Icon />
                  </DragHandle>
                </Table.Cell>
              );
            }

            case CHECKBOX_KEY: {
              if (this.props.checkedCx) {
                return (
                  <CheckboxCell
                    key={iCol}
                    menuID={menuID}
                    col={col}
                    item={item}
                    checkedCx={this.props.checkedCx}
                    afterCheckOne={this.props.afterCheckOne}
                  />
                );
              }

              return (
                <BasicCell key={iCol} menuID={menuID} col={col} item={item} />
              );
            }

            default: {
              if (col.actions) {
                /** draw actions menu with an option for each action */
                return (
                  <ActionsCell
                    key={iCol}
                    menuID={menuID}
                    col={col}
                    item={item}
                  />
                );
              }

              return (
                <BasicCell key={iCol} menuID={menuID} col={col} item={item} />
              );
            }
          }
        })}
      </Table.Row>
    );
  }
}
