import { Flex } from '@radix-ui/themes';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const SectionBox = (props: { children: React.ReactNode }) => (
  <Flex
    direction="column"
    gap={RADIX.FLEX.GAP.MD}
    className="rounded-md foreground"
  >
    {props.children}
  </Flex>
);
