import { EnvelopeOpenIcon, PlusIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Badge, Box, Code, Grid, Link } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { AnnouncementDialog } from 'components/common/dialogs/announcement';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { RefreshListsDialog } from 'components/sections/admin-portal/dialogs/refresh-lists';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { EditUserDialog } from 'components/sections/admin-portal/users/dialogs/edit-user';
import { ViewSessionsDialog } from 'components/sections/admin-portal/users/dialogs/view-sessions';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { ITeamsContext } from 'contexts/admin/teams.context';
import { IUsersContext } from 'contexts/admin/users.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
  ICheckedContext,
} from 'contexts/layout/checked.context';
import { PitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { AdminRoute } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { IDisplayCol, ITablePageable } from 'interfaces/i-tables';
import { UserRole, UserStatus } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IUser } from 'lib_ts/interfaces/i-user';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import React from 'react';
import { AdminUsersService } from 'services/admin/users.service';
import { DeleteUsersDialog } from './dialogs/delete-users';

const IDENTIFIER = TableIdentifier.AdminUserList;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  teamsCx: ITeamsContext;
  machinesCx: IMachinesContext;
  usersCx: IUsersContext;
}

interface IDialogs {
  dialogAnnouncement?: number;
  dialogDelete?: number;
  dialogEditor?: number;
  dialogReassign?: number;
  dialogRefresh?: number;
  dialogResetPassword?: number;
  dialogSessions?: number;
}

interface IState extends IDialogs {
  selected?: IUser;

  manageUser?: IUser;
  reassignListsPayload: IPitchListPutManyRequest;
  deleteUsers: IUser[];
}

export class UsersTable extends React.Component<IProps, IState> {
  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'common.edit',
          onClick: (user: IUser) => {
            this.setState({
              manageUser: user,
              dialogEditor: Date.now(),
            });
          },
        },
        {
          label: 'admin.reset-password',
          color: RADIX.COLOR.WARNING,
          onClick: (user: IUser) => {
            this.setState({
              manageUser: user,
              dialogResetPassword: Date.now(),
            });
          },
        },
        {
          label: 'admin.refresh-lists',
          color: RADIX.COLOR.WARNING,
          onClick: (m: IUser) => {
            this.setState({
              manageUser: m,
              dialogRefresh: Date.now(),
            });
          },
        },
        {
          label: 'common.reassign-lists',
          onClick: (user: IUser) => {
            const payload: IPitchListPutManyRequest = {
              filter: {
                _parent_id: user._id,
                _parent_def: 'team-users',
              },
              update: {
                processed: new Date(),
                process_notes: `reassigned from user ${user.email} by admin ${this.props.authCx.current.email}`,
                _parent_def: 'team-users',
              },
            };

            this.setState({
              dialogReassign: Date.now(),
              reassignListsPayload: payload,
            });
          },
          color: RADIX.COLOR.WARNING,
        },
        {
          label: 'admin.view-sessions',
          icon: <SuperAdminIcon />,
          invisibleFn: () => this.props.authCx.current.role !== UserRole.admin,
          onClick: (user: IUser) => {
            this.setState({
              dialogSessions: Date.now(),
              manageUser: user,
            });
          },
          color: RADIX.COLOR.WARNING,
        },
        {
          label: 'admin.impersonate',
          icon: <SuperAdminIcon />,
          invisibleFn: (user: IUser) =>
            this.props.authCx.current.role !== UserRole.admin ||
            user.status !== UserStatus.verified,
          onClick: (user: IUser) => {
            this.props.authCx.impersonate({ email: user.email });
          },
          color: RADIX.COLOR.WARNING,
        },
        {
          label: 'admin.toggle-access',
          onClick: (user: IUser) => {
            const wasVerified = user.status === UserStatus.verified;

            this.props.usersCx
              .updateUser(
                {
                  _id: user._id,
                  status: wasVerified
                    ? UserStatus.deactivated
                    : UserStatus.verified,
                },
                true
              )
              .then((success) => {
                if (success) {
                  NotifyHelper.success({
                    message_md: `User ${user.email} has been ${
                      wasVerified ? 'deactivated' : 'activated'
                    }!`,
                  });
                } else {
                  NotifyHelper.error({
                    message_md: `There was a problem ${
                      wasVerified ? 'deactivating' : 'activating'
                    } user ${user.email}.`,
                  });
                }
              });
          },
          color: RADIX.COLOR.DANGER,
        },
        {
          label: 'common.delete',
          icon: <SuperAdminIcon />,
          onClick: (user: IUser) => {
            this.setState({
              deleteUsers: [user],
              dialogDelete: Date.now(),
            });
          },
          color: RADIX.COLOR.DANGER,
          /** only super admins can delete users */
          invisibleFn: () => this.props.authCx.current.role !== UserRole.admin,
        },
      ],
    },
    {
      label: 'common.created',
      key: '_created',
      formatFn: (m: IMongoBase) =>
        lightFormat(parseISO(m._created), 'yyyy-MM-dd'),
    },
    {
      label: 'common.email',
      key: 'email',
      formatFn: (m: IUser) => <Link href={`mailto:${m.email}`}>{m.email}</Link>,
    },
    {
      label: 'common.machine',
      key: 'machineID',
      formatFn: (m: IUser) => <Badge>{m.machineID}</Badge>,
    },
    { label: 'admin.role', key: 'role' },
    {
      label: 'admin.access',
      key: '_access',
      formatFn: (v: IUser) => (
        <Badge
          color={
            v.status === UserStatus.verified
              ? RADIX.COLOR.SUCCESS
              : RADIX.COLOR.DANGER
          }
        >
          {v.status === UserStatus.verified ? 'Enabled' : 'Disabled'}
        </Badge>
      ),
      sortRowsFn: (a: IUser, b: IUser, dir: number) =>
        (a.status ?? '').localeCompare(b.status ?? '') * -dir,
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      reassignListsPayload: { filter: {}, update: {} },
      deleteUsers: [],
    };

    this.renderDialogs = this.renderDialogs.bind(this);
    this.getCheckedMenuActions = this.getCheckedMenuActions.bind(this);
    this.renderTableToolbar = this.renderTableToolbar.bind(this);
  }

  private renderDialogs() {
    return (
      <>
        {this.state.dialogResetPassword && this.state.manageUser && (
          <CommonConfirmationDialog
            identifier="ResetPassword"
            title="admin.reset-password"
            content={
              <p>
                Are you sure you want to reset{' '}
                <Code>{this.state.manageUser.email}</Code>'s password?
              </p>
            }
            action={{
              label: 'admin.reset-password',
              onClick: async () => {
                if (!this.state.manageUser) {
                  this.setState({
                    dialogResetPassword: undefined,
                  });
                  return;
                }

                const result =
                  await AdminUsersService.getInstance().resetPasswordUrl(
                    this.state.manageUser._id
                  );

                if (!result.success) {
                  NotifyHelper.warning({
                    message_md:
                      result.error ??
                      `There was an error generating a reset password URL for ${
                        this.state.manageUser?.email ?? '(no email)'
                      }.`,
                  });
                  return;
                }

                const url = result.data as string;

                NotifyHelper.info({
                  message_md:
                    'Password reset URL generated. How would you like to notify the user?',
                  delay_ms: 0,
                  buttons: [
                    {
                      label: 'Link',
                      dismissAfterClick: true,
                      onClick: () => {
                        navigator.clipboard.writeText(url).then(() =>
                          NotifyHelper.success({
                            message_md: 'Copied URL to clipboard.',
                          })
                        );
                      },
                    },
                    {
                      label: 'Email',
                      dismissAfterClick: true,
                      onClick: () => {
                        NotifyHelper.info({
                          message_md: 'Attempting to open email client...',
                        });

                        /** this will allow the mailto link to preserve the ?email and &code parts of the URL */
                        const escapedUrl = url
                          .replace('?', '%3F')
                          .replace('&', '%26');
                        window.open(
                          `mailto:${this.state.manageUser?.email}?subject=Trajekt App Password Reset&body=Please visit the following link to  reset your password:%0D%0A%0D%0A${escapedUrl}`
                        );
                      },
                    },
                  ],
                });

                this.setState({
                  dialogResetPassword: undefined,
                });
              },
            }}
            cancel={{
              onClick: () =>
                this.setState({
                  dialogResetPassword: undefined,
                }),
            }}
          />
        )}
        {this.state.dialogSessions && this.state.manageUser && (
          <ViewSessionsDialog
            key={this.state.dialogSessions}
            user={this.state.manageUser}
            onClose={() => this.setState({ dialogSessions: undefined })}
          />
        )}

        {this.state.dialogRefresh && this.state.manageUser && (
          <RefreshListsDialog
            key={this.state.dialogRefresh}
            name={this.state.manageUser.email}
            parent_def="team-users"
            parent_id={this.state.manageUser._id}
            onClose={() => this.setState({ dialogRefresh: undefined })}
          />
        )}

        {this.state.dialogReassign && (
          <PitchListsContext.Consumer>
            {(listsCx) => (
              <ReassignListsDialog
                key={this.state.dialogReassign}
                identifier="UsersTableReassignListsDialog"
                authCx={this.props.authCx}
                listsCx={listsCx}
                refPayload={this.state.reassignListsPayload}
                onClose={() => this.setState({ dialogReassign: undefined })}
              />
            )}
          </PitchListsContext.Consumer>
        )}

        {this.state.dialogAnnouncement && (
          <AnnouncementDialog
            key={this.state.dialogAnnouncement}
            identifier="UsersTableAnnouncementDialog"
            authCx={this.props.authCx}
            teamsCx={this.props.teamsCx}
            machinesCx={this.props.machinesCx}
            usersCx={this.props.usersCx}
            onClose={() => this.setState({ dialogAnnouncement: undefined })}
          />
        )}

        {this.state.dialogEditor && (
          <AuthContext.Consumer>
            {(authCx) => (
              <EditUserDialog
                key={this.state.dialogEditor}
                id={this.state.manageUser?._id}
                authCx={authCx}
                teamsCx={this.props.teamsCx}
                machinesCx={this.props.machinesCx}
                usersCx={this.props.usersCx}
                onClose={() => this.setState({ dialogEditor: undefined })}
              />
            )}
          </AuthContext.Consumer>
        )}
      </>
    );
  }

  private getCheckedMenuActions(): IMenuAction[] {
    const checked = this.props.usersCx.users.filter((p) => p._checked === true);

    const actions: IMenuAction[] = [
      {
        label: 'admin.copy-emails',
        onClick: () => {
          navigator.clipboard
            .writeText(checked.map((u) => u.email).join(';'))
            .then(() =>
              NotifyHelper.success({
                message_md: 'Copied user emails to clipboard!',
              })
            );
        },
      },
      {
        label: 'common.delete',
        icon: <SuperAdminIcon />,
        color: RADIX.COLOR.DANGER,
        invisible: this.props.authCx.current.role !== UserRole.admin,
        onClick: () =>
          this.setState({
            deleteUsers: checked,
            dialogDelete: Date.now(),
          }),
      },
    ];

    return actions;
  }

  private renderTableToolbar(checkedCx: ICheckedContext) {
    return (
      <Grid columns="6" gap={RADIX.FLEX.GAP.SM}>
        <Box>
          <CommonSearchInput
            id="users-email"
            placeholder="common.email"
            options={this.props.usersCx.options.emails}
            values={this.props.usersCx.filter.email}
            onChange={(v) => {
              checkedCx.checkAll(false);
              this.props.usersCx.setFilter({ email: v });
            }}
            multiple
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="users-team"
            placeholder="common.team"
            options={this.props.teamsCx.options.teams}
            values={this.props.usersCx.filter.team}
            onChange={(v) => {
              checkedCx.checkAll(false);
              this.props.usersCx.setFilter({ team: v });
            }}
            multiple
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="users-created"
            placeholder="common.date-added"
            options={this.props.usersCx.options._created.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.props.usersCx.filter.created}
            onChange={(v) => {
              checkedCx.checkAll(false);
              this.props.usersCx.setFilter({ created: v });
            }}
            multiple
            optional
            reverseSort
          />
        </Box>

        <CommonTableButton
          label="common.refresh"
          icon={<UpdateIcon />}
          color={RADIX.COLOR.NEUTRAL}
          disabled={this.props.usersCx.loading}
          onClick={() => {
            checkedCx.checkAll(false);
            this.props.usersCx.refresh();
          }}
        />

        <CommonTableButton
          color={RADIX.COLOR.SUCCESS}
          disabled={this.props.usersCx.loading}
          onClick={() => {
            this.setState({
              manageUser: undefined,
              dialogEditor: Date.now(),
            });
          }}
          icon={<PlusIcon />}
          label="common.create"
        />

        <CommonTableButton
          color={RADIX.COLOR.WARNING}
          disabled={this.props.usersCx.loading}
          onClick={() => {
            this.setState({
              dialogAnnouncement: Date.now(),
            });
          }}
          icon={<EnvelopeOpenIcon />}
          label="admin.announcement"
        />
      </Grid>
    );
  }

  render() {
    const pagination: ITablePageable = {
      total: this.props.usersCx.filtered.length,
      enablePagination: true,
      pageSize: this.props.cookiesCx.getPageSize(IDENTIFIER) ?? PAGE_SIZES[0],
      pageSizeOptions: PAGE_SIZES,
      pageSizeCallback: (value) =>
        this.props.cookiesCx.setPageSize(IDENTIFIER, value),
    };

    return (
      <ErrorBoundary componentName="UsersTable">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={<AdminTabNav active={AdminRoute.Users} />}
          table={
            <GlobalContext.Consumer>
              {(globalCx) => (
                <CheckedProvider data={this.props.usersCx.filtered}>
                  <CheckedContext.Consumer>
                    {(checkedCx) => (
                      <CommonTable
                        id="AdminUsers"
                        checkedCx={checkedCx}
                        checkedMenuActions={this.getCheckedMenuActions()}
                        suspendKeyListener={globalCx.dialogs.length > 0}
                        toolbarContent={this.renderTableToolbar(checkedCx)}
                        displayColumns={this.BASE_COLUMNS}
                        displayData={this.props.usersCx.filtered}
                        afterSelectRow={async (config) =>
                          this.setState({ selected: config.model })
                        }
                        checkboxColumnIndex={0}
                        {...pagination}
                        defaultSortKey="email"
                        defaultSortDir={-1}
                        enableSort
                        vFlex
                      />
                    )}
                  </CheckedContext.Consumer>
                </CheckedProvider>
              )}
            </GlobalContext.Consumer>
          }
        />

        {this.renderDialogs()}

        {this.state.dialogDelete && this.state.deleteUsers.length > 0 && (
          <DeleteUsersDialog
            key={this.state.dialogDelete}
            users={this.state.deleteUsers}
          />
        )}
      </ErrorBoundary>
    );
  }
}
