import { DownloadIcon, PlusIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Box, Flex, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonChecklist } from 'components/common/checklist';
import { CommonDetails } from 'components/common/details';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonDateInput } from 'components/common/form/date';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { PrintMetric } from 'components/sections/admin-portal/machine-models/metrics/print-metric';
import { SummarizeModelMetrics } from 'components/sections/admin-portal/machine-models/metrics/summarize-model-metrics';
import { HistogramGroup } from 'components/sections/admin-portal/machine-models/preview-plots/histogram-group';
import {
  PlotGroupStd,
  PlotMode,
} from 'components/sections/admin-portal/machine-models/preview-plots/plot-group-std';
import env from 'config';
import { IMachineModelsContext } from 'contexts/admin/machine-models.context';
import { IMachinesContext } from 'contexts/admin/machines.context';
import {
  addWeeks,
  endOfToday,
  isBefore,
  parseISO,
  startOfToday,
} from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT_SHORT, LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { IFullDialog } from 'interfaces/i-dialogs';
import { ALLOWED_MODEL_TYPES } from 'interfaces/i-models';
import { FT_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { BallType } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPythonEvalModelsResult } from 'lib_ts/interfaces/modelling/i-eval-models';
import { IGatherSummary } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import { ITrainModelsRequest } from 'lib_ts/interfaces/modelling/i-train-model';
import React from 'react';
import {
  AdminMachineModelsService,
  getDefaultDataFilter,
} from 'services/admin/machine-models.service';

const COMPONENT_NAME = 'MachineModelCreationDialog';

enum Step {
  Creation,
  Review,
}

interface IProps {
  /** used for copying details from */
  refModel?: IMachineModel;
  machinesCx: IMachinesContext;
  machineModelsCx: IMachineModelsContext;
  onClose: (refresh: boolean) => void;
}

interface IState {
  step: Step;

  // for date picker
  start: Date;
  // for date picker
  end: Date;

  summary?: IGatherSummary[];
  created?: IPythonEvalModelsResult;

  loading?: boolean;

  requirePreview: boolean;
  model: ITrainModelsRequest;

  hash?: string;
}

export class MachineModelCreationDialog extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);

    const start = addWeeks(startOfToday(), -2);
    const end = new Date();

    this.state = {
      start: start,
      end: end,
      step: Step.Creation,
      requirePreview: true,
      model: {
        ...getDefaultDataFilter(start, end),
        machineID: props.refModel?.machineID,
        model_types: props.refModel ? [props.refModel.type] : [],
      },
    };

    this.handleCreate = this.handleCreate.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderPreviewPlots = this.renderPreviewPlots.bind(this);
    this.renderPreviewData = this.renderPreviewData.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);
    this.validateModel = this.validateModel.bind(this);
  }

  private updateMetadata(
    model: Partial<ITrainModelsRequest>,
    resetPreview = true
  ) {
    this.setState({
      requirePreview: resetPreview || this.state.requirePreview,
      model: {
        ...this.state.model,
        ...model,
      },
    });
  }

  private renderForm() {
    const machine = this.props.machinesCx.machines.find(
      (m) => m.machineID === this.state.model.machineID
    );

    const minStartDate = machine?.last_hardware_changed
      ? parseISO(machine.last_hardware_changed)
      : undefined;

    const selectMachineCallback = (machineID: string | undefined) => {
      const machine = this.props.machinesCx.machines.find(
        (m) => m.machineID === machineID
      );

      if (!machine) {
        // user might still be typing a selection, just update the value
        this.setState({
          model: { ...this.state.model, machineID: machineID },
        });
        return;
      }

      const minStartDate = machine.last_hardware_changed
        ? parseISO(machine.last_hardware_changed)
        : undefined;

      const safeStartDate =
        minStartDate && isBefore(this.state.start, minStartDate)
          ? minStartDate
          : this.state.start;

      this.setState({ start: safeStartDate });

      this.updateMetadata({
        machineID: machine.machineID,
        start_date: safeStartDate.toISOString(),
      });
    };

    return (
      <CommonFormGrid columns={3}>
        {/* row 1 */}
        <CommonSearchInput
          id="create-model-machineID"
          label="common.machine"
          options={this.props.machinesCx.machines.map((o) => ({
            label: `${o.machineID} (${o.nickname ?? 'no nickname'})`,
            value: o.machineID,
          }))}
          values={
            this.state.model.machineID ? [this.state.model.machineID] : []
          }
          onChange={(machineIDs) => {
            selectMachineCallback(machineIDs[0]);
          }}
        />
        <CommonSelectInput
          id="create-model-ball"
          name="ball_type"
          label="Ball Type"
          disabled={this.state.loading}
          options={Object.values(BallType).map((m) => ({
            label: m,
            value: m,
          }))}
          value={this.state.model.ball_type}
          onChange={(v) => this.updateMetadata({ ball_type: v as BallType })}
          optional
        />
        {/* row 2 */}
        <Box gridColumn="1">
          <CommonDateInput
            id="create-model-start"
            label="common.start-date"
            defaultValue={this.state.start}
            minDate={minStartDate}
            maxDate={this.state.end}
            onChange={(date) => {
              if (!date) {
                NotifyHelper.warning({
                  message_md: t('common.check-inputs-msg'),
                });
                return;
              }

              this.setState({
                start: date,
              });

              this.updateMetadata({
                start_date: date.toISOString(),
              });
            }}
            showTime
          />
        </Box>
        <CommonDateInput
          id="create-model-end"
          label="common.end-date"
          defaultValue={this.state.end}
          minDate={this.state.start}
          maxDate={endOfToday()}
          onChange={(date) => {
            if (!date) {
              NotifyHelper.warning({
                message_md: t('common.check-inputs-msg'),
              });
              return;
            }

            this.setState({
              end: date,
            });

            this.updateMetadata({
              end_date: date.toISOString(),
            });
          }}
          showTime
        />
        <CommonTextInput
          id="create-model-session"
          label="Session"
          name="session"
          disabled={this.state.loading}
          value={this.state.model.session}
          onChange={(v) =>
            this.updateMetadata({
              session: v,
            })
          }
          hint_md="Like \`AAAAAAAA-...-BBBB.X\`"
          optional
        />
        <CommonTextInput
          id="create-model-min-groups"
          label="Min Groups"
          type="number"
          name="min_groups"
          disabled={this.state.loading}
          value={this.state.model.min_groups?.toString()}
          placeholder="Type an integer value"
          onOptionalNumericChange={(e) => {
            this.updateMetadata({
              min_groups: e === undefined ? undefined : Math.round(e),
            });
          }}
          hint_md="Minimum number of unique target machine states. This value is ignored for previews."
        />
        <CommonTextInput
          id="create-model-max-groups"
          label="Max Groups"
          type="number"
          name="max_groups"
          disabled={this.state.loading}
          value={this.state.model.max_groups?.toString()}
          placeholder="Type an integer value"
          onOptionalNumericChange={(e) => {
            this.updateMetadata({
              max_groups: e === undefined ? undefined : Math.round(e),
            });
          }}
          hint_md="Maximum number of unique target machine states."
          optional
        />
        {/* row */}
        <Box gridColumn="1">
          <CommonTextInput
            id="create-model-min-group-size"
            label="Min Group Size"
            type="number"
            name="min_group_size"
            disabled={this.state.loading}
            value={this.state.model.min_group_size?.toString()}
            placeholder="Type an integer value"
            onOptionalNumericChange={(e) => {
              this.updateMetadata({
                min_group_size: e === undefined ? undefined : Math.round(e),
              });
            }}
            hint_md="Minimum number of shots per target machine state."
            optional
          />
        </Box>
        <CommonTextInput
          id="create-model-record-limit"
          label="Record Limit"
          type="number"
          name="limit"
          disabled={this.state.loading}
          value={this.state.model.limit?.toString()}
          placeholder="Type an integer value"
          onOptionalNumericChange={(v) => {
            this.updateMetadata({
              limit: v === undefined ? undefined : Math.round(v),
            });
          }}
          hint_md="Query limit on maximum number of records to use."
          optional
        />
        <Box gridColumn="span 3">
          <CommonDetails summary="Advanced">
            <CommonFormGrid columns={3}>
              <CommonTextInput
                id="create-model-max-std-v"
                label="Max StDev V (mph)"
                type="number"
                name="speed_stdev_limit_mph"
                disabled={this.state.loading}
                value={this.state.model.speed_stdev_limit_mph?.toString()}
                placeholder="Type a value"
                onOptionalNumericChange={(v) => {
                  this.updateMetadata({
                    speed_stdev_limit_mph: v,
                  });
                }}
                hint_md="Maximum Velo StDev of a Group"
                optional
              />
              <CommonTextInput
                id="create-model-max-std-w"
                label="Max StDev W (rpm)"
                type="number"
                name="spin_stdev_limit_rpm"
                disabled={this.state.loading}
                value={this.state.model.spin_stdev_limit_rpm?.toString()}
                placeholder="Type a value"
                onOptionalNumericChange={(v) => {
                  this.updateMetadata({
                    spin_stdev_limit_rpm: v,
                  });
                }}
                hint_md="Maximum Spin StDev of a Group"
                optional
              />
              <CommonTextInput
                id="create-model-max-std-break"
                label="Max StDev Break (in)"
                type="number"
                name="break_stdev_limit_in"
                disabled={this.state.loading}
                value={
                  this.state.model.break_stdev_limit_ft !== undefined
                    ? (
                        this.state.model.break_stdev_limit_ft * FT_TO_INCHES
                      ).toFixed(4)
                    : undefined
                }
                placeholder="Type a value"
                onOptionalNumericChange={(maybeNum) => {
                  this.updateMetadata({
                    break_stdev_limit_ft:
                      maybeNum === undefined
                        ? undefined
                        : maybeNum / FT_TO_INCHES,
                  });
                }}
                hint_md="Maximum Break StDev of a Group"
                optional
              />
            </CommonFormGrid>
          </CommonDetails>
        </Box>

        <Box gridColumn="span 3">
          <Heading size={RADIX.HEADING.SIZE.SM} mb={RADIX.FLEX.GAP.INPUT}>
            Model Types
          </Heading>

          <CommonFormGrid columns={3}>
            {[0, 1, 2].map((iCol) => (
              <Box key={`model-types-${iCol}`}>
                <CommonChecklist
                  id={`create-model-types-${iCol}`}
                  name="types"
                  values={this.state.model.model_types ?? []}
                  disabled={this.state.loading}
                  options={ALLOWED_MODEL_TYPES.filter(
                    (_, i) => i % 3 === iCol
                  ).map((m) => ({
                    label: m.name,
                    value: m.value,
                    disabled:
                      env.identifier.includes('prod') && !m.production_enabled,
                    title: m.title,
                  }))}
                  onChange={(selected) => {
                    this.updateMetadata(
                      {
                        model_types: selected,
                      },
                      false
                    );
                  }}
                />
              </Box>
            ))}
          </CommonFormGrid>
        </Box>
      </CommonFormGrid>
    );
  }

  private renderPreviewData() {
    if (!this.state.summary) {
      return <></>;
    }

    return (
      <CommonDetails summary="Raw Data">
        <pre>{JSON.stringify(this.state.summary, null, 2)}</pre>
      </CommonDetails>
    );
  }

  private renderPreviewPlots() {
    if (!this.state.summary) {
      return <></>;
    }

    const modes: PlotMode[][] = [
      ['std_vy', 'std_wx'],
      ['std_wy', 'std_wz'],
      ['std_break_x_in', 'std_break_z_in'],
    ];

    return (
      <CommonDetails summary="Preview" defaultOpen>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <HistogramGroup
            mode="target_ms_group_size"
            groups={this.state.summary}
          />

          {modes.map((row, iRow) => (
            <Box key={`mode-row-${iRow}`}>
              {row.map((mode, iMode) => (
                <PlotGroupStd
                  key={`mode-${iRow}-${iMode}`}
                  mode={mode}
                  groups={this.state.summary ?? []}
                />
              ))}
            </Box>
          ))}
        </Flex>
      </CommonDetails>
    );
  }

  private renderResults() {
    if (!this.state.created) {
      return <></>;
    }

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Heading size={RADIX.HEADING.SIZE.SM}>Results</Heading>

        <SummarizeModelMetrics metrics={this.state.created.results ?? []} />

        <CommonDetails summary="Raw Metrics">
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            {this.state.created.results?.map((m) => (
              <PrintMetric key={`print-metric-${m.model_id}`} metric={m} />
            ))}
          </Flex>
        </CommonDetails>
      </Flex>
    );
  }

  private renderContent() {
    switch (this.state.step) {
      case Step.Review: {
        return this.renderResults();
      }

      case Step.Creation: {
        return (
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            {this.renderForm()}
            {this.renderPreviewPlots()}
            {this.renderPreviewData()}
          </Flex>
        );
      }

      default: {
        return <></>;
      }
    }
  }

  private validateModel(config: {
    checkTypes: boolean;
    checkMinGroups: boolean;
  }): boolean {
    if (!this.state.model.ball_type) {
      NotifyHelper.error({
        message_md: 'Please select a ball type and try again.',
      });
      return false;
    }

    const machine = this.props.machinesCx.machines.find(
      (m) => m.machineID === this.state.model.machineID
    );

    if (!machine) {
      NotifyHelper.error({
        message_md: 'Please select a valid machine and try again.',
      });
      return false;
    }

    const minStartDate = machine.last_hardware_changed
      ? parseISO(machine.last_hardware_changed)
      : undefined;

    if (minStartDate && isBefore(this.state.start, minStartDate)) {
      const startDS = format(this.state.start, LOCAL_DATETIME_FORMAT_SHORT, {
        timeZone: LOCAL_TIMEZONE,
      });

      const minStartDS = format(minStartDate, LOCAL_DATETIME_FORMAT_SHORT, {
        timeZone: LOCAL_TIMEZONE,
      });

      NotifyHelper.error({
        message_md: `Start date (${startDS}) cannot be earlier than last hardware changed date (${minStartDS}). Please check inputs and try again.`,
      });
      return false;
    }

    if (config.checkTypes) {
      if (
        !this.state.model.model_types ||
        this.state.model.model_types.length === 0
      ) {
        NotifyHelper.error({
          message_md: 'Please select at least one model type and try again.',
        });
        return false;
      }
    }

    if (config.checkMinGroups) {
      if (this.state.model.min_groups === undefined) {
        NotifyHelper.warning({
          message_md: `Please provide a valid min groups value and try again.`,
        });
        return false;
      }

      if (!this.state.summary) {
        NotifyHelper.warning({
          message_md: `Please preview your query and try again.`,
        });
        return false;
      }

      const selectedTypes = ALLOWED_MODEL_TYPES.filter((m) =>
        this.state.model.model_types.includes(m.value)
      ).sort((a, b) => (a.min_groups > b.min_groups ? -1 : 1));

      const maxGroupType = selectedTypes[0];

      if (maxGroupType && this.state.summary.length < maxGroupType.min_groups) {
        NotifyHelper.warning({
          message_md: `${maxGroupType.name} requires at least ${maxGroupType.min_groups} min groups, but your query only contains ${this.state.summary.length}. Please deselect this model type or increase min groups and try again.`,
        });
        return false;
      }
    }

    return true;
  }

  private handleCreate() {
    if (
      this.validateModel({ checkTypes: true, checkMinGroups: true }) &&
      !this.state.loading
    ) {
      this.setState({ loading: true });

      NotifyHelper.warning({
        message_md:
          'Creating model(s), please wait as this may take some time...',
      });

      this.props.machineModelsCx
        .trainModels(this.state.model)
        .then((result) => {
          this.setState({
            step: Step.Review,
            created: result,
          });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private async handlePreview() {
    if (!this.validateModel({ checkTypes: false, checkMinGroups: false })) {
      return;
    }

    // set summary to undefined so that the graph components are destroyed and recreated
    this.setState({ loading: true, summary: undefined });

    NotifyHelper.warning({
      message_md:
        'Generating preview, please wait as this may take some time...',
    });

    const result = await AdminMachineModelsService.getInstance()
      .gatherSummary({ ...this.state.model, min_groups: 0 })
      .finally(() => {
        this.setState({ loading: false });
      });

    const nextHash = MiscHelper.hashify(this.state.model);

    this.setState({
      hash: nextHash,
      summary: result,
      requirePreview: false,
    });
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.XL,
      title: 'Create Model',
      loading: this.props.machineModelsCx.loading,
      content: this.renderContent(),
      buttons: [
        {
          icon: this.state.summary ? <UpdateIcon /> : <DownloadIcon />,
          label: this.state.summary ? 'Update Preview' : 'Fetch Preview',
          color: RADIX.COLOR.SUCCESS,
          disabled:
            this.state.loading ||
            MiscHelper.hashify(this.state.model) === this.state.hash,
          invisible: this.state.step !== Step.Creation,
          onClick: () => this.handlePreview(),
        },
        {
          icon: <PlusIcon />,
          label: 'common.create',
          color: RADIX.COLOR.INFO,
          tooltip: this.state.summary
            ? 'Please update preview first.'
            : 'Please fetch preview first.',
          disabled: this.state.loading || this.state.requirePreview,
          invisible: !!this.state.created,
          onClick: () => this.handleCreate(),
        },
      ],
      onClose: () => this.props.onClose(false),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
