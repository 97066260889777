import { NotifyHelper } from 'classes/helpers/notify.helper';
import { HardwareVersion } from 'lib_ts/enums/machine.enums';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import {
  IVideo,
  IVideoFrame,
  IVideoFrameSpec,
  IVideoPlayback,
} from 'lib_ts/interfaces/i-video';
import { BaseRESTService } from 'services/_base-rest.service';

interface IFileReport {
  tempID: string;
  filename: string;
  skipped: boolean;
  message: string;
}

export class VideosService extends BaseRESTService {
  private static instance: VideosService;
  static getInstance(): VideosService {
    if (!VideosService.instance) {
      VideosService.instance = new VideosService();
    }

    return VideosService.instance;
  }

  private constructor() {
    super({
      controller: 'videos',
    });
  }

  /** get list of user's videos */
  async getVideos(): Promise<IVideo[]> {
    return await this.get({
      uri: 'list',
    });
  }

  /** get list of user's videos as CSV string, use an empty list of ids to get everything */
  async exportCSV(videoIDs: string[]): Promise<string> {
    return await this.post(
      {
        uri: 'export/csv',
      },
      { ids: videoIDs }
    );
  }

  /** import current team's CSV to update the values; import another team's CSV to create a copy of those video records for current team's use */
  async importCSV(
    formData: FormData
  ): Promise<{ event: string; success: boolean; error?: any }> {
    return await this.post(
      {
        uri: 'import/csv',
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      formData
    );
  }

  /** get signed S3 URL for a particular video */
  async getVideoPlayback(videoID: string): Promise<IVideoPlayback> {
    return await this.get({
      uri: 'url',
      params: {
        video_id: videoID,
      } as any,
    });
  }

  async deleteVideos(videoIDs: string[]): Promise<IServerResponse> {
    return await this.post(
      {
        uri: 'delete',
      },
      videoIDs
    );
  }

  /** the original records will be copied, with new _id values */
  async copyVideos(videoIDs: string[]): Promise<IServerResponse> {
    return await this.post(
      {
        uri: 'copy',
      },
      videoIDs
    );
  }

  /** via server, one or more preview images (at various scales) of a specific frame from video */
  async extractFrames(config: {
    video_id: string;
    mode: 'raw' | 'preview';
    frameSpec: Partial<IVideoFrameSpec>;
    frame_indices: number[];
    scales: number[];
    hardware_version: HardwareVersion;
  }): Promise<IVideoFrame[]> {
    if (config.frame_indices.filter((i) => i > 0).length === 0) {
      NotifyHelper.warning({
        message_md: 'Cannot extract frames without non-zero frame index.',
      });
      return [];
    }

    if (
      config.mode === 'preview' &&
      (!config.scales || config.scales.filter((s) => s > 0).length === 0)
    ) {
      NotifyHelper.warning({
        message_md:
          'Cannot extract preview frames without any positive scale values.',
      });
      return [];
    }

    return await this.post(
      {
        uri: `frames/${config.mode}`,
        params: {
          video_id: config.video_id,
        } as any,
      },
      {
        frameSpec: config.frameSpec,
        frame_indices: config.frame_indices,
        scales: config.scales,
        hardware_version: config.hardware_version,
      }
    );
  }

  /** provide extraConfig for tracking progress of uploads */
  async postVideos(
    formData: FormData,
    onProgress?: (event: ProgressEvent) => void
  ): Promise<{ event: string; reports: IFileReport[] }> {
    return await this.post(
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      formData,
      onProgress
    );
  }

  /** the original record will be updated */
  async putVideo(data: Partial<IVideo>): Promise<IServerResponse> {
    if (!data._id) {
      throw new Error('Cannot put without id');
    }
    return await this.put(
      {
        uri: 'metadata',
        params: {
          video_id: data._id,
        } as any,
      },
      data
    );
  }
}
