import { Code, Flex, Spinner, Table, Text } from '@radix-ui/themes';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IReadyMsg } from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';

const TRUTHY_VALUES = [true, 'True'];

const COMPONENT_NAME = 'R2FStatusDialog';

interface IProps extends IBaseDialog {}

interface IState {
  lastR2F?: IReadyMsg;
  lastUpdate?: Date;
}

const PROC_CATEGORIES: { name: string; attributes: string[] }[] = [
  {
    name: 'Position',
    attributes: ['px', 'py', 'pz'],
  },
  {
    name: 'Wheels',
    attributes: ['w1', 'w2', 'w3'],
  },
  {
    name: 'Angles',
    attributes: ['a1', 'a2', 'a3', 'tilt', 'yaw'],
  },
  {
    name: 'Ball',
    attributes: ['firing', 'no_ball'],
  },
  {
    name: 'Orientation',
    attributes: ['qw', 'qx', 'qy', 'qz'],
  },
  {
    name: 'Miscellaneous',
    attributes: ['video_uuid', 'od_busy', 'training'],
  },
];

interface IRow {
  category: string;
  ready: string;
  notReady: string;
}

export class R2FStatusDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.handleReadyToFire = this.handleReadyToFire.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
  }

  componentWillUnmount() {
    /** stop listening to websocket */
    WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
  }

  /** (*) -> isReady */
  private handleReadyToFire(event: CustomEvent) {
    const r2f: IReadyMsg = event.detail;

    if (!r2f?.data) {
      // don't update unless the data is well-formed
      return;
    }

    this.setState({
      lastR2F: r2f,
      lastUpdate: new Date(),
    });
  }

  private renderContent() {
    if (!this.state.lastR2F?.data) {
      return <Spinner />;
    }

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Category</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Ready</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Not Ready</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(() => {
              const readyDict = (this.state.lastR2F?.data ?? {}) as any;

              const rows: IRow[] = PROC_CATEGORIES.map((c) => {
                return {
                  category: c.name,
                  ready: c.attributes
                    .filter((key) => TRUTHY_VALUES.includes(readyDict[key]))
                    .join(', '),
                  notReady: c.attributes
                    .filter((key) => !TRUTHY_VALUES.includes(readyDict[key]))
                    .join(', '),
                };
              });

              return rows.map((row, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{row.category}</Table.Cell>
                  <Table.Cell>
                    {row.ready && (
                      <Code color={RADIX.COLOR.SUCCESS}>{row.ready}</Code>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {row.notReady && (
                      <Code color={RADIX.COLOR.DANGER}>{row.notReady}</Code>
                    )}
                  </Table.Cell>
                </Table.Row>
              ));
            })()}
          </Table.Body>
        </Table.Root>

        {this.state.lastUpdate && (
          <Text>
            As of{' '}
            {format(this.state.lastUpdate, LOCAL_DATETIME_FORMAT, {
              timeZone: LOCAL_TIMEZONE,
            })}
          </Text>
        )}
      </Flex>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.MD}
          title="Machine Status"
          content={this.renderContent()}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
