import { NotifyHelper } from 'classes/helpers/notify.helper';
import { getPitchYearOptions } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { PitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { DEFAULT_ACCEPT_BTN } from 'interfaces/i-dialogs';
import { SHUFFLE_FREQUENCY_OPTIONS } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch, IPitchMetadata } from 'lib_ts/interfaces/pitches';
import React from 'react';

const COMPONENT_NAME = 'EditPitchDialog';

interface IProps {
  pitch: IPitch;
  onClose: (result: IPitch | undefined) => void;
}

interface IState extends Partial<IPitchMetadata> {}

export class EditPitchDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      ...props.pitch,
    };
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <PitchListsContext.Consumer>
          {(listsCx) => (
            <CommonDialog
              identifier={COMPONENT_NAME}
              title="pl.edit-pitch-metadata"
              loading={listsCx.loading}
              width={RADIX.DIALOG.WIDTH.SM}
              description={`Use the form below to update metadata for ${
                this.props.pitch.name ?? '(no name)'
              }`}
              content={
                <CommonFormGrid columns={1}>
                  <CommonTextInput
                    id="pitch-name"
                    label="pl.pitch-name"
                    name="name"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e })}
                  />
                  <CommonSelectInput
                    id="pitch-year"
                    name="year"
                    label="common.year"
                    options={getPitchYearOptions()}
                    value={this.state.year}
                    onChange={(v) => this.setState({ year: v })}
                    optional
                  />
                  <CommonSelectInput
                    id="pitch-frequency"
                    name="frequency"
                    label="common.frequency"
                    options={SHUFFLE_FREQUENCY_OPTIONS}
                    value={this.state.frequency?.toString()}
                    onNumericChange={(v) => this.setState({ frequency: v })}
                    skipSort
                    optional
                  />
                </CommonFormGrid>
              }
              buttons={[
                {
                  ...DEFAULT_ACCEPT_BTN,
                  onClick: async () => {
                    if (
                      !this.state.name ||
                      this.state.name.trim().length === 0
                    ) {
                      NotifyHelper.error({
                        message_md:
                          'Please provide a non-empty name for your pitch.',
                      });
                      return;
                    }

                    const payload: Partial<IPitch> = {
                      _id: this.props.pitch._id,
                      name: this.state.name.trim(),
                      year: this.state.year,
                      frequency: this.state.frequency,
                    };

                    const result = await listsCx.updatePitches({
                      payloads: [payload],
                    });

                    if (result) {
                      this.props.onClose(result[0]);
                    }
                  },
                },
              ]}
              onClose={() => this.props.onClose(undefined)}
            />
          )}
        </PitchListsContext.Consumer>
      </ErrorBoundary>
    );
  }
}
