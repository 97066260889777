import { Flex } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { t } from 'i18next';
import { IInputWrapper } from 'interfaces/forms/_base';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const CommonInputWrapper = (props: IInputWrapper) => (
  <ErrorBoundary componentName={props.id}>
    <Flex
      id={props.id}
      direction="column"
      gap={RADIX.FLEX.GAP.INPUT}
      title={props.title ? t(props.title).toString() : undefined}
    >
      {props.children}
    </Flex>
  </ErrorBoundary>
);
