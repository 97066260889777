import { Button } from '@radix-ui/themes';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const MenuAsDialogButton = (props: IMenuAction) => (
  <Button
    className="btn-block"
    size={RADIX.BUTTON.SIZE.LG}
    title={props.tooltip}
    color={props.color ?? RADIX.COLOR.NEUTRAL}
    variant={props.variant ?? 'soft'}
    disabled={props.disabled}
    onClick={() => {
      props.onClick();
      props.afterClick?.();
    }}
  >
    {props.icon}
    {t(props.label)}
  </Button>
);
