export enum MlbOutcomeCode {
  StrikeCalled = 'C',
  BallCalled = 'B',
  StrikeSwinging = 'S',
  BallAutomatic = 'V',
  StrikeAutomatic = 'A',
  BallHitByPitch = 'H',
  StrikeFoulTip = 'T',
  StrikeFoul = 'F',
  StrikeUnknown = 'K',
  BallIntentional = 'I',
  BallPitchout = 'P',
  BallBallInDirt = '*B',
  StrikeSwingingBlocked = 'W',
  StrikeFoulBunt = 'L',
  StrikeMissedBunt = 'M',
  StrikeBuntFoulTip = 'O',
  StrikeFoulOnPitchout = 'R',
  StrikeSwingingOnPitchout = 'Q',
  HitIntoPlayOutS = 'X',
  HitIntoPlayRunS = 'E',
  HitIntoPlayNoOutS = 'D',
  PitchoutHitIntoPlayOutS = 'Y',
  PitchoutHitIntoPlayNoOutS = 'J',
  PitchoutHitIntoPlayRunS = 'Z',
  PickoffThrow_1stPitcher = '1',
  PickoffThrow_2ndPitcher = '2',
  PickoffThrow_3rdPitcher = '3',
  PickoffThrow_1stCatcher = '+1',
  PickoffThrow_2ndCatcher = '+2',
  PickoffThrow_3rdCatcher = '+3',
  NoPitch = 'N',
  BallAutomaticIbb = 'VB',
  BallAutomaticPitchTimerViolationPitcher = 'VP',
  BallAutomaticPitchTimerViolationCatcher = 'VC',
  BallAutomaticShiftViolation = 'VS',
  StrikeAutomaticPitchTimerViolation = 'AC',
  StrikeAutomaticBatterTimeoutViolation = 'AB',
  PitcherStepOff = 'PSO',
  NonPitch = '.',
}

// when averaging, don't average plays with these outcomes to avoid using outliers
export const AVG_EXCLUDED_OUTCOMES = [
  MlbOutcomeCode.BallHitByPitch,
  MlbOutcomeCode.BallIntentional,
  MlbOutcomeCode.BallPitchout,
  MlbOutcomeCode.BallCalled,
  MlbOutcomeCode.PickoffThrow_1stPitcher,
  MlbOutcomeCode.PickoffThrow_2ndPitcher,
  MlbOutcomeCode.PickoffThrow_3rdPitcher,
  MlbOutcomeCode.PickoffThrow_1stCatcher,
  MlbOutcomeCode.PickoffThrow_2ndCatcher,
  MlbOutcomeCode.PickoffThrow_3rdCatcher,
  MlbOutcomeCode.NoPitch,
  MlbOutcomeCode.BallAutomaticIbb,
  MlbOutcomeCode.BallAutomaticPitchTimerViolationPitcher,
  MlbOutcomeCode.BallAutomaticPitchTimerViolationCatcher,
  MlbOutcomeCode.BallAutomaticShiftViolation,
  MlbOutcomeCode.StrikeAutomaticPitchTimerViolation,
  MlbOutcomeCode.StrikeAutomaticBatterTimeoutViolation,
  MlbOutcomeCode.PitcherStepOff,
  MlbOutcomeCode.NonPitch,
];

export const STRIKE_OUTCOMES = [
  MlbOutcomeCode.StrikeAutomatic,
  MlbOutcomeCode.StrikeBuntFoulTip,
  MlbOutcomeCode.StrikeCalled,
  MlbOutcomeCode.StrikeFoul,
  MlbOutcomeCode.StrikeFoulBunt,
  MlbOutcomeCode.StrikeFoulTip,
  MlbOutcomeCode.StrikeMissedBunt,
  MlbOutcomeCode.StrikeSwinging,
  MlbOutcomeCode.StrikeSwingingBlocked,
  MlbOutcomeCode.StrikeUnknown,
];

export const MLB_OUTCOME_CODES: {
  code: MlbOutcomeCode;
  description: string;
}[] = [
  {
    code: MlbOutcomeCode.StrikeCalled,
    description: 'Strike - Called',
  },
  {
    code: MlbOutcomeCode.BallCalled,
    description: 'Ball - Called',
  },
  {
    code: MlbOutcomeCode.StrikeSwinging,
    description: 'Strike - Swinging',
  },
  {
    code: MlbOutcomeCode.BallAutomatic,
    description: 'Ball - Automatic',
  },
  {
    code: MlbOutcomeCode.StrikeAutomatic,
    description: 'Strike - Automatic',
  },
  {
    code: MlbOutcomeCode.BallHitByPitch,
    description: 'Ball - Hit by Pitch',
  },
  {
    code: MlbOutcomeCode.StrikeFoulTip,
    description: 'Strike - Foul Tip',
  },
  {
    code: MlbOutcomeCode.StrikeFoul,
    description: 'Strike - Foul',
  },
  {
    code: MlbOutcomeCode.StrikeUnknown,
    description: 'Strike - Unknown',
  },
  {
    code: MlbOutcomeCode.BallIntentional,
    description: 'Ball - Intentional',
  },
  {
    code: MlbOutcomeCode.BallPitchout,
    description: 'Ball - Pitchout',
  },
  {
    code: MlbOutcomeCode.BallBallInDirt,
    description: 'Ball - Ball In Dirt',
  },
  {
    code: MlbOutcomeCode.StrikeSwingingBlocked,
    description: 'Strike - Swinging Blocked',
  },
  {
    code: MlbOutcomeCode.StrikeFoulBunt,
    description: 'Strike - Foul Bunt',
  },
  {
    code: MlbOutcomeCode.StrikeMissedBunt,
    description: 'Strike - Missed Bunt',
  },
  {
    code: MlbOutcomeCode.StrikeBuntFoulTip,
    description: 'Strike - Bunt Foul Tip',
  },
  {
    code: MlbOutcomeCode.StrikeFoulOnPitchout,
    description: 'Strike - Foul on Pitchout',
  },
  {
    code: MlbOutcomeCode.StrikeSwingingOnPitchout,
    description: 'Strike - Swinging on Pitchout',
  },
  {
    code: MlbOutcomeCode.HitIntoPlayOutS,
    description: 'Hit Into Play - Out(s)',
  },
  {
    code: MlbOutcomeCode.HitIntoPlayRunS,
    description: 'Hit Into Play - Run(s)',
  },
  {
    code: MlbOutcomeCode.HitIntoPlayNoOutS,
    description: 'Hit Into Play - No Out(s)',
  },
  {
    code: MlbOutcomeCode.PitchoutHitIntoPlayOutS,
    description: 'Pitchout Hit Into Play - Out(s)',
  },
  {
    code: MlbOutcomeCode.PitchoutHitIntoPlayNoOutS,
    description: 'Pitchout Hit Into Play - No Out(s)',
  },
  {
    code: MlbOutcomeCode.PitchoutHitIntoPlayRunS,
    description: 'Pitchout Hit Into Play - Run(s)',
  },
  {
    code: MlbOutcomeCode.PickoffThrow_1stPitcher,
    description: 'Pickoff Throw 1st - Pitcher',
  },
  {
    code: MlbOutcomeCode.PickoffThrow_2ndPitcher,
    description: 'Pickoff Throw 2nd - Pitcher',
  },
  {
    code: MlbOutcomeCode.PickoffThrow_3rdPitcher,
    description: 'Pickoff Throw 3rd - Pitcher',
  },
  {
    code: MlbOutcomeCode.PickoffThrow_1stCatcher,
    description: 'Pickoff Throw 1st - Catcher',
  },
  {
    code: MlbOutcomeCode.PickoffThrow_2ndCatcher,
    description: 'Pickoff Throw 2nd - Catcher',
  },
  {
    code: MlbOutcomeCode.PickoffThrow_3rdCatcher,
    description: 'Pickoff Throw 3rd - Catcher',
  },
  {
    code: MlbOutcomeCode.NoPitch,
    description: 'No Pitch',
  },
  {
    code: MlbOutcomeCode.BallAutomaticIbb,
    description: 'Ball - Automatic (IBB)',
  },
  {
    code: MlbOutcomeCode.BallAutomaticPitchTimerViolationPitcher,
    description: 'Ball - Automatic (Pitch Timer Violation - Pitcher)',
  },
  {
    code: MlbOutcomeCode.BallAutomaticPitchTimerViolationCatcher,
    description: 'Ball - Automatic (Pitch Timer Violation - Catcher)',
  },
  {
    code: MlbOutcomeCode.BallAutomaticShiftViolation,
    description: 'Ball - Automatic (Shift Violation)',
  },
  {
    code: MlbOutcomeCode.StrikeAutomaticPitchTimerViolation,
    description: 'Strike - Automatic (Pitch Timer Violation)',
  },
  {
    code: MlbOutcomeCode.StrikeAutomaticBatterTimeoutViolation,
    description: 'Strike - Automatic (Batter Timeout Violation)',
  },
  {
    code: MlbOutcomeCode.PitcherStepOff,
    description: 'Pitcher Step Off',
  },
  {
    code: MlbOutcomeCode.NonPitch,
    description: 'Non Pitch',
  },
].sort((a, b) => a.description.localeCompare(b.description));
