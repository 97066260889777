import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SettingsSection } from 'components/common/settings-dialog';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { SectionName } from 'enums/route.enums';
import { BallType } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'ActiveCalibrationModelWarning';

interface IProps {
  overrideBallType?: BallType;

  // what to do after user navigates to the machine calibration section
  beforeNavCallback?: () => Promise<void>;
  showSettingsButton?: boolean;
}

export class ActiveCalibrationModelWarning extends React.Component<IProps> {
  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SessionEventsContext.Consumer>
          {(sessionsCx) => (
            <SectionsContext.Consumer>
              {(sectionsCx) => (
                <MachineContext.Consumer>
                  {(machineCx) => {
                    if (
                      !this.props.overrideBallType &&
                      !machineCx.activeModel?.calibration_only
                    ) {
                      return;
                    }

                    return (
                      <CommonCallout
                        color={RADIX.COLOR.WARNING}
                        header="Uncalibrated Ball Type"
                        content={
                          <p>
                            The{' '}
                            {this.props.overrideBallType ??
                              machineCx.machine.ball_type}{' '}
                            ball type hasn't been calibrated and can't be used
                            with the machine yet. Please calibrate this ball
                            type or change it in the settings.
                          </p>
                        }
                        actions={[
                          {
                            label: 'Calibrate Ball Type',
                            onClick: () =>
                              sectionsCx.tryChangeSection({
                                trigger: COMPONENT_NAME,
                                name: SectionName.MachineCalibration,
                                beforeNavCallback: this.props.beforeNavCallback,
                              }),
                          },
                          {
                            label: 'Open Settings',
                            invisible: !this.props.showSettingsButton,
                            onClick: () => {
                              sessionsCx.setSettingsDialog({
                                initialSection: SettingsSection.Machine,
                                sections: [SettingsSection.Machine],
                              });
                            },
                          },
                        ]}
                      />
                    );
                  }}
                </MachineContext.Consumer>
              )}
            </SectionsContext.Consumer>
          )}
        </SessionEventsContext.Consumer>
      </ErrorBoundary>
    );
  }
}
