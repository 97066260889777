import { IOption } from '../interfaces/common/i-option';

/** camera position, i.e. where the camera is located (left hand of hitter, right hand of hitter) when video was recorded */
export enum CamPos {
  LHH = 'LHH',
  CEN = 'CEN',
  RHH = 'RHH',
}

export const CAMERA_POSITION_OPTIONS: IOption[] = [
  { label: 'LHH Box', value: CamPos.LHH },
  { label: 'Center', value: CamPos.CEN },
  { label: 'RHH Box', value: CamPos.RHH },
];

export enum GenerateStadium {
  Default = 'Default',
}
