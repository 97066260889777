import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, Grid, Heading, IconButton } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import { t } from 'i18next';
import { numberToImperial } from 'lib_ts/classes/math.utilities';
import { MS_LIMITS } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { Component } from 'react';
import Slider from 'react-input-slider';

interface IReleaseDetails {
  header: string;
  decimal: string;
  imperial: string;
  adjust: {
    axis: 'x' | 'y';
    upIcon: JSX.Element;
    downIcon: JSX.Element;
  };
}

const ENABLE_KEYBOARD_AJUSTMENT = false;

/** for adjusting release position via keyboard input */
const DIR_Y_INC = 'ArrowUp';
const DIR_Y_DEC = 'ArrowDown';
const DIR_X_DEC = 'ArrowLeft';
const DIR_X_INC = 'ArrowRight';

const DIR_KEY_CODES = [DIR_Y_DEC, DIR_Y_INC, DIR_X_DEC, DIR_X_INC];

/** given in inches */
const DIR_PRECISION_INCHES = {
  /** i.e. 0.01 feet per press */
  CTRL_SHIFT: 0.12,
  CTRL: 1,
  SHIFT: 3,
};

const BOUNDARY = {
  /** governs overall boundary for slider */
  SLIDER_X: {
    MIN: -4,
    MAX: 4,
    DEFAULT: 0,
    STEP: 0.1,
  },
  /** governs selectable values within slider */
  VALID_X: MS_LIMITS.POSITION.X,
  /** governs overall boundary for slider */
  SLIDER_Y: {
    MIN: 0,
    MAX: 7.5,
    DEFAULT: 4.2,
    STEP: 0.1,
  },
  /** governs selectable values within slider */
  VALID_Y: MS_LIMITS.POSITION.Z,
};

interface IProps {
  px?: number;
  pz?: number;
  onUpdate: (pos: { px: number; pz: number }) => void;
}

interface IState {
  slider_x: number;
  slider_y: number;
}

export class ReleaseView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      slider_x: props.px ?? BOUNDARY.SLIDER_X.DEFAULT,
      slider_y: props.pz ?? BOUNDARY.SLIDER_Y.DEFAULT,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.adjustPosition = this.adjustPosition.bind(this);
  }

  /**
   *
   * @param axis 'x' or 'y'
   * @param amount given in feet
   */
  private adjustPosition(axis: string, amount: number) {
    /** component is updating sliders, trigger parent's onUpdate with end results */
    const callback = (x: number, y: number) =>
      this.props.onUpdate({ px: x, pz: y });

    switch (axis) {
      case 'x': {
        this.setState(
          {
            slider_x: this.confineToBounds(
              this.state.slider_x + amount,
              BOUNDARY.VALID_X
            ),
          },
          () => callback(this.state.slider_x, this.state.slider_y)
        );
        break;
      }

      case 'y': {
        this.setState(
          {
            slider_y: this.confineToBounds(
              this.state.slider_y + amount,
              BOUNDARY.VALID_Y
            ),
          },
          () => callback(this.state.slider_x, this.state.slider_y)
        );
        break;
      }

      default: {
        break;
      }
    }
  }

  private handleKeyDown(event: KeyboardEvent) {
    if ((this.context as IGlobalContext).dialogs.length > 0) {
      return;
    }

    if (!event.ctrlKey && !event.shiftKey) {
      // only intercept while pressing CTRL and/or SHIFT
      return;
    }

    if (!DIR_KEY_CODES.includes(event.code)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.repeat) {
      return;
    }

    /** in feet */
    const deltaFt: number = (() => {
      if (event.ctrlKey && event.shiftKey) {
        return DIR_PRECISION_INCHES.CTRL_SHIFT / 12;
      }

      if (event.ctrlKey) {
        /** precise */
        return DIR_PRECISION_INCHES.CTRL / 12;
      }

      if (event.shiftKey) {
        /** rough */
        return DIR_PRECISION_INCHES.SHIFT / 12;
      }

      return 0;
    })();

    switch (event.code) {
      case DIR_X_INC: {
        this.adjustPosition('x', deltaFt);
        return;
      }

      case DIR_X_DEC: {
        this.adjustPosition('x', -deltaFt);
        return;
      }
      case DIR_Y_INC: {
        this.adjustPosition('y', deltaFt);
        return;
      }

      case DIR_Y_DEC: {
        this.adjustPosition('y', -deltaFt);
        return;
      }

      default: {
        return;
      }
    }
  }

  componentDidMount() {
    if (ENABLE_KEYBOARD_AJUSTMENT) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (ENABLE_KEYBOARD_AJUSTMENT) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.px !== prevProps.px || this.props.pz !== prevProps.pz) {
      /** parent is changing the props, set sliders from props */
      this.setState({
        slider_x: this.props.px ?? BOUNDARY.SLIDER_X.DEFAULT,
        slider_y: this.props.pz ?? BOUNDARY.SLIDER_Y.DEFAULT,
      });
    }
  }

  private handleChange(pos: { x: number; y: number }) {
    this.setState({
      slider_x: pos.x,
      slider_y: pos.y,
    });
  }

  private confineToBounds(
    value: number,
    bounds: { MIN: number; MAX: number }
  ): number {
    return Math.max(bounds.MIN, Math.min(bounds.MAX, value));
  }

  render() {
    const position_x = numberToImperial(this.state.slider_x);
    const position_y = numberToImperial(this.state.slider_y);

    const sliderStyle = {
      track: {
        backgroundColor: 'rgba(0, 0, 255, 0)',
        backgroundImage: `url(/img/${
          this.state.slider_x < 0 ? 'pitcher_RHR.png' : 'pitcher_LHR.png'
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
      },
      thumb: {
        backgroundImage: 'url(/img/baseball.png)',
        backgroundColor: 'rgba(0, 255, 0, 0)',
        backgroundSize: 'cover',
        boxShadow: 'none',
        height: 32,
        width: 32,
        margin: '-16px',
        opacity: 1,
      },
    };

    const relDetails: IReleaseDetails[] = [
      {
        header: t('pd.release-side-short').toUpperCase(),
        imperial: `${position_x.sign === -1 ? '-' : ''} ${position_x.ft}' ${
          position_x.in
        }"`,
        decimal: `${position_x.value_ft.toFixed(2)} ft`,
        adjust: {
          axis: 'x',
          upIcon: <ArrowRightIcon />,
          downIcon: <ArrowLeftIcon />,
        },
      },
      {
        header: t('pd.release-height-short').toUpperCase(),
        imperial: `${position_y.sign === -1 ? '-' : ''} ${position_y.ft}' ${
          position_y.in
        }"`,
        decimal: `${position_y.value_ft.toFixed(2)} ft`,
        adjust: {
          axis: 'y',
          upIcon: <ArrowUpIcon />,
          downIcon: <ArrowDownIcon />,
        },
      },
    ];

    return (
      <ErrorBoundary componentName="ReleaseView">
        <Flex gap={RADIX.FLEX.GAP.LG}>
          <Box flexGrow="1">
            <div className="slider">
              <div
                className="sizer"
                style={{
                  aspectRatio: '0.77',
                  maxHeight: '500px',
                  minHeight: '100px',
                }}
              >
                <div className="slider-wrapper">
                  <Slider
                    axis="xy"
                    xstep={BOUNDARY.SLIDER_X.STEP}
                    xmin={BOUNDARY.SLIDER_X.MIN}
                    xmax={BOUNDARY.SLIDER_X.MAX}
                    x={this.state.slider_x}
                    ystep={BOUNDARY.SLIDER_Y.STEP}
                    ymin={BOUNDARY.SLIDER_Y.MIN}
                    ymax={BOUNDARY.SLIDER_Y.MAX}
                    y={this.state.slider_y}
                    onChange={this.handleChange}
                    onDragEnd={() => {
                      /** wait for onChange to finish */
                      setTimeout(() => {
                        this.props.onUpdate({
                          px: this.confineToBounds(
                            this.state.slider_x,
                            BOUNDARY.VALID_X
                          ),
                          pz: this.confineToBounds(
                            this.state.slider_y,
                            BOUNDARY.VALID_Y
                          ),
                        });
                      }, 100);
                    }}
                    styles={sliderStyle}
                    yreverse
                  />
                </div>
              </div>
            </div>
          </Box>
          <Box>
            <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
              {relDetails.map((item, index) => (
                <div key={index} className="align-center">
                  <Heading size={RADIX.HEADING.SIZE.SM}>{item.header}</Heading>
                  <Grid
                    columns="2"
                    gap={RADIX.FLEX.GAP.XS}
                    align="center"
                    className="rounded-sm foreground"
                  >
                    <Box gridColumn="span 2">
                      <Heading size={RADIX.HEADING.SIZE.XS}>
                        {item.imperial}
                      </Heading>
                    </Box>
                    <Box gridColumn="span 2">
                      <Heading size={RADIX.HEADING.SIZE.XS}>
                        {item.decimal}
                      </Heading>
                    </Box>

                    {/* controls */}
                    <Box>
                      <IconButton
                        color={RADIX.COLOR.NEUTRAL}
                        variant="soft"
                        onClick={() =>
                          this.adjustPosition(item.adjust.axis, -0.01)
                        }
                      >
                        {item.adjust.downIcon}
                      </IconButton>
                    </Box>

                    <Box>
                      <IconButton
                        color={RADIX.COLOR.NEUTRAL}
                        variant="soft"
                        onClick={() =>
                          this.adjustPosition(item.adjust.axis, 0.01)
                        }
                      >
                        {item.adjust.upIcon}
                      </IconButton>
                    </Box>
                  </Grid>
                </div>
              ))}
            </Flex>
          </Box>
        </Flex>
      </ErrorBoundary>
    );
  }
}

ReleaseView.contextType = GlobalContext;
