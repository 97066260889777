import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Box, Button, Flex, Grid, Heading } from '@radix-ui/themes';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTooltip } from 'components/common/tooltip';
import { CommonVideoPlayer } from 'components/common/video-player';
import { IDirtyContext } from 'contexts/dirty.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import {
  DeliveryType,
  PITCH_TYPE_OPTIONS,
  PitchType,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { CAMERA_POSITION_OPTIONS, CamPos } from 'lib_ts/enums/videos.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IVideo, IVideoPlayback } from 'lib_ts/interfaces/i-video';
import React from 'react';

/** toggles on/off next and prev frame buttons, as well as release time and frame inputs */
const ENABLE_PRECISION = false;

/** toggles on/off the reset time button */
const ENABLE_RESET = false;

const roundTo6 = (n: number) => {
  return Math.round(1_000_000 * n) / 1_000_000;
};

interface IProps {
  teamOptions: IOption[];
  dirtyCx: IDirtyContext;
  video_id: string;
  videosCx: IVideosContext;
}

interface IState {
  video_playback?: IVideoPlayback;
  video: IVideo;

  /** inverse of fps, rounded to 6 decimals */
  readonly spf: number;
  /** floor of n_frames to prevent next past end of file */
  readonly safe_n_frames: number;
  /** recorded for reset functionality */
  readonly originalTime: number;
}

export class SummaryTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const video = props.videosCx.videos.find((v) => v._id === props.video_id);
    if (!video) {
      throw new Error(
        `Could not find video ${props.video_id} in videos context`
      );
    }

    /** ensure we don't have undefined frame number */
    if (!video.ReleaseFrame) {
      video.ReleaseFrame = 1;
    }

    if (!video.ReleaseTime) {
      video.ReleaseTime = 0;
    }

    this.state = {
      /** make a copy of video to manipulate via forms */
      video: {
        ...video,
      },
      spf: roundTo6(1 / (video.fps ?? 1)),
      safe_n_frames: Math.floor(video.n_frames),
      originalTime: video.ReleaseTime,
    };

    this.handleSave = this.handleSave.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderPlayer = this.renderPlayer.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);
  }

  componentDidMount() {
    this.props.videosCx
      .getCachedPlayback(this.props.video_id)
      .then((playback) => {
        this.setState({
          video_playback: playback,
        });
      });
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.video_id !== this.props.video_id) {
      this.setState({ video_playback: undefined });
      const video = this.props.videosCx.videos.find(
        (v) => v._id === this.props.video_id
      );
      if (!video) {
        throw new Error(
          `Could not find video ${this.props.video_id} in videos context`
        );
      } else {
        /** ensure we don't have undefined frame number */
        if (!video.ReleaseFrame) {
          video.ReleaseFrame = 1;
        }

        if (!video.ReleaseTime) {
          video.ReleaseTime = 0;
        }

        /** make a copy to manipulate via forms */
        this.setState({
          video: {
            ...video,
          },
          spf: roundTo6(1 / (video.fps ?? 1)),
          safe_n_frames: Math.floor(video.n_frames),
          originalTime: video.ReleaseTime,
        });
      }

      this.props.videosCx
        .getCachedPlayback(this.props.video_id)
        .then((playback) => {
          this.setState({
            video_playback: playback,
          });
        });
    }
  }

  handleSave() {
    this.props.videosCx.updateVideo(this.state.video);
    this.props.dirtyCx.clearDirty('InfoTab', 'saved');
  }

  private updateTime(newTime: number) {
    const video = { ...this.state.video };

    /** get closest timestamp corresponding directly to a frame */
    const closestFrame = Math.round(newTime / this.state.spf);
    video.ReleaseTime = roundTo6(closestFrame * this.state.spf);
    video.ReleaseFrame = Math.max(
      1,
      Math.min(this.state.safe_n_frames, closestFrame)
    );

    this.setState({ video });
  }

  private updateMetadata(model: Partial<IVideo>) {
    const video = {
      ...this.state.video,
      ...model,
    };

    this.setState({ video });
    this.props.dirtyCx.markDirty('InfoTab', 'update metadata');
  }

  render() {
    return (
      <Grid columns="5" gap={RADIX.FLEX.GAP.LG}>
        <Box gridColumn="span 3">{this.renderPlayer()}</Box>
        <Box gridColumn="span 2">{this.renderForm()}</Box>
      </Grid>
    );
  }

  private renderForm() {
    return (
      <CommonFormGrid columns={2}>
        {ENABLE_PRECISION && (
          <Box>
            <Button
              className="btn-block"
              onClick={() =>
                this.updateTime(this.state.video.ReleaseTime - this.state.spf)
              }
            >
              {t('common.prev-x', { x: t('videos.frame') })}
            </Button>
          </Box>
        )}

        {ENABLE_PRECISION && (
          <Box>
            <Button
              className="btn-block"
              onClick={() =>
                this.updateTime(this.state.video.ReleaseTime + this.state.spf)
              }
            >
              {t('common.next-x', { x: t('videos.frame') })}
            </Button>
          </Box>
        )}

        {ENABLE_RESET && (
          <Box gridColumn="span 2">
            <Button
              className="btn-block"
              onClick={() => this.updateTime(this.state.originalTime)}
            >
              {t('common.reset-x', { x: t('videos.release-time') })}
            </Button>
          </Box>
        )}

        {ENABLE_PRECISION && (
          <Box>
            <CommonTextInput
              id="video-release-time"
              label="videos.release-time"
              type="number"
              value={this.state.video.ReleaseTime.toString()}
              disabled
            />
          </Box>
        )}

        {ENABLE_PRECISION && (
          <Box>
            <CommonTextInput
              id="video-release-frame"
              label="videos.release-frame"
              type="number"
              value={this.state.video.ReleaseFrame.toString()}
              disabled
            />
          </Box>
        )}

        <Box gridColumn="span 2">
          <CommonTextInput
            id="video-name"
            label="videos.video"
            data-testid="VideoName"
            placeholder="Video Name"
            value={this.state.video.VideoTitle}
            onChange={(v) => this.updateMetadata({ VideoTitle: v })}
          />
        </Box>

        <Box gridColumn="span 2">
          <CommonTextInput
            id="video-pitcher"
            label="common.pitcher"
            data-testid="Pitcher"
            placeholder="Pitcher Name"
            value={this.state.video.PitcherFullName}
            onChange={(v) => this.updateMetadata({ PitcherFullName: v })}
          />
        </Box>

        <Box>
          <CommonSearchInput
            id="video-team"
            name="TeamName"
            label="common.team"
            values={
              this.state.video.TeamName ? [this.state.video.TeamName] : []
            }
            options={this.props.teamOptions}
            onChange={(v) => this.updateMetadata({ TeamName: v[0] })}
            optional
          />
        </Box>

        <Box>
          <CommonSearchInput
            id="video-pitch-type"
            name="PitchType"
            label="common.pitch-type"
            options={PITCH_TYPE_OPTIONS}
            values={
              this.state.video.PitchType ? [this.state.video.PitchType] : []
            }
            onChange={(v) =>
              this.updateMetadata({ PitchType: v[0] as PitchType })
            }
            optional
          />
        </Box>

        <Box>
          <CommonSelectInput
            id="video-delivery"
            name="DeliveryType"
            label="videos.pitch-delivery-type"
            options={Object.values(DeliveryType).map((o) => ({
              label: o,
              value: o,
            }))}
            value={this.state.video.DeliveryType}
            onChange={(v) =>
              this.updateMetadata({ DeliveryType: v as DeliveryType })
            }
            optional
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="video-position"
            name="CameraPosition"
            label="videos.camera-position"
            value={this.state.video.CameraPosition}
            options={CAMERA_POSITION_OPTIONS}
            onChange={(v) =>
              this.updateMetadata({ CameraPosition: v as CamPos })
            }
            optional
          />
        </Box>
      </CommonFormGrid>
    );
  }

  private renderPlayer() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
        <Flex gap={RADIX.FLEX.GAP.LG} justify="between">
          <Heading size={RADIX.HEADING.SIZE.SM}>
            {t('videos.choose-approximate-release-time')}
          </Heading>
          <CommonTooltip
            trigger={<InfoCircledIcon />}
            text_md="videos.release-time-instructions"
          />
        </Flex>

        <Box>
          {this.state.video_playback ? (
            <CommonVideoPlayer
              playback={this.state.video_playback}
              startAtTime={this.state.video.ReleaseTime}
              onTimeUpdate={(t) => this.updateTime(t)}
            />
          ) : (
            <img className="block" src="/img/video/loading.svg" alt="loading" />
          )}
        </Box>
      </Flex>
    );
  }
}
