import { Box, Grid } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CookiesContext } from 'contexts/cookies.context';
import { IMlbBrowseContext } from 'contexts/mlb-browse.context';
import { CookieKey } from 'enums/cookies.enums';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import React from 'react';

const COMPONENT_NAME = 'MlbStatsMainFilters';

interface IProps {
  browseCx: IMlbBrowseContext;
  resetChecked: () => void;
}

interface IState {}

export class MlbStatsMainFilters extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    const browseCx = this.props.browseCx;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CookiesContext.Consumer>
          {(cookiesCx) => (
            <Grid columns="7" gap={RADIX.FLEX.GAP.SM}>
              <Box>
                <CommonSelectInput
                  key={browseCx.seasonsKey}
                  id="mlb-stats-season"
                  name="season"
                  placeholder="common.season"
                  options={browseCx.seasons}
                  value={browseCx.gameFilter.season?.toString()}
                  onNumericChange={(v) => {
                    if (v === browseCx.gameFilter.season) {
                      return;
                    }

                    this.props.resetChecked();

                    browseCx.mergeGameFilter({
                      season: v,
                    });
                  }}
                  disabled={browseCx.loading}
                  optional
                  skipSort
                />
              </Box>
              <Box>
                <CommonSearchInput
                  key={browseCx.seasonPlayersKey}
                  id="mlb-stats-pitchers"
                  placeholder="common.pitcher"
                  title={
                    !browseCx.gameFilter.season
                      ? 'Select a season first.'
                      : 'Filter games and pitches by pitcher.'
                  }
                  disabled={browseCx.loading || !browseCx.gameFilter.season}
                  values={browseCx.gameFilter.pitchers?.map((p) => p._id) ?? []}
                  options={browseCx.seasonPlayers
                    .filter((p) => p.isPitcher)
                    .map((p) => ({
                      label: p.name,
                      value: p._id,
                    }))}
                  onChange={(ids) => {
                    const players = browseCx.seasonPlayers.filter((p) =>
                      ids.includes(p._id)
                    );

                    this.props.resetChecked();

                    browseCx.mergeGameFilter({
                      pitchers: players,
                    });
                  }}
                  optional
                />
              </Box>
              <Box>
                <CommonSearchInput
                  key={browseCx.seasonPlayersKey}
                  id="mlb-stats-batters"
                  placeholder="common.batter"
                  title={
                    !browseCx.gameFilter.season
                      ? 'Select a season first.'
                      : 'Filter games and pitches by batter.'
                  }
                  disabled={browseCx.loading || !browseCx.gameFilter.season}
                  values={browseCx.gameFilter.batters?.map((p) => p._id) ?? []}
                  options={browseCx.seasonPlayers.map((p) => ({
                    label: p.name,
                    value: p._id,
                  }))}
                  onChange={(ids) => {
                    const players = browseCx.seasonPlayers.filter((p) =>
                      ids.includes(p._id)
                    );

                    this.props.resetChecked();

                    browseCx.mergeGameFilter({
                      batters: players,
                    });
                  }}
                  optional
                />
              </Box>
              <Box>
                <CommonSearchInput
                  key={browseCx.seasonTeamsKey}
                  id="mlb-stats-team"
                  name="teamPk"
                  title={
                    !browseCx.gameFilter.season
                      ? 'Select a season first.'
                      : 'Filter games by team (home or away).'
                  }
                  placeholder="common.team"
                  options={browseCx.seasonTeams}
                  values={
                    browseCx.gameFilter.teamPk
                      ? [browseCx.gameFilter.teamPk.toString()]
                      : []
                  }
                  onChange={(v) => {
                    const teamPk = v.length > 0 ? parseInt(v[0]) : undefined;

                    if (teamPk === browseCx.gameFilter.teamPk) {
                      return;
                    }

                    this.props.resetChecked();

                    browseCx.mergeGameFilter({
                      teamPk: teamPk,
                    });
                  }}
                  disabled={browseCx.loading || !browseCx.gameFilter.season}
                  optional
                />
              </Box>
              <Box>
                <CommonSearchInput
                  key={browseCx.seasonGamesKey}
                  id="mlb-stats-game"
                  name="gamePk"
                  title={
                    !browseCx.gameFilter.season
                      ? 'Select a season first.'
                      : 'Filter pitches by game.'
                  }
                  placeholder="common.game"
                  options={browseCx.getFilteredGames().map((g) => {
                    const o: IOption = {
                      label: `${g.officialDate}: ${g.away.name} @ ${g.home.name}`,
                      group: g.officialDate,
                      value: g.gamePk.toString(),
                    };

                    return o;
                  })}
                  values={
                    browseCx.gameFilter.gamePk
                      ? [browseCx.gameFilter.gamePk.toString()]
                      : []
                  }
                  onChange={(v) => {
                    const gamePk = v.length > 0 ? parseInt(v[0]) : undefined;

                    if (gamePk === browseCx.gameFilter.gamePk) {
                      return;
                    }

                    this.props.resetChecked();

                    browseCx.mergeGameFilter({
                      gamePk: gamePk,
                    });
                  }}
                  disabled={browseCx.loading || !browseCx.gameFilter.season}
                  optional
                  skipSort
                />
              </Box>
              <Box>
                <CommonSelectInput
                  id="mlb-stats-build-priority"
                  name="buildPriority"
                  title={`Prioritizes ${cookiesCx.app.pitch_upload_options.priority.toLowerCase()} when building pitches.`}
                  placeholder="common.priority"
                  options={Object.values(BuildPriority).map((o) => ({
                    label: o,
                    value: o,
                  }))}
                  value={cookiesCx.app.pitch_upload_options.priority}
                  onChange={(v) => {
                    cookiesCx.setCookie(CookieKey.app, {
                      pitch_upload_options: {
                        ...cookiesCx.app.pitch_upload_options,
                        priority: v as BuildPriority,
                      },
                    });
                  }}
                />
              </Box>
              <Box>
                <CommonSelectInput
                  id="mlb-stats-normalize-release"
                  name="normalizeRelease"
                  title={`${
                    browseCx.normalizeRelease ? 'Normalize' : 'Do not normalize'
                  } release position per pitcher when building multiple pitches.`}
                  placeholder="pd.release-position"
                  options={[
                    {
                      label: 'Normalize Releases',
                      value: 'true',
                    },
                    {
                      label: 'Original Releases',
                      value: 'false',
                    },
                  ]}
                  value={browseCx.normalizeRelease.toString()}
                  onBooleanChange={(v) => browseCx.setNormalizeRelease(v)}
                />
              </Box>
            </Grid>
          )}
        </CookiesContext.Consumer>
      </ErrorBoundary>
    );
  }
}
