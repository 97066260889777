import { Quaternion, Vector3 } from 'three';
import { Orientation, PitcherHand } from '../enums/pitches.enums';
import { IBallChar } from '../interfaces/i-ball-char';
import { IMachineState } from '../interfaces/i-machine-state';
import { IGatherShot } from '../interfaces/modelling/i-gather-shot-data';
import { IBallState, IPitch, ITrajectory } from '../interfaces/pitches';
import {
  IAziAltCoordinate,
  IOrientation,
  IPosition,
  ISeamOrientation,
  ISpin,
  ISpinExt,
  ISurfacePoint,
  IVelocity,
} from '../interfaces/pitches/i-base';
import { IMachineShot } from '../interfaces/training/i-machine-shot';
import {
  EMPTY_RAPSODO_BREAKS,
  IRapsodoBreak,
} from '../interfaces/training/i-rapsodo-shot';
import { BallState } from './ball-state.class';
import { getRotationMatrix } from './math.utilities';
import { MiscHelper } from './misc.helper';
import { TrajHelper } from './trajectory.helper';

export const DEG2RAD = Math.PI / 180;
export const RAD2DEG = 180 / Math.PI;

type NumberIsh = number | undefined;

export const RAD_RIGHT_ANGLE = 0.5 * Math.PI;
export const RAD_FULL_ROTATION = 2 * Math.PI;

export const MIN_SHOTS_TO_ROTATE = 1;
export interface IGetQuaternionInput extends ISeamOrientation {
  spin: ISpin;
}

export class BallHelper {
  /**
   * Translates and rotates the machine state, ball state and trajectory of all shots in a list
   * such that they match the px, pz, tilt, and yaw of a given machine state.
   * @param config.ms: IMachineState to align shots to
   * @param config.shots: IMachineShot[] to align
   * @returns alignedPitchChars: IBuildPitchChars[]
   */
  static alignShots = (config: {
    ms: IMachineState;
    shots: IGatherShot[];
  }): IGatherShot[] => {
    // Use given MS as reference for translation and rotation of all shots
    const refTilt = config.ms.tilt;
    const refYaw = config.ms.yaw;
    const refPx = config.ms.px;
    const refPz = config.ms.pz;

    const output: IGatherShot[] = config.shots
      .filter((s) => {
        return (
          s.actual_bs &&
          s.actual_bs.px !== undefined &&
          s.actual_bs.pz !== undefined
        );
      })
      .map((shot) => {
        const adj_target_ms = {
          ...shot.target_ms,
          tilt: refTilt,
          yaw: refYaw,
          px: refPx,
          pz: refPz,
        } as IMachineState;

        const delta: IAziAltCoordinate = {
          altitude_rad: (refTilt - shot.target_ms.tilt) * DEG2RAD,
          azimuth_rad: (refYaw - shot.target_ms.yaw) * DEG2RAD,
        };

        const adj_actual_ms = {
          ...shot.actual_ms,
          tilt: shot.actual_ms.tilt + delta.altitude_rad * RAD2DEG,
          yaw: shot.actual_ms.yaw + delta.azimuth_rad * RAD2DEG,
          px: shot.actual_ms.px - shot.target_ms.px + refPx,
          pz: shot.actual_ms.pz - shot.target_ms.pz + refPz,
        } as IMachineState;

        const trans_bs = new BallState({
          ...shot.actual_bs,
          px: refPx,
          pz: refPz,
        } as IBallState);

        const adj_actual_bs = trans_bs.rotateBallState(delta);

        const trans_traj: ITrajectory = {
          ...shot.actual_traj,
          px: refPx,
          pz: refPz,
        };

        const adj_actual_traj = TrajHelper.getRotatedTrajectory(
          trans_traj,
          delta
        );

        const o: IGatherShot = {
          actual_bs: adj_actual_bs,

          target_ms: adj_target_ms,
          actual_ms: adj_actual_ms,

          actual_traj: adj_actual_traj,
          rapsodo_breaks: shot.rapsodo_breaks,

          _created: shot._created,
        };

        return o;
      });

    return output;
  };

  static getMeanModelShot = (shots: IGatherShot[]): IGatherShot | undefined => {
    if (shots.length < 1) {
      return undefined;
    }

    const actual_ms = {
      ...shots[0].actual_ms,
      ...MiscHelper.getMeanObject(shots.map((c) => c.actual_ms)),
    } as IMachineState;

    const target_ms = {
      ...shots[0].target_ms,
      ...MiscHelper.getMeanObject(shots.map((c) => c.target_ms)),
    } as IMachineState;

    const actual_bs = {
      ...shots[0].actual_bs,
      ...MiscHelper.getMeanObject(shots.map((c) => c.actual_bs)),
    } as IBallState;

    const actual_traj = {
      ...shots[0].actual_traj,
      ...MiscHelper.getMeanObject(shots.map((c) => c.actual_traj)),
    } as ITrajectory;

    const shotsWithBreaks = shots.filter((c) => c.rapsodo_breaks);

    const actual_breaks: IRapsodoBreak =
      shotsWithBreaks.length === 0
        ? EMPTY_RAPSODO_BREAKS
        : (MiscHelper.getMeanObject(
            shotsWithBreaks.map((c) => c.rapsodo_breaks)
          ) as IRapsodoBreak);

    return {
      actual_ms: actual_ms,
      target_ms: target_ms,
      actual_bs: actual_bs,
      actual_traj: actual_traj,
      rapsodo_breaks: actual_breaks,
      _created: shots[0]._created,
    };
  };

  static getStDevModelShot = (
    shots: IGatherShot[]
  ): IGatherShot | undefined => {
    if (shots.length < 2) {
      return undefined;
    }

    const actual_ms: IMachineState = {
      ...shots[0].actual_ms,
      ...MiscHelper.getStDevObject(shots.map((c) => c.actual_ms)),
    };

    const target_ms: IMachineState = {
      ...shots[0].target_ms,
      ...MiscHelper.getStDevObject(shots.map((c) => c.target_ms)),
    };

    const actual_bs: IBallState = {
      ...shots[0].actual_bs,
      ...MiscHelper.getStDevObject(shots.map((c) => c.actual_bs)),
    };

    const actual_traj: ITrajectory = {
      ...shots[0].actual_traj,
      ...MiscHelper.getStDevObject(shots.map((c) => c.actual_traj)),
    };

    const shotsWithBreaks = shots.filter((c) => c.rapsodo_breaks);

    const actual_breaks: IRapsodoBreak =
      shotsWithBreaks.length === 0
        ? EMPTY_RAPSODO_BREAKS
        : (MiscHelper.getStDevObject(
            shotsWithBreaks.map((c) => c.rapsodo_breaks)
          ) as IRapsodoBreak);

    return {
      actual_ms: actual_ms,
      target_ms: target_ms,
      actual_bs: actual_bs,
      actual_traj: actual_traj,
      rapsodo_breaks: actual_breaks,
      _created: shots[0]._created,
    };
  };

  /** calls getMagnitude, formats to string if valid, else returns undefined */
  static displayMagnitude(coordinates: NumberIsh[], decimals: number) {
    if (coordinates.findIndex((n) => isNaN(Number(n))) !== -1) {
      return undefined;
    }

    return this.getMagnitude(coordinates as number[]).toFixed(decimals);
  }

  static displayEfficiency(spin: ISpin, decimals: number) {
    try {
      return this.getEfficiency(spin).toFixed(decimals);
    } catch {
      return undefined;
    }
  }

  /** proportion of spin (0-100) that remains after removing wy */
  static getEfficiency(spin: ISpin) {
    const safeSign = Math.sign(spin.wy) || 1;
    const absSpinRatio = Math.abs(spin.wy) / this.getNetSpin(spin);
    return safeSign * (100 - absSpinRatio * 100);
  }

  static displaySpinAxis(spin: ISpin, decimals: number) {
    try {
      return this.getSpinAxis(spin).toFixed(decimals);
    } catch {
      return undefined;
    }
  }

  /**
   * e.g. use bs vx/vy/vz to get speed
   * @param coordinates
   * @returns in same units as input coordinates
   */
  static getMagnitude(coordinates: number[]) {
    return Math.sqrt(
      coordinates.reduce((prev, curr) => prev + Math.pow(curr, 2), 0)
    );
  }

  static getSpinAxis(spin: ISpin) {
    return Math.atan2(spin.wz, spin.wx) * RAD2DEG;
  }

  /** shorthand using getMagnitude with qw, qx, qy, qz */
  static getOrientationNorm(value: IOrientation) {
    return this.getMagnitude([value.qw, value.qx, value.qy, value.qz]);
  }

  /** shorthand using getMagnitude with vx, vy, vz */
  static getSpeed(value: IVelocity): number {
    return this.getMagnitude([value.vx, value.vy, value.vz]);
  }

  /** shorthand using getMagnitude with wx, wy, wz */
  static getNetSpin(value: ISpin): number {
    return this.getMagnitude([value.wx, value.wy, value.wz]);
  }

  static convertSpinExtToSpin(value: ISpinExt): ISpin {
    const trueSpin = value.wnet * Math.cos(value.gyro_angle * DEG2RAD);

    return {
      wx: trueSpin * Math.cos(value.waxis * DEG2RAD),
      wy: Math.sqrt(Math.pow(value.wnet, 2) - Math.pow(trueSpin, 2)),
      wz: trueSpin * Math.sin(value.waxis * DEG2RAD),
    };
  }

  static convertSpinToSpinExt(value: ISpin): ISpinExt {
    return {
      wnet: this.getNetSpin(value),
      gyro_angle: this.getGyroAngle(value),
      waxis: this.getSpinAxis(value),
    };
  }

  static getGyroAngle(value: ISpin): number {
    const wnet = this.getNetSpin(value);
    const wy = value.wy;
    const trueSpin = Math.sqrt(Math.pow(wnet, 2) - Math.pow(wy, 2));
    const gyroAngle = Math.acos(trueSpin / wnet) * RAD2DEG;
    return gyroAngle;
  }

  /** shorthand using getMagnitude with qw, qx, qy, qz */
  static getSafeOrientationNorm(value: IOrientation) {
    try {
      return this.getOrientationNorm(value);
    } catch {
      return undefined;
    }
  }

  /** shorthand using getMagnitude with vx, vy, vz */
  static getSafeSpeed(value: IVelocity): number | undefined {
    try {
      return this.getSpeed(value);
    } catch {
      return undefined;
    }
  }

  /** shorthand using getMagnitude with wx, wy, wz */
  static getSafeNetSpin(value: ISpin): number | undefined {
    try {
      return this.getNetSpin(value);
    } catch {
      return undefined;
    }
  }

  /** shorthand using px relative to 0 */
  static getHand(value: IPosition): PitcherHand {
    try {
      return Math.sign(value.px) <= 0 ? PitcherHand.RHP : PitcherHand.LHP;
    } catch (e) {
      console.error(e);
      return PitcherHand.RHP;
    }
  }

  /** inverse of {getBallStateFromChars} */
  static getCharsFromPitch(pitch: IPitch): IBallChar {
    const bs = pitch.bs;
    const seams = pitch.seams ?? this.getBallStateSeams(bs);
    const spinExt = this.convertSpinToSpinExt(bs);

    const output: IBallChar = {
      speed: this.getSpeed(bs),
      orientation: Orientation.CUS,

      px: bs.px,
      py: bs.py,
      pz: bs.pz,
      wx: bs.wx,
      wy: bs.wy,
      wz: bs.wz,

      wnet: spinExt.wnet,
      gyro_angle: spinExt.gyro_angle,
      waxis: spinExt.waxis,

      breaks: pitch.breaks,

      latitude_deg: seams.latitude_deg,
      longitude_deg: seams.longitude_deg,
    };

    return output;
  }

  /** inverse of {getCharsFromBallState} */
  static getBallStateFromChars(ball: IBallChar): IBallState {
    if (ball.wx === 0 && ball.wy === 0 && ball.wz === 0) {
      /** fix to avoid math weirdness */
      ball.wx = 0.1;
    }

    const vel: IVelocity = {
      vx: 0,
      vy: -ball.speed,
      vz: 0,
    };

    const quat = this.getQuatFromSeams({
      latitude_deg: ball.latitude_deg,
      longitude_deg: ball.longitude_deg,
      spin: {
        wx: ball.wx,
        wy: ball.wy,
        wz: ball.wz,
      },
    });

    const output: IBallState = {
      ...quat,

      px: ball.px,
      py: ball.py,
      pz: ball.pz,

      ...vel,

      vnet: BallHelper.getMagnitude([vel.vx, vel.vy, vel.vz]),

      wx: ball.wx,
      wy: ball.wy,
      wz: ball.wz,

      wnet: BallHelper.getMagnitude([ball.wx, ball.wy, ball.wz]),
    };

    return output;
  }

  static safeNormalize(vector: Vector3, minNonZero = 1e-9): Vector3 {
    if (vector.length() >= minNonZero) {
      return vector.normalize();
    }

    const clone = new Vector3(minNonZero, vector.y, vector.z);
    return clone.normalize();
  }

  static getPitchSeams(pitch: IPitch): ISeamOrientation {
    if (pitch.seams) {
      return pitch.seams;
    }

    return this.getBallStateSeams(pitch.bs);
  }

  // todo: determine which of Rapsodo vs OD gives better results and substitute BS method
  static getShotSeams(shot: IMachineShot): ISeamOrientation {
    if (shot.seams) {
      return shot.seams;
    }

    if (!shot.bs) {
      // shouldn't trigger
      throw new Error(`Insufficient data to find seams for shot ${shot._id}`);
    }

    // fallback to ball state (if possible)
    return this.getBallStateSeams(shot.bs);
  }

  static getBallStateSeams(bs: IBallState): ISeamOrientation {
    const spin: ISpin = {
      wx: bs.wx,
      wy: bs.wy,
      wz: bs.wz,
    };
    const spinAxis = this.safeNormalize(new Vector3(spin.wx, spin.wy, spin.wz));
    const quat = new Quaternion(bs.qx, bs.qy, bs.qz, bs.qw);
    const invQuat = quat.invert();
    const ptViaQuat: ISurfacePoint = spinAxis.applyQuaternion(invQuat);

    const seams = this.convertPointToSeams(ptViaQuat);

    return seams;
  }

  // converts from Trajekt (x,y,z) to Hawkeye (x,z,-y)
  static convertPoint_T2H(point: ISurfacePoint): ISurfacePoint {
    const output: ISurfacePoint = {
      x: point.x,
      y: point.z,
      z: -point.y,
    };

    return output;
  }

  // converts from Hawkeye (x,y,z) to Trajekt (x,-z,y)
  static convertPoint_H2T(point: ISurfacePoint): ISurfacePoint {
    const output: ISurfacePoint = {
      x: point.x,
      y: -point.z,
      z: point.y,
    };

    return output;
  }

  static convertPointToSeams(point: ISurfacePoint): ISeamOrientation {
    const output: ISeamOrientation = {
      latitude_deg: (RAD_RIGHT_ANGLE - Math.acos(point.z)) * RAD2DEG,
      longitude_deg: Math.atan2(point.x, -point.y) * RAD2DEG,
    };

    return output;
  }

  static convertSeamsToPoint(seam: ISeamOrientation): ISurfacePoint {
    const z = Math.cos(RAD_RIGHT_ANGLE - seam.latitude_deg * DEG2RAD);
    const zFactor = Math.sqrt(1 - Math.pow(z, 2));

    const output: ISurfacePoint = {
      x: Math.sin(seam.longitude_deg * DEG2RAD) * zFactor,
      y: -Math.cos(seam.longitude_deg * DEG2RAD) * zFactor,
      z: z,
    };

    return output;
  }

  static getAverageSeams(
    pitch: IPitch,
    shots: IMachineShot[]
  ): ISeamOrientation {
    const pitchSeams = this.getPitchSeams(pitch);
    const pitchPt = this.convertSeamsToPoint(pitchSeams);

    const closestShotPts = shots.map((s) => {
      const shotSeams = this.getShotSeams(s);
      const shotPt = this.convertSeamsToPoint(shotSeams);

      // ascending 3D cartesian distance from pitchPt
      const equivPts = this.getEquivalentPoints(shotPt);

      const logs = equivPts.map((pt) => {
        const seams = this.convertPointToSeams(pt);

        return {
          ref_point: pitchPt,
          point: pt,
          point_distance: this.getPointDistance(pitchPt, pt),

          ref_seams: pitchSeams,
          seams: seams,
          seams_distance: this.getSeamsDistance(pitchSeams, seams),
        };
      });

      console.debug({
        event: 'getAverageSeams',
        logs,
      });

      equivPts.sort((a, b) => {
        // delta between surface points
        const distA = this.getPointDistance(pitchPt, a);
        const distB = this.getPointDistance(pitchPt, b);

        if (distA !== distB) {
          return distA < distB ? -1 : 1;
        }

        // delta between seams degree values as tie breaker
        const seamA = this.convertPointToSeams(a);
        const seamB = this.convertPointToSeams(b);

        const sDistA = this.getSeamsDistance(pitchSeams, seamA);
        const sDistB = this.getSeamsDistance(pitchSeams, seamB);

        return sDistA < sDistB ? -1 : 1;
      });

      return equivPts[0];
    });

    const avgPt: ISurfacePoint = MiscHelper.getMeanObject(
      closestShotPts
    ) as ISurfacePoint;

    const unitAvgPt = this.getUnitPoint(avgPt);
    return this.convertPointToSeams(unitAvgPt);
  }

  static getUnitPoint(point: ISurfacePoint): ISurfacePoint {
    const magnitude = this.getPointDistance(point);

    if (magnitude === 0) {
      return {
        x: 0,
        y: 0,
        z: 0,
      };
    }

    const output: ISurfacePoint = {
      x: point.x / magnitude,
      y: point.y / magnitude,
      z: point.z / magnitude,
    };

    return output;
  }

  // second point is optional (origin by default)
  static getPointDistance(
    p1: ISurfacePoint,
    p2: ISurfacePoint = { x: 0, y: 0, z: 0 }
  ): number {
    const diffs = [p1.x - p2.x, p1.y - p2.y, p1.z - p2.z];
    const squares = diffs.map((d) => Math.pow(d, 2));
    const sum = squares.reduce((total, current) => total + current, 0);
    return Math.sqrt(sum);
  }

  // second seams is optional (origin by default)
  static getSeamsDistance(
    s1: ISeamOrientation,
    s2: ISeamOrientation = { latitude_deg: 0, longitude_deg: 0 }
  ): number {
    const diffs = [
      s1.latitude_deg - s2.latitude_deg,
      s1.longitude_deg - s2.longitude_deg,
    ];
    const squares = diffs.map((d) => Math.pow(d, 2));
    const sum = squares.reduce((total, current) => total + current, 0);
    return Math.sqrt(sum);
  }

  static getEquivalentPoints(point: ISurfacePoint): ISurfacePoint[] {
    return [
      {
        // eq0, aka: original
        x: point.x,
        y: point.y,
        z: point.z,
      },
      {
        // eq1
        x: -point.x,
        y: point.y,
        z: -point.z,
      },
      {
        // eq2
        x: point.z,
        y: -point.y,
        z: point.x,
      },
      {
        // eq3
        x: -point.z,
        y: -point.y,
        z: -point.x,
      },
    ];
  }

  // Generates an arbitrary quaternion which fully defines the ball's release orientation such that
  // the input latitude_deg and longitude_deg are conserved.
  static getQuatFromSeams(input: IGetQuaternionInput): IOrientation {
    // Get surface point and spin axis as vectors
    const surfacePoint = this.convertSeamsToPoint(input);
    const surfacePointVec = new Vector3(
      surfacePoint.x,
      surfacePoint.y,
      surfacePoint.z
    );
    const spinAxisVec = this.safeNormalize(
      new Vector3(input.spin.wx, input.spin.wy, input.spin.wz)
    );
    // Compute angle and axis which separate the two unit vectors
    const theta = Math.acos(surfacePointVec.dot(spinAxisVec));
    const axis = this.safeNormalize(surfacePointVec.cross(spinAxisVec));
    // Convert axis/angle to quaternion
    return {
      qw: Math.cos(theta / 2),
      qx: Math.sin(theta / 2) * axis.x,
      qy: Math.sin(theta / 2) * axis.y,
      qz: Math.sin(theta / 2) * axis.z,
    };
  }

  static getLocalSpin(globalSpin: ISpin, speed: IVelocity): ISpin {
    const xSq = Math.pow(speed.vx, 2);
    const ySq = Math.pow(speed.vy, 2);
    const zSq = Math.pow(speed.vz, 2);

    const invRot: IAziAltCoordinate = {
      azimuth_rad: -Math.asin(speed.vx / Math.sqrt(xSq + ySq)),
      altitude_rad: -Math.asin(speed.vz / Math.sqrt(xSq + ySq + zSq)),
    };

    const mx = getRotationMatrix({
      z_rad: invRot.azimuth_rad,
      x_rad: -invRot.altitude_rad,
    });

    const localSpin = new Vector3(
      globalSpin.wx,
      globalSpin.wy,
      globalSpin.wz
    ).applyMatrix3(mx);

    return {
      wx: localSpin.x,
      wy: localSpin.y,
      wz: localSpin.z,
    };
  }
}
