import { IOption } from '../interfaces/common/i-option';
import { IOrientation, ISeamOrientation } from '../interfaces/pitches/i-base';

export enum BuildPriority {
  Spins = 'Spins',
  Breaks = 'Breaks',
}

export enum TrainingStatus {
  Full = 'Fully Trained',
  Partial = 'Partially Trained',
  Not = 'Not Trained',
  Unknown = 'Unknown',
}

export enum PitchListExtType {
  Card = 'Card',
  Reference = 'Reference',
  Sample = 'Sample',
}

export enum PitcherHand {
  RHP = 'RHP',
  LHP = 'LHP',
}

export enum PitcherRelease {
  Overhand = 'Overhand',
  ThreeQuarter = '3/4',
  Sidearm = 'Sidearm',
}

export enum PlayerLevel {
  Pro = 'Pro',
  AAA = 'AAA',
  AA = 'AA',
  A = 'A',
  Prospect = 'Prospect',
  Rookie = 'Rookie',
}

export type OutcomeType = 'Strike' | 'Ball' | 'In Play';

export enum Orientation {
  FF2 = 'Two Seam',
  FF4 = 'Four Seam',
  CUS = 'Custom',
  // UNK = 'Unspecified',
  // DEF = 'Default',
}

export const ORIENTATIONS: { [key: string]: IOrientation } = {
  [Orientation.FF2]: { qw: 1, qx: 0, qy: 0, qz: 0 },
  [Orientation.FF4]: { qw: 0.7071, qx: 0, qy: 0, qz: -0.7071 },
  // [Orientation.UNK]: { qw: 0, qx: 0, qy: 0, qz: 0 },
};

export const ORIENTATION_SEAM_ALT_AZ: { [key: string]: ISeamOrientation } = {
  [Orientation.FF2]: { latitude_deg: 0, longitude_deg: 90 },
  [Orientation.FF4]: { latitude_deg: 0, longitude_deg: 0 },
  // [Orientation.DEF]: { latitude_deg: 0, longitude_deg: 0 },
  // [Orientation.UNK]: { latitude_deg: NaN, longitude_deg: NaN },
};

export const DEFAULT_ORIENTATION = ORIENTATIONS[Orientation.FF2];

export const orientationDropdownOptions: {
  label: Orientation;
  value: IOrientation;
}[] = Object.keys(ORIENTATIONS).map((key) => {
  return { label: key as Orientation, value: ORIENTATIONS[key] };
});

export enum DeliveryType {
  Stretch = 'Stretch',
  Windup = 'Windup',
}

export enum PitchType {
  None = 'NONE',

  ChangeUp = 'CH',
  Curveball = 'CU',
  Custom = 'CUSTOM',
  Cutter = 'FC',
  Eephus = 'EP',
  Forkball = 'FO',
  FourSeamFastball = 'FF',
  Gyroball = 'GY',
  Knuckleball = 'KN',
  KnuckleCurve = 'KC',
  Screwball = 'SC',
  Sinker = 'SI',
  Slider = 'SL',
  SlowCurve = 'CS',
  Slurve = 'SV',
  Splitter = 'FS',
  Sweeper = 'SW',
  TwoSeamFastball = 'FT',
}

export enum PitchTypeGroup {
  Heat = 'Heat',
  OffSpeed = 'Off-Speed',
}

const HEAT_PITCHES: IOption[] = [
  {
    group: PitchTypeGroup.Heat,
    label: 'Four-Seam Fastball',
    value: PitchType.FourSeamFastball,
    aliases: [
      'fa',
      'ff',
      'fb',
      '4s',
      '4-seam fb',
      '4-seam fastball',
      '4 Seam fastball',
      'fastball',
      'four-seam fb',
      'four-seam fastball',
      'four Seam fastball',
    ],
  },
  {
    group: PitchTypeGroup.Heat,
    label: 'Two-Seam Fastball',
    value: PitchType.TwoSeamFastball,
    aliases: [
      'ft',
      '2s',
      '2-seam fb',
      '2-seam fastball',
      '2 Seam fastball',
      'two-seam fb',
      'two-seam fastball',
      'two Seam fastball',
    ],
  },
  {
    group: PitchTypeGroup.Heat,
    label: 'Cutter',
    value: PitchType.Cutter,
    aliases: ['cf', 'ct', 'fc', 'cutter'],
  },
  {
    group: PitchTypeGroup.Heat,
    label: 'Sinker',
    value: PitchType.Sinker,
    aliases: ['si', 'sinker'],
  },
];

const OFF_SPEED_PITCHES: IOption[] = [
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Change-up',
    value: PitchType.ChangeUp,
    aliases: ['ch', 'change', 'changeup', 'change-up'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Curveball',
    value: PitchType.Curveball,
    aliases: ['cb', 'cu', 'curveball', 'curve'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Eephus',
    value: PitchType.Eephus,
    aliases: ['ep', 'eephus'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Forkball',
    value: PitchType.Forkball,
    aliases: ['fo', 'forkball'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Gyroball',
    value: PitchType.Gyroball,
    aliases: ['gy', 'gyro', 'gyroball'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Knuckle Curve',
    value: PitchType.KnuckleCurve,
    aliases: ['kc', 'knuckle-curve', 'knuckle curve'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Knuckleball',
    value: PitchType.Knuckleball,
    aliases: ['kn', 'knuckle', 'knuckleball'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Screwball',
    value: PitchType.Screwball,
    aliases: ['sc', 'screw', 'screwball'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Slider',
    value: PitchType.Slider,
    aliases: ['sl', 'slider'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Slow Curve',
    value: PitchType.SlowCurve,
    aliases: ['cs', 'slow curve'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Slurve',
    value: PitchType.Slurve,
    aliases: ['sv', 'slurve'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Splitter',
    value: PitchType.Splitter,
    aliases: ['sf', 'fs', 'sp', 'splitter'],
  },
  {
    group: PitchTypeGroup.OffSpeed,
    label: 'Sweeper',
    value: PitchType.Sweeper,
    aliases: ['st', 'sw', 'sweeper'],
  },
];

export const PITCH_TYPE_OPTIONS = [...HEAT_PITCHES, ...OFF_SPEED_PITCHES];

/**
 *
 * @param term provided as colloquial or common terms (many) that map to pitch type (one)
 * @returns pitch type if found, otherwise FF as fallback
 */
export const lookupPitchType = (
  term?: string,
  fallback?: PitchType
): PitchType => {
  try {
    if (!term) {
      throw new Error('PITCH TYPE LOOKUP: empty term provided');
    }

    const safeTerm = term.toLowerCase();

    const result = PITCH_TYPE_OPTIONS.find((t) => {
      if (t.value.toLowerCase() === safeTerm) {
        return true;
      }

      if (t.label.toLowerCase() === safeTerm) {
        return true;
      }

      return !!t.aliases?.includes(safeTerm);
    });

    if (result) {
      return result.value as PitchType;
    }

    throw new Error(`PITCH TYPE LOOKUP: term ${term} could not be found`);
  } catch (e) {
    console.error(e);
    return fallback ?? PitchType.FourSeamFastball;
  }
};

export const expandPitchType = (type?: PitchType): string => {
  const found = [...HEAT_PITCHES, ...OFF_SPEED_PITCHES].find(
    (o) => o.value === type
  );
  return found?.label ?? 'Custom';
};

export const SHUFFLE_FREQUENCY_OPTIONS: IOption[] = [
  {
    label: 'common.low',
    value: '1',
  },
  {
    label: 'common.medium',
    value: '2',
  },
  {
    label: 'common.high',
    value: '3',
  },
];
