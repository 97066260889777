import {
  BookmarkIcon,
  HamburgerMenuIcon,
  PersonIcon,
} from '@radix-ui/react-icons';
import { Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { DetectionFailedCallout } from 'components/machine/detection-failed';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { SectionBox } from './section-box';

const lorem =
  'Ad pariatur occaecat proident cillum sit cillum tempor laboris quis ullamco cupidatat.';

const COLORS: RadixColor[] = [
  'purple',
  RADIX.COLOR.SUCCESS,
  RADIX.COLOR.WARNING,
  RADIX.COLOR.DANGER,
  RADIX.COLOR.NEUTRAL,
  RADIX.COLOR.ACCENT,
];

export const CalloutsDemo = () => (
  <ErrorBoundary componentName="CalloutsDemo">
    <SectionBox>
      <Heading size={RADIX.HEADING.SIZE.MD}>Callouts</Heading>

      <CommonFormGrid columns={2}>
        <DetectionFailedCallout testing />

        {/* without icon */}
        {COLORS.map((color, index) => (
          <CommonCallout
            key={index}
            text={color.toUpperCase() + ' as text without icon... ' + lorem}
            color={color}
            hideIcon
          />
        ))}
        {/* with header */}
        {COLORS.map((color, index) => (
          <CommonCallout
            key={index}
            icon={<PersonIcon />}
            header="With Header"
            text={color.toUpperCase() + ' as text with header... ' + lorem}
            color={color}
          />
        ))}
        {/* without header */}
        {COLORS.map((color, index) => (
          <CommonCallout
            key={index}
            icon={<BookmarkIcon />}
            text={color.toUpperCase() + ' as text without header... ' + lorem}
            color={color}
          />
        ))}
        {/* with actions */}
        {COLORS.map((color, index) => (
          <CommonCallout
            key={index}
            icon={<HamburgerMenuIcon />}
            text={color.toUpperCase() + ' as text with actions... ' + lorem}
            color={color}
            actions={[
              {
                label: 'Action 1',
                color: color,
                onClick: () => {
                  NotifyHelper.info({ message_md: `Action clicked!` });
                },
              },
              {
                label: 'Action 2',
                color: color,
                onClick: () => {
                  NotifyHelper.info({ message_md: `Action clicked!` });
                },
              },
            ]}
          />
        ))}
      </CommonFormGrid>
    </SectionBox>
  </ErrorBoundary>
);
