import * as Accordion from '@radix-ui/react-accordion';
import { CommonAccordionItem, IAccordionItem } from './item';

interface IProps extends Accordion.AccordionSingleProps {
  items: IAccordionItem[];
}

export const CommonSingleAccordion = (props: IProps) => (
  <Accordion.Root className="AccordionRoot" {...props}>
    {props.items.map((item, i) => (
      <CommonAccordionItem key={i} {...item} />
    ))}
  </Accordion.Root>
);
