import { Box, Button, Flex, Grid, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { IDirtyContext } from 'contexts/dirty.context';
import { IMachineContext } from 'contexts/machine.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { isNumber } from 'lib_ts/classes/math.utilities';
import { HardwareVersion, MS_LIMITS } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideo, IVideoFrame } from 'lib_ts/interfaces/i-video';
import React from 'react';
import Slider from 'react-input-slider';
import { VideosService } from 'services/videos.service';
import { v4 } from 'uuid';

const RENDER_SCALE = {
  /** how many decimals to round scaling steps to use and for display */
  ROUND_DECIMALS: 2,
  MIN: 0.8,
  MAX: 1.201,
  STEP: 0.05,
};

/** because floats are weird */
const RENDER_SCALE_STEPS: number[] = (() => {
  const ROUND_FACTOR = Math.pow(10, RENDER_SCALE.ROUND_DECIMALS);

  const output: number[] = [];
  for (
    let i = RENDER_SCALE.MIN;
    i <= RENDER_SCALE.MAX;
    i = Math.round((i + RENDER_SCALE.STEP) * ROUND_FACTOR) / ROUND_FACTOR
  ) {
    output.push(i);
  }
  return output;
})();

/** how many frames +/- about the pivot to fetch,
 * use a smaller number for smaller/faster requests
 * use a larger number for larger/fewer requests
 */
const WIGGLE_FRAMES = 3;

/** safely return time * fps (not necessarily an integer), constrained to 1 or maxFrame; returns -1 if inputs are invalid */
const frameFromTime = (config: {
  time: number;
  fps: number;
  maxFrame: number;
}): number => {
  if (isNaN(config.time) || isNaN(config.fps)) {
    console.warn('Cannot use NaN time and/or fps, returning -1');
    return -1;
  }

  if (config.time < 0 || config.fps < 0) {
    console.warn('Cannot use negative time and/or fps, returning -1');
    return -1;
  }

  return Math.min(
    config.maxFrame,
    Math.max(1, Math.floor(config.time * config.fps))
  );
};

const timeFromFrame = (config: {
  frame: number;
  fps: number;
  maxFrame: number;
}): number => {
  if (isNaN(config.frame) || isNaN(config.fps)) {
    console.warn('Cannot use NaN frame and/or fps, returning -1');
    return -1;
  } else if (config.frame < 0 || config.fps < 0) {
    console.warn('Cannot use negative frame and/or fps, returning -1');
    return -1;
  } else if (config.fps === 0) {
    console.warn('Cannot use 0 fps, returning -1');
    return -1;
  } else {
    return Math.min(config.maxFrame, Math.max(1, config.frame)) / config.fps;
  }
};

const ENABLE_RESET = false;

/** in feet, 11 inches */
const MOUND_HEIGHT = 11 / 12;

const RELEASE_HEIGHT = {
  MIN: 0,
  MAX: MS_LIMITS.POSITION.Z.MAX,
};

type ModeName = 'release_pos' | 'mound_pos';

interface IMode {
  name: ModeName;
  title: string;
  color: string;
}

interface IImageDictionary {
  [key: string]: { release?: string; mound?: string };
}

interface IProps {
  dirtyCx: IDirtyContext;
  machineCx: IMachineContext;
  videosCx: IVideosContext;
  video_id: string;

  /** set to true to prevent editing, only show preview on machine */
  preview?: boolean;
}

interface IState {
  video: IVideo;
  loading: boolean;

  /** store requested images to avoid reloading constantly
   * when in raw mode (i.e. release tab), key is frame number, value release is base64 of image, mound is empty (except where key is 1)
   * when in preview mode (i.e. preview tab), key is scale number combined with hw version, value release and mound are base64 of images
   * */
  image_dict: IImageDictionary;

  /** base64, what is shown */
  image_release?: string;

  /** base64, what is shown */
  image_mound?: string;

  slider_x: number;
  slider_y: number;

  original_ReleasePixelX: number;
  original_ReleasePixelY: number;

  original_MoundPixelX: number;
  original_MoundPixelY: number;

  width: number;
  height: number;
  mode: IMode;

  /** for RenderScale value */
  scale: number;

  hardware_version: HardwareVersion;

  /** in feet */
  release_height: number;
}

const SLIDER_MODES: IMode[] = [
  {
    name: 'release_pos',
    title: 'videos.release-frame',
    color: 'rgba(255, 255, 255, 0.9)',
  },
  {
    name: 'mound_pos',
    title: 'videos.mound-frame',
    color: '#45E788',
  },
];

export class VideoFrameTab extends React.Component<IProps, IState> {
  private init = false;
  private wrapperNode?: HTMLDivElement;

  private fetchedHardwares: HardwareVersion[] = [];

  constructor(props: IProps) {
    super(props);

    const refVideo = props.videosCx.videos.find(
      (v) => v._id === props.video_id
    );

    if (!refVideo) {
      throw new Error(
        `Could not find video ${props.video_id} in videos context`
      );
    }

    const stateVideo = { ...refVideo };

    /** ensure we don't have undefined frame number */
    if (!stateVideo.ReleaseFrame) {
      stateVideo.ReleaseFrame = 1;
    }

    /** run after ReleaseFrame is fixed */
    if (!stateVideo.ReleaseTime) {
      if (stateVideo.ReleaseFrame)
        stateVideo.ReleaseTime = timeFromFrame({
          frame: stateVideo.ReleaseFrame,
          fps: stateVideo.fps,
          maxFrame: stateVideo.n_frames,
        });
    }

    /** make a copy to manipulate via forms */
    this.state = {
      video: stateVideo,
      loading: false,

      slider_x: stateVideo.ReleasePixelX,
      slider_y: stateVideo.ReleasePixelY,

      original_ReleasePixelX: stateVideo.ReleasePixelX,
      original_ReleasePixelY: stateVideo.ReleasePixelY,

      original_MoundPixelX: stateVideo.MoundPixelX,
      original_MoundPixelY: stateVideo.MoundPixelY,

      image_dict: {},
      image_release: '',
      image_mound: '',

      scale: stateVideo.RenderScale ?? 1,

      hardware_version: props.machineCx.machine.hardware_version,

      width: 0,
      height: 0,
      mode: SLIDER_MODES[0],

      release_height: stateVideo.ReleaseHeight,
    };

    /** image stuff */
    this.extractPreviewFrames = this.extractPreviewFrames.bind(this);
    this.extractRawFrames = this.extractRawFrames.bind(this);
    this.extractRawReleaseFrame = this.extractRawReleaseFrame.bind(this);
    this.getKeyForNumber = this.getKeyForNumber.bind(this);
    this.getReleaseImage = this.getReleaseImage.bind(this);
    this.handleSetReleaseTime = this.handleSetReleaseTime.bind(this);
    this.handleWiggleFrame = this.handleWiggleFrame.bind(this);
    this.initializeFrames = this.initializeFrames.bind(this);
    this.mergeRawImages = this.mergeRawImages.bind(this);

    /** form stuff */
    this.renderHardwareInput = this.renderHardwareInput.bind(this);
    this.renderNonPreviewForm = this.renderNonPreviewForm.bind(this);
    this.renderPlayer = this.renderPlayer.bind(this);
    this.renderPreviewForm = this.renderPreviewForm.bind(this);
    this.renderScaleInput = this.renderScaleInput.bind(this);

    this.resetCoordinates = this.resetCoordinates.bind(this);
    this.updateEditing = this.updateEditing.bind(this);

    /** window stuff */
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    if (this.init) {
      return;
    }

    this.init = true;
    this.initializeFrames();
  }

  private initializeFrames() {
    if (this.props.preview) {
      /** ensure that whatever scale the video is on (even if not in our steps) gets loaded */
      const uniqueScales: number[] = ArrayHelper.unique([
        this.state.scale,
        ...RENDER_SCALE_STEPS,
      ]);

      this.extractPreviewFrames({
        scales: uniqueScales,
        block: true,
      });
      return;
    }

    this.extractRawFrames({
      pivot: Math.floor(this.state.video.ReleaseFrame),
      plusMinus: WIGGLE_FRAMES,
      block: true,
      additionalFrames: [1],
    });
  }

  componentWillUnmount() {
    /** stop listening for resize events */
    window.removeEventListener('resize', this.handleResize);
  }

  private handleResize() {
    const aspectRatio =
      this.state.video.cap_size_0 / (this.state.video.cap_size_1 || 1);
    if (!this.wrapperNode) {
      console.warn('VideoFrameTab: slider wrapper is not defined');
      return;
    }

    const width = this.wrapperNode.offsetWidth ?? 100;
    const height = Math.round(width * aspectRatio);

    this.setState({
      width: width,
      height: height,
    });
  }

  private updateEditing(name: ModeName) {
    this.handleResize();

    /** skip if already on the mode */
    if (name === this.state.mode.name) {
      return;
    }

    switch (name) {
      case 'release_pos': {
        this.setState({
          slider_x: this.state.video.ReleasePixelX
            ? this.state.video.ReleasePixelX
            : 500,
          slider_y: this.state.video.ReleasePixelY
            ? this.state.video.ReleasePixelY
            : 500,
          mode: SLIDER_MODES[0],
        });
        break;
      }

      case 'mound_pos': {
        this.setState({
          slider_x: this.state.video.MoundPixelX
            ? this.state.video.MoundPixelX
            : 500,
          slider_y: this.state.video.MoundPixelY
            ? this.state.video.MoundPixelY
            : 500,
          mode: SLIDER_MODES[1],
        });
        break;
      }

      default: {
        throw new Error(`Unexpected editing mode: ${name}`);
      }
    }
  }

  private resetCoordinates() {
    switch (this.state.mode.name) {
      case 'release_pos': {
        this.setState({
          slider_x: this.state.original_ReleasePixelX,
          slider_y: this.state.original_ReleasePixelY,
        });
        break;
      }

      case 'mound_pos': {
        this.setState({
          slider_x: this.state.original_MoundPixelX,
          slider_y: this.state.original_MoundPixelY,
        });
        break;
      }

      default: {
        throw new Error(`Unexpected editing mode: ${this.state.mode}`);
      }
    }

    this.props.dirtyCx.markDirty('FrameTab', 'reset coordinates');
  }

  /** pivot index defines the frame that +/- frames in both directions (if possible) will be requested
   * extracts first raw frames based on release chars of the video and t=0
   * */
  private async extractRawFrames(config: {
    pivot: number;
    plusMinus: number;
    /** i.e. whether to block the UI while this loads */
    block: boolean;
    /** e.g. [1] for mound */
    additionalFrames?: number[];
  }) {
    const safeIndex = Math.min(
      this.state.video.n_frames,
      Math.max(1, config.pivot)
    );

    // use cached values
    if (
      Object.keys(this.state.image_dict).includes(
        this.getKeyForNumber(safeIndex)
      )
    ) {
      this.setState({
        image_release:
          this.state.image_dict[this.getKeyForNumber(safeIndex)]?.release ??
          this.state.image_release,
        image_mound:
          this.state.image_dict[this.getKeyForNumber(1)]?.mound ??
          this.state.image_mound,
      });
      return;
    }

    const timeLabel = `extractRawFrames ${v4()}`;

    /** auto-fetch the release index +/- 5 */
    const minIndex = Math.max(2, safeIndex - config.plusMinus);
    const maxIndex = Math.min(
      this.state.video.n_frames,
      safeIndex + config.plusMinus
    );

    const frame_indices: number[] = [];

    if (config.additionalFrames) {
      frame_indices.push(...config.additionalFrames);
    }

    for (let i = minIndex; i <= maxIndex; i++) {
      if (
        !Object.keys(this.state.image_dict).includes(this.getKeyForNumber(i))
      ) {
        /** only pull if not already in dict */
        frame_indices.push(i);
      }
    }

    if (frame_indices.length === 0) {
      return;
    }

    if (config.block) {
      this.setState({ loading: true });
    }

    const images = await VideosService.getInstance().extractFrames({
      mode: 'raw',
      video_id: this.state.video._id,
      frameSpec: this.state.video,
      frame_indices: frame_indices,
      scales: [1],
      hardware_version: this.state.hardware_version,
    });

    if (config.block) {
      this.setState({ loading: false });
    }

    if (!images || images.length === 0) {
      NotifyHelper.error({
        message_md: 'Image extraction failed, please try again.',
      });
      return;
    }

    this.mergeRawImages({
      index: safeIndex,
      images: images,
    });
  }

  private getKeyForNumber(value: number) {
    if (this.props.preview) {
      return `${value}_${this.state.hardware_version}`;
    }

    return `${value}`;
  }

  /** extracts preview frames based on release chars of the video and t=0 */
  private async extractPreviewFrames(config: {
    scales: number[];
    /** i.e. whether to block the UI while this loads */
    block: boolean;
  }) {
    const key = this.getKeyForNumber(this.state.scale);

    // avoid re-fetch if it's already been fetched
    if (this.fetchedHardwares.includes(this.state.hardware_version)) {
      this.setState({
        image_release: this.state.image_dict[key]?.release,
        image_mound: this.state.image_dict[key]?.mound,
      });
      return;
    }

    const timeLabel = `extractPreviewFrames ${v4()}`;

    if (config.block) {
      this.setState({ loading: true });
    }

    const service = VideosService.getInstance();

    const images = await service.extractFrames({
      mode: 'preview',
      video_id: this.state.video._id,
      frameSpec: this.state.video,
      frame_indices: [1, this.state.video.ReleaseFrame],
      scales: config.scales,
      hardware_version: this.state.hardware_version,
    });

    const scaled_images_release = images.filter(
      (i) => i.frame === this.state.video.ReleaseFrame
    );
    const scaled_images_mound = images.filter((i) => i.frame === 1);

    if (config.block) {
      this.setState({ loading: false });
    }

    if (!scaled_images_release || !scaled_images_mound) {
      NotifyHelper.error({
        message_md: 'Image extraction failed, please try again.',
      });
      return;
    }

    const dict: IImageDictionary = {
      // retain current contents of image dict (e.g. for other hardwares, if any)
      ...this.state.image_dict,
    };

    scaled_images_release.forEach((frame) => {
      if (frame.scale === undefined) {
        return;
      }

      const key = this.getKeyForNumber(frame.scale);

      if (!dict[key]) {
        dict[key] = {};
      }

      dict[key].release = frame.base64;
    });

    scaled_images_mound.forEach((frame) => {
      if (frame.scale === undefined) {
        return;
      }

      const key = this.getKeyForNumber(frame.scale);

      if (!dict[key]) {
        dict[key] = {};
      }

      dict[key].mound = frame.base64;
    });

    this.setState(
      {
        image_dict: dict,
        image_release: dict[key]?.release,
        image_mound: dict[key]?.mound,
      },
      () => this.fetchedHardwares.push(this.state.hardware_version)
    );
  }

  handleSave() {
    const callback = async (payload: IVideo) => {
      const success = await this.props.videosCx.updateVideo(payload);

      if (success) {
        this.setState({ video: payload });
        this.props.dirtyCx.clearDirty('FrameTab', 'saved');
      }
    };

    /** preview tab (where release can't change) */
    if (this.props.preview) {
      callback(this.state.video);
      return;
    }

    /** release tab */
    if (!this.props.preview) {
      if (
        this.state.release_height < RELEASE_HEIGHT.MIN ||
        this.state.release_height > RELEASE_HEIGHT.MAX
      ) {
        NotifyHelper.error({
          message_md: `Please ensure that release height is between ${RELEASE_HEIGHT.MIN}-${RELEASE_HEIGHT.MAX} feet.`,
        });
        return;
      }

      /** apply some pre-processing to adjust release and mound details before submission */
      const temp = { ...this.state.video };

      temp.ReleaseHeight = this.state.release_height;

      if (
        temp.MoundPixelY !== undefined &&
        temp.MoundPixelX !== undefined &&
        temp.ReleasePixelY !== undefined &&
        temp.ReleasePixelX !== undefined
      ) {
        const scale = Math.abs(
          (temp.ReleasePixelY - temp.MoundPixelY) /
            (temp.ReleaseHeight - MOUND_HEIGHT)
        );
        temp.ReleaseSide = (temp.ReleasePixelX - temp.MoundPixelX) / scale;
      }

      callback(temp);
      return;
    }
  }

  private renderScaleInput() {
    return (
      <Box>
        <CommonInputWrapper id="video-render-scale">
          <CommonInputLabel label="videos.render-scale" />

          <Slider
            axis="x"
            xstep={RENDER_SCALE.STEP}
            xmin={RENDER_SCALE.MIN}
            xmax={RENDER_SCALE.MAX}
            x={this.state.scale}
            disabled={this.state.loading}
            onChange={(pos) => {
              const ROUND_FACTOR = Math.pow(10, RENDER_SCALE.ROUND_DECIMALS);
              const nextScale = Math.round(pos.x * ROUND_FACTOR) / ROUND_FACTOR;
              this.setState({
                scale: nextScale,
              });
              this.props.dirtyCx.markDirty('FrameTab', 'change render scale');
            }}
            onDragEnd={() => {
              setTimeout(() => {
                const temp = { ...this.state.video };
                temp.RenderScale = this.state.scale;

                const nextImages =
                  this.state.image_dict[this.getKeyForNumber(this.state.scale)];

                if (!nextImages) {
                  NotifyHelper.warning({
                    message_md: `Could not find images entry for ${this.state.scale}x scaling. Please try again or contact support.`,
                  });
                }

                this.setState({
                  video: temp,
                  image_release: nextImages ? nextImages.release ?? '' : '',
                  image_mound: nextImages ? nextImages.mound ?? '' : '',
                });
                this.props.dirtyCx.markDirty('FrameTab', 'drag render scale');
              }, 100);
            }}
            styles={{
              track: {
                width: '100%',
              },
            }}
          />

          <CommonInputHint
            hint_md={[
              `${t('common.current')}: ${this.state.scale.toFixed(
                RENDER_SCALE.ROUND_DECIMALS
              )}`,
              '|',
              `Min: ${RENDER_SCALE.MIN.toFixed(RENDER_SCALE.ROUND_DECIMALS)}x`,
              '-',
              `Max: ${RENDER_SCALE.MAX.toFixed(RENDER_SCALE.ROUND_DECIMALS)}x`,
            ].join(' ')}
          />
        </CommonInputWrapper>
      </Box>
    );
  }

  /** just for preview purposes, the changes don't actually save to the database */
  private renderHardwareInput() {
    return (
      <Box>
        <CommonSelectInput
          id="video-hardware-version"
          name="hardware_version"
          label="common.hardware-version"
          options={Object.values(HardwareVersion).map((t) => {
            return {
              label:
                t === this.props.machineCx.machine.hardware_version
                  ? `${t}*`
                  : t,
              value: t,
            };
          })}
          value={this.state.hardware_version}
          onChange={(v) => {
            this.setState({ hardware_version: v as HardwareVersion }, () => {
              const uniqueScales: number[] = ArrayHelper.unique([
                this.state.scale,
                ...RENDER_SCALE_STEPS,
              ]);

              this.extractPreviewFrames({
                scales: uniqueScales,
                block: true,
              });
            });
          }}
        />
      </Box>
    );
  }

  private async handleSetReleaseTime(releaseTime: number | undefined) {
    if (this.state.mode.name !== 'release_pos') {
      NotifyHelper.error({
        message_md: `Ad-hoc frame extraction is not allowed for this mode: ${this.state.mode.name}`,
      });
      return;
    }

    if (releaseTime === undefined || !isNumber(releaseTime)) {
      console.error(
        `VideoFrameTab: invalid release time value of ${releaseTime}`
      );
      return;
    }

    const safeTargetFrame = frameFromTime({
      time: releaseTime,
      fps: this.state.video.fps,
      maxFrame: this.state.video.n_frames,
    });

    const videoPayload: IVideo = {
      ...this.state.video,
      ReleaseFrame: safeTargetFrame,
      ReleaseTime: releaseTime,
    };

    const existingImage = this.getReleaseImage(safeTargetFrame);
    if (!existingImage) {
      /** update video immediately for input to show, then request the image that isn't in dictionary yet */
      this.setState(
        {
          video: videoPayload,
        },
        () => {
          this.props.dirtyCx.markDirty('FrameTab', 'set release time');
          this.extractRawReleaseFrame(videoPayload);
        }
      );
      return;
    }

    /** image was already fetched, just load it from dictionary */
    this.setState(
      {
        video: videoPayload,
        image_release: existingImage,
      },
      () => {
        this.props.dirtyCx.markDirty('FrameTab', 'set release time');
      }
    );
  }

  /** updates image dict using deserialization to ensure render is triggered */
  private async mergeRawImages(config: {
    index: number;
    images: IVideoFrame[];
  }): Promise<void> {
    const imageDict = {
      ...this.state.image_dict,
    };

    /** add/overwrite existing entries with new values */
    config.images.forEach((img) => {
      const key = this.getKeyForNumber(img.frame);

      if (img.frame === 1) {
        imageDict[key] = { mound: img.base64 };
      } else {
        imageDict[key] = { release: img.base64 };
      }
    });

    this.setState({
      image_dict: imageDict,
      image_release:
        imageDict[this.getKeyForNumber(config.index)]?.release ??
        this.state.image_release,
      image_mound:
        imageDict[this.getKeyForNumber(1)]?.mound ?? this.state.image_mound,
    });
  }

  /** converts frame to floor before checking dictionary, returns empty string if not found */
  private getReleaseImage(key: number): string {
    const entry = this.state.image_dict[Math.floor(key)];
    if (!entry) {
      return '';
    }

    return entry.release ?? '';
  }

  /** informed by current mode + release images dictionary */
  private async handleWiggleFrame(frameOffset: number) {
    if (this.state.mode.name !== 'release_pos') {
      NotifyHelper.error({
        message_md: `Ad-hoc frame extraction is not allowed for this mode: ${this.state.mode.name}`,
      });
      return;
    }

    const safeTargetFrame = Math.floor(
      Math.max(
        1,
        Math.min(
          this.state.video.n_frames,
          this.state.video.ReleaseFrame + frameOffset
        )
      )
    );

    const videoPayload: IVideo = {
      ...this.state.video,
      ReleaseFrame: safeTargetFrame,
      ReleaseTime: safeTargetFrame / this.state.video.fps,
    };

    const existingImage = this.getReleaseImage(safeTargetFrame);
    if (!existingImage) {
      /** update video immediately for input to show, then request the image that isn't in dictionary yet */
      this.setState(
        {
          video: videoPayload,
        },
        () => {
          this.props.dirtyCx.markDirty('FrameTab', 'wiggle release frame');
          this.extractRawReleaseFrame(videoPayload);
        }
      );
      return;
    }

    /** image was already fetched, just load it from dictionary */
    this.setState(
      {
        video: videoPayload,
        image_release: existingImage,
      },
      () => {
        this.props.dirtyCx.markDirty('FrameTab', 'wiggle release frame');
      }
    );
  }

  /** provide video object to extract frame corresponding to release frame and merge it into the frame dictionary */
  private async extractRawReleaseFrame(frameSpec: IVideo) {
    this.extractRawFrames({
      pivot: frameSpec.ReleaseFrame,
      plusMinus: WIGGLE_FRAMES,
      block: true,
    });
  }

  render() {
    return (
      <ErrorBoundary
        componentName={
          this.props.preview ? 'PreviewFrameTab' : 'ReleaseFrameTab'
        }
      >
        <Heading size={RADIX.HEADING.SIZE.MD}>
          {this.state.video.VideoTitle || this.state.video.VideoFileName}
        </Heading>

        <Grid columns="5" gap={RADIX.FLEX.GAP.LG}>
          <Box gridColumn="span 3">{this.renderPlayer()}</Box>
          <Flex gridColumn="span 2" direction="column" gap={RADIX.FLEX.GAP.MD}>
            <CommonFormGrid columns={SLIDER_MODES.length}>
              {SLIDER_MODES.map((mode, i) => (
                <Box key={`slider-mode-${i}`} flexGrow="1">
                  <Button
                    className="btn-block"
                    disabled={this.state.loading}
                    color={RADIX.COLOR.NEUTRAL}
                    variant={
                      this.state.mode.name === mode.name
                        ? RADIX.BUTTON.VARIANT.SELECTED
                        : RADIX.BUTTON.VARIANT.NOT_SELECTED
                    }
                    onClick={() => this.updateEditing(mode.name)}
                  >
                    {t(mode.title)}
                  </Button>
                </Box>
              ))}
            </CommonFormGrid>

            {this.props.preview
              ? this.renderPreviewForm()
              : this.renderNonPreviewForm()}
          </Flex>
        </Grid>
      </ErrorBoundary>
    );
  }

  private renderPlayer() {
    const { sliderThumb, sliderBackground } = (() => {
      switch (this.state.mode.name) {
        case 'release_pos': {
          return {
            sliderBackground: this.state.image_release ?? '',
            sliderThumb: {
              backgroundImage: 'url(/img/baseball.png)',
              backgroundColor: 'rgba(0, 255, 0, 0)',
              backgroundSize: 'cover',
              boxShadow: 'none',
              height: '32px',
              width: '32px',
              margin: '-16px',
              opacity: this.props.preview ? 0 : 1,
            },
          };
        }

        case 'mound_pos': {
          return {
            sliderBackground: this.state.image_mound ?? '',
            sliderThumb: {
              backgroundImage: 'url(/img/mound.png)',
              backgroundColor: 'rgba(0, 255, 0, 0)',
              backgroundSize: 'cover',
              boxShadow: 'none',
              width: 140,
              height: 140,
              opacity: this.props.preview ? 0 : 1,
            },
          };
        }

        default: {
          throw new Error(`Unexpected mode: ${this.state.mode.name}`);
        }
      }
    })();

    return (
      <Box>
        <div ref={(elem) => (this.wrapperNode = elem as HTMLDivElement)}>
          {(() => {
            if (this.state.loading) {
              /** loading */
              return (
                <img
                  alt="loading"
                  className="block"
                  src="/img/video/loading.svg"
                />
              );
            }

            if (!sliderBackground) {
              /** error */
              return (
                <img alt="error" className="block" src="/img/video/error.svg" />
              );
            }

            if (this.props.preview) {
              /** preview frame with hdpe drawn on */
              return (
                <div style={{ position: 'relative' }}>
                  <img
                    alt="frame-preview"
                    style={{ width: '100%' }}
                    src={sliderBackground}
                  />
                </div>
              );
            }

            /** details frame with ball/mound sliders */
            return (
              <Slider
                axis="xy"
                xstep={1}
                xmin={0}
                xmax={this.state.video.cap_size_1}
                x={this.state.slider_x}
                ystep={1}
                ymin={0}
                ymax={this.state.video.cap_size_0}
                y={this.state.slider_y}
                disabled={this.props.preview}
                onChange={(pos) => {
                  this.setState({
                    slider_x: pos.x,
                    slider_y: pos.y,
                  });
                }}
                onDragEnd={() => {
                  setTimeout(() => {
                    const temp = { ...this.state.video };

                    switch (this.state.mode.name) {
                      case 'release_pos': {
                        temp.ReleasePixelX = this.state.slider_x;
                        temp.ReleasePixelY = this.state.slider_y;
                        break;
                      }

                      case 'mound_pos': {
                        temp.MoundPixelX = this.state.slider_x;
                        temp.MoundPixelY = this.state.slider_y;
                        break;
                      }

                      default: {
                        throw new Error(
                          `Unexpected editing mode: ${this.state.mode}`
                        );
                      }
                    }

                    this.setState({ video: temp });
                  }, 100);
                }}
                styles={{
                  track: {
                    backgroundColor: 'rgba(0, 0, 255, 0)',
                    backgroundImage: `url(${sliderBackground})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: this.state.width,
                    height: this.state.height,
                  },
                  thumb: sliderThumb,
                }}
              />
            );
          })()}
        </div>
      </Box>
    );
  }

  private renderNonPreviewForm() {
    return (
      <CommonFormGrid columns={2}>
        <Box gridColumn="span 2">
          <Heading size={RADIX.HEADING.SIZE.SM}>
            {t(this.state.mode.title)} {t('videos.coordinates')}
          </Heading>
        </Box>

        <Box>
          <CommonTextInput
            id="video-release-x"
            label={`${t(this.state.mode.title)} X`}
            type="number"
            value={this.state.slider_x.toString()}
            onNumericChange={(v) => {
              const temp = { ...this.state.video };

              if (this.state.mode.name === 'release_pos') {
                temp.ReleasePixelX = v;
              } else {
                temp.MoundPixelX = v;
              }

              this.setState({
                slider_x: v,
                video: temp,
              });

              this.props.dirtyCx.markDirty('FrameTab', 'edit coordinate x');
            }}
          />
        </Box>
        <Box>
          <CommonTextInput
            id="video-release-y"
            label={`${t(this.state.mode.title)} Y`}
            type="number"
            value={this.state.slider_y.toString()}
            onNumericChange={(v) => {
              const temp = { ...this.state.video };

              if (this.state.mode.name === 'release_pos') {
                temp.ReleasePixelY = v;
              } else {
                temp.MoundPixelY = v;
              }

              this.setState({
                slider_y: v,
                video: temp,
              });

              this.props.dirtyCx.markDirty('FrameTab', 'edit coordinate y');
            }}
          />
        </Box>

        {ENABLE_RESET && (
          <Box gridColumn="span 2">
            <Button
              disabled={this.state.loading}
              className="btn-block"
              variant="outline"
              onClick={this.resetCoordinates}
            >
              {t('common.reset-x', { x: t('videos.coordinates') })}
            </Button>
          </Box>
        )}

        {this.state.mode.name === 'release_pos' && (
          <>
            <Box gridColumn="span 2">
              <CommonTextInput
                id="video-release-time"
                label="videos.release-time"
                name="release_time"
                type="number"
                value={this.state.video.ReleaseTime.toString()}
                onNumericChange={(e) => this.handleSetReleaseTime(e)}
                hint_md={
                  this.state.video.ReleaseFrame !==
                  Math.round(this.state.video.ReleaseFrame)
                    ? t('videos.release-time-between-frames-x-y', {
                        x: Math.floor(this.state.video.ReleaseFrame),
                        y: Math.ceil(this.state.video.ReleaseFrame),
                      }).toString()
                    : undefined
                }
              />
            </Box>

            <Box>
              <Button
                disabled={this.state.loading}
                color={RADIX.COLOR.NEUTRAL}
                className="btn-block"
                onClick={() => this.handleWiggleFrame(-1)}
              >
                {t('common.prev-x', { x: t('videos.frame') })}
              </Button>
            </Box>
            <Box>
              <Button
                disabled={this.state.loading}
                color={RADIX.COLOR.NEUTRAL}
                className="btn-block"
                onClick={() => this.handleWiggleFrame(1)}
              >
                {t('common.next-x', { x: t('videos.frame') })}
              </Button>
            </Box>

            <Box gridColumn="span 2">
              <Heading size={RADIX.HEADING.SIZE.SM}>
                {t('pd.release-height')}
              </Heading>
            </Box>

            <Box gridColumn="span 2">
              <CommonTextInput
                id="video-release-height"
                name="release_height"
                type="number"
                value={this.state.release_height.toString()}
                onNumericChange={(v) => {
                  this.setState({
                    release_height: v,
                  });

                  this.props.dirtyCx.markDirty(
                    'FrameTab',
                    'change release height'
                  );
                }}
                hint_md={t('common.min-x-max-y-units', {
                  x: RELEASE_HEIGHT.MIN,
                  y: RELEASE_HEIGHT.MAX,
                  units: 'ft',
                }).toString()}
              />
            </Box>
          </>
        )}
      </CommonFormGrid>
    );
  }

  private renderPreviewForm() {
    return (
      <CommonFormGrid columns={1}>
        <Box>{this.renderScaleInput()}</Box>
        <Box>{this.renderHardwareInput()}</Box>
      </CommonFormGrid>
    );
  }
}
