import { Button } from '@radix-ui/themes';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';

export const SectionHeaderActionButton = (props: IButton) => (
  <Button
    title={props.tooltip}
    disabled={props.disabled}
    className={props.className}
    color={props.color}
    variant={props.variant}
    onClick={props.onClick}
  >
    {props.icon} {props.label && t(props.label)} {props.suffixIcon}
  </Button>
);
