import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { IAuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getModelKeyFromModel } from 'lib_ts/classes/ms.helper';
import {
  MachineModelType,
  ModelStatus,
} from 'lib_ts/enums/machine-models.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  IEvalModelResult,
  IPythonEvalModelsResult,
} from 'lib_ts/interfaces/modelling/i-eval-models';
import { IGatherShotDataQuery } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import { ITrainModelsRequest } from 'lib_ts/interfaces/modelling/i-train-model';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';

interface IOptionsDict {
  names: IOption[];
  machineIDs: string[];
  types: MachineModelType[];
  tags: string[];
  _created: string[];
}

export interface IMachineModelsContext {
  models: IMachineModel[];
  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly activateModel: (config: {
    modelID: string;
    machineID: string;
  }) => Promise<boolean>;

  readonly trainModels: (
    payload: ITrainModelsRequest
  ) => Promise<IPythonEvalModelsResult | undefined>;
  readonly updateModel: (payload: Partial<IMachineModel>) => Promise<boolean>;
  readonly archiveModels: (ids: string[]) => Promise<boolean>;

  readonly evalModelMetrics: (
    modelIDs: string[],
    query: Partial<IGatherShotDataQuery>
  ) => Promise<IEvalModelResult[]>;

  /** mostly for tracking checkbox values from tables */
  readonly setModels: (models: IMachineModel[]) => void;

  /** mostly for using an existing model for a new machine */
  readonly copyModel: (payload: Partial<IMachineModel>) => Promise<boolean>;
}

const DEFAULT: IMachineModelsContext = {
  models: [],

  options: {
    names: [],
    machineIDs: [],
    tags: [],
    types: [],
    _created: [],
  },

  loading: false,

  refresh: () => console.debug('not init'),

  activateModel: async () => new Promise(() => false),

  trainModels: async () => new Promise(() => console.debug('not init')),
  updateModel: async () => new Promise(() => console.debug('not init')),
  archiveModels: async () => new Promise(() => console.debug('not init')),

  evalModelMetrics: async () => new Promise(() => console.debug('not init')),

  setModels: () => console.debug('not init'),

  copyModel: async () => new Promise(() => console.debug('not init')),
};

const getOptions = (data: IMachineModel[]): IOptionsDict => {
  try {
    if (!data) {
      throw new Error('MODELS CONTEXT: no data provided to getOptions');
    }

    return {
      names: data.map((m) => ({
        label: m.name,
        group: m.machineID,
        value: m.name,
      })),

      machineIDs: ArrayHelper.unique(data.map((m) => m.machineID)).sort(
        (a: string, b: string) => a.localeCompare(b)
      ),

      tags: ArrayHelper.unique(data.flatMap((m) => m.tags ?? [])).sort(
        (a: string, b: string) => a.localeCompare(b)
      ),

      types: ArrayHelper.unique(data.map((m) => m.type)).sort(
        (a: string, b: string) => a.localeCompare(b)
      ),

      /** for some reason, some models exist without _created date */
      _created: ArrayHelper.unique(
        data.map((m) =>
          m._created ? lightFormat(parseISO(m._created), 'yyyy-MM-dd') : ''
        )
      ).sort((a: string, b: string) => a.localeCompare(b)),
    };
  } catch (e) {
    console.error(e);
    return DEFAULT.options;
  }
};

export const MachineModelsContext = createContext(DEFAULT);

interface IProps {
  authCx: IAuthContext;
  machinesCx: IMachinesContext;
  children: ReactNode;
}

export const MachineModelsProvider: FC<IProps> = (props) => {
  const [_lastFetched, _setLastFetched] = useState(new Date());

  const [_models, _setModels] = useState(DEFAULT.models);

  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_options, _setOptions] = useState(getOptions(DEFAULT.models));

  const _updateModel = async (
    payload: Partial<IMachineModel>
  ): Promise<boolean> => {
    try {
      _setLoading(true);

      const result =
        await AdminMachineModelsService.getInstance().updateModel(payload);

      if (result) {
        const current = [..._models];
        const index = current.findIndex((v) => v._id === result._id);

        if (index !== -1) {
          if (result.archived) {
            /** remove current without replacement */
            current.splice(index, 1);
          } else {
            /** remove current with replacement using updated value */
            current.splice(index, 1, result);
          }
        } else if (!result.archived) {
          /** append to end */
          current.push(result);
        }

        _setModels(current);
      }

      return !!result;
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md: 'There was a problem updating your model.',
      });

      return false;
    } finally {
      _setLoading(false);
    }
  };

  const state: IMachineModelsContext = {
    models: _models,

    options: _options,

    loading: _loading,

    refresh: () => {
      _setLastFetched(new Date());
    },

    activateModel: async (config) => {
      const model = _models.find((m) => m._id === config.modelID);

      if (!model) {
        NotifyHelper.warning({
          message_md: `Failed to find model ${config.modelID} in loaded models.`,
        });
        return false;
      }

      const machine = props.machinesCx.machines.find(
        (m) => m.machineID === config.machineID
      );

      if (!machine) {
        NotifyHelper.warning({
          message_md: `Failed to find machine ${config.machineID} in loaded machines.`,
        });
        return false;
      }

      if (!machine.model_ids) {
        machine.model_ids = {};
      }

      machine.model_ids[getModelKeyFromModel(model)] = model._id;

      const success = !!(await props.machinesCx.update({
        _id: machine._id,
        model_ids: machine.model_ids,
      }));

      if (success) {
        const payload: Partial<IMachineModel> = {
          _id: model._id,
          status: ModelStatus.Published,
        };

        _updateModel(payload);
      }

      return success;
    },

    setModels: (models) => {
      _setModels(models);
    },

    copyModel: async (model) => {
      try {
        _setLoading(true);

        const result =
          await AdminMachineModelsService.getInstance().copyModel(model);

        if (result) {
          _setModels([..._models, result]);
        }

        return !!result;
      } catch (e) {
        console.error(e);

        return false;
      } finally {
        _setLoading(false);
      }
    },

    trainModels: async (payload) => {
      try {
        _setLoading(true);

        const result =
          await AdminMachineModelsService.getInstance().trainModels(payload);

        if (result && result.results && result.results.length > 0) {
          _setLastFetched(new Date());
        }

        return result;
      } catch (e) {
        console.error(e);

        return undefined;
      } finally {
        _setLoading(false);
      }
    },

    updateModel: _updateModel,

    archiveModels: async (ids) => {
      try {
        _setLoading(true);

        const success =
          await AdminMachineModelsService.getInstance().archiveModels(ids);

        if (success) {
          const models = _models.filter((m) => !ids.includes(m._id));
          _setModels(models);
        }

        return success;
      } catch (e) {
        console.error(e);

        return false;
      } finally {
        _setLoading(false);
      }
    },

    evalModelMetrics: async (modelIDs, query) => {
      try {
        _setLoading(true);

        const result =
          await AdminMachineModelsService.getInstance().evalModelMetrics(
            modelIDs,
            query
          );

        return result;
      } catch (e) {
        console.error(e);
        return [];
      } finally {
        _setLoading(false);
      }
    },
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    (async (): Promise<void> => {
      _setLoading(true);

      return AdminMachineModelsService.getInstance()
        .getAllModels()
        .then((result) => {
          _setModels(
            result.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
          );
        })
        .finally(() => _setLoading(false));
    })();
  }, [_lastFetched]);

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh */
    _setLastFetched(new Date());
  }, [props.authCx.current.session]);

  /** update options and show warnings whenever models changes */
  useEffect(() => {
    if (_models) {
      _setOptions(getOptions(_models));
    }
  }, [_models]);

  return (
    <MachineModelsContext.Provider value={state}>
      {props.children}
    </MachineModelsContext.Provider>
  );
};
