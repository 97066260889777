import { Box, Container, Flex, Heading, Text } from '@radix-ui/themes';
import { ContactSupportButton } from 'components/common/buttons/contact-support';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SectionHeader } from 'components/sections/header';
import { CollectData } from 'components/sections/machine-calibration/steps/collect-data';
import { ReviewMetric } from 'components/sections/machine-calibration/steps/review-metric';
import { Setup } from 'components/sections/machine-calibration/steps/setup';
import { MachinesProvider } from 'contexts/admin/machines.context';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import {
  IMachineCalibrationContext,
  MachineCalibrationContext,
  MachineCalibrationProvider,
} from 'contexts/machine-calibration.context';
import { IMachineContext } from 'contexts/machine.context';
import {
  MatchingShotsContext,
  MatchingShotsProvider,
} from 'contexts/pitch-lists/matching-shots.context';
import { ISectionsContext } from 'contexts/sections.context';
import { CalibrationStep } from 'enums/machine.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import './index.scss';

const COMPONENT_NAME = 'MachineCalibration';

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  sectionsCx: ISectionsContext;
}

interface IState {}

export class MachineCalibration extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.renderCollectData = this.renderCollectData.bind(this);
  }

  private renderCollectData(calibrationCx: IMachineCalibrationContext) {
    return (
      <MatchingShotsProvider
        authCx={this.props.authCx}
        sectionsCx={this.props.sectionsCx}
        machineCx={this.props.machineCx}
      >
        <MatchingShotsContext.Consumer>
          {(matchingCx) => (
            <AimingProvider
              machineCx={this.props.machineCx}
              matchingCx={matchingCx}
              newerThan={
                this.props.cookiesCx.machineCalibration.start_date ??
                new Date().toISOString()
              }
            >
              <AimingContext.Consumer>
                {(aimingCx) => (
                  <CollectData
                    cookiesCx={this.props.cookiesCx}
                    sectionsCx={this.props.sectionsCx}
                    machineCx={this.props.machineCx}
                    calibrationCx={calibrationCx}
                    matchingCx={matchingCx}
                    aimingCx={aimingCx}
                  />
                )}
              </AimingContext.Consumer>
            </AimingProvider>
          )}
        </MatchingShotsContext.Consumer>
      </MatchingShotsProvider>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Container size="3" className="MachineCalibration">
          <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
            <SectionHeader header={t('main.machine-calibration')} />

            <Box className="foreground rounded-md">
              <MachinesProvider authCx={this.props.authCx}>
                <MachineCalibrationProvider
                  cookiesCx={this.props.cookiesCx}
                  authCx={this.props.authCx}
                  machineCx={this.props.machineCx}
                  sectionsCx={this.props.sectionsCx}
                >
                  <MachineCalibrationContext.Consumer>
                    {(calibrationCx) => (
                      <>
                        {calibrationCx.step === CalibrationStep.Setup && (
                          <Setup
                            cookiesCx={this.props.cookiesCx}
                            machineCx={this.props.machineCx}
                            calibrationCx={calibrationCx}
                          />
                        )}

                        {calibrationCx.step === CalibrationStep.CollectData &&
                          calibrationCx.pitches.length > 0 &&
                          this.renderCollectData(calibrationCx)}

                        {calibrationCx.step ===
                          CalibrationStep.ReviewMetric && (
                          <ReviewMetric
                            authCx={this.props.authCx}
                            machineCx={this.props.machineCx}
                            calibrationCx={calibrationCx}
                          />
                        )}

                        {calibrationCx.step === CalibrationStep.TrainError && (
                          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
                            <Heading
                              size={RADIX.HEADING.SIZE.SM}
                              className="text-titlecase"
                            >
                              Training Error
                            </Heading>

                            <Box>
                              <Text>
                                There was an error while calibrating your
                                machine but your calibration session is saved.
                              </Text>
                            </Box>

                            <Box>
                              <Text>
                                Please contact support for assistance.
                              </Text>
                            </Box>

                            <Box>
                              <ContactSupportButton />
                            </Box>
                          </Flex>
                        )}
                      </>
                    )}
                  </MachineCalibrationContext.Consumer>
                </MachineCalibrationProvider>
              </MachinesProvider>
            </Box>
          </Flex>
        </Container>
      </ErrorBoundary>
    );
  }
}
