import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { ICookiesContext } from 'contexts/cookies.context';
import { ITrainingMsgExt } from 'interfaces/i-training';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import slugify from 'slugify';

export interface ITrainingContext {
  training_mode: TrainingMode;

  lastUpdated: Date;

  // for children to see what has arrived since the last reset
  msgs: ITrainingMsgExt[];

  // e.g. when a new fire happens and previous msgs are no longer relevant
  readonly resetMsgs: () => void;
  readonly getFinalMsg: () => ITrainingMsgExt | undefined;
}

const DEFAULT: ITrainingContext = {
  training_mode: TrainingMode.Quick,
  lastUpdated: new Date(),
  msgs: [],
  resetMsgs: () => console.debug('not init'),
  getFinalMsg: () => undefined,
};

export const TrainingContext = createContext(DEFAULT);

interface IProps {
  cookiesCx: ICookiesContext;

  // usually from user setting, but can be overridden (e.g. to manual mode during a home game)
  mode: TrainingMode | undefined;

  children: ReactNode;

  // if something has to happen after a training msg is received by the context, e.g. fire button
  // use this if the action exists in the parent to the provider (otherwise just react to changes in last updated or msgs)
  afterTrainingMsg?: (msg: ITrainingMsgExt) => void;
}

export const TrainingProvider: FC<IProps> = (props) => {
  const [_lastUpdated, _setLastUpdated] = useState(DEFAULT.lastUpdated);
  const [_msgs, _setMsgs] = useState<ITrainingMsgExt[]>([]);

  const _handleTrainingMsg = (event: CustomEvent) => {
    const data: ITrainingMsgExt = {
      ...event.detail,
      received: new Date(),
    };

    _setMsgs([..._msgs, data]);

    props.afterTrainingMsg?.(data);

    NotifyHelper.debug(
      {
        message_md: `Received a training message.`,
        buttons: [
          {
            label: 'Save',
            onClick: () => {
              const filename = `training-response_${slugify(
                new Date().toISOString(),
                { strict: true }
              )}`;

              MiscHelper.saveAs(
                new Blob([JSON.stringify(data, null, 2)]),
                `${filename}.json`
              );
            },
          },
        ],
      },
      props.cookiesCx
    );

    _setLastUpdated(new Date());
  };

  const state: ITrainingContext = {
    training_mode: props.mode ?? TrainingMode.Quick,
    lastUpdated: _lastUpdated,
    msgs: _msgs,
    resetMsgs: () => {
      _setMsgs([]);
      _setLastUpdated(new Date());
    },
    getFinalMsg: () => _msgs.find((m) => typeof m.success === 'boolean'),
  };

  useEffect(() => {
    WebSocketHelper.on(WsMsgType.M2U_TrainingResponse, _handleTrainingMsg);

    return () => {
      WebSocketHelper.remove(
        WsMsgType.M2U_TrainingResponse,
        _handleTrainingMsg
      );
    };
  }, []);

  return (
    <TrainingContext.Provider value={state}>
      {props.children}
    </TrainingContext.Provider>
  );
};
