import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'DeletePitchesDialog';

interface IProps {
  pitches: IPitch[];
  onDelete: () => void;
}

interface IState {}

export class DeletePitchesDialog extends React.Component<IProps, IState> {
  state: IState = {};

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <PitchListsContext.Consumer>
          {(listsCx) => (
            <CommonConfirmationDialog
              identifier={COMPONENT_NAME}
              title="common.delete"
              content={
                <ReactMarkdown
                  children={[
                    `Are you sure you want to remove the following pitch(es) from this list? **Warning: This cannot be undone.**`,
                    PitchListHelper.enumeratePitches(this.props.pitches, 5),
                  ].join('\n\n')}
                />
              }
              action={{
                label: 'common.delete',
                color: RADIX.COLOR.DANGER,
                onClick: () => {
                  listsCx
                    .deletePitches(this.props.pitches.map((p) => p._id))
                    .then((success) => {
                      if (success) {
                        this.props.onDelete();
                      }
                    });
                },
              }}
            />
          )}
        </PitchListsContext.Consumer>
      </ErrorBoundary>
    );
  }
}
