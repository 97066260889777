import { ResetIcon } from '@radix-ui/react-icons';
import { Box, Grid } from '@radix-ui/themes';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTableButton } from 'components/common/table/button';
import { IMlbBrowseContext } from 'contexts/mlb-browse.context';
import { BatSide, HomeAway, PitchHand } from 'interfaces/i-mlb-browse';
import { HitterSide } from 'lib_ts/enums/hitters.enums';
import { MLB_OUTCOME_CODES } from 'lib_ts/enums/mlb-stats-api/guid-metadata-types.enum';
import {
  PITCH_TYPE_OPTIONS,
  PitcherHand,
  PitchType,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'MlbStatsAdditionalFilters';

interface IProps {
  browseCx: IMlbBrowseContext;
  resetChecked: () => void;
}

interface IState {}

export class MlbStatsAdditionalFilters extends React.Component<IProps, IState> {
  private filterIsHome?: CommonSelectInput;
  private filterPitchHand?: CommonSelectInput;
  private filterBatSide?: CommonSelectInput;
  private filterPitchType?: CommonSelectInput;
  private filterOutcomeType?: CommonSelectInput;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.getHomeAwayTooltip = this.getHomeAwayTooltip.bind(this);
  }

  private getHomeAwayTooltip() {
    if (!this.props.browseCx.gameFilter.gamePk) {
      return 'Select a game first.';
    }

    if (!this.props.browseCx.pitchFilter.isHome) {
      return 'Filters results based on the team that is pitching.';
    }

    if (this.props.browseCx.pitchFilter.isHome === 'home') {
      return `Showing results for the home team's pitches.`;
    }

    if (this.props.browseCx.pitchFilter.isHome === 'away') {
      return `Showing results for the away team's pitches.`;
    }
  }

  render() {
    const browseCx = this.props.browseCx;
    const tooltip = this.getHomeAwayTooltip();

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDetails summary="Additional Filters" gap={RADIX.FLEX.GAP.SM}>
          <Grid pt={RADIX.FLEX.PAD.SM} columns="6" gap={RADIX.FLEX.GAP.SM}>
            <Box>
              <CommonSelectInput
                ref={(elem) => (this.filterIsHome = elem as CommonSelectInput)}
                id="mlb-stats-team"
                name="isHome"
                placeholder="Pitcher Team"
                title={tooltip}
                options={browseCx.getTeamOptions()}
                value={browseCx.pitchFilter.isHome}
                onChange={(v) => {
                  this.props.resetChecked();
                  browseCx.mergePitchFilter({ isHome: v as HomeAway });
                }}
                disabled={browseCx.loading || !browseCx.gameFilter.gamePk}
                optional
                skipSort
              />
            </Box>
            <Box>
              <CommonSelectInput
                ref={(elem) =>
                  (this.filterPitchHand = elem as CommonSelectInput)
                }
                id="mlb-stats-pitcher-hand"
                name="pitchHand"
                placeholder="Pitcher Hand"
                options={[
                  { label: PitcherHand.RHP, value: 'R' },
                  { label: PitcherHand.LHP, value: 'L' },
                ]}
                value={browseCx.pitchFilter.pitchHand}
                onChange={(v) => {
                  this.props.resetChecked();
                  browseCx.mergePitchFilter({ pitchHand: v as PitchHand });
                }}
                optional
              />
            </Box>
            <Box>
              <CommonSelectInput
                ref={(elem) => (this.filterBatSide = elem as CommonSelectInput)}
                id="mlb-stats-batter-side"
                name="batSide"
                placeholder="Batter Side"
                options={[
                  { label: HitterSide.RHH, value: 'R' },
                  { label: HitterSide.LHH, value: 'L' },
                ]}
                value={browseCx.pitchFilter.batSide}
                onChange={(v) => {
                  this.props.resetChecked();
                  browseCx.mergePitchFilter({ batSide: v as BatSide });
                }}
                optional
              />
            </Box>
            <Box>
              <CommonSelectInput
                ref={(elem) =>
                  (this.filterPitchType = elem as CommonSelectInput)
                }
                id="mlb-stats-pitch-type"
                name="pitchType"
                placeholder="common.pitch-type"
                options={PITCH_TYPE_OPTIONS}
                value={browseCx.pitchFilter.pitchType}
                onChange={(v) => {
                  this.props.resetChecked();
                  browseCx.mergePitchFilter({ pitchType: v as PitchType });
                }}
                optional
              />
            </Box>
            <Box>
              <CommonSelectInput
                ref={(elem) =>
                  (this.filterOutcomeType = elem as CommonSelectInput)
                }
                id="mlb-stats-outcome"
                name="outcomeType"
                placeholder="common.outcome"
                options={MLB_OUTCOME_CODES.map((c) => ({
                  label: c.description,
                  value: c.code,
                }))}
                value={browseCx.pitchFilter.outcomeType}
                onChange={(v) => {
                  this.props.resetChecked();
                  browseCx.mergePitchFilter({ outcomeType: v });
                }}
                optional
              />
            </Box>
            <Box>
              <CommonTableButton
                icon={<ResetIcon />}
                label="common.reset"
                className="btn-block"
                onClick={() => {
                  this.props.resetChecked();

                  // clear the filter values
                  browseCx.mergePitchFilter({
                    isHome: undefined,
                    pitchType: undefined,
                    pitchHand: undefined,
                    batSide: undefined,
                    outcomeType: undefined,
                  });

                  // clear the select inputs
                  this.filterIsHome?.reset(true);
                  this.filterPitchHand?.reset(true);
                  this.filterBatSide?.reset(true);
                  this.filterPitchType?.reset(true);
                  this.filterOutcomeType?.reset(true);
                }}
              />
            </Box>
          </Grid>
        </CommonDetails>
      </ErrorBoundary>
    );
  }
}
