import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IMachine, IMachineModelDictionary } from 'lib_ts/interfaces/i-machine';
import { BaseRESTService } from 'services/_base-rest.service';

export class MachinesService extends BaseRESTService {
  private static instance: MachinesService;
  static getInstance(): MachinesService {
    if (!MachinesService.instance) {
      MachinesService.instance = new MachinesService();
    }

    return MachinesService.instance;
  }

  private constructor() {
    super({
      controller: 'machines',
    });
  }

  /** for users, returns machines in user's team */
  async getTeamMachines(): Promise<IMachine[]> {
    return await this.get({
      uri: '',
    });
  }

  /** machineID like 'Sandbox' */
  async getByMachineID(
    machineID: string
  ): Promise<{ machine: IMachine; connected: boolean; calibrated: boolean }> {
    return await this.get({
      uri: machineID,
    });
  }

  /** key is model._id, value is model.name */
  async getModelsDictionary(): Promise<IMachineModelDictionary> {
    return await this.get({
      uri: 'models/dictionary',
    });
  }

  async update(data: Partial<IMachine>): Promise<IMachine | undefined> {
    if (!data._id) {
      NotifyHelper.warning({ message_md: 'Cannot update machine without ID' });
      return;
    }

    return this.put(
      {
        uri: '',
        params: {
          machine_id: data._id,
        } as any,
      },
      data
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ?? 'There was a problem updating your machine.',
          });
          return undefined;
        }

        return result.data as IMachine;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was a problem updating your machine.',
        });
        return undefined;
      });
  }
}
