import {
  DotsVerticalIcon,
  ExclamationTriangleIcon,
} from '@radix-ui/react-icons';
import { Checkbox, IconButton, Table, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonMenu } from 'components/common/menu';
import { CommonTooltip } from 'components/common/tooltip';
import { ICheckedContext } from 'contexts/layout/checked.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_TIMEZONE } from 'enums/env';
import { IMenuAction } from 'interfaces/i-menus';
import { IDisplayCol } from 'interfaces/i-tables';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ICheckable } from 'lib_ts/interfaces/mongo/_base';
import { useState } from 'react';

interface IBodyCell {
  menuID: string;
  col: IDisplayCol;
  item: any;
}

interface ICheckCell extends IBodyCell {
  checkedCx: ICheckedContext;
  afterCheckOne?: (changed: any, checked: boolean) => void;
}
export const CheckboxCell = (props: ICheckCell) => {
  const [classes, setClasses] = useState(
    StringHelper.classNames([props.col.classNameFn?.(props.item)])
  );

  return (
    <Table.Cell
      className={classes}
      data-testid="col-checkbox"
      data-checked={(props.item as ICheckable)._checked}
      align={props.col.align ?? 'center'}
    >
      <Checkbox
        style={{ marginTop: '1px' }}
        checked={props.item._checked}
        color={props.col.colorFn?.(props.item) ?? props.col.color}
        onClick={(event) => {
          if (event.shiftKey) {
            // this avoids toggling an extra time via the row shift-click for bulk toggling
            event.preventDefault();
          }

          // prevent this from triggering select row logic
          event.stopPropagation();
        }}
        onCheckedChange={(v) => {
          const checked = v === true;

          // change the item directly
          props.item._checked = checked;

          props.checkedCx.updateTally();

          // because of async, passing checked explicitly (instead of using the item's _checked flag) is safest
          props.afterCheckOne?.(props.item, checked);
        }}
      />
    </Table.Cell>
  );
};

export const ActionsCell = (props: IBodyCell) => {
  const [classes, setClasses] = useState(
    StringHelper.classNames([props.col.classNameFn?.(props.item)])
  );

  return (
    <Table.Cell
      className={classes}
      data-testid="col-actions"
      align={props.col.align ?? 'center'}
    >
      <CommonMenu
        title="Actions"
        trigger={
          <IconButton
            id={props.menuID}
            color={
              props.col.colorFn?.(props.item) ??
              props.col.color ??
              RADIX.COLOR.NEUTRAL
            }
            className="btn-floating"
            size={RADIX.BUTTON.SIZE.TABLE}
            variant={RADIX.BUTTON.VARIANT.TABLE}
          >
            <DotsVerticalIcon />
          </IconButton>
        }
        actions={(props.col.actions ?? [])
          .filter(
            (action) => !action.invisibleFn || !action.invisibleFn(props.item)
          )
          .map((action) => {
            const mAction: IMenuAction = {
              ...action,
              onClick: () => {
                if (action.disableFn && action.disableFn(props.item)) {
                  NotifyHelper.warning({
                    message_md: 'This action is not possible at this time.',
                  });
                  return;
                }

                action.onClick(props.item);
              },
            };

            return mAction;
          })}
        skipSort
      />
    </Table.Cell>
  );
};

export const BasicCell = (props: IBodyCell) => {
  const [classes, setClasses] = useState(
    StringHelper.classNames([props.col.classNameFn?.(props.item)])
  );

  const content: string | JSX.Element = (() => {
    try {
      if (props.col.formatFn) {
        try {
          return props.col.formatFn(props.item);
        } catch (e) {
          console.error(e);
          return '';
        }
      }

      const anyValue = props.item[props.col.key];

      switch (props.col.dataType) {
        case 'date': {
          const value = anyValue ? parseISO(anyValue) : '';
          if (value) {
            return format(value, 'yyyy-MM-dd', {
              timeZone: LOCAL_TIMEZONE,
            });
          }

          return '--';
        }

        case 'datetime': {
          const value = anyValue ? parseISO(anyValue) : '';
          if (value) {
            return format(value, 'yyyy-MM-dd @ HH:mm z', {
              timeZone: LOCAL_TIMEZONE,
            });
          }

          return '--';
        }

        default: {
          return anyValue ?? '';
        }
      }
    } catch (e) {
      console.error(e);
      return <ExclamationTriangleIcon />;
    }
  })();

  const tooltip = props.col.tooltipFn?.(props.item);

  return (
    <Table.Cell
      className={classes}
      data-type={props.col.dataType}
      align={props.col.align}
    >
      <CommonTooltip
        disabled={!tooltip}
        text_md={tooltip}
        trigger={
          <Text color={props.col.colorFn?.(props.item) ?? props.col.color}>
            {content}
          </Text>
        }
      />
    </Table.Cell>
  );
};
