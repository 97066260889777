export const CHART_COLORS = [
  '#bc76c5',
  '#ee7092',
  '#e7b386',
  '#d0ef72',
  '#a4f0a6',
];

/** teals */
export const MACHINE_COLORS = ['#CBE4DE', '#0E8388', '#83A2BC'];

/** oranges */
export const MODEL_COLORS = ['#F2CD5C', '#F2921D', '#F2875C'];
