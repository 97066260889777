import { Flex, Heading } from '@radix-ui/themes';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PresetTrainingControls } from 'components/machine/dialogs/preset-training/controls';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import { MachineDialogMode } from 'contexts/machine.context';
import {
  IMatchingShotsContext,
  MatchingShotsContext,
  MatchingShotsProvider,
} from 'contexts/pitch-lists/matching-shots.context';
import {
  IPitchListsContext,
  PitchListsContext,
} from 'contexts/pitch-lists/pitch-lists.context';
import { SectionsContext } from 'contexts/sections.context';
import { t } from 'i18next';
import { ITrainingDialog } from 'interfaces/i-training';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import React from 'react';

const COMPONENT_NAME = 'PresetTrainingDialog';

const SKIP_DUPLICATES = false;

interface IState {
  session_start: string;

  /** in case provided pitches contains duplicates by matching_hash */
  uniquePitches: Partial<IPitch>[];
}

export class PresetTrainingDialog extends React.Component<
  ITrainingDialog,
  IState
> {
  controls?: PresetTrainingControls;

  constructor(props: ITrainingDialog) {
    super(props);

    this.state = {
      session_start: new Date().toISOString(),
      uniquePitches: SKIP_DUPLICATES
        ? PitchListHelper.getUniquePitches(
            props.pitches,
            props.machineCx.machine
          )
        : props.pitches,
    };

    this.provideAiming = this.provideAiming.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount(): void {
    (this.context as IGlobalContext).countDialog({
      id: this.props.identifier,
      open: true,
    });
  }

  componentWillUnmount() {
    (this.context as IGlobalContext).countDialog({
      id: this.props.identifier,
      open: false,
    });
    this.props.machineCx.onEndTraining();
  }

  private renderContent() {
    return (
      <AuthContext.Consumer>
        {(authCx) => {
          if (authCx.restrictedGameStatus()) {
            return (
              <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                <Heading size={RADIX.HEADING.SIZE.LG}>
                  Game Status: {authCx.gameStatus}
                </Heading>

                <p>
                  Training is not possible at this time. Please try again later
                  or contact support.
                </p>
              </Flex>
            );
          }

          return (
            <CookiesContext.Consumer>
              {(cookiesCx) => (
                <AuthContext.Consumer>
                  {(authCx) => (
                    <SectionsContext.Consumer>
                      {(sectionsCx) => (
                        <PitchListsContext.Consumer>
                          {(listsCx) => (
                            <MatchingShotsProvider
                              authCx={authCx}
                              sectionsCx={sectionsCx}
                              machineCx={this.props.machineCx}
                            >
                              <MatchingShotsContext.Consumer>
                                {(matchingCx) =>
                                  this.provideAiming(
                                    matchingCx,
                                    cookiesCx,
                                    authCx,
                                    listsCx
                                  )
                                }
                              </MatchingShotsContext.Consumer>
                            </MatchingShotsProvider>
                          )}
                        </PitchListsContext.Consumer>
                      )}
                    </SectionsContext.Consumer>
                  )}
                </AuthContext.Consumer>
              )}
            </CookiesContext.Consumer>
          );
        }}
      </AuthContext.Consumer>
    );
  }

  private provideAiming(
    matchingCx: IMatchingShotsContext,
    cookiesCx: ICookiesContext,
    authCx: IAuthContext,
    listsCx: IPitchListsContext
  ): React.ReactNode {
    return (
      <AimingProvider
        machineCx={this.props.machineCx}
        matchingCx={matchingCx}
        newerThan={this.state.session_start}
      >
        <AimingContext.Consumer>
          {(aimingCx) => (
            <PresetTrainingControls
              ref={(elem) => (this.controls = elem as PresetTrainingControls)}
              machineCx={this.props.machineCx}
              trainingCx={this.props.trainingCx}
              cookiesCx={cookiesCx}
              authCx={authCx}
              matchingCx={matchingCx}
              listsCx={listsCx}
              aimingCx={aimingCx}
              pitches={this.state.uniquePitches}
              onFinish={this.props.onClose}
              left_button={
                authCx.current.mode === 'impostor' ||
                authCx.current.role === UserRole.admin
                  ? {
                      label: 'common.ready-status',
                      color: RADIX.COLOR.SUPER_ADMIN,
                      tooltip: t('common.super-admins-only-msg').toString(),
                      onClick: () =>
                        this.props.machineCx.setDialog(MachineDialogMode.R2F),
                    }
                  : undefined
              }
            />
          )}
        </AimingContext.Consumer>
      </AimingProvider>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          width={RADIX.DIALOG.WIDTH.XL}
          title={this.controls?.getTitle() ?? 'common.training'}
          content={this.renderContent()}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}

PresetTrainingDialog.contextType = GlobalContext;
