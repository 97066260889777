import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { TrainingControls } from 'components/machine/dialogs/training/controls';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import { MachineDialogMode } from 'contexts/machine.context';
import {
  IMatchingShotsContext,
  MatchingShotsContext,
  MatchingShotsProvider,
} from 'contexts/pitch-lists/matching-shots.context';
import { SectionsContext } from 'contexts/sections.context';
import { t } from 'i18next';
import { ITrainingDialog } from 'interfaces/i-training';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import React from 'react';

const COMPONENT_NAME = 'TrainingDialog';

interface IProps extends ITrainingDialog {
  threshold: number;
}

interface IState {
  session_start: string;

  /** in case provided pitches contains duplicates by matching_hash */
  uniquePitches: Partial<IPitch>[];
}

export class TrainingDialog extends React.Component<IProps, IState> {
  controls?: TrainingControls;

  constructor(props: IProps) {
    super(props);

    this.state = {
      session_start: new Date().toISOString(),

      uniquePitches: PitchListHelper.getUniquePitches(
        props.pitches,
        props.machineCx.machine
      ),
    };

    this.provideAiming = this.provideAiming.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount(): void {
    (this.context as IGlobalContext).countDialog({
      id: this.props.identifier,
      open: true,
    });
  }

  componentWillUnmount() {
    (this.context as IGlobalContext).countDialog({
      id: this.props.identifier,
      open: false,
    });
    this.props.machineCx.onEndTraining();
  }

  private renderContent() {
    return (
      <CookiesContext.Consumer>
        {(cookiesCx) => (
          <AuthContext.Consumer>
            {(authCx) => (
              <SectionsContext.Consumer>
                {(sectionsCx) => (
                  <MatchingShotsProvider
                    authCx={authCx}
                    sectionsCx={sectionsCx}
                    machineCx={this.props.machineCx}
                  >
                    <MatchingShotsContext.Consumer>
                      {(matchingCx) =>
                        this.provideAiming(cookiesCx, authCx, matchingCx)
                      }
                    </MatchingShotsContext.Consumer>
                  </MatchingShotsProvider>
                )}
              </SectionsContext.Consumer>
            )}
          </AuthContext.Consumer>
        )}
      </CookiesContext.Consumer>
    );
  }

  private provideAiming(
    cookiesCx: ICookiesContext,
    authCx: IAuthContext,
    matchingCx: IMatchingShotsContext
  ) {
    return (
      <AimingProvider
        machineCx={this.props.machineCx}
        matchingCx={matchingCx}
        newerThan={this.state.session_start}
      >
        <AimingContext.Consumer>
          {(aimingCx) => (
            <TrainingControls
              ref={(elem) => (this.controls = elem as TrainingControls)}
              machineCx={this.props.machineCx}
              trainingCx={this.props.trainingCx}
              cookiesCx={cookiesCx}
              authCx={authCx}
              matchingCx={matchingCx}
              aimingCx={aimingCx}
              pitches={this.state.uniquePitches}
              threshold={this.props.threshold}
              onFinish={this.props.onClose}
              left_button={
                authCx.current.mode === 'impostor' ||
                authCx.current.role === UserRole.admin
                  ? {
                      label: 'common.ready-status',
                      color: RADIX.COLOR.INFO,
                      icon: <SuperAdminIcon />,
                      tooltip: t('common.super-admins-only-msg').toString(),
                      onClick: () =>
                        this.props.machineCx.setDialog(MachineDialogMode.R2F),
                    }
                  : undefined
              }
            />
          )}
        </AimingContext.Consumer>
      </AimingProvider>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          width={RADIX.DIALOG.WIDTH.XL}
          title={this.controls?.getTitle() ?? 'Training Pitch'}
          content={this.renderContent()}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}

TrainingDialog.contextType = GlobalContext;
