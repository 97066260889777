import { ChevronDownIcon, Table, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { FT_TO_INCHES, METERS_TO_FT } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBallState, ITrajectoryBreak } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { IRapsodoBreak } from 'lib_ts/interfaces/training/i-rapsodo-shot';
import { useState } from 'react';

const NA = t('common.na');

const renderNumber = (value: number | undefined, precision: number) =>
  value?.toFixed(precision) ?? NA;

export const DataTable = (props: {
  sampleSize: number;
  priority: BuildPriority;
  targetBS: IBallState;
  targetBreaks?: ITrajectoryBreak;
  actualShots: IMachineShot[];
}) => {
  const [_expanded, _setExpanded] = useState(false);

  const toggler = (
    <Text
      color={RADIX.COLOR.ACCENT}
      className="cursor-pointer"
      onClick={() => _setExpanded(!_expanded)}
    >
      {t('common.details')}&nbsp;
      <ChevronDownIcon
        style={{
          transform: _expanded ? 'rotate(180deg)' : undefined,
        }}
      />
    </Text>
  );

  if (!_expanded) {
    return toggler;
  }

  const ballStates = props.actualShots
    .map((s) => s.bs)
    .filter((s) => s) as IBallState[];

  const medianBS = MiscHelper.getMedianObject(ballStates) as
    | IBallState
    | undefined;

  const shotBreaks = props.actualShots
    .map((s) => s.break)
    .filter((s) => s) as IRapsodoBreak[];

  const medianBreaks = MiscHelper.getMedianObject(shotBreaks) as
    | IRapsodoBreak
    | undefined;

  const emptyRows: number[] = [];

  for (let i = 1; i <= props.sampleSize - props.actualShots.length; i++) {
    emptyRows.push(i + props.actualShots.length);
  }

  const totalCols =
    2 +
    (props.priority === BuildPriority.Spins ? 3 : 0) +
    (props.priority === BuildPriority.Breaks ? 2 : 0);

  return (
    <ErrorBoundary componentName="PrecisionTrainComparison">
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>{toggler}</Table.ColumnHeaderCell>

            <Table.ColumnHeaderCell align="right">
              {t('common.speed')} (mph)
            </Table.ColumnHeaderCell>

            {props.priority === BuildPriority.Spins && (
              <>
                <Table.ColumnHeaderCell align="right">
                  {t('common.spin-x')} (rpm)
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell align="right">
                  {t('common.spin-y')} (rpm)
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell align="right">
                  {t('common.spin-z')} (rpm)
                </Table.ColumnHeaderCell>
              </>
            )}

            {props.priority === BuildPriority.Breaks && (
              <>
                <Table.ColumnHeaderCell align="right">
                  {t('common.break-x')} (in)
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell align="right">
                  {t('common.break-z')} (in)
                </Table.ColumnHeaderCell>
              </>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('common.target')}</Table.Cell>

            <Table.Cell align="right">
              {renderNumber(BallHelper.getSpeed(props.targetBS), 1)}
            </Table.Cell>

            {props.priority === BuildPriority.Spins && (
              <>
                <Table.Cell align="right">
                  {renderNumber(props.targetBS.wx, 0)}
                </Table.Cell>
                <Table.Cell align="right">
                  {renderNumber(props.targetBS.wy, 0)}
                </Table.Cell>
                <Table.Cell align="right">
                  {renderNumber(props.targetBS.wz, 0)}
                </Table.Cell>
              </>
            )}

            {props.priority === BuildPriority.Breaks && (
              <>
                <Table.Cell align="right">
                  {props.targetBreaks
                    ? renderNumber(-1 * props.targetBreaks.xInches, 1)
                    : NA}
                </Table.Cell>

                <Table.Cell align="right">
                  {renderNumber(props.targetBreaks?.zInches, 1)}
                </Table.Cell>
              </>
            )}
          </Table.Row>

          <Table.Row>
            <Table.Cell>{t('tr.median')}</Table.Cell>

            <Table.Cell align="right">
              {renderNumber(medianBS?.vnet, 1)}
            </Table.Cell>

            {props.priority === BuildPriority.Spins && (
              <>
                <Table.Cell align="right">
                  {renderNumber(medianBS?.wx, 0)}
                </Table.Cell>
                <Table.Cell align="right">
                  {renderNumber(medianBS?.wy, 0)}
                </Table.Cell>
                <Table.Cell align="right">
                  {renderNumber(medianBS?.wz, 0)}
                </Table.Cell>
              </>
            )}

            {props.priority === BuildPriority.Breaks && (
              <>
                <Table.Cell align="right">
                  {medianBreaks?.PITCH_HBTrajectory !== undefined
                    ? renderNumber(
                        medianBreaks.PITCH_HBTrajectory *
                          METERS_TO_FT *
                          FT_TO_INCHES,
                        1
                      )
                    : NA}
                </Table.Cell>
                <Table.Cell align="right">
                  {medianBreaks?.PITCH_VBTrajectory !== undefined
                    ? renderNumber(
                        medianBreaks.PITCH_VBTrajectory *
                          METERS_TO_FT *
                          FT_TO_INCHES,
                        1
                      )
                    : NA}
                </Table.Cell>
              </>
            )}
          </Table.Row>

          {props.actualShots
            .sort((a, b) =>
              // ascending order of _created
              a._created.localeCompare(b._created)
            )
            .map((s, i) => (
              <Table.Row key={`shot-${i}`} data-created={s._created}>
                <Table.Cell
                  title={format(parseISO(s._created), LOCAL_DATETIME_FORMAT, {
                    timeZone: LOCAL_TIMEZONE,
                  })}
                >
                  {t('tr.sample')} {i + 1}
                </Table.Cell>

                <Table.Cell align="right">
                  {renderNumber(
                    s.bs ? BallHelper.getSpeed(s.bs) : undefined,
                    1
                  )}
                </Table.Cell>

                {props.priority === BuildPriority.Spins && (
                  <>
                    <Table.Cell align="right">
                      {renderNumber(s.bs?.wx, 0)}
                    </Table.Cell>
                    <Table.Cell align="right">
                      {renderNumber(s.bs?.wy, 0)}
                    </Table.Cell>
                    <Table.Cell align="right">
                      {renderNumber(s.bs?.wz, 0)}
                    </Table.Cell>
                  </>
                )}

                {props.priority === BuildPriority.Breaks && (
                  <>
                    <Table.Cell align="right">
                      {s.break?.PITCH_HBTrajectory !== undefined
                        ? renderNumber(
                            s.break.PITCH_HBTrajectory *
                              METERS_TO_FT *
                              FT_TO_INCHES,
                            1
                          )
                        : NA}
                    </Table.Cell>
                    <Table.Cell align="right">
                      {s.break?.PITCH_VBTrajectory !== undefined
                        ? renderNumber(
                            s.break.PITCH_VBTrajectory *
                              METERS_TO_FT *
                              FT_TO_INCHES,
                            1
                          )
                        : NA}
                    </Table.Cell>
                  </>
                )}
              </Table.Row>
            ))}

          {emptyRows.map((n) => (
            <Table.Row key={`padding-row-${n}`}>
              <Table.Cell colSpan={totalCols}>
                {t('tr.sample')} {n}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </ErrorBoundary>
  );
};
