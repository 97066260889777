import { IMachineState } from '../interfaces/i-machine-state';
import {
  EMPTY_TRAJECTORY,
  IBasePitchChars,
  IBuildPitchChars,
  IPlateLoc,
  ITrajectory,
} from '../interfaces/pitches';
import {
  IAziAltCoordinate,
  IReleasePosition,
} from '../interfaces/pitches/i-base';
import { IMachineShot } from '../interfaces/training/i-machine-shot';
import { BallState } from './ball-state.class';
import { DEG2RAD, MIN_SHOTS_TO_ROTATE, RAD2DEG } from './ball.helper';
import { TrajHelper } from './trajectory.helper';

const VERBOSE = false;

const vDebug = (v: any) => (VERBOSE ? console.debug(v) : undefined);

export class AimingHelper {
  static checkAssertions = (config: {
    chars: IBuildPitchChars;
    plate: IPlateLoc;
    refPlate: IPlateLoc;
  }) => {
    if (!VERBOSE) {
      return;
    }

    const PLATE_PRECISION_FT = 0.01;

    console.assert(
      Math.abs(config.chars.plate.plate_x - config.refPlate.plate_x) <
        PLATE_PRECISION_FT,
      `large plate_x delta (${(
        config.chars.plate.plate_x - config.refPlate.plate_x
      ).toFixed(
        4
      )}) between rotated plate (${config.chars.plate.plate_x.toFixed(
        4
      )}) and target plate (${config.refPlate.plate_x.toFixed(4)})`
    );

    console.assert(
      Math.abs(config.chars.plate.plate_z - config.refPlate.plate_z) <
        PLATE_PRECISION_FT,
      `large plate_z delta (${(
        config.chars.plate.plate_z - config.refPlate.plate_z
      ).toFixed(
        4
      )}) between rotated plate (${config.chars.plate.plate_z.toFixed(
        4
      )}) and target plate (${config.refPlate.plate_z.toFixed(4)})`
    );

    console.assert(
      Math.abs(config.plate.plate_x - config.refPlate.plate_x) <
        PLATE_PRECISION_FT,
      `large plate_x delta (${(
        config.plate.plate_x - config.refPlate.plate_x
      ).toFixed(4)}) between rotated traj plate (${config.plate.plate_x.toFixed(
        4
      )}) and target plate (${config.refPlate.plate_x.toFixed(4)})`
    );

    console.assert(
      Math.abs(config.plate.plate_z - config.refPlate.plate_z) <
        PLATE_PRECISION_FT,
      `large plate_z delta (${(
        config.plate.plate_z - config.refPlate.plate_z
      ).toFixed(4)}) between rotated traj plate (${config.plate.plate_z.toFixed(
        4
      )}) and target plate (${config.refPlate.plate_z.toFixed(4)})`
    );
  };

  /** aims the given chars at the given plate */
  static aimWithoutShots(config: {
    chars: IBasePitchChars;
    release: IReleasePosition;
    plate_location: IPlateLoc;
  }) {
    return this._aimHelper({
      chars: config.chars,
      refTraj: { ...config.chars.traj },
      release: config.release,
      plate: config.plate_location,
    });
  }

  /** a mean traj will be computed using shots, which then be used as the traj for the aim function (instead of the traj provided in chars) */
  static aimWithShots(config: {
    source: string;
    chars: IBasePitchChars;
    release: IReleasePosition;
    plate_location: IPlateLoc;
    plate_distance: number;
    shots: IMachineShot[];
    stepSize?: number;
  }): IBuildPitchChars {
    vDebug({
      event: 'aimWithShots',
      config: config,
    });

    const hitterPresent = config.shots.filter((s) => s.hitter_present);

    if (hitterPresent.length > 0) {
      console.warn(
        `aimUsingShots invoked on ${hitterPresent.length} shot(s) where hitter_present is true!`
      );
    }

    const meanTraj = this._getMeanTraj({
      refMs: config.chars.ms,
      refTraj: config.chars.traj,
      shots: config.shots.filter((s) => !s.hitter_present),
      plate_distance: config.plate_distance,
    });

    if (meanTraj.py !== config.plate_distance) {
      console.warn(
        `aimUsingShots produced a meanTraj with py ${meanTraj.py} that isn't equal to plate distance ${config.plate_distance}`
      );
    }

    return this._aimHelper({
      chars: config.chars,
      refTraj: meanTraj,
      release: config.release,
      plate: config.plate_location,
      stepSize: config.stepSize,
    });
  }

  private static _aimHelper(config: {
    chars: IBasePitchChars;
    refTraj: ITrajectory;
    release: IReleasePosition;
    plate: IPlateLoc;
    stepSize?: number;
  }): IBuildPitchChars {
    const FN_NAME = '_aimHelper';

    vDebug({
      event: `${FN_NAME} initial release`,
      release: config.release,
    });

    const rotation = TrajHelper.getAltAzForTranslation(
      config.refTraj,
      config.release,
      config.plate
    );

    const translated = this._translate({
      chars: config.chars,
      release: config.release,
      plate: config.plate,
      plate_distance: config.refTraj.py,
    });

    vDebug({
      event: `${FN_NAME} after translation`,
      release: config.release,
      trans_bs_release: { px: translated.bs.px, pz: translated.bs.pz },
    });

    translated.traj = TrajHelper.getRotatedTrajectory(
      translated.traj,
      rotation
    );

    translated.bs = new BallState(translated.bs).rotateBallState(rotation);

    vDebug({
      event: `${FN_NAME} after rotation`,
      release: config.release,
      rotated_bs_release: { px: translated.bs.px, pz: translated.bs.pz },
    });

    if (config.release.px !== translated.bs.px) {
      console.warn(
        `${FN_NAME}: aimed release px (${translated.bs.px}) differs from initial (${config.release.px})`
      );
    }

    if (config.release.pz !== translated.bs.pz) {
      console.warn(
        `${FN_NAME}: aimed release pz (${translated.bs.pz}) differs from initial (${config.release.pz})`
      );
    }

    if (config.refTraj.py !== translated.bs.py) {
      console.warn(
        `${FN_NAME}: aimed plate_distance (${translated.bs.py}) differs from initial (${config.refTraj.py})`
      );
    }

    translated.ms.tilt +=
      rotation.altitude_rad * (config.stepSize ?? 1) * RAD2DEG;
    translated.ms.yaw +=
      rotation.azimuth_rad * (config.stepSize ?? 1) * RAD2DEG;

    /** preserve traj metadata values */
    translated.traj.model_id = config.chars.traj.model_id;

    /** preserve ms metadata values */
    translated.ms.model_id = config.chars.ms.model_id;
    translated.ms.ball_type = config.chars.ms.ball_type;
    translated.ms.full_hash = config.chars.ms.full_hash;
    translated.ms.matching_hash = config.chars.ms.matching_hash;
    translated.ms.last_built = config.chars.ms.last_built;

    return translated;
  }

  /** returns average traj of matching shots (after rotation to match pitch),
   * or the original pitch traj if insufficient matching shots are found
   */
  private static _getMeanTraj = (config: {
    refMs: IMachineState;
    refTraj: ITrajectory;
    shots: IMachineShot[];
    plate_distance: number;
  }) => {
    if (config.shots.length < MIN_SHOTS_TO_ROTATE) {
      return TrajHelper.translate(config.refTraj, {
        px: config.refTraj.px,
        pz: config.refTraj.pz,
        py: config.plate_distance,
      });
    }

    const rotatedShots = config.shots.map((shot) => {
      const baseTraj = shot.user_traj ?? shot.traj;

      // translate to desired release position
      const translatedTraj = TrajHelper.translate(baseTraj, {
        px: config.refTraj.px,
        pz: config.refTraj.pz,
        py: config.plate_distance,
      });

      // rotate to match ms
      const rotation: IAziAltCoordinate = {
        altitude_rad: (config.refMs.tilt - shot.actual_ms.tilt) * DEG2RAD,
        azimuth_rad: (config.refMs.yaw - shot.actual_ms.yaw) * DEG2RAD,
      };

      /** rotate each shot */
      return TrajHelper.getRotatedTrajectory(translatedTraj, rotation);
    });

    /** initialize with 0 for every property */
    const output: ITrajectory = { ...EMPTY_TRAJECTORY };

    /** tally up the values for each attribute, for each shot */
    let k: keyof ITrajectory;
    rotatedShots.forEach((shot) => {
      /** sum for each attribute */
      for (k in output) {
        if (k === 'model_id') {
          continue;
        }

        output[k] += shot[k];
      }
    });

    /** calculate average by dividing once */
    const denomCoeff = 1 / config.shots.length;
    for (k in output) {
      if (k === 'model_id') {
        continue;
      }

      output[k] *= denomCoeff;
    }

    return output;
  };

  private static _translate(config: {
    chars: IBasePitchChars;
    plate: IPlateLoc;
    release: IReleasePosition;
    plate_distance: number;
  }): IBuildPitchChars {
    /** don't mess around with deep-copy bs */
    return {
      priority: config.chars.priority,
      seams: config.chars.seams,
      breaks: config.chars.breaks,
      plate: config.plate,
      traj: {
        wnet: config.chars.traj.wnet,
        ax: config.chars.traj.ax,
        ay: config.chars.traj.ay,
        az: config.chars.traj.az,
        vx: config.chars.traj.vx,
        vy: config.chars.traj.vy,
        vz: config.chars.traj.vz,

        // important
        px: config.release.px,
        pz: config.release.pz,
        py: config.plate_distance,
      },
      bs: {
        qw: config.chars.bs.qw,
        qx: config.chars.bs.qx,
        qy: config.chars.bs.qy,
        qz: config.chars.bs.qz,

        wx: config.chars.bs.wx,
        wy: config.chars.bs.wy,
        wz: config.chars.bs.wz,
        wnet: config.chars.bs.wnet,

        vx: config.chars.bs.vx,
        vy: config.chars.bs.vy,
        vz: config.chars.bs.vz,
        vnet: config.chars.bs.vnet,

        // important
        px: config.release.px,
        pz: config.release.pz,
        py: config.plate_distance,
      },
      ms: {
        model_id: config.chars.ms.model_id,
        model_key: config.chars.ms.model_key,
        ball_type: config.chars.ms.ball_type,
        training: config.chars.ms.training,
        last_built: config.chars.ms.last_built,

        tilt: config.chars.ms.tilt,
        yaw: config.chars.ms.yaw,
        qw: config.chars.ms.qw,
        qx: config.chars.ms.qx,
        qy: config.chars.ms.qy,
        qz: config.chars.ms.qz,
        w1: config.chars.ms.w1,
        w2: config.chars.ms.w2,
        w3: config.chars.ms.w3,
        a1: config.chars.ms.a1,
        a2: config.chars.ms.a2,
        a3: config.chars.ms.a3,

        // important
        px: config.release.px,
        pz: config.release.pz,
        py: config.plate_distance,
      },
    };
  }
}
