import { Badge, Code, Flex, Heading } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SessionVisualizer } from 'components/common/sessions/dialogs/visualize-session/visualizer';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { ACTIONS_KEY } from 'enums/tables';
import { IFullDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISessionSummary } from 'lib_ts/interfaces/i-session-summary';
import { IUser } from 'lib_ts/interfaces/i-user';
import React from 'react';
import {
  MAX_USER_SESSIONS,
  SessionEventsService,
} from 'services/session-events.service';

const COMPONENT_NAME = 'ViewSessionsDialog';

interface IProps {
  user: IUser;
  onClose: () => void;
}

interface IState {
  selected?: string;
  summaries?: ISessionSummary[];
}

export class ViewSessionsDialog extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    /** make a copy to manipulate via forms */
    this.state = {};
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    SessionEventsService.getUserSessions(this.props.user._id, MAX_USER_SESSIONS)
      .then((results) => {
        this.setState({
          summaries: results.sort((a, b) => -a.start.localeCompare(b.start)),
        });
      })
      .catch((error) => console.error(error));
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      title: 'admin.view-sessions',
      width: RADIX.DIALOG.WIDTH.LG,
      content: (
        <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
          <Heading size={RADIX.HEADING.SIZE.SM}>
            Recent Sessions for{' '}
            <Badge color={RADIX.COLOR.WARNING}>{this.props.user.email}</Badge> -
            at most {MAX_USER_SESSIONS} will be loaded
          </Heading>

          <CommonTable
            id="view-sessions"
            loading={!this.state.summaries}
            displayColumns={[
              {
                key: 'session',
                label: 'Session ID',
                formatFn: (s: ISessionSummary) => <Code>{s.session}</Code>,
              },
              {
                key: 'start',
                label: 'common.created',
                dataType: 'datetime',
              },
              {
                key: ACTIONS_KEY,
                label: 'common.actions',
                formatFn: (s: ISessionSummary) => {
                  const isActive = this.state.selected === s.session;

                  return (
                    <CommonTableButton
                      disabled={isActive}
                      color={isActive ? RADIX.COLOR.SUCCESS : RADIX.COLOR.INFO}
                      onClick={() => this.setState({ selected: s.session })}
                      label={isActive ? 'common.active' : 'common.select'}
                    />
                  );
                },
              },
            ]}
            displayData={this.state.summaries ?? []}
            total={this.state.summaries?.length ?? 0}
            enablePagination
            pageSize={10}
          />

          {this.state.selected && (
            <>
              <Heading size={RADIX.HEADING.SIZE.MD}>
                Active: <Code>{this.state.selected}</Code>
              </Heading>
              <SessionVisualizer session={this.state.selected} />
            </>
          )}
        </Flex>
      ),
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
