import {
  ArchiveIcon,
  PlusIcon,
  TimerIcon,
  UpdateIcon,
} from '@radix-ui/react-icons';
import { Box, Code, Grid } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import {
  ArchiveModelsDialog,
  CopyModelDialog,
  MachineModelCreationDialog,
  MachineModelEditorDialog,
  ModelPerformanceDialog,
} from 'components/sections/admin-portal/machine-models/dialogs';
import { CreateMetricsDialog } from 'components/sections/admin-portal/machine-models/metrics/dialogs/create-metric';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import env from 'config';
import { IMachineModelsContext } from 'contexts/admin/machine-models.context';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
  ICheckedContext,
} from 'contexts/layout/checked.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { AdminRoute } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { IDisplayCol, ITablePageable } from 'interfaces/i-tables';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getModelKeyFromModel } from 'lib_ts/classes/ms.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import React from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';

const ALLOW_CREATE_METRIC = false;
const ALLOW_EVAL_ALL = !env.production;
const IDENTIFIER = TableIdentifier.AdminModelsList;

const ALLOW_DELETE = false;
const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machinesCx: IMachinesContext;
  machineModelsCx: IMachineModelsContext;
}

interface IDialogs {
  dialogArchive?: number;
  dialogCopy?: number;
  dialogCreateModel?: number;
  dialogCreateMetrics?: number;
  dialogEditor?: number;
  dialogPerformance?: number;
}

interface IFilters {
  filterTags: string[];
  filterTypes: string[];
  filterMachines: string[];
  filterNames: string[];
  filterCreated: string[];
}

interface IState extends IFilters, IDialogs {
  selected?: IMachineModel;

  contextModel?: IMachineModel;
  contextModels?: IMachineModel[];

  evaluating: boolean;
}

export class MachineModelsTable extends React.Component<IProps, IState> {
  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'common.edit',
          onClick: (model: IMachineModel) => {
            this.setState({
              contextModel: model,
              dialogEditor: Date.now(),
            });
          },
        },
        {
          label: 'admin.create-from',
          onClick: (model: IMachineModel) => {
            this.setState({
              contextModel: model,
              dialogCreateModel: Date.now(),
            });
          },
        },
        {
          label: 'admin.compare-w-active',
          onClick: (model: IMachineModel) => {
            const machine = this.props.machinesCx.machines.find(
              (m) => m.machineID === model.machineID
            );
            const modelKey = getModelKeyFromModel(model);
            const currentModelID = machine?.model_ids?.[modelKey];

            const active = this.props.machineModelsCx.models.find(
              (m) => m._id === currentModelID
            );
            if (!active) {
              NotifyHelper.error({
                message_md: `Failed to find the model for ${model.machineID} associated with model key ${modelKey}, nothing to compare.`,
              });
              return;
            }

            this.setState({
              dialogPerformance: Date.now(),
              contextModels: [model, active],
            });
          },
        },
        {
          label: 'admin.view-performance',
          onClick: (model: IMachineModel) => {
            this.setState({
              contextModels: [model],
              dialogPerformance: Date.now(),
            });
          },
        },
        {
          label: 'common.copy',
          onClick: (model: IMachineModel) => {
            this.setState({
              dialogCopy: Date.now(),
              contextModel: model,
            });
          },
        },
        {
          label: 'common.archive',
          onClick: (model: IMachineModel) => {
            this.setState({
              dialogArchive: Date.now(),
              contextModels: [model],
            });
          },
          color: RADIX.COLOR.DANGER,
          invisibleFn: () => ALLOW_DELETE,
        },
      ],
    },
    {
      label: 'common.created',
      key: '_created',
      formatFn: (m: IMongoBase) =>
        m._created ? lightFormat(parseISO(m._created), 'yyyy-MM-dd') : '',
    },
    {
      label: 'common.name',
      key: 'name',
    },
    {
      label: 'common.type',
      key: 'type',
    },
    {
      label: 'common.machine',
      key: 'machineID',
    },
    {
      label: 'admin.status',
      key: 'status',
    },
    {
      label: 'common.ball-type',
      key: 'ball_type',
    },
    {
      label: 'common.tags',
      key: '_tags',
      disableSort: true,
      formatFn: (m: IMachineModel) => {
        if (!m.tags) {
          return <></>;
        }

        return (
          <>
            {m.tags.map((t, i) => (
              <Code key={`model-${m._id}-tag-${i}`} className="block">
                {t}
              </Code>
            ))}
          </>
        );
      },
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      filterTags: [],
      filterTypes: [],
      filterNames: [],
      filterMachines: [],
      filterCreated: [],

      contextModels: [],

      evaluating: false,
    };

    this.getCheckedMenuActions = this.getCheckedMenuActions.bind(this);
    this.renderDialogs = this.renderDialogs.bind(this);
    this.renderTableToolbar = this.renderTableToolbar.bind(this);
  }

  private renderDialogs() {
    return (
      <>
        {this.state.dialogCreateMetrics && (
          <CreateMetricsDialog
            key={this.state.dialogCreateMetrics}
            onClose={() => this.setState({ dialogCreateMetrics: undefined })}
            machineModelsCx={this.props.machineModelsCx}
            machinesCx={this.props.machinesCx}
          />
        )}

        {this.state.dialogEditor && (
          <MachineModelEditorDialog
            key={this.state.dialogEditor}
            id={this.state.contextModel?._id}
            authCx={this.props.authCx}
            machineModelsCx={this.props.machineModelsCx}
            onClose={() => this.setState({ dialogEditor: undefined })}
          />
        )}

        {this.state.dialogCopy && this.state.contextModel && (
          <CopyModelDialog
            key={this.state.dialogCopy}
            model={this.state.contextModel}
            onClose={() => this.setState({ dialogCopy: undefined })}
          />
        )}

        {this.state.dialogCreateModel && (
          <MachineModelCreationDialog
            key={this.state.dialogCreateModel}
            machineModelsCx={this.props.machineModelsCx}
            machinesCx={this.props.machinesCx}
            refModel={this.state.contextModel}
            onClose={(refresh) => {
              this.setState({ dialogCreateModel: undefined }, () => {
                if (refresh) {
                  this.props.machineModelsCx.refresh();
                }
              });
            }}
          />
        )}

        {this.state.dialogPerformance && this.state.contextModels && (
          <ModelPerformanceDialog
            key={this.state.dialogPerformance}
            machineModelsCx={this.props.machineModelsCx}
            machinesCx={this.props.machinesCx}
            models={this.state.contextModels}
            onClose={() => this.setState({ dialogPerformance: undefined })}
          />
        )}
      </>
    );
  }

  private getCheckedMenuActions(): IMenuAction[] {
    const checked = this.props.machineModelsCx.models.filter(
      (p) => p._checked === true
    );

    const actions: IMenuAction[] = [
      {
        label: 'Compare checked',
        onClick: () => {
          this.setState({
            dialogPerformance: Date.now(),
            contextModels: checked,
          });
        },
      },
      {
        label: 'Archive checked',
        color: RADIX.COLOR.DANGER,
        onClick: () => {
          this.setState({
            contextModels: checked,
            dialogArchive: Date.now(),
          });
        },
      },
    ];

    return actions;
  }

  private renderTableToolbar(checkedCx: ICheckedContext) {
    const filtered = this.getFiltered();

    return (
      <Grid columns="5" gap={RADIX.FLEX.GAP.SM}>
        <Box>
          <CommonSearchInput
            id="machine-models-names"
            placeholder="common.name"
            options={this.props.machineModelsCx.options.names}
            onChange={(v: string[]) => {
              checkedCx.checkAll(false);
              this.setState({ filterNames: v });
            }}
            values={this.state.filterNames}
            multiple
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="machine-models-machines"
            placeholder="common.machine"
            options={this.props.machinesCx.machines.map((o) => ({
              label: `${o.machineID} (${o.nickname ?? 'no nickname'})`,
              value: o.machineID,
            }))}
            values={this.state.filterMachines}
            onChange={(machineIDs) => {
              checkedCx.checkAll(false);
              this.setState({
                filterMachines: machineIDs,
              });
            }}
            multiple
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="machine-models-types"
            placeholder="common.type"
            options={this.props.machineModelsCx.options.types.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterTypes}
            onChange={(v) => {
              checkedCx.checkAll(false);
              this.setState({ filterTypes: v });
            }}
            multiple
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="machine-models-tags"
            placeholder="common.tags"
            options={this.props.machineModelsCx.options.tags.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterTags}
            onChange={(v) => {
              checkedCx.checkAll(false);
              this.setState({ filterTags: v });
            }}
            multiple
            optional
          />
        </Box>
        <Box>
          <CommonSearchInput
            id="machine-models-created"
            placeholder="common.date-added"
            options={this.props.machineModelsCx.options._created.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterCreated}
            onChange={(v) => {
              checkedCx.checkAll(false);
              this.setState({ filterCreated: v });
            }}
            multiple
            reverseSort
            optional
          />
        </Box>

        <CommonTableButton
          color={RADIX.COLOR.NEUTRAL}
          icon={<UpdateIcon />}
          label="common.refresh"
          disabled={this.props.machineModelsCx.loading}
          onClick={() => {
            checkedCx.checkAll(false);
            this.props.machineModelsCx.refresh();
          }}
        />

        <CommonTableButton
          icon={<PlusIcon />}
          label="common.create"
          color={RADIX.COLOR.SUCCESS}
          disabled={this.props.machineModelsCx.loading}
          onClick={() => {
            this.setState({
              contextModel: undefined,
              dialogCreateModel: Date.now(),
            });
          }}
        />

        {ALLOW_CREATE_METRIC && (
          <CommonTableButton
            icon={<PlusIcon />}
            label="admin.create-metric"
            color={RADIX.COLOR.INFO}
            disabled={this.props.machineModelsCx.loading}
            onClick={() => {
              this.setState({
                dialogCreateMetrics: Date.now(),
              });
            }}
          />
        )}

        {ALLOW_EVAL_ALL && !this.state.evaluating && (
          <CommonTableButton
            icon={<TimerIcon />}
            label="admin.evaluate-all"
            color={RADIX.COLOR.DANGER}
            onClick={() => {
              this.setState({ evaluating: true });
              const machineIDs = filtered.map((m) => m.machineID);
              AdminMachineModelsService.getInstance().evalMachineMetrics(
                machineIDs
              );

              NotifyHelper.success({
                message_md: 'Evaluating machines, please wait...',
              });
            }}
          />
        )}

        <CommonTableButton
          icon={<ArchiveIcon />}
          label="common.archive-unused"
          color={RADIX.COLOR.WARNING}
          disabled={this.props.machineModelsCx.loading}
          onClick={() => {
            checkedCx.checkAll(false);

            AdminMachineModelsService.getInstance()
              .archiveUnused()
              .then(() => this.props.machineModelsCx.refresh());
          }}
        />
      </Grid>
    );
  }

  private getFiltered(): IMachineModel[] {
    return this.props.machineModelsCx.models
      .filter(
        (m) =>
          this.state.filterNames.length === 0 ||
          this.state.filterNames.includes(m.name)
      )
      .filter(
        (m) =>
          this.state.filterMachines.length === 0 ||
          this.state.filterMachines.includes(m.machineID)
      )
      .filter(
        (m) =>
          this.state.filterTags.length === 0 ||
          (m.tags &&
            ArrayHelper.countIntersection(m.tags, this.state.filterTags) > 0)
      )
      .filter(
        (m) =>
          this.state.filterTypes.length === 0 ||
          this.state.filterTypes.includes(m.type)
      )
      .filter(
        (m) =>
          this.state.filterCreated.length === 0 ||
          (m._created &&
            this.state.filterCreated.includes(
              lightFormat(parseISO(m._created), 'yyyy-MM-dd')
            ))
      );
  }

  render() {
    const filtered = this.getFiltered();

    const pagination: ITablePageable = {
      total: filtered.length,
      enablePagination: true,
      pageSize: this.props.cookiesCx.getPageSize(IDENTIFIER) ?? PAGE_SIZES[0],
      pageSizeOptions: PAGE_SIZES,
      pageSizeCallback: (value) =>
        this.props.cookiesCx.setPageSize(IDENTIFIER, value),
    };

    return (
      <ErrorBoundary componentName="MachineModelsTable">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={<AdminTabNav active={AdminRoute.MachineModels} />}
          table={
            <GlobalContext.Consumer>
              {(globalCx) => (
                <CheckedProvider data={filtered}>
                  <CheckedContext.Consumer>
                    {(checkedCx) => (
                      <CommonTable
                        id="AdminMachineModels"
                        checkedCx={checkedCx}
                        checkedMenuActions={this.getCheckedMenuActions()}
                        suspendKeyListener={globalCx.dialogs.length > 0}
                        toolbarContent={this.renderTableToolbar(checkedCx)}
                        displayColumns={this.BASE_COLUMNS}
                        displayData={filtered}
                        afterSelectRow={async (config) =>
                          this.setState({ selected: config.model })
                        }
                        checkboxColumnIndex={0}
                        {...pagination}
                        defaultSortKey="_created"
                        defaultSortDir={1}
                        enableSort
                        vFlex
                      />
                    )}
                  </CheckedContext.Consumer>
                </CheckedProvider>
              )}
            </GlobalContext.Consumer>
          }
        />

        {this.renderDialogs()}

        {this.state.dialogArchive && this.state.contextModels && (
          <ArchiveModelsDialog
            key={this.state.dialogArchive}
            models={this.state.contextModels}
          />
        )}
      </ErrorBoundary>
    );
  }
}
