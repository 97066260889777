import { User, useAuth0 } from '@auth0/auth0-react';
import { DEFAULT_DELAY_MS, NotifyHelper } from 'classes/helpers/notify.helper';
import { RouteHelper } from 'classes/helpers/route.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { TermsOfServiceDialog } from 'components/common/dialogs/terms-of-service';
import env from 'config';
import { ICookiesContext } from 'contexts/cookies.context';
import {
  addMilliseconds,
  addMinutes,
  differenceInMilliseconds,
  isBefore,
  isFuture,
  isPast,
  parseISO,
} from 'date-fns';
import format from 'date-fns-tz/format';
import { addSeconds } from 'date-fns/esm';
import { AuthEvent } from 'enums/auth';
import { CookieKey } from 'enums/cookies.enums';
import { LOCAL_TIMEZONE } from 'enums/env';
import { SectionName } from 'enums/route.enums';
import { t } from 'i18next';
import {
  DEFAULT_SESSION_UNAUTH,
  ISessionCookie,
} from 'interfaces/cookies/i-session.cookie';
import { DEFAULT_SNAPSHOT_COOKIE } from 'interfaces/cookies/i-snapshot.cookie';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import {
  GAME_STATUS_BLACKLIST,
  GameStatus,
  MLB_TEAMS,
} from 'lib_ts/enums/mlb.enums';
import { NPB_TEAMS } from 'lib_ts/enums/npm.enums';
import { League } from 'lib_ts/enums/team.enums';
import { LanguageCode } from 'lib_ts/enums/translation';
import {
  ILoginError,
  ILoginPayload,
  ILoginResult,
  IResetPasswordPayload,
} from 'lib_ts/interfaces/common/i-login';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IGameStatusMsg } from 'lib_ts/interfaces/i-machine-msg';
import { IUser } from 'lib_ts/interfaces/i-user';
import { IReassignOptions } from 'lib_ts/interfaces/pitches/i-pitch-list';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { AuthService } from 'services/auth.service';
import { MainService } from 'services/main.service';
import { SessionEventsService } from 'services/session-events.service';
import { TermsService } from 'services/terms.service';
import { WebSocketService } from 'services/web-socket.service';
import i18n from 'translations/i18n';

const CONTEXT_NAME = 'AuthContext';

const getVersionMatch = (version1: string, version2: string) => {
  const dl = '.';

  const v1Parts = version1.split(dl);
  const v2Parts = version2.split(dl);

  const tiers = ['major', 'minor', 'build', 'patch'];

  const output: { [tier: string]: boolean } = {};

  tiers.forEach((t, i) => {
    output[t] = v1Parts.slice(0, i).join(dl) === v2Parts.slice(0, i).join(dl);
  });

  return output as {
    major: boolean;
    minor: boolean;
    build: boolean;
    patch: boolean;
  };
};

export const getLeagueTeams = (league: League) => {
  switch (league) {
    case League.NipponProfessionalBaseball: {
      return NPB_TEAMS.map((s) => {
        const o: IOption = {
          label: s,
          value: s,
          group: League.NipponProfessionalBaseball,
        };
        return o;
      });
    }

    case League.MajorLeagueBaseball: {
      return MLB_TEAMS.map((s) => {
        const o: IOption = {
          label: s,
          value: s,
          group: League.MajorLeagueBaseball,
        };
        return o;
      });
    }

    case League.None:
    default: {
      return [];
    }
  }
};

/** setting to true will cause the token to refresh every 30 seconds instead of 1 min prior to expiry (only on local) */
const TEST_REFRESH_TOKEN = false;
/** 5 min in ms, how often to check the server for new versions/builds */
const VERSION_CHECK_MS = 5 * 60 * 1_000;

/** expiresIn is provided in ms from now, targets 30 sec before expires in */
const getExpiresFromNow = (expiresIn: number) => {
  return new Date(Date.now() + expiresIn - 30 * 1_000);
};

/** determine whether the given datestring means the user must accept terms before proceeding/closing */
const termsAcceptanceRequired = (ds?: string): boolean => {
  if (!ds) {
    /** user has never accepted TOS before */
    return true;
  }

  const lastAccepted = new Date(ds);
  if (isBefore(lastAccepted, new Date(env.termsRevisionDate))) {
    /** user has not accepted new TOS */
    return true;
  }

  return false;
};

const VALID_START_EVENTS: AuthEvent[] = ['launch', 'login', 'resume'];
const VALID_CONTINUE_EVENTS: AuthEvent[] = [
  'impersonation',
  'new-session',
  'refresh',
  'super-session',
];
const REWRITE_TO_LOGIN_EVENTS: AuthEvent[] = ['impersonation', 'super-session'];

interface IAuthState extends ISessionCookie {}

export interface IAuthContext {
  // as per last WS message
  gameStatus?: GameStatus;

  current: IAuthState;
  error?: ILoginError;

  loading: boolean;

  /** teams / machines / other users the user can interact with (e.g. for pitch list reassignment) */
  reassignOptions: IReassignOptions;

  readonly effectiveTrainingMode: () => TrainingMode;
  readonly restrictedGameStatus: () => boolean;
  readonly impersonate: (config: { email: string }) => void;
  readonly loginAuth0: () => void;
  readonly signupAuth0: () => void;
  readonly loginLegacy: (config: ILoginPayload) => Promise<ILoginResult>;
  readonly logout: (silent?: boolean) => void;
  readonly logoutAuth0: () => void;
  readonly newSession: () => Promise<boolean>;
  readonly checkTerms: () => void;
  readonly showTermsOfService: () => void;
  readonly reconnectWS: () => void;
  readonly refreshToken: () => Promise<boolean>;
  readonly resetPassword: (config: IResetPasswordPayload) => Promise<boolean>;
  readonly toggleSuperSession: () => Promise<boolean>;
  readonly updateUser: (
    user: Partial<IUser>,
    silently?: boolean
  ) => Promise<IUser>;
  readonly getLeagueTeamOptions: () => IOption[];
}

const DEFAULT: IAuthContext = {
  current: DEFAULT_SESSION_UNAUTH,
  loading: false,

  reassignOptions: {
    teams: [],
    machines: [],
    users: [],
  },

  effectiveTrainingMode: () => TrainingMode.Manual,
  restrictedGameStatus: () => false,
  impersonate: () => console.debug('not init'),
  loginAuth0: () => console.debug('not init'),
  signupAuth0: () => console.debug('not init'),
  loginLegacy: () => new Promise(() => console.debug('not init')),
  logout: () => console.debug('not init'),
  logoutAuth0: () => console.debug('not init'),
  newSession: () => new Promise(() => console.debug('not init')),
  checkTerms: () => console.debug('not init'),
  showTermsOfService: () => console.debug('not init'),
  reconnectWS: () => console.debug('not init'),
  refreshToken: async () => new Promise(() => console.debug('not init')),
  resetPassword: () => new Promise(() => console.debug('not init')),
  toggleSuperSession: async () => new Promise(() => console.debug('not init')),
  updateUser: async () => new Promise(() => console.debug('not init')),
  getLeagueTeamOptions: () => [],
};

export const AuthContext = createContext(DEFAULT);

interface IProps {
  cookiesCx: ICookiesContext;
  children: ReactNode;
}

export const AuthProvider: FC<IProps> = (props) => {
  /** reminder to accept terms won't trigger again until this date is past */
  let termsNotifyDequeue = new Date();

  /** automatically refresh session token */
  let refreshTokenTimer: any = undefined;

  let buildVersionInterval: any = undefined;

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const [_authEvent, _setAuthEvent] = useState<AuthEvent>('launch');

  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [_error, _setError] = useState<ILoginError | undefined>(undefined);

  const [_authState, _setAuthState] = useState(DEFAULT_SESSION_UNAUTH);

  const [_reassignOptions, _setReassignOptions] = useState(
    DEFAULT.reassignOptions
  );

  /** used to trigger a new WS connection (e.g. if you are disconnected) */
  const [_wsCounter, _setWSCounter] = useState(0);

  const [_showTermsOfService, _setShowTermsOfService] = useState(false);

  const [_gameStatus, _setGameStatus] = useState<GameStatus | undefined>();

  const _logoutAuth0 = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const _executeRefreshToken = async (): Promise<boolean> => {
    try {
      _setAuthEvent('refresh');
      const result = await AuthService.getInstance().refresh();

      if (!result?.token) {
        throw new Error('empty token');
      }

      props.cookiesCx.setCookie(
        CookieKey.session,
        result,
        getExpiresFromNow(result.expiresIn),
        'refresh'
      );

      return true;
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md:
          'There was an error refreshing your token. Please logout and login again.',
      });

      props.cookiesCx.setCookie(
        CookieKey.session,
        DEFAULT_SESSION_UNAUTH,
        new Date(),
        e instanceof Error ? e.message : 'refresh token error'
      );

      return false;
    }
  };

  const _notifyNewVersion = (version: string, updated: string | Date) => {
    if (typeof updated === 'string') {
      updated = parseISO(updated);
    }

    NotifyHelper.warning({
      message_md: [
        `The application has been updated to **v${version}** on ${format(
          updated,
          'yyyy-MM-dd @ h:mm a',
          { timeZone: LOCAL_TIMEZONE }
        )}.`,
        'Please refresh this page using the button below or using your browser when you are ready.',
      ].join('\n\n'),
      delay_ms: 0,
      inbox: true,
      buttons: [
        {
          label: t('common.refresh'),
          onClick: () => window.location.reload(),
          dismissAfterClick: true,
        },
      ],
    });
  };

  const _executeLogout = (reason: AuthEvent, silent?: boolean) => {
    _setAuthEvent('logout');

    /** log before wiping required session variables */
    if (_authState.auth) {
      SessionEventsService.postEvent({
        category: 'auth',
        tags: 'logout',
        data: {
          reason,
          silent,
        },
      });
    }

    if (!silent) {
      switch (reason) {
        case 'logout': {
          NotifyHelper.info({ message_md: 'Logging you out, goodbye!' });
          break;
        }

        case 'invalid-token': {
          NotifyHelper.warning({
            message_md: 'Invalid token detected, please login again.',
          });
          break;
        }

        case 'server-offline': {
          NotifyHelper.warning({
            message_md: 'Server could not be reached, please try again later.',
          });
          break;
        }

        default: {
          /** most likely a failed login, notify would be handled in auth service */
          break;
        }
      }
    }

    /** logout of Auth0 if necessary */
    _logoutAuth0();

    /** courtesy call to server */
    AuthService.getInstance().logout();

    /** clearing cookies here will actually result in the logout */
    props.cookiesCx.setCookie(
      CookieKey.machineCalibration,
      {},
      undefined,
      'logout'
    );

    props.cookiesCx.setCookie(
      CookieKey.session,
      DEFAULT_SESSION_UNAUTH,
      new Date(),
      'logout'
    );

    props.cookiesCx.setCookie(
      CookieKey.snapshot,
      DEFAULT_SNAPSHOT_COOKIE,
      new Date(),
      'logout'
    );
  };

  const _loginAuth0 = (token: string, user: User) => {
    const errorCallback = (error: ILoginError) => {
      // _executeLogout('failed-auth0-login');
      const iError: ILoginError = { type: error.type, message: error.message };
      _setError(iError);
    };

    return new Promise<ILoginResult>((resolve) => {
      _setAuthEvent('login');
      _setLoading(true);
      AuthService.getInstance()
        .loginAuth0(token, user)
        .then((result) => {
          const token = result.success;

          if (token) {
            /** update auth context */
            props.cookiesCx.setCookie(
              CookieKey.session,
              token,
              getExpiresFromNow(token.expiresIn),
              'auth0 login'
            );

            _setError(result.error);
          } else {
            if (result.error) {
              errorCallback({
                ...result.error,
                message: result.error
                  ? result.error.message
                  : ERROR_MSGS.CONTACT_SUPPORT,
              });
            }

            resolve({ error: _error });
          }

          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          errorCallback({
            type: 'internal',
            message:
              'There was an unexpected problem authenticating via Auth0 Login.',
          });
          resolve({ error: _error });
        })
        .finally(() => _setLoading(false));
    });
  };

  const _handleTerms = (accepted: boolean) => {
    if (accepted) {
      /** date will be updated, let the user proceed once saved */
      TermsService.getInstance()
        .accept()
        .then(() => _setShowTermsOfService(false));
      return;
    }

    /** check whether an existing (previous) acceptance date exists and is still valid */
    TermsService.getInstance()
      .check()
      .then((ds) => {
        if (termsAcceptanceRequired(ds)) {
          /** show a notification but don't spam the area */
          if (isPast(termsNotifyDequeue)) {
            NotifyHelper.warning({
              message_md:
                'You must accept the terms of service before proceeding.',
            });
            termsNotifyDequeue = addMilliseconds(
              new Date(),
              DEFAULT_DELAY_MS + 500
            );
          }

          return;
        }

        /** existing (previous) acceptance date is still valid */
        _setShowTermsOfService(false);
      });
  };

  const _handleGameStatus = (event: CustomEvent) => {
    const data: IGameStatusMsg = event.detail;

    NotifyHelper.debug(
      {
        message_md: `Received game status message (${data.status}).`,
      },
      props.cookiesCx
    );

    if (
      (!_gameStatus || !GAME_STATUS_BLACKLIST.includes(_gameStatus)) &&
      GAME_STATUS_BLACKLIST.includes(data.status)
    ) {
      // notify the user if game status changes to a restricted one
      NotifyHelper.warning({
        message_md: 'A game is underway, entering offline mode.',
      });
    }

    _setGameStatus(data.status);
  };

  const state: IAuthContext = {
    current: _authState,
    loading: _loading || isLoading,
    gameStatus: _gameStatus,

    error: _error,

    reassignOptions: _reassignOptions,

    getLeagueTeamOptions: () => {
      if (_authState.role === UserRole.admin) {
        // super admins can see every team
        return Object.values(League).flatMap((l) => getLeagueTeams(l));
      }

      // users and team admins see teams based on their team's league
      return getLeagueTeams(_authState.league);
    },

    checkTerms: () => {
      TermsService.getInstance()
        .check()
        .then((result) => {
          if (termsAcceptanceRequired(result)) {
            _setShowTermsOfService(true);
          }
        });
    },

    effectiveTrainingMode: () =>
      // force users into manual mode if there's a game going on
      _gameStatus === undefined || !GAME_STATUS_BLACKLIST.includes(_gameStatus)
        ? _authState.training_mode ?? TrainingMode.Quick
        : TrainingMode.Manual,

    restrictedGameStatus: () =>
      _gameStatus !== undefined && GAME_STATUS_BLACKLIST.includes(_gameStatus),

    refreshToken: _executeRefreshToken,

    resetPassword: (payload) => {
      _setLoading(true);

      return AuthService.getInstance()
        .resetPassword(payload)
        .then((result) => {
          if (!result.success) {
            NotifyHelper.warning({
              message_md:
                result.error ?? 'There was a problem resetting your password.',
            });
          }

          return result.success;
        })
        .catch((reason) => {
          console.error(reason);
          NotifyHelper.error({
            message_md: 'There was a problem resetting your password.',
          });
          return false;
        })
        .finally(() => _setLoading(false));
    },

    updateUser: async (user, silently) => {
      /** ensure this method is never used to update any other user but the current user */
      user._id = _authState.userID;

      if (!silently) {
        _setLoading(true);
      }

      const result = await AuthService.getInstance()
        .putUser(user)
        .finally(() => {
          if (!silently) {
            _setLoading(false);
          }
        });

      _setAuthState({
        ..._authState,

        enable_beta: result.enable_beta,
        menu_mode: result.menu_mode,
        placeholder_folders: result.placeholder_folders,
        plate_show_ellipses: result.plate_show_ellipses,
        preset_training_mode: result.preset_training_mode,
        training_mode: result.training_mode,
        language: result.language,
      });

      return result;
    },

    loginAuth0: () =>
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${window.origin}${RouteHelper.section(
            SectionName.Home
          )}`,
        },
      }),
    logoutAuth0: _logoutAuth0,
    signupAuth0: () =>
      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          redirect_uri: `${window.origin}${RouteHelper.section(
            SectionName.Home
          )}`,
        },
      }),

    loginLegacy: async (config) => {
      try {
        _setAuthEvent('login');
        _setLoading(true);

        const output = await AuthService.getInstance().loginLegacy(config);

        if (!output.success) {
          throw new Error(output.error?.message ?? 'failed login');
        }

        /** update auth context */
        props.cookiesCx.setCookie(
          CookieKey.session,
          output.success,
          getExpiresFromNow(output.success.expiresIn),
          'login'
        );

        return output;
      } catch (e) {
        console.error(e);

        props.cookiesCx.setCookie(
          CookieKey.session,
          DEFAULT_SESSION_UNAUTH,
          new Date(),
          e instanceof Error ? e.message : 'error login'
        );

        const output: ILoginResult = {
          error: {
            type: 'internal',
            message:
              'There was an unexpected problem authenticating via Legacy Login.',
          },
        };

        return output;
      } finally {
        _setLoading(false);
      }
    },

    newSession: async () => {
      try {
        if (!_authState.auth) {
          NotifyHelper.warning({
            message_md: 'You need to be authenticated to perform this action.',
          });
          return false;
        }

        _setAuthEvent('new-session');

        _setLoading(true);

        const result = await AuthService.getInstance().newSession();

        if (!result?.token) {
          throw new Error('empty token');
        }

        /** start the session */
        props.cookiesCx.setCookie(
          CookieKey.session,
          result,
          getExpiresFromNow(result.expiresIn),
          'new session'
        );
        return true;
      } catch (e) {
        console.error(e);

        /** end any existing session */
        props.cookiesCx.setCookie(
          CookieKey.session,
          DEFAULT_SESSION_UNAUTH,
          new Date(),
          e instanceof Error ? e.message : 'error new session'
        );

        return false;
      } finally {
        _setLoading(false);
      }
    },

    showTermsOfService: () => {
      _setShowTermsOfService(true);
    },

    impersonate: (config) => {
      /** log a session event for the attempt regardless of result */
      SessionEventsService.postEvent({
        category: 'auth',
        tags: 'impersonation',
        data: {
          msg: 'Impersonation attempted',
          admin: _authState.email,
          role: _authState.role,
          session: _authState.session,
          user: config.email,
        },
      });

      /** only super admins can impersonate other users */
      if (_authState.role !== UserRole.admin) {
        NotifyHelper.warning({
          message_md:
            'You do not have the necessary permissions to perform this action.',
        });
        return;
      }

      _setAuthEvent('impersonation');
      _setLoading(true);

      AuthService.getInstance()
        .impersonate(config)
        .then((result) => {
          if (result && result.token && result.mode === 'impostor') {
            NotifyHelper.success({
              message_md: `Starting a new session impersonating user ${result.email}...`,
            });
            props.cookiesCx.setCookie(
              CookieKey.session,
              result,
              getExpiresFromNow(result.expiresIn),
              'impersonate'
            );
          } else {
            NotifyHelper.warning({
              message_md: `There was a problem attempting to impersonate user ${config.email}.`,
            });
            props.cookiesCx.setCookie(
              CookieKey.session,
              DEFAULT_SESSION_UNAUTH,
              new Date(),
              'failed impersonate'
            );
          }
        })
        .catch((error) => {
          console.error(error);
          NotifyHelper.error({
            message_md: `There was an error impersonating user ${config.email}.`,
          });
          props.cookiesCx.setCookie(
            CookieKey.session,
            DEFAULT_SESSION_UNAUTH,
            new Date(),
            'error impersonate'
          );
        })
        .finally(() => _setLoading(false));
    },

    toggleSuperSession: async () => {
      try {
        /** only super admins can enter super mode */
        if (_authState.role !== UserRole.admin) {
          NotifyHelper.warning({
            message_md:
              'You do not have the necessary permissions to perform this action.',
          });
          return false;
        }

        _setAuthEvent('super-session');
        _setLoading(true);

        /** log a session event for the attempt regardless of result */
        SessionEventsService.postEvent({
          category: 'auth',
          tags: 'super',
          data: {
            msg: 'Toggle super session attempted',
            admin: _authState.email,
            role: _authState.role,
            session: _authState.session,
          },
        });

        const result = await AuthService.getInstance().super();

        if (!result?.token) {
          NotifyHelper.warning({
            message_md: 'There was a problem toggling a super session.',
          });

          throw new Error('failed super session toggle');
        }

        const isSuper = result.mode === 'super';

        NotifyHelper.success({
          message_md: isSuper
            ? 'Starting a super session...'
            : 'Leaving a super session...',
        });

        props.cookiesCx.setCookie(
          CookieKey.session,
          result,
          getExpiresFromNow(result.expiresIn),
          'super session'
        );

        return true;
      } catch (e) {
        console.error(e);

        props.cookiesCx.setCookie(
          CookieKey.session,
          DEFAULT_SESSION_UNAUTH,
          new Date(),
          e instanceof Error ? e.message : 'failed super session'
        );

        return false;
      } finally {
        _setLoading(false);
      }
    },

    logout: (silent) => _executeLogout('logout', silent),

    reconnectWS: () => {
      _setWSCounter(_wsCounter + 1);
    },
  };

  // start listening to game-status messages
  useEffect(() => {
    WebSocketHelper.on(WsMsgType.S2U_GameStatus, _handleGameStatus);

    return () => {
      WebSocketHelper.remove(WsMsgType.S2U_GameStatus, _handleGameStatus);
    };
  }, []);

  /** clear old and schedule new refresh (if necessary) whenever expires value changes */
  useEffect(() => {
    /** clear existing timers if any */
    clearTimeout(refreshTokenTimer);

    /** only schedule if logged in */
    if (_authState.auth && _authState.expires) {
      const renewAt =
        TEST_REFRESH_TOKEN && env.identifier === 'local'
          ? /** refresh the token every 30 seconds to see what happens in console */
            addSeconds(new Date(), 30)
          : /** target renewal 1 minute before expected expiry time */
            addMinutes(
              typeof _authState.expires === 'string'
                ? parseISO(_authState.expires)
                : _authState.expires,
              -1
            );

      if (isFuture(renewAt)) {
        const difference_ms = differenceInMilliseconds(renewAt, new Date());

        /** save the value here as it may have changed by the time the timeout resolves */
        const pastSession = (() => _authState.session)();

        /** only ever have a single timer running */
        clearTimeout(refreshTokenTimer);
        refreshTokenTimer = setTimeout(() => {
          /** only refresh if still logged in with same session */
          if (_authState.auth && _authState.session === pastSession) {
            _executeRefreshToken();
          }
        }, difference_ms);
      }
    }
  }, [
    /** don't include _executeTokenRefresh to avoid infinite recursion */
    props.cookiesCx.session.expires,
  ]);

  /** run the following whenever token changes (i.e. after all login steps complete) */
  useEffect(() => {
    (async (): Promise<void> => {
      if (props.cookiesCx.session.auth && props.cookiesCx.session.token) {
        const result = await AuthService.getInstance().reassignOptions();
        _setReassignOptions(result);
        return;
      }

      /** default behaviour if not authorized */
      _setReassignOptions(DEFAULT.reassignOptions);
      return;
    })();
  }, [props.cookiesCx.session.token]);

  /** for syncing with CookiesContext for a *NEW* session */
  useEffect(() => {
    /** to be compared against authState (current) values */
    const nextState = props.cookiesCx.session;

    if (!nextState.auth) {
      /** don't resume if not authenticated */
      return;
    }

    const versMatch = getVersionMatch(nextState.version, env.version);
    if (!versMatch.build) {
      /** don't resume if the build version doesn't match */
      return;
    }

    const createSessionEvent = () => {
      SessionEventsService.postEvent({
        category: 'auth',
        tags: REWRITE_TO_LOGIN_EVENTS.includes(_authEvent)
          ? 'login'
          : _authEvent,
      });
    };

    if (_authState.auth && nextState.auth) {
      /** continuing to be logged in */
      if (VALID_CONTINUE_EVENTS.includes(_authEvent)) {
        createSessionEvent();
      } else {
        console.warn(
          `${CONTEXT_NAME}: continue expected one of ${VALID_CONTINUE_EVENTS.join(
            ', '
          )} but encountered ${_authEvent}`
        );
      }
    }

    if (!_authState.auth && nextState.auth) {
      /** just logged in (or resumed session) */
      if (VALID_START_EVENTS.includes(_authEvent)) {
        createSessionEvent();
      } else {
        console.warn(
          `${CONTEXT_NAME}: start expected one of ${VALID_START_EVENTS.join(
            ', '
          )} but encountered ${_authEvent}`
        );
      }
    }

    _setAuthState(nextState);
  }, [
    /** update authState and log auth session events whenever cookies change */
    props.cookiesCx.session,
  ]);

  /** for resetting the ws connection whenever token and/or wsConnect changes */
  useEffect(() => {
    (async (): Promise<void> => {
      if (props.cookiesCx.session.token !== _authState.token) {
        console.warn(
          `${CONTEXT_NAME}: WS reconnection skipped, mismatched tokens`
        );
        return;
      }

      /** always close any existing connection when triggered */
      const instance = WebSocketService.getInstance();

      if (instance) {
        /** wait for old connection to be closed */
        await instance.close();
      }

      /** only create a connection if authenticated with a valid session */
      if (_authState.auth && _authState.token) {
        console.debug({
          event: `${CONTEXT_NAME}: opening new WS connection`,
          cookieSession: props.cookiesCx.session.session,
          authSession: _authState.session,
        });
        WebSocketService.init(_authState.token);
      }
    })();
  }, [_wsCounter, _authState.token]);

  /** on launch, just check if server is up + what app version is expected */
  useEffect(() => {
    (async (): Promise<void> => {
      if (env.maintenance) {
        return;
      }

      // result implies whether the refresh interval (if any) should be cleared
      const getBuildVersionMismatch = async () => {
        /** ensure server is online */
        const result = await MainService.getInstance().checkServer();

        if (!result) {
          /** auto-logout, e.g. server is down for maintenance or the user's cookies are invalid */
          _executeLogout('server-offline');
          return false;
        }

        const versMatch = getVersionMatch(result.version, env.version);
        // no problem when build version still matches
        if (versMatch.build) {
          return false;
        }

        _notifyNewVersion(result.version, result.updated);
        return true;
      };

      // first run happens immediately
      const mismatched = await getBuildVersionMismatch();
      if (mismatched) {
        return;
      }

      // if no issues were detected, schedule recurring runs
      clearInterval(buildVersionInterval);
      buildVersionInterval = setInterval(async () => {
        const mismatched = await getBuildVersionMismatch();
        if (mismatched) {
          clearInterval(buildVersionInterval);
        }
      }, VERSION_CHECK_MS);
    })();
  }, []);

  /** attempt resume if possible */
  useEffect(() => {
    const callback = async () => {
      if (!isAuthenticated) {
        return;
      }

      if (!user) {
        return;
      }

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: env.integrations.auth0.audience,
        },
      });

      _loginAuth0(token, user);
    };

    callback();
  }, [isAuthenticated, getAccessTokenSilently, user?.sub]);

  // update automatically update i18n language when auth changes
  useEffect(() => {
    if (!_authState.language) {
      // default and reset behaviour
      if (i18n.language !== LanguageCode.English) {
        // only update if necessary
        i18n.changeLanguage(LanguageCode.English);
      }
      return;
    }

    const nextLanguage = env.enable.change_language
      ? _authState.language
      : LanguageCode.English;

    if (i18n.language !== nextLanguage) {
      i18n.changeLanguage(nextLanguage);
    }
  }, [_authState.language]);

  return (
    <AuthContext.Provider value={state}>
      {props.children}
      {_showTermsOfService && (
        <TermsOfServiceDialog
          logoutFn={_executeLogout}
          show={_showTermsOfService}
          onClose={_handleTerms}
        />
      )}
    </AuthContext.Provider>
  );
};
