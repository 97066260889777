import { DownloadIcon } from '@radix-ui/react-icons';
import { Flex, Heading, Spinner } from '@radix-ui/themes';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { ISessionEventsContext } from 'contexts/session-events.context';
import { t } from 'i18next';
import { IDisplayCol, ITablePageable } from 'interfaces/i-tables';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MPS_TO_MPH } from 'lib_ts/classes/math.utilities';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IRapsodoShot } from 'lib_ts/interfaces/training/i-rapsodo-shot';
import React from 'react';
import { SessionEventsService } from 'services/session-events.service';

interface IOptionsDict {
  PITCH_PitchType: string[];
}

interface IProps {
  sessionsCx: ISessionEventsContext;
}

interface IState {
  filterStrike: string[];
  filterPitchType: string[];

  disableNext: boolean;
  disablePrev: boolean;

  loading: boolean;

  data: IRapsodoShot[];
  filterOptions: IOptionsDict;

  fileURL: string;
}

const DEFAULT_STATE: IState = {
  filterStrike: [],
  filterPitchType: [],

  disableNext: false,
  disablePrev: false,

  loading: false,

  data: [],
  filterOptions: {
    PITCH_PitchType: [],
  },

  fileURL: '',
};

const PAGE_SIZE = 10;

const getOptions = (items: IRapsodoShot[]): IOptionsDict => {
  if (items) {
    return {
      PITCH_PitchType: ArrayHelper.unique(
        items.map((m) => m.PITCH_PitchType)
      ).sort((a: string, b: string) => a.localeCompare(b)),
    };
  } else {
    return DEFAULT_STATE.filterOptions;
  }
};

export class RapsodoShotsTable extends React.Component<IProps, IState> {
  private anchorNode?: HTMLAnchorElement;
  private tableNode?: CommonTable;

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.created',
      key: '_created',
      dataType: 'datetime',
    },
    {
      label: 'Strike',
      key: 'PITCH_Strike',
      formatFn: (m: IRapsodoShot) => {
        if (typeof m.PITCH_Strike === 'boolean') {
          return m.PITCH_Strike ? 'Strike' : 'Ball';
        }

        return t('common.no-data');
      },
    },
    {
      label: 'common.pitch-type',
      key: 'PITCH_PitchType',
      formatFn: (m: IRapsodoShot) => {
        return m.PITCH_PitchType ?? t('common.no-data');
      },
      sortRowsFn: (a: IRapsodoShot, b: IRapsodoShot, dir: number) =>
        -(a.PITCH_PitchType ?? '').localeCompare(b.PITCH_PitchType ?? '') * dir,
    },
    {
      label: 'common.speed',
      key: 'PITCH_Speed',
      subLabel: 'mph',
      formatFn: (shot: IRapsodoShot) =>
        isNaN(shot.PITCH_Speed)
          ? t('common.na')
          : (shot.PITCH_Speed * MPS_TO_MPH).toFixed(2),
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.sessionsCx.selectedSession !==
      this.props.sessionsCx.selectedSession
    ) {
      /** clear filters since options may change */
      this.setState(DEFAULT_STATE, this.resetTable);
      this.loadData();
    }
  }

  private loadData() {
    if (this.props.sessionsCx.selectedSession) {
      this.setState({ loading: true });
      SessionEventsService.getRapsodoShotsForSession(
        this.props.sessionsCx.selectedSession.session
      )
        .then((result) => {
          this.setState({
            data: result,
            filterOptions: getOptions(result),
          });
        })
        .finally(() => this.setState({ loading: false }));
    }
  }

  private resetTable() {
    if (this.tableNode) {
      this.tableNode.updatePage(0);
    }
  }

  private exportData(data: IRapsodoShot[]) {
    if (this.props.sessionsCx.selectedSession) {
      const whitelist = ['_id', '_created'];

      const blob = new Blob(
        [
          JSON.stringify(
            data,
            (key, value) => {
              if (key.startsWith('_') && !whitelist.includes(key)) {
                return undefined;
              } else {
                return value;
              }
            },
            2
          ),
        ],
        { type: 'application/json' }
      );

      this.setState(
        {
          fileURL: URL.createObjectURL(blob),
        },
        () => {
          if (this.anchorNode) {
            this.anchorNode.click();
          }
        }
      );
    }
  }

  render() {
    const session = this.props.sessionsCx.selectedSession
      ? this.props.sessionsCx.selectedSession.session
      : 'unknown';

    const loading = this.props.sessionsCx.loading || this.state.loading;

    const all = this.state.data;
    const filtered = all
      .filter(
        (m) =>
          this.state.filterStrike.length === 0 ||
          this.state.filterStrike.includes(m.PITCH_Strike ? 'Strike' : 'Ball')
      )
      .filter(
        (m) =>
          this.state.filterPitchType.length === 0 ||
          this.state.filterPitchType.includes(m.PITCH_PitchType)
      );

    /** sessions table */
    const pagination: ITablePageable = {
      total: filtered.length,
      enablePagination: true,
      pageSize: PAGE_SIZE,
    };

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
        <Spinner loading={loading}>
          <Heading size={RADIX.HEADING.SIZE.SM}>Rapsodo Shots</Heading>
        </Spinner>

        <CommonFormGrid columns={2}>
          <CommonSearchInput
            id="filter-strike"
            label="Strikes / Balls"
            options={['Strike', 'Ball'].map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterStrike}
            onChange={(v) => {
              this.setState({ filterStrike: v }, this.resetTable);
            }}
            multiple
          />
          <CommonSearchInput
            id="filter-pitch-type"
            label="Pitch Type"
            options={this.state.filterOptions.PITCH_PitchType.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterPitchType}
            onChange={(v) => {
              this.setState({ filterPitchType: v }, this.resetTable);
            }}
            multiple
          />
        </CommonFormGrid>

        <CommonTable
          ref={(elem) => (this.tableNode = elem as CommonTable)}
          id="SessionRapsodoShots"
          displayColumns={this.BASE_COLUMNS}
          displayData={filtered}
          toolbarContent={
            <Flex gap={RADIX.FLEX.GAP.SM}>
              <CommonTableButton
                icon={<DownloadIcon />}
                label="Export All"
                disabled={loading}
                onClick={() => this.exportData(all)}
              />

              {all.length !== filtered.length && (
                <CommonTableButton
                  icon={<DownloadIcon />}
                  label="Export Filtered"
                  disabled={loading}
                  onClick={() => this.exportData(filtered)}
                />
              )}
            </Flex>
          }
          {...pagination}
          enableSort
          vFlex
        />

        <a
          ref={(elem) => (this.anchorNode = elem as HTMLAnchorElement)}
          download={`session-${session}-rapsodo-shots.json`}
          href={this.state.fileURL}
          className="hidden"
        >
          Download
        </a>
      </Flex>
    );
  }
}
