import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import { Box, DataList, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CHART_COLORS } from 'enums/charts.enums';
import { t } from 'i18next';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IFireEvent,
  IFireEventData,
  IMSTargetEventData,
  ISessionEventDetails,
} from 'lib_ts/interfaces/i-session-event';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { SessionEventsService } from 'services/session-events.service';

const COMPONENT_NAME = 'VisualizeSessionDialog';

enum EventBucket {
  AUTH = 'AUTH',
  TRAINING = 'TRAINING',
  OTHER = 'OTHER',

  MSTARGET = 'MACHINE TARGET',
  FIRE = 'MACHINE FIRE',
}

interface IEventData extends ISessionEventDetails {
  index: number;
  bucket: EventBucket;
}

// mapping between session categories and chart categories
const getBucket = (event: ISessionEventDetails): EventBucket => {
  switch (event.category) {
    case 'auth': {
      return EventBucket.AUTH;
    }

    case 'machine': {
      switch (event.tags) {
        case 'mstarget,sent': {
          return EventBucket.MSTARGET;
        }

        case 'fire': {
          return EventBucket.FIRE;
        }

        case 'training': {
          return EventBucket.TRAINING;
        }

        default: {
          return EventBucket.OTHER;
        }
      }
    }

    default: {
      console.warn(
        `getBucket encountered ${event.category}, returning ${EventBucket.OTHER}`
      );
      return EventBucket.OTHER;
    }
  }
};

const SUCCESS_ICON = <CheckCircledIcon color={RADIX.COLOR.SUCCESS} />;
const FAILURE_ICON = <CrossCircledIcon color={RADIX.COLOR.DANGER} />;

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (!active) {
    return null;
  }

  if (!payload) {
    return null;
  }

  if (payload.length === 0) {
    return null;
  }

  const data: IEventData = payload[0].payload;

  if (!data) {
    return null;
  }

  const content = (() => {
    switch (data.bucket) {
      case EventBucket.AUTH: {
        return (
          <>
            <pre>{JSON.stringify(data, ['tags', 'machine', 'user'], 2)}</pre>
          </>
        );
      }

      case EventBucket.MSTARGET: {
        const subdata = data.data as Partial<IMSTargetEventData>;
        if (!subdata) {
          return <p>{t('common.no-data')}</p>;
        }

        return (
          <>
            <pre>{JSON.stringify(subdata.ms, null, 2)}</pre>
          </>
        );
      }

      case EventBucket.FIRE: {
        const _data = data as unknown as IFireEvent;
        const subdata = data.data as IFireEventData;
        if (!subdata) {
          return <p>{t('common.no-data')}</p>;
        }

        return (
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            <DataList.Root>
              {subdata.training_mode !== TrainingMode.Manual && (
                <DataList.Item>
                  <DataList.Label>MSBS</DataList.Label>
                  <DataList.Value>
                    {_data.msbs ? SUCCESS_ICON : FAILURE_ICON}
                  </DataList.Value>
                </DataList.Item>
              )}
              {subdata.training_mode === TrainingMode.Manual && (
                <DataList.Item>
                  <DataList.Label>Manual</DataList.Label>
                  <DataList.Value>
                    {_data.manual ? SUCCESS_ICON : FAILURE_ICON}
                  </DataList.Value>
                </DataList.Item>
              )}
              <DataList.Item>
                <DataList.Label>Rapsodo</DataList.Label>
                <DataList.Value>
                  {_data.rapsodo ? SUCCESS_ICON : FAILURE_ICON}
                </DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Shot</DataList.Label>
                <DataList.Value>
                  {_data.shot ? SUCCESS_ICON : FAILURE_ICON}
                </DataList.Value>
              </DataList.Item>
            </DataList.Root>

            <Box>
              <pre>{JSON.stringify(subdata, null, 2)}</pre>
            </Box>
          </Flex>
        );
      }

      case EventBucket.TRAINING: {
        const subdata = data.data;
        if (!subdata) {
          return <p>{t('common.no-data')}</p>;
        }

        return (
          <>
            <pre>{JSON.stringify(subdata, null, 2)}</pre>
          </>
        );
      }

      default: {
        return <pre>{JSON.stringify(data, null, 2)}</pre>;
      }
    }
  })();

  return (
    <div className="custom-tooltip">
      <h6>
        {data.bucket} @ {data._created}
      </h6>
      <div>{content}</div>
    </div>
  );
};

interface IProps {
  session: string;
}

interface IState {
  chartEvents?: IEventData[];
  chronoEvents?: IEventData[];
}

export class SessionVisualizer extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.loadData = this.loadData.bind(this);
    this.summarizeEvent = this.summarizeEvent.bind(this);
  }

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.session !== this.props.session) {
      // detect changes in session and reload data accordingly
      this.loadData();
    }
  }

  private async loadData() {
    // any chart DOM elements will be removed
    this.setState({
      chartEvents: undefined,
      chronoEvents: undefined,
    });

    const results = await SessionEventsService.getEventsForSession(
      this.props.session
    );

    if (!results || results.length === 0) {
      NotifyHelper.warning({
        message_md: `Failed to find any events for session \`${this.props.session}\`.`,
      });
      return;
    }

    const events = results
      .sort((a, b) => a._created.localeCompare(b._created))
      .map((ev, i) => this.summarizeEvent(ev, i));

    this.setState({
      chartEvents: [...events].sort(
        (a, b) => a.bucket.localeCompare(b.bucket) * -1
      ),
      chronoEvents: [...events].sort((a, b) =>
        a._created.localeCompare(b._created)
      ),
    });
  }

  private summarizeEvent(ev: ISessionEventDetails, index: number): IEventData {
    const output: IEventData = {
      ...ev,
      index: index,
      bucket: getBucket(ev),
    };

    return output;
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
          {!this.state.chartEvents && <p>Please wait...</p>}

          {this.state.chartEvents && (
            <ResponsiveContainer width="100%" height={800}>
              <ScatterChart
                margin={{
                  left: 40,
                  right: 20,
                  top: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />

                <XAxis dataKey="index" type="number" name="Index" />

                <YAxis dataKey="bucket" type="category" name="Bucket" />

                <Tooltip
                  cursor={{ strokeDasharray: '3 3' }}
                  content={<CustomTooltip />}
                  animationDuration={0}
                />

                <Legend />

                <Scatter
                  name="Session Events Over Time"
                  data={this.state.chartEvents}
                  fill={CHART_COLORS[3]}
                />
              </ScatterChart>
            </ResponsiveContainer>
          )}

          {this.state.chronoEvents && (
            <CommonDetails summary="Raw data">
              <pre>{JSON.stringify(this.state.chronoEvents, null, 2)}</pre>
            </CommonDetails>
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
