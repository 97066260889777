import { Box } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { IVideoSelectionProps } from 'components/common/dialogs/video-selection';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { OPTION_DIVIDER_ID } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import {
  PITCH_TYPE_OPTIONS,
  PitcherHand,
  PitchType,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IVideoOption } from 'lib_ts/interfaces/i-video';
import React from 'react';
import { CommonDetails } from '../../details';
import { CommonFormGrid } from '../../form/grid';

export interface IVideoSelectionFormProps extends IVideoSelectionProps {
  /** video options to select from, e.g. pre-filtered by release side */
  options: IVideoOption[];
}

interface IFilters {
  /** cascades to pitcher */
  team?: string;

  /** cascades from teams */
  pitcher?: string;

  type?: PitchType;

  /** px <= 0 => RHP, else LHP */
  releaseSide?: PitcherHand;

  /** feet */
  releaseHeightMin?: number;

  /** feet */
  releaseHeightMax?: number;
}

interface IState {
  /** initially set from props, updated by dropdown, passed back to parent via handleChange */
  video_id?: string;

  filters: Partial<IFilters>;

  /** subset of options provided in props, based on filter criteria */
  filteredOptions: IVideoOption[];
}

export class VideoSelectionForm extends React.Component<
  IVideoSelectionFormProps,
  IState
> {
  constructor(props: IVideoSelectionFormProps) {
    super(props);

    this.state = {
      video_id: props.video_id,
      filteredOptions: props.options,
      filters: {},
    };

    this.setFilters = this.setFilters.bind(this);
    this.getTeamOptions = this.getTeamOptions.bind(this);
    this.getPitcherOptions = this.getPitcherOptions.bind(this);
    this.performFiltering = this.performFiltering.bind(this);
  }

  componentDidUpdate(
    _: Readonly<IVideoSelectionFormProps>,
    prevState: Readonly<IState>
  ): void {
    if (prevState.video_id !== this.state.video_id) {
      // automatically pass new video_id value back to parent
      this.props.handleChange(this.state.video_id);
    }

    if (prevState.filters !== this.state.filters) {
      this.performFiltering();
    }
  }

  private setFilters(value: Partial<IFilters>) {
    this.setState({
      filters: {
        ...this.state.filters,
        ...value,
      },
    });
  }

  private getTeamOptions(): IOption[] {
    const unique: string[] = ArrayHelper.unique(
      this.props.options.map((vo) => vo.TeamName ?? '')
    ).sort((a: string, b: string) => a.localeCompare(b));

    return unique.map((value) => {
      const o: IOption = {
        label: value,
        value: value,
      };
      return o;
    });
  }

  private getPitcherOptions(): IOption[] {
    const filtered = this.props.options.filter(
      (vo) =>
        !this.state.filters.team || vo.TeamName === this.state.filters.team
    );

    const dict: { [team: string]: string[] } = {};

    filtered.forEach((p) => {
      if (!p.PitcherFullName) {
        return;
      }

      const teamKey = p.TeamName ?? '';

      if (!dict[teamKey]) {
        dict[teamKey] = [];
      }

      dict[teamKey].push(p.PitcherFullName);
    });

    return Object.keys(dict).flatMap((team) => {
      const players = ArrayHelper.unique(dict[team]);

      return players.map((p) => {
        const o: IOption = {
          group: team,
          label: p,
          value: p,
        };

        return o;
      });
    });
  }

  /** performs multiple filter-related tasks, including:
   * - cascading filter options as necessary
   * - updating filteredOptions on-demand, rather than constantly within render()
   * */
  private performFiltering(): void {
    /** start with all options */
    let result = this.props.options;

    if (this.state.filters.team) {
      result = result.filter(
        (vo) =>
          vo.value === OPTION_DIVIDER_ID ||
          vo.TeamName === this.state.filters.team
      );
    }

    if (this.state.filters.pitcher) {
      result = result.filter(
        (vo) =>
          vo.value === OPTION_DIVIDER_ID ||
          vo.PitcherFullName === this.state.filters.pitcher
      );
    }

    if (this.state.filters.type) {
      result = result.filter(
        (vo) =>
          vo.value === OPTION_DIVIDER_ID ||
          vo.PitchType === this.state.filters.type
      );
    }

    if (this.state.filters.releaseSide) {
      result = result.filter(
        (vo) =>
          vo.ReleaseSide !== undefined &&
          (this.state.filters.releaseSide === PitcherHand.RHP
            ? vo.ReleaseSide <= 0
            : vo.ReleaseSide > 0)
      );
    }

    if (this.state.filters.releaseHeightMin !== undefined) {
      const min = this.state.filters.releaseHeightMin;
      result = result.filter(
        (vo) =>
          vo.value === OPTION_DIVIDER_ID ||
          (vo.ReleaseHeight !== undefined && vo.ReleaseHeight >= min)
      );
    }

    if (this.state.filters.releaseHeightMax !== undefined) {
      const max = this.state.filters.releaseHeightMax;
      result = result.filter(
        (vo) =>
          vo.value === OPTION_DIVIDER_ID ||
          (vo.ReleaseHeight !== undefined && vo.ReleaseHeight <= max)
      );
    }

    // check if the existing selection is still available (e.g. for automatically clearing no-longer-valid selections)
    const selectionFound = this.state.video_id
      ? result.findIndex((vo) => vo._id === this.state.video_id) !== -1
      : false;

    this.setState({
      // reset the video_id if it's not a valid option anymore, given filters
      video_id: selectionFound ? this.state.video_id : undefined,
      filteredOptions: result,
    });
  }

  render() {
    if (!this.props.options || this.props.options.length === 0) {
      return (
        <CommonCallout
          color={RADIX.COLOR.INFO}
          text="Provide some videos to use them here."
        />
      );
    }

    return (
      <CommonFormGrid columns={3}>
        <CommonSearchInput
          id="video-selection-team"
          name="filters.team"
          label="common.team"
          values={this.state.filters.team ? [this.state.filters.team] : []}
          onChange={(v) =>
            this.setFilters({
              team: v[0],
              pitcher: undefined,
            })
          }
          options={this.getTeamOptions()}
          optional
        />
        <CommonSearchInput
          id="video-selection-pitcher"
          name="filters.pitcher"
          label="common.pitcher"
          values={
            this.state.filters.pitcher ? [this.state.filters.pitcher] : []
          }
          onChange={(v) => this.setFilters({ pitcher: v[0] })}
          options={this.getPitcherOptions()}
          optional
        />
        <CommonSearchInput
          id="video-selection-type"
          name="filters.type"
          label="common.pitch-type"
          values={this.state.filters.type ? [this.state.filters.type] : []}
          onChange={(v) => this.setFilters({ type: v[0] as PitchType })}
          options={PITCH_TYPE_OPTIONS}
          optional
        />

        <Box gridColumn="span 3">
          <CommonDetails summary="common.more-filters">
            <CommonFormGrid columns={3}>
              <CommonSelectInput
                id="video-selection-side"
                name="filters.releaseSide"
                label="pd.release-side-short"
                value={this.state.filters.releaseSide}
                onChange={(v) =>
                  this.setFilters({ releaseSide: v as PitcherHand })
                }
                options={Object.values(PitcherHand).map((o) => ({
                  label: o,
                  value: o,
                }))}
                optional
              />
              <CommonTextInput
                id="video-selection-height"
                label={`${t('pd.release-height-short')} (ft)`}
                type="number"
                placeholder={t('common.min').toString()}
                value={this.state.filters.releaseHeightMin?.toString()}
                onNumericChange={(v) => {
                  this.setFilters({
                    releaseHeightMin: v,
                  });
                }}
              />
              <CommonTextInput
                id="video-selection-max-height"
                label=" "
                type="number"
                placeholder={t('common.max').toString()}
                value={this.state.filters.releaseHeightMax?.toString()}
                onNumericChange={(v) => {
                  this.setFilters({
                    releaseHeightMax: v,
                  });
                }}
              />
            </CommonFormGrid>
          </CommonDetails>
        </Box>

        <Box gridColumn="span 3">
          <CommonSearchInput
            id="video-selection-videoID"
            name="video_id"
            label="videos.video"
            values={this.state.video_id ? [this.state.video_id] : []}
            onChange={(v) => this.setState({ video_id: v[0] })}
            options={this.state.filteredOptions}
            optional
          />
        </Box>
      </CommonFormGrid>
    );
  }
}
