import { CommonCallout } from 'components/common/callouts';
import { SettingsSection } from 'components/common/settings-dialog';
import { MachineSettingsTabKey } from 'components/common/settings-dialog/main-sections/machine.section';
import { SessionEventsContext } from 'contexts/session-events.context';
import { TrainingContext } from 'contexts/training.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useState } from 'react';

interface IProps {
  testing?: boolean;

  // side-effect when user clicks on open settings, e.g. hide training
  onOpenSettings?: () => void;
}

export const DetectionFailedCallout = (props: IProps) => {
  const [testing, setTesting] = useState(!!props.testing);

  return (
    <TrainingContext.Consumer>
      {(trainingCx) => {
        if (!testing && !trainingCx.getFinalMsg()?.detectionFailed) {
          // not testing and there's no detection failed
          return <></>;
        }

        return (
          <SessionEventsContext.Consumer>
            {(sessionsCx) => (
              <CommonCallout
                color={RADIX.COLOR.WARNING}
                header="No Pitch Detected"
                text="We were unable to detect the pitch that was just fired. Please check that your tracking device is configured correctly."
                actions={[
                  {
                    label: 'Open Settings',
                    onClick: () => {
                      setTesting(false);
                      props.onOpenSettings?.();

                      sessionsCx.setSettingsDialog({
                        initialSection: SettingsSection.Machine,
                        sections: [SettingsSection.Machine],
                        initialTab: MachineSettingsTabKey.TrackingDevice,
                      });
                      trainingCx.resetMsgs();
                    },
                  },
                  {
                    label: 'Dismiss',
                    onClick: () => {
                      setTesting(false);
                      trainingCx.resetMsgs();
                    },
                  },
                ]}
              />
            )}
          </SessionEventsContext.Consumer>
        );
      }}
    </TrainingContext.Consumer>
  );
};
