import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IAuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IAdminMachine, IMachine } from 'lib_ts/interfaces/i-machine';
import { IMachineDetails } from 'lib_ts/interfaces/i-machine-details';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';

interface IOptionsDict {
  _created: string[];
  firmware: string[];
}

export interface IMachinesContext {
  machineModels: IMachineModel[];
  machines: IAdminMachine[];
  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly create: (
    payload: Partial<IMachine>
  ) => Promise<IMachine | undefined>;
  readonly update: (
    payload: Partial<IMachine>
  ) => Promise<IMachine | undefined>;

  /** mostly for tracking checkbox values from tables */
  readonly set: (machines: IAdminMachine[]) => void;

  /** set by getDetails */
  details: IMachineDetails;

  /** sets details on context to be used */
  readonly loadDetails: (machineID: string) => Promise<void>;
}

const DEFAULT: IMachinesContext = {
  machineModels: [],
  machines: [],

  options: {
    _created: [],
    firmware: [],
  },

  loading: false,

  refresh: () => console.debug('not init'),
  create: async () => new Promise(() => console.debug('not init')),
  update: async () => new Promise(() => console.debug('not init')),

  set: () => console.debug('not init'),

  details: {
    machineID: 'none',
    calibrated: false,
    connected: false,
    waiting: [],
  },

  loadDetails: async () => new Promise(() => console.debug('not init')),
};

const getOptions = (data: IAdminMachine[]): IOptionsDict => {
  if (data) {
    return {
      _created: ArrayHelper.unique(
        data.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ).sort((a: string, b: string) => a.localeCompare(b)),

      firmware: ArrayHelper.unique(
        data.map((m) => m.last_firmware?.split(' ')[0] as string)
      ).sort((a: string, b: string) => a.localeCompare(b)),
    };
  } else {
    return DEFAULT.options;
  }
};

export const MachinesContext = createContext(DEFAULT);

interface IProps {
  authCx: IAuthContext;
  children: ReactNode;
}

export const MachinesProvider: FC<IProps> = (props) => {
  const [_lastFetched, _setLastFetched] = useState<Date | undefined>(undefined);
  const [_machineModels, _setMachineModels] = useState(DEFAULT.machineModels);

  const [_machines, _setMachines] = useState(DEFAULT.machines);

  const [_options, _setOptions] = useState(getOptions(DEFAULT.machines));
  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_details, _setDetails] = useState(DEFAULT.details);

  const state: IMachinesContext = {
    machineModels: _machineModels,
    machines: _machines,

    options: _options,

    loading: _loading,

    refresh: () => {
      _setLastFetched(new Date());
    },

    set: (machines) => {
      _setMachines(machines);
    },

    details: _details,

    loadDetails: (machineID) => {
      NotifyHelper.info({
        message_md: `Retrieving details for \`${machineID}\`, please wait...`,
      });

      _setLoading(true);
      return AdminMachinesService.getInstance()
        .getMachineDetails(machineID)
        .then((results) => _setDetails(results))
        .catch((error) => {
          console.error(error);
          NotifyHelper.warning({
            message_md: `Details for \`${machineID}\` could not be retrieved at this time. Please try again later.`,
          });
        })
        .finally(() => _setLoading(false));
    },

    create: async (payload) => {
      try {
        _setLoading(true);

        const result = await AdminMachinesService.getInstance().create(payload);

        const newMachines = [
          ..._machines,
          /** append to end of list */
          {
            ...result,
            connected: false,
            calibrated: false,
            waiting: [],
          },
        ];

        _setMachines(newMachines);

        NotifyHelper.success({
          message_md: `\`${result.machineID}\` created!`,
        });

        return result;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: 'There was a problem creating your machine.',
        });

        return undefined;
      } finally {
        _setLoading(false);
      }
    },

    update: async (payload) => {
      try {
        _setLoading(true);

        const result = await AdminMachinesService.getInstance().update(payload);

        if (result) {
          /** success */
          const current = [..._machines];
          const index = current.findIndex((v) => v._id === result._id);
          if (index !== -1) {
            const existing = current[index];
            /** replace current context value with updated result */
            current.splice(index, 1, {
              ...existing,
              ...result,
            });
          } else {
            /** append to end */
            current.push({
              connected: false,
              calibrated: false,
              waiting: [],
              ...result,
            });
          }

          _setMachines(current);
        }

        return result;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: 'There was a problem updating the machine.',
        });

        return undefined;
      } finally {
        _setLoading(false);
      }
    },
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    if (!_lastFetched) {
      return;
    }

    (async (): Promise<void> => {
      _setLoading(true);

      const instance = AdminMachinesService.getInstance();

      const [models, machines, summaries] = await Promise.all([
        instance.getActiveModels(),
        instance.getMachines(true),
        instance.getMachinesSummary(),
      ]);

      _setMachineModels(models.sort((a, b) => a.name.localeCompare(b.name)));

      const newMachines = machines
        .sort((a, b) => (a.machineID ?? '').localeCompare(b.machineID ?? ''))
        .map((m) => {
          const mQueue = summaries.machines.find(
            (ms) => ms.machineID === m.machineID
          );
          const machine: IAdminMachine = {
            ...m,
            active: mQueue ? mQueue.active : undefined,
            waiting: mQueue ? mQueue.waiting : [],
            connected: mQueue ? mQueue.connected : false,
            connect_date: mQueue ? mQueue.connect_date : undefined,
            calibrated: mQueue ? mQueue.calibrated : false,
            disconnect_date: mQueue ? mQueue.disconnect_date : undefined,
          };

          return machine;
        });

      _setMachines(newMachines);
      _setLoading(false);
    })();
  }, [_lastFetched]);

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh */
    _setLastFetched(new Date());
  }, [props.authCx.current.session]);

  /** update options whenever machines changes */
  useEffect(() => {
    _setOptions(getOptions(_machines));
  }, [_machines]);

  return (
    <MachinesContext.Provider value={state}>
      {props.children}
    </MachinesContext.Provider>
  );
};
