import { Box, Button, Flex, Heading } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import { lightFormat } from 'date-fns';
import { AuthEvent } from 'enums/auth';
import { IFullDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import TERMS_OF_SERVICE_MD from './content.md';

const COMPONENT_NAME = 'TermsOfServiceDialog';

interface IProps {
  show: boolean;
  onClose: (changed: boolean) => void;
  logoutFn: (reason: AuthEvent, silent?: boolean) => void;
}

interface IState {
  loggedOut: boolean;
  content: string;
}

export class TermsOfServiceDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loggedOut: false,
      content: 'Please wait...',
    };
  }

  componentDidMount(): void {
    /** workaround for loading markdown text from a static file */
    fetch(TERMS_OF_SERVICE_MD)
      .then((response) => response.text())
      .then((text) => this.setState({ content: text }));
  }

  render() {
    if (this.state.loggedOut) {
      return;
    }

    const lastUpdated = new Date(env.termsRevisionDate);

    const DEFAULT_PROPS: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.XL,
      vFlexHeight: RADIX.DIALOG.HEIGHT.LG,
      title: 'Terms of Service',
      content: (
        <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION} pr="2">
          <Heading size={RADIX.HEADING.SIZE.SM}>
            Updated: {lightFormat(lastUpdated, 'yyyy-MM-dd')}
          </Heading>
          <Box className={RADIX.VFLEX.GROW}>
            <ReactMarkdown children={this.state.content} />
          </Box>
          <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
            <Button
              color={RADIX.COLOR.SUCCESS}
              onClick={() => this.props.onClose(true)}
            >
              Accept
            </Button>

            <AuthContext.Consumer>
              {(authCx) => (
                <Button
                  color={RADIX.COLOR.DANGER}
                  onClick={() => {
                    this.setState({ loggedOut: true });
                    authCx.logout();
                  }}
                >
                  Logout
                </Button>
              )}
            </AuthContext.Consumer>
          </Flex>
        </Flex>
      ),
      onClose: () => this.props.onClose(false),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...DEFAULT_PROPS} />
      </ErrorBoundary>
    );
  }
}
