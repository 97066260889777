import * as Toast from '@radix-ui/react-toast';
import { COLORS, getShape, NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonToast } from 'components/common/toast';
import { ICookiesContext } from 'contexts/cookies.context';
import { IInboxContext } from 'contexts/inbox';
import { IMachineContext } from 'contexts/machine.context';
import { INotification, INotificationButton } from 'interfaces/i-notification';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { IAnnouncement } from 'lib_ts/interfaces/common/i-announcement';
import { IUserErrorMsg } from 'lib_ts/interfaces/machine-msg/i-error';
import React from 'react';
import Confetti from 'react-confetti';
import { SessionEventsService } from 'services/session-events.service';
import { v4 } from 'uuid';
import './index.scss';

const RESTART_LABEL = 'common.restart';

interface IProps {
  cookiesCx: ICookiesContext;
  inboxCx: IInboxContext;
  machineCx?: IMachineContext;
}

interface IState {
  notifications: INotification[];
  fun: boolean;
}

export class NotificationListener extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      notifications: [],
      fun: false,
    };

    this.handleAnnouncement = this.handleAnnouncement.bind(this);
    this.handleDispatch = this.handleDispatch.bind(this);
    this.handleFun = this.handleFun.bind(this);
    this.handleUserError = this.handleUserError.bind(this);
  }

  componentDidMount() {
    NotifyHelper.onToast(this.handleDispatch);
    WebSocketHelper.on(WsMsgType.Misc_Error, this.handleUserError);
    WebSocketHelper.on(WsMsgType.S2U_Announcement, this.handleAnnouncement);

    NotifyHelper.onFun(this.handleFun);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.cookiesCx.session !== this.props.cookiesCx.session) {
      this.props.inboxCx.clear();
    }
  }

  componentWillUnmount() {
    NotifyHelper.removeToast(this.handleDispatch);
    WebSocketHelper.remove(WsMsgType.Misc_Error, this.handleUserError);
    WebSocketHelper.remove(WsMsgType.S2U_Announcement, this.handleAnnouncement);

    NotifyHelper.removeFun(this.handleFun);
  }

  private handleDispatch(event: CustomEvent) {
    try {
      const data: INotification = {
        ...event.detail,
        id: v4(),
      };

      if (data.show_restart) {
        const restartBtn: INotificationButton = {
          label: RESTART_LABEL,
          dismissAfterClick: true,
          onClick: () => this.props.machineCx?.restartArc('errormsg'),
        };

        if (!data.buttons) {
          data.buttons = [restartBtn];
        } else if (data.buttons.findIndex((b) => b.label) === -1) {
          data.buttons.push(restartBtn);
        }
      }

      if (data.inbox) {
        this.props.inboxCx.add(data);
      }

      this.state.notifications.push(data);

      this.setState({
        notifications: this.state.notifications.filter((n) => !n.closed),
      });
    } catch (e) {
      console.error('NotifyListener caught an error in handleToast', e);
    }
  }

  private handleAnnouncement(model: { detail: IAnnouncement }) {
    NotifyHelper.announce(model.detail);
  }

  // routes to this.handleDispatch
  private handleUserError(model: { detail: IUserErrorMsg }) {
    try {
      SessionEventsService.postEvent({
        category: 'machine',
        tags: 'errormsg',
        data: model.detail,
      });

      const type = model.detail.type;

      if (!type.user_message || typeof type.user_message !== 'string') {
        console.error({
          event: 'Invalid machine error-types data',
          type: type,
        });

        NotifyHelper.error({
          message_md:
            'Received machine error with invalid data. Check console.',
          delay_ms: 0,
          inbox: true,
        });
        return;
      }

      NotifyHelper.userError(type);
    } catch (e) {
      console.error('NotifyListener caught an error in handleErrorMsg', e);
    }
  }

  private handleFun() {
    if (!this.state.fun) {
      this.setState({ fun: true });
    }
  }

  render() {
    return (
      <ErrorBoundary componentName="NotifyListener">
        {/* provide it here to ensure it exists where machine context is defined */}
        <Toast.Provider swipeDirection="right" duration={60_000}>
          {this.state.notifications
            .filter((n) => !n.closed)
            .map((n) => (
              <CommonToast key={n.id} config={n} />
            ))}
          <Toast.Viewport className="ToastViewport top-right" />
        </Toast.Provider>

        {this.state.fun && (
          <Confetti
            colors={COLORS}
            drawShape={getShape}
            recycle={false}
            onConfettiComplete={() => this.setState({ fun: false })}
            style={{
              // draw above dialogs
              zIndex: 2000,
            }}
          />
        )}
      </ErrorBoundary>
    );
  }
}
